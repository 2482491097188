/* © 2014 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, June 19, 2014
 * For information or permission request, email info@aetonixsystems.com
 */

"use strict";

var Store = require("./store");
var map = require("fj-map");
var par = require("par");

import * as Sentry from '@sentry/react';

module.exports = UserInactiveStore;

function UserInactiveStore(api, events, config) {
	var store = new Store();

	store.newUserInactive = newUserInactive;

	store.raw_get = store.get;
	store.get = get;

	var tracking = [];

	refresh();

	return store;

	function refresh() {
		// Store holds a list of group users' inactivity status
		api.org.users.inactive.list()
			.then(function (userdata) {
				setStatus(userdata);
				var users = Object.keys(userdata);
				users.map(function (user) {
					listen_for_changes(user);
				});
			})
			.catch(function(e) {
				Sentry.captureException(e);
				store.error(e);
				console.error(e);
			});
	}
	function get(id) {
		if (!id) return;
		var existing = store.raw_get(id);
		if (typeof existing === "boolean" && existing !== undefined) return existing;

		if (!isTracking(id))
			newUserInactive(id);

		return {
			loading: true
		};
	}

	function setStatus(users) {
		store.setAll(users);
	}

	function listen_for_changes(user) {
		tracking.push(user);
		var orgGroup = config.orgGroup;
		if(!orgGroup) return;
		events.on("mqtt:orggroup/" + orgGroup + "/u/" + user + "/inactive/updated", par(setInactive, user));
	}

	function setInactive(id, data) {
		store.set(id, data.inactive);
	}

	function newUserInactive(id) {
		api.org.users.inactive.status(id)
			.then(par(setInactive, id))
			.catch(error => {
				Sentry.captureException(error);
				store.error(error);
			})
			.then(listen_for_changes(id));
	}

	function isTracking(id) {
		return tracking.indexOf(id) !== -1;
	}

}
