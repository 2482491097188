/* © 2014 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, June 19, 2014
 * For information or permission request, email info@aetonixsystems.com
 */

"use strict";

var Store = require("./store");
var par = require("par");

module.exports = UserContactStore;

function UserContactStore(api, events, config) {
	var store = new Store();

	store.raw_get = store.get;
	store.get = get;

	var tracking = [];

	return store;

	function get(id) {
		if (!id) return;
		var existing = store.raw_get(id);
		if (existing) return existing;

		if (!isTracking(id))
			track(id);

		return [];
	}

	function isTracking(id) {
		return tracking.indexOf(id) !== -1;
	}

	function track(id) {
		tracking.push(id);

		api.org.users.contacts.list(null, null, id)
			.then(par(setContacts, id));
		var orgGroup = config.orgGroup;
		if(!orgGroup) return;
		events.on("mqtt:orggroup/" + orgGroup + "/u/" + id + "/contacts/added", par(addContact, id));
		events.on("mqtt:orggroup/" + orgGroup + "/u/" + id + "/contacts/removed", par(removeContact, id));
	}

	function addContact(id, data) {
		var who = data.user;
		var existing = store._get(id) || [];
		store.set(id, existing.concat(who));
	}

	function removeContact(id, data) {
		var who = data.user;
		var existing = store._get(id) || [];
		store.set(id, existing.filter(function (contact) {
			return contact !== who;
		}));
	}

	function setContacts(id, data) {
		store.set(id, data);
	}
}
