/* © 2014 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, June 19, 2014
 * For information or permission request, email info@aetonixsystems.com
 */

var Store = require("./store");
var xtend = require("xtend");

var makeStore = require("../shared/make_store");

var UsersAllStore = require("./users_bystatus_store");
var UsersStatusStore = require("./user_status_store");
var UsersLatestStore = require("./user_observations_latest_store");
var UsersManagedStore = require("./user_managed_store");
var UsersNoteStore = require("./user_core_store");
const isEmpty = require ('lodash/isEmpty');

var VITAL_COLUMNS = [
	"bloodoxygen",
	"bloodoxygen",
	"bodytemperature",
	"bloodsugar",
	"weight",
	"bloodpressure",
	"bloodpressure",
	"activity",
	"note"
]

module.exports = UserStore;

function UserStore(api, events, config) {
	var store = new Store();

	var userLocalStorageKey = localStorage.getItem("userLocalStorageKey");
	var type = localStorage.getItem(userLocalStorageKey + "listType") || "all";

	var map = new Map();
	var UsersStore = makeStore(UsersAllStore)(api, events, config);
	var UsersManaged = makeStore(UsersManagedStore)(api, events, config);
	var UsersStatus = makeStore(UsersStatusStore)(api, events, config);
	var UsersLatest = makeStore(UsersLatestStore)(api, events, config);
	var UsersNote = makeStore(UsersNoteStore)(api, events, config);

	var Users = UsersStore;
	if(type === "managed")
		Users = UsersManaged;
	initUsers();
	UsersStatus.listen(handle_status_update);
	UsersLatest.listen(handle_observation_update);
	UsersNote.listen(handle_note_update);

	var userStore = Users.store;
	var userStatusStore = UsersStatus.store;
	var userObservationStore = UsersLatest.store;
	var userNoteStore = UsersNote.store;

	trackUsers();

	store.more = userStore.more || noop;
	store.toggleType = changeUsers;
	store.updateActivity = updateActivity;
	store.updateManageGroups = updateManageGroups;
	store.search = search;
	store.sort = sort;

	return store;

	function noop(){}

	function changeUsers(){
		var activityState = Users.store.activity;
		const manageGroups = Users.store.manageGroups;
		var query = Users.store.query;
		map.clear();
		if(type === "all"){
			type = "managed";
			Users = UsersManaged;
		} else if (type === "managed"){
			type = "all";
			Users = UsersStore;
		}
		Users.store.activity = activityState;
		Users.store.manageGroups = manageGroups;
		if (query) {
			search(query);
		} else {
			Users.store.reset();
		}
		store.more = Users.store.more;
		initUsers();
	}

	function updateActivity(value){
		var changeActivity = Users.store.changeActivity || noop;
		map.clear();
		return changeActivity(value);
	}

	function updateManageGroups(value){
		var changeManageGroups = Users.store.changeManageGroups || noop;
		map.clear();
		return changeManageGroups(value);
	}

	function initUsers(){
		Users.listen(handle_user_update);
	}

	function search(query) {
		var usersStoreSearch = Users.store.search || noop;
		map.clear();
		return usersStoreSearch(query);
	}

	function sort(sortQuery,sortServer) {
		if(sortServer) {
			var usersStoreSort = Users.store.sort || noop;
			map.clear();
			return usersStoreSort(sortQuery);
		} else {
			store.set("users", sortMapBy(map, sortQuery));
		}
	}

	function handle_note_update(data) {
		map.forEach(function(value, key){
			value = value || {};
			var latest = data.get(key) || [];
			var note = {
				note: latest[0]
			};
			var update = xtend(value, note);
			map.set(key, update);
		});
		store.set("users", sortMap(map));
	}

	function handle_user_update(data){
		var allUsers = data.all();
		userObservationStore.addUsers(allUsers);
		allUsers.forEach(function(user){
			var current = map.get(user) || {};
			var status = userStatusStore.get(user) || {};
			var note = userNoteStore.get(user) || [];
			var update = xtend(current, {
				current_status: status.current_status ?? -1,
				indicator_status: accumulateStatus(status.indicator_status || {}),
				workflow_status: accumulateStatus(status.status || {}),
				note: note
			});
			map.set(user, update);
		});
		store.set("users", sortMap(map));
	}

	function handle_status_update(data){
		map.forEach(function(value, key){
			value = value || {};
			var status = data.get(key);
			var current_status = status.current_status;
			if(!(current_status || current_status === 0)) current_status = -1;
			var update = xtend(value, {
				current_status: current_status,
				indicator_status: accumulateStatus(status.indicator_status || {}),
				workflow_status: accumulateStatus(status.status || {}),
				workflow_last_updated_at: getLastUpdatedAt(status.status || {})
			});
			map.set(key, update);
		});
		store.set("users", sortMap(map));
	}

	function handle_observation_update(data){
		map.forEach(function(value, key){
			value = value || {};
			var latest = data.get(key) || {};
			var update = xtend(value, latest);
			map.set(key, update);
		});
		store.set("users", sortMap(map));
	}

	function trackUsers(){
		var orgGroup = config.orgGroup;
		if(!orgGroup) return;
		events.on("mqtt:orggroup/" + orgGroup + "/users/status/change", handle_user_status_update);
	}

	function handle_user_status_update(data){
		var user = data.user;
		userStore.trackUser(user);
	}
}

function sortMap(map){
	return new Map([...map].sort(sortArr));
}

function sortMapBy(map, sortQuery){
	return new Map([...map].sort((a,b)=>{
		var aKey = a[1];
		var bKey = b[1];

		var order = sortQuery.slice(0,1)
		var columnName =sortQuery.slice(1)

		if(columnName==="workflow_status" || columnName==="current_status") {

			var statusA = typeof(aKey[columnName]) !== 'number' && isEmpty(aKey[columnName]) ? -1 : aKey[columnName]
			var statusB = typeof(bKey[columnName]) !== 'number' && isEmpty(bKey[columnName]) ? -1 : bKey[columnName]

		} else if(VITAL_COLUMNS.includes(columnName)) {
			var statusA = new Date(aKey[columnName]?.updated_at || 0).getTime();
			var statusB = new Date(bKey[columnName]?.updated_at || 0).getTime();
		} else if(columnName.match(/^[0-9a-fA-F]{24}$/)) {
			var statusA = new Date(aKey.customIndicators?.[columnName]?.updated_at || 0).getTime();
			var statusB = new Date(bKey.customIndicators?.[columnName]?.updated_at || 0).getTime();
		}

		if(order==="-") 
			return statusB - statusA;
		return statusA - statusB

	}));
}

function sortArr(a, b){
	var aKey = a[1];
	var bKey = b[1];

	var statusA = aKey.updated_at || -1;
	var statusB = bKey.updated_at || -1;

	return statusB - statusA;
}

function accumulateStatus(statuses){
	return Object.keys(statuses).reduce(function(acc, key){
		var status = statuses[key].status;
		if(status > acc)
			acc = status;
		return acc;
	}, -1);
}

function getLastUpdatedAt(workflows) {
	const allUpdatedAts = Object.values(workflows).map(entry => entry.updated_at);
	return allUpdatedAts.sort().reverse()[0]
}