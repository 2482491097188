import { SpinningIcon } from "@aetonix/web-ui";
import {
	Dialog,
	DialogContent,
	DialogTitle,
	Divider,
	FormControl,
	Grid,
	InputLabel,
	MenuItem,
	Select
} from "@material-ui/core";
import { Sync } from "@material-ui/icons";
import * as Sentry from "@sentry/react";
import Form from "ae-forms";
import React, { useState } from "react";
import { useLocalize } from "../../../shared/contexts/LocalizeContext";
import { useLocalizeFrom } from "../../../shared/contexts/LocalizeFromContext";

const styles = {
	centerContent: {
		display: "flex",
		justifyContent: "center",
		padding: "1rem"
	},
	customTitle: {
		display: "flex",
		flexDirection: "column",
		alignItems: "flex-end",
		paddingRight: "1rem",
		marginTop: "-1rem",
	},
};


function CustomDialogTitle({ patient, emrId, localize }) {
	const name = patient?.fname + " " + patient?.lname;

	return (
		<div style={styles.customTitle}>
			<div> {localize("groupforms.patient")} : {name} </div>
			{  emrId ? (<div>{localize("groupforms.emrId")} : {emrId} </div>) : null  }
		</div>
	);
};


export function CreateFormDialog({
	forms,
	formsById,
	isOpen,
	onClose,
	submitForm,
	getFormPrepopulate,
	retrieveSubmissions,
	person
}) {
	const localize = useLocalize();
	const [selectedFormId, setSelectedFormId] = React.useState("");
	const [selectedForm, setSelectedForm] = React.useState(null);
	const [formSubmitting, setFormSubmitting] = useState(false);
	const [prepopulateData, setPrepopulate] = useState({});
	const [existingSubmissions, setExistingSubmissions] = useState({});
	const [isLoadingFormData, setIsLoadingFormData] = useState(false);
	const localizeFrom = useLocalizeFrom();

	function handleClose() {
		setSelectedForm(null);
		setSelectedFormId("");
		onClose();
	}

	async function handleFormChange(event) {
		setIsLoadingFormData(true);
		const newFormId = event.target.value;
		const { data: prepopulateRes } = await getFormPrepopulate(newFormId);

		const newForm = formsById[newFormId];
		if (newForm.formType === "single") {
			const { data: prevSubs } = await retrieveSubmissions(newFormId);
			if (prevSubs.length) {
				setExistingSubmissions({ ...existingSubmissions, [newFormId]: prevSubs[0] });
			}
		}
		setSelectedForm(newForm);
		setSelectedFormId(newFormId);
		setPrepopulate(prepopulateRes);
		setIsLoadingFormData(false);
	}

	async function handleSubmit(data) {
		setFormSubmitting(true);
		try {
			const submissionId = selectedForm.formType === "single" ? existingSubmissions[selectedFormId]?._id : undefined;
			const response = await submitForm({ formId: selectedForm._id, data, submissionId });
			if (response.status === "success") {
				handleClose();
				return;
			}
		} catch (error) {
			Sentry.captureException(error);
		}
		setFormSubmitting(false);
	}

	const formData = existingSubmissions[selectedFormId]?.data;
	const emrId = selectedForm?.emrId || "";

	return (
		<>
			<Dialog aria-labelledby="create-form-title" fullWidth maxWidth="md" onClose={handleClose} open={isOpen}>
				<DialogTitle id="create-form-title">{localize("groupforms.addnew")}</DialogTitle>
				<CustomDialogTitle emrId={emrId} patient={person} localize={localize} />
				<DialogContent>
					<FormControl fullWidth>
						<InputLabel id="create-form-label">{localize("groupforms.select_form")}</InputLabel>
						<Select
							labelId="create-form-label"
							id="create-form-select"
							value={selectedFormId}
							onChange={handleFormChange}
						>
							{forms.map(form => (
								<MenuItem key={form._id} value={form._id}>
									{localizeFrom(form.localization, ".") || ""}
								</MenuItem>
							))}
						</Select>
					</FormControl>
					<Divider />
					{selectedFormId &&
						(isLoadingFormData ? (
							<Grid item xs={12} style={styles.centerContent}>
								<SpinningIcon icon={Sync} spinning={true} />
							</Grid>
						) : (
							<Form
								schema={selectedForm?.schema}
								defaultData={formData || prepopulateData}
								onCancel={handleClose}
								cancelText={localize("groupforms.discardchanges")}
								submitText={localize("common.save")}
								onSubmit={handleSubmit}
								submitting={formSubmitting}
							/>
						))}
				</DialogContent>
			</Dialog>
		</>
	);
}
