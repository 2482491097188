/* © 2017 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, January 01, 2017
 * For information or permission request, email info@aetonixsystems.com
 */

var Store = require("./store");
var par = require("par");
var xtend = require("xtend");

module.exports = AdminGroupDataStore;

function AdminGroupDataStore(api, events) {
	var store = new Store();

	var tracking = [];

	store.raw_get = store.get;
	store.get = get;
	return store;

	function get(id) {
		var existing = store.raw_get(id);
		if (existing) return existing;
		if (!isTracking(id)) track(id);
		return {};
	}

	function track(id) {
		tracking.push(id);
		api.admin.orgGroup.data(id)
			.then(par(set_group, id));

		events.on(`orgGroup/${id}/updated`, par(update_group, id));
	}

	function set_group(id, data) {
		store.set(id, data);
	}

	function update_group(id, data) {
		var existing = store.raw_get(id);
		store.set(id, xtend(existing, data));
	}

	function isTracking(id) {
		return tracking.indexOf(id) !== -1;
	}
}
