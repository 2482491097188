"use strict";

var Store = require("./store");
var par = require("par");

module.exports = OwnExportPresets;

function OwnExportPresets(api, events) {
	var store = new Store();

	var tracking = [];

	store.raw_get = store.get;
	store.get = get;

	trackChanges();
	return store;

	function get(type){
		var list = store.raw_get(type);
		if(list) return list;

		if(tracking.indexOf(type) === -1)
			fetch(type);

		return [];
	}

	function fetch(type){
		tracking.push(type);
		return api.exportpresets.listType(type)
			.then(par(setList, type));
	}

	function setList(type, list){
		store.set(type, list);
	}

	function trackChanges(){
		events.on("mqtt:$/export/preset/added", handle_added);
		events.on("mqtt:$/export/preset/removed", handle_removed);
	}

	function handle_added(data){
		var type = data.exportType;

		var current = store.raw_get(type);
		if(!current) current = [];
		var newList = current.concat(data);
		store.set(type, newList);
	}

	function handle_removed(preset){
		var current = flatten(store.all());
		var newList = current.filter(e => e._id !== preset);

		var oldKeys = store.keys().reduce((a, e) => {
			a[e] = [];
			return a
		}, {});

		var formatted = newList.reduce((a, e) => {
			var currentType = a[e.exportType] || [];
			a[e.exportType] = currentType.concat(e);
			return a;
		}, oldKeys);

		store.setAll(formatted);
	}

	function flatten(arr){
		return arr.reduce((acc, e) => acc.concat(e));
	}
}