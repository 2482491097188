/* © 2017 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, January 01, 2017
 * For information or permission request, email info@aetonixsystems.com
 */

var React = require("react");
var par = require("par");
var config = require("../../configs/config.json");
var isEmpty = require("is-empty");

/**
 * User Interface elements material-ui
 */
var Header = require("../shared/Header.js");
var Button = require("@material-ui/1.5.1/Button").default;
var Dialog = require("../shared/Dialog");

var TextField = require("@material-ui/1.5.1/TextField").default;
var Chip = require("@material-ui/1.5.1/Chip").default;
var Avatar = require("@material-ui/1.5.1/Avatar").default;
var FontIcon = require("@material-ui/1.5.1/Icon").default;

var Table = require("@material-ui/1.5.1/Table").default;
var TableHeader = require("@material-ui/1.5.1/TableHead").default;
var TableHeaderColumn = require("@material-ui/1.5.1/TableCell").default;
var TableBody = require("@material-ui/1.5.1/TableBody").default;
var TableRow = require("@material-ui/1.5.1/TableRow").default;
var TableRowColumn = require("@material-ui/1.5.1/TableCell").default;

/**
 * User Interface elements Aetonix
 */
var Utility = require("../shared/Utility.js");
var Scroll = require("../shared/InfiniScroll.jsx");
var SearchTable = require("../shared/SearchTable.jsx");
var DatePicker = require("../shared/DatePicker");
var Colors = require("../shared/AetonixTheme").palette;

var styles = {
	icon: {
		fontSize: 20,
		color: Colors.canvas
	},
	avatar: {
		backgroundColor: Colors.primary.dark
	},
	back: {
		backgroundColor: Colors.primary.light,
		color: Colors.primary.main
	},
	backPrimary: {
		backgroundColor: "#E0E0E0"
	},
	avatarPrimary: {
		backgroundColor: "#BCBCBC"
	},
	inputProp: {
		style: {
			color: Colors.primary.main,
		}
	},
};

module.exports = render;

function render() {

	//Boiler plate standard for every history page
	var component = this;
	var state = component.state;
	var localization = state.localization;
	var careplanNoticeList = state.careplanChangeList.all();
	var careplanChanges = !!careplanNoticeList.length;
	var offline = state.connection.get("offline");

	// States relevant to _this_ history page
	var people = state.people;
	var currentPerson = state.currentPerson;
	var rowData = state.glucose.map(par(convertData, localization, people));
	var startDate = state.startDate;
	var endDate = state.endDate;
	var report = state.report;
	var fileName = state.fileName;
	var loadMore = state.more;

	// Search related variables
	var choose_date = localization.get("choose_date");
	var userFilter = state.userFilter;
	var dateFilter = state.dateFilter;
	var filterUser = state.filterUser;
	var closeSearch = state.closeSearch;
	var titleKey = "user_glucose_history";

	var headers = [
		{
			name: "user",
			content: localization.get("user_glucose_history_user")
		}, {
			name: "glucose",
			content: localization.get("user_glucose_history_glucose")
		}, {
			name: "meanblood",
			content: localization.get("user_glucose_history_meanblood")
		}, {
			name: "time",
			content: localization.get("user_glucose_history_time")
		}
	];

	var report_actions = [
		csvReport(component, localization), (
			<Button key={"buttoncancel"} onClick={par(hideReport, component)}>
				{localization.get("report_cancel")}
			</Button >
		)
	];

	var date_actions = [
		<Button key={"buttonapply"}
			onClick={par(handle_date, component, "time")}>
			{localization.get("search_apply")}
		</Button >,
		<Button key={"buttoncancel"}
			onClick={par(hideSearch, component, "time")}>
			{localization.get("form_submissions_history_cancel")}
		</Button >
	];

	if (dataHasChanged(state.glucose, state.previousGlucose)) {
		component.state.userMetrics.trackEvent("user-glucose-history: viewed glucose history", {
			records: state.glucose.map((record) => record._id),
		});
		component.setState({
			previousGlucose: state.glucose,
		});
	}

	return (
		<div className="flex-vertical flex-1">

			<Header
				careplanChanges={careplanChanges}
				offline={offline}
				currentPerson={currentPerson}
				localization={localization}
				titleKey={titleKey}
			/>
			<div className="flex-horizontal ae-empty">
				{renderReportChip(component)}
				{renderUserFilterChip(component)}
				{renderDateFilterChip(component)}
			</div>

			<Scroll loadMore={loadMore}>
				<Table>
					{renderHeader(component, headers)}
					{renderBody(component, headers, rowData)}
				</Table>
			</Scroll>

			<Dialog actions={report_actions} open={report} title={localization.get("dashboards_glucoseHistory_title")}>
				<div>
					<DatePicker invalid={choose_date}
						labelStart={localization.get("report_start")}
						labelEnd={localization.get("report_end")}
						startDate={startDate}
						endDate={endDate}
						updateStart={par(updateChanged, component, "startDate")}
						updateEnd={par(updateChanged, component, "endDate")}
					/>
					<TextField fullWidth placeholder={localization.get("report_filename")} inputProps={{"aria-label": localization.get("report_filename")}} value={fileName} onChange={par(updateFileNameChanged, component)} InputProps={styles.inputProp} />
				</div>
			</Dialog>

			<SearchTable action={par(filterUser, component)} ref="searchDialog" search={state.search}
				localization={localization}
				people={people}
				showing={userFilter}
				onClose={closeSearch}
				title={localization.get("search_panel_title_patient")} />

			<Dialog actions={date_actions} open={dateFilter} title={localization.get("dashboards_glucoseHistory_title")}>
				<DatePicker invalid={choose_date}
					labelStart={localization.get("report_start")}
					labelEnd={localization.get("report_end")}
					startDate={startDate}
					endDate={endDate}
					updateStart={par(updateChanged, component, "startDate")}
					updateEnd={par(updateChanged, component, "endDate")}
				/>
			</Dialog>

		</div>
	);
}

function dataHasChanged(records, previousRecords) {
	records = records || [];
	previousRecords = previousRecords || [];
	if (records.length !== previousRecords.length) {
		return true;
	}
	for (var i = 0; i < records.length; i++) {
		if (records[i]._id !== previousRecords[i]._id) {
			return true;
		}
	}
	return false;
}

function renderDateFilterChip(component) {
	var state = component.state;
	var deleteOn = noop();
	var styleavatar = styles.avatarPrimary;
	var styleback = styles.backPrimary;

	if (!!state.startDate && !!state.endDate) {
		deleteOn = par(clearSearch, component, "time");
		styleavatar = styles.avatar;
		styleback = styles.back;
	}
	return (<Chip className="ae-icon ae-fonticon"
		onDelete={deleteOn}
		style={styleback}
		onClick={par(showSearch, component, "time")}
		avatar={<Avatar style={styleavatar}>
			<FontIcon className="fa fa-filter fa-2x" style={styles.icon} />
		</Avatar>}
		label={renderFilterLabel(component, "time")}
	/>);
}

function renderUserFilterChip(component) {
	var state = component.state;
	var deleteOn = noop();
	var styleavatar = styles.avatarPrimary;
	var styleback = styles.backPrimary;

	if (state.query.length) {
		deleteOn = par(clearSearch, component, "user");
		styleavatar = styles.avatar;
		styleback = styles.back;
	}
	return (
		<Chip className="ae-icon ae-fonticon"
			onDelete={deleteOn}
			style={styleback}
			onClick={par(showSearch, component, "user")}
			avatar={<Avatar style={styleavatar}>
				<FontIcon className="fa fa-filter fa-2x" style={styles.icon} />
			</Avatar>}
			label={renderFilterLabel(component, "user")}
		/>
	);
}

function renderReportChip(component) {
	var state = component.state;
	return (
		<Chip className="ae-icon ae-fonticon"
			style={styles.back}
			onClick={par(showReport, component)}
			avatar={<Avatar style={styles.avatar}>
				<FontIcon className="fa fa-floppy-o fa-2x" style={styles.icon} />
			</Avatar>}
			label={state.localization.get("groupfall_report_button")}
		/>
	);
}

function renderHeader(component, headers) {
	return (
		<TableHeader>
			<TableRow>
				{headers.map(par(renderHeaderColumn, component))}
			</TableRow>
		</TableHeader>
	);
}

function renderHeaderColumn(component, column) {
	return (
		<TableHeaderColumn key={column.content} className="flex-spread">
			{column.content}
		</TableHeaderColumn>
	);
}

function renderBody(component, headers, rows) {
	return (
		<TableBody>
			{rows.map(par(renderRow, headers))}
		</TableBody>
	);
}

function renderRow(headers, row, index) {
	return (
		<TableRow key={index}>
			{headers.map(par(renderRowColumn, row))}
		</TableRow>
	);
}

function renderRowColumn(row, column, index) {
	return (
		<TableRowColumn key={index}>
			{row[column.name]}
		</TableRowColumn>
	);
}

function convertData(localization, people, record) {

	var bracelet_user = Utility.format_name(people.get(record.who));
	var time_of_event = Utility.timestamp(record.created_at);
	var glucose = record.history.mmol || record.history.glucose;
	var meanblood = record.history.mg || record.history.meanblood;
	if (!glucose) {
		glucose = 0;
	}
	if (!meanblood) {
		meanblood = 0;
	}

	return {
		"user": bracelet_user,
		"glucose": glucose,
		"meanblood": meanblood,
		"time": time_of_event
	};
}

function csvReport(component, localization) {

	var comp = component.state;
	var start = comp.startDate;
	var end = adjust_date(comp.endDate);
	var filename = comp.fileName;

	if (!filename) filename = localization.get("default_file_glucose");

	var language = comp.currentPerson.get("personal").language;
	var timezone = new Date().getTimezoneOffset();

	var url = config.host +
		"/v2/org/observations/glucose/history/report?token=" +
		config.token +
		"&start=" +
		encodeURIComponent(start) +
		"&end=" + encodeURIComponent(end) +
		"&filename=" +
		encodeURIComponent(filename) +
		"&language=" +
		encodeURIComponent(language) +
		"&timezone=" +
		encodeURIComponent(timezone);

	return (
		<a
			key={url}
			className="ae-report-save"
			href={url}
			download={filename}
			onClick={() =>
				component.state.userMetrics.trackEvent("user-glucose-history: download report", {
					start,
					end,
					filename,
					timezone,
					language
				})
			}
		>
			{localization.get("report_save")}
		</a>
	);
}

function hideReport(component) {
	component.state.userMetrics.trackEvent("user-glucose-history: close report popup");
	return (
		component.setState({
			report: false,
		})
	);
}

function showReport(component) {
	component.state.userMetrics.trackEvent("user-glucose-history: open report popup");
	return (
		component.setState({ report: true })
	);
}

function updateFileNameChanged(component, event) {
	event.persist();
	var value = event.target.value;
	component.setState({
		fileName: value
	});
}

function updateChanged(component, name, event, date) {
	var value = date;
	if (event) {
		value = event._d;
	}
	var update = {};
	update[name] = value;
	component.setState(update);
}

/**
 * A function to reset a date by 1 day ahead.	Used to adjust the end date
 * on the date selector function because the default value is midnight of the
 * day selected instead of say, 23:59 of the day chosen or midnight of the next
 * day
 * @param	{String} date Date string
 * @return {String}			Date string
 */
function adjust_date(date) {
	if (isEmpty(date)) return "";
	var end = new Date(date);
	end.setDate(end.getDate() + 1);
	return end;
}

function showSearch(component, name) {
	if (name === "user") component.setState({ userFilter: true });
	if (name === "time") component.setState({ dateFilter: true });
	const eventText = {
		user: "user-glucose-history: open user filter",
		time: "user-glucose-history: open date filter",
	}[name] || name;
	component.state.userMetrics.trackEvent(eventText);
}

function hideSearch(component, name) {
	if (name === "user") component.setState({ userFilter: false });
	if (name === "status") component.setState({ statusFilter: false });
	if (name === "time") component.setState({ dateFilter: false });
	const eventText = {
		time: "user-glucose-history: close date filter",
	}[name] || name;
	component.state.userMetrics.trackEvent(eventText);
}

function clearSearch(component, name) {
	var clear = component.state.filterClear;
	if (name === "user") {
		component.setState({
			query: []
		});
		return clear("user");
	}
	if (name === "time") {
		component.setState({
			startDate: "",
			endDate: new Date
		});
		return clear("time");
	}
}

function renderFilterLabel(component, name) {
	var state = component.state;
	var localization = state.localization;
	var label;
	if (name === "time") {
		var start = state.startDate;
		var end = state.endDate;
		if (!start || !end) return (localization.get("search_time"));
		start = shortDate(start);
		end = shortDate(end);
		label = start + " - " + end;
		return label;
	}
	if (name === "user") {
		var length = state.query.length;
		if (!length) return (localization.get("search_patient"));
		label = localization.get("search_patient") + " (" + length + ")";
		return label;
	}
}

function shortDate(date) {
	var newdate = new Date(date);
	var day = newdate.getDate();
	var month = newdate.getMonth() + 1;
	var year = newdate.getFullYear();
	return (day + "/" + month + "/" + year);

}

function handle_date(component, value) {
	var state = component.state;
	hideSearch(component, value);
	var start = state.startDate;
	var end = adjust_date(state.endDate);
	component.state.userMetrics.trackEvent("user-glucose-history: filter by date", {
		start,
		end,
	});
	return state.filterDate(start, end);
}

function noop() { }
