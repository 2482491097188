
/* © 2017 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, January 01, 2017
 * For information or permission request, email info@aetonixsystems.com
 */

var par = require("par");

var defineUI = require("../shared/define");
var render = require("./render.jsx");

// Stores required for all pages
var PersonStore = require("../stores/person_store");
var LocalizationStore = require("../stores/localization_store");
var CareplanNotificationStore = require("../stores/careplan_notice_store");
var ConnectionStore = require("../stores/connection_store");

// Stores unique to this page
var WorkflowHistoryStore = require("../stores/workflow_history_store");
var WorkflowOngoingStore = require("../stores/workflow_ongoing_store");
var WorkflowDefinitionsStore = require("../stores/workflow_definitions_store");

var CurrentPersonInfoStore = require("../stores/current_person_info_store");

var makeStores = require("../shared/stores")({
	people: PersonStore,
	workflowactions: WorkflowHistoryStore,
	workflowongoing: WorkflowOngoingStore,
	workflowdefinitions: WorkflowDefinitionsStore,

	currentPerson: CurrentPersonInfoStore,
	localization: LocalizationStore,
	careplanChangeList: CareplanNotificationStore,
	connection: ConnectionStore
});

function make_ui(api, events, config, userMetrics) {
	var storeStore = makeStores(api, events, config);

	window.stores = storeStore.store;

	userMetrics.trackEvent("page view", {
		"page": "workflow-history",
		"category": "history",
		"permission level": "admin",
	});

	return defineUI({
		getInitialState: getInitialState,
		componentDidMount: listen_for_changes,
		render: render,
		componentWillUnmount: removeListeners
	});

	function removeListeners() {
		events.off();
	}

	function listen_for_changes() {
		storeStore.listen(par(handle_change, this));
	}

	function handle_change(component, stores) {
		component.setState({
			people: stores.people,
			workflowactions: stores.workflowactions,
			workflowdefinitions: stores.workflowdefinitions,
			workflowongoing: stores.workflowongoing,
			currentPerson: stores.currentPerson,
			localization: stores.localization,
			careplanChangeList: stores.careplanChangeList,
			connection: stores.connection
		});
	}

	function getInitialState() {
		return {
			people: storeStore.stores.people,
			workflowactions: storeStore.stores.workflowactions,
			workflowdefinitions: storeStore.stores.workflowdefinitions,
			workflowongoing: storeStore.stores.workflowongoing,

			localization: storeStore.stores.localization,
			careplanChangeList: storeStore.stores.careplanChangeList,
			connection: storeStore.stores.connection,
			currentPerson: storeStore.stores.currentPerson,

			more: storeStore.stores.workflowactions.more,

			searchActorName: searchActorName,
			closeActorSearch: par(closeActorSearch, this),
			closeOwnerSearch: par(closeOwnerSearch, this),
			closeActionSearch: par(closeActionSearch, this),
			filterActors: filterActors,
			filterOwners: filterOwners,
			filterActions: filterActions,
			filterClear: filterClear,
			filterDateRespond: filterDateRespond,
			filterWorkflow: filterWorkflow,
			filterInitial: filterInitial,
			respondFilter: false,
			ownerFilter: false,
			workflowFilter: false,
			actionFilter: false,
			actorFilter: false,
			report: false,
			startRespond: "",
			endRespond: new Date(),
			fileName: "",
			actors: [],
			owners: [],
			action: [],
			workflow: [],
			chosen_workflow: "",
			chosen_action: "",
			chosen_definition: "",
			chosen_actor: "",
			openingDataView: false,
			dataToView: null,
			errorToast: false,
			userMetrics: userMetrics,
		};
	}

	function searchActorName(query) {
		return api.org.group.members(query);
	}

	function filterOwners(component, data) {
		var owners = component.state.owners;
		component.setState({
			owners: owners.concat(data)
		});
		storeStore.stores.workflowactions.searchOwners(data);
	}

	function filterDateRespond(start, end) {
		storeStore.stores.workflowactions.searchDate(start, end);
	}

	function filterWorkflow(workflow) {
		storeStore.stores.workflowactions.searchWorkflow(workflow);
	}

	// actor is an array of strings
	function filterActors(component, actor) {
		var actors = component.state.actors;
		actors.push(actor);
		component.setState({
			actors: actors
		});
		storeStore.stores.workflowactions.searchActor(actors);
	}

	function filterActions(component, action) {
		var actions = component.state.action;
		actions.push(action);
		component.setState({
			actions: actions
		});
		storeStore.stores.workflowactions.searchAction(actions);
	}
	//'workflows' is an actual workflow name not a type
	function filterInitial(workflows, action) {
		storeStore.stores.workflowactions.searchInitial(workflows, action);
	}

	function filterClear(name) {
		storeStore.stores.workflowactions.clearSearch(name);
	}

	function closeActorSearch(component) {
		component.setState({
			actorFilter: false
		});
	}

	function closeOwnerSearch(component) {
		component.setState({
			ownerFilter: false
		});
		userMetrics.trackEvent("workflow-history: close actor filter popup");
	}

	function closeActionSearch(component) {
		component.setState({
			actionFilter: false
		});
	}

}

module.exports = make_ui;
