/* © 2014 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, June 19, 2014
 * For information or permission request, email info@aetonixsystems.com
 */

"use strict";

var par = require("par");

var config = require("../../configs/config.json");

var React = require("react");
var CreateReactClass = require("create-react-class");
var ReactPropTypes = require("prop-types");
var AetonixTheme = require('./AetonixTheme.js');

var Dialog = require("../shared/Dialog");
var List = require("@material-ui/1.5.1/List").default;
var ListItem = require("@material-ui/1.5.1/ListItem").default;
var ListItemText = require("@material-ui/1.5.1/ListItemText").default;
var TextField = require("@material-ui/1.5.1/TextField").default;
var Avatar = require("@material-ui/1.5.1/Avatar").default;
var Button = require("@material-ui/1.5.1/Button").default;

var Utility = require("./Utility.js");

import * as Sentry from '@sentry/react';

module.exports = CreateReactClass({
	displayName: "SearchTable",
	childContextTypes: {
		muiTheme: ReactPropTypes.object
	},
	getChildContext: getChildContext,
	getInitialState: getInitialState,

	render: render,
	show: show,
	dismiss: dismiss,
	doSearch: doSearch,
	setResults: setResults
});

function getChildContext() {
	return {
		muiTheme: AetonixTheme
	};
}

function getInitialState() {
	return {
		showing: false,
		results: []
	};
}

function render() {
	var searchResults = this.state.results;
	var showing = this.props.showing;
	var searchTitle = this.props.title;
	var searchName = this.props.localization.get("search_name");
	var isIds = (typeof searchResults[0]) === "string";
	var people = this.props.people;
	var onClose = this.props.onClose;

	var actions = [
		(<Button key={"buttonclose"}  onClick={onClose}>{this.props.localization.get("search_close")}</Button >)
	];

	if(isIds)
		searchResults = searchResults.map(people.get);

	return (
		<Dialog actions={actions} autoDetectWindowHeight={true} open={showing} title={searchTitle}>
			<TextField placeholder={searchName} inputProps={{'aria-label' : searchName }} onChange={this.doSearch}/>
			<List>
				{searchResults.sort(by_name).map(par(renderSearchItem, this))}
			</List>
		</Dialog>
	);
}

function by_name(x,y) {
	var X = x.fname.toLowerCase();
	var Y = y.fname.toLowerCase();
	if (X === Y) {
		X = x.lname.toLowerCase();
		Y = y.lname.toLowerCase();
	}

	if (X < Y) return -1;
	if (X > Y) return 1;
	return 0;
}

function renderSearchItem(component, person) {
	var avatar = <Avatar alt="User Avatar Image" src={config.image_cdn + person.image}/>;
	var text = Utility.format_name(person);
	var clickAction = par(component.props.action, person._id);
	return (
		<ListItem key={person._id} button onClick={clickAction} >
			{avatar}
			<ListItemText primary={text} />
		</ListItem>
	);
}

function doSearch(e) {
	var query = e.target.value;

	var search = this.props.search;

	search(query).then(this.setResults).catch(error => {
		Sentry.captureException(error);
		console.error.bind(console, error)
	});
}

function setResults(results) {
	this.setState({
		results: results
	});
}

function show() {
	this.setState({
		showing: true,
		results: []
	});
}

function dismiss() {
	this.setState({
		results: [],
		showing: false
	});
}
