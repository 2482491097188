/* © 2014 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, June 19, 2014
 * For information or permission request, email info@aetonixsystems.com
 */

var par = require("par");

var defineUI = require("../shared/define");
var render = require("./render.jsx");

var UrgencyStore = require("../stores/urgency_store");
var PersonStore = require("../stores/person_store");
var LocalizationStore = require("../stores/localization_store");
var CurrentPersonInfoStore = require("../stores/current_person_info_store");
var CareplanNotificationStore = require("../stores/careplan_notice_store");
var ConnectionStore = require("../stores/connection_store");

var makeStores = require("../shared/stores")({
	people: PersonStore,
	urgencies: UrgencyStore,
	currentPerson: CurrentPersonInfoStore,
	localization: LocalizationStore,
	careplanChangeList: CareplanNotificationStore,
	connection: ConnectionStore
});

function make_ui(api, events, config, userMetrics) {
	var storeStore = makeStores(api, events, config);

	window.stores = storeStore.store;

	userMetrics.trackEvent("page view", {
		"page": "user-urgency-history",
		"category": "history",
		"permission level": "staff",
	});

	return defineUI({
		getInitialState: getInitialState,
		componentDidMount: listen_for_changes,
		render: render,
		componentWillUnmount: removeListeners
	});

	function removeListeners() {
		events.off();
	}

	function listen_for_changes() {
		storeStore.listen(par(handle_change, this));
	}

	function handle_change(component, stores) {
		component.setState({
			history: stores.urgencies.sorted(),
			urgencies: stores.urgencies,
			people: stores.people,
			currentPerson: stores.currentPerson,
			localization: stores.localization,
			careplanChangeList: stores.careplanChangeList,
			connection: stores.connection
		});
	}

	function getInitialState() {
		return {
			details: false,
			detailing: false,
			history: storeStore.stores.urgencies.sorted(),
			urgencies: storeStore.stores.urgencies,
			people: storeStore.stores.people,
			currentPerson: storeStore.stores.currentPerson,
			localization: storeStore.stores.localization,
			more: storeStore.stores.urgencies.more,
			careplanChangeList: storeStore.stores.careplanChangeList,
			connection: storeStore.stores.connection,

			filterCallers: filterCallers,
			filterRecipients: filterRecipients,
			filterClear: filterClear,
			filterDate: filterDate,
			filterStatus: filterStatus,
			closeCallerSearch: par(closeCallerSearch, this),
			closeRecipientSearch: par(closeRecipientSearch, this),
			searchCaller: searchCaller,
			searchRecipient: searchRecipient,
			report: false,
			callersFilter: false,
			recipientsFilter: false,
			statusFilter: false,
			dateFilter: false,
			callers: [],
			recipients: [],
			callstatus: "",
			startDate: "",
			endDate: new Date(),
			fileName: "",
			userMetrics: userMetrics,
		};
	}

	/**
	 * The first function returns a list of the group's users while the second
	 * is a list of _any_ person in the system matching the criteria
	 * @param  {objectId} query User_id
	 * @return {array}       User _id's
	 */
	function searchCaller(query) {
		return api.org.users.list(query);
	}
	function searchRecipient(query) {
		return api.people.search(query);
	}

	function filterCallers(component, data) {
		var callers = component.state.callers;
		component.setState({
			callers: callers.concat(data)
		});
		storeStore.stores.urgencies.searchCallers(data);
		userMetrics.trackEvent("user-urgency-history: filter by caller", {
			"caller": data
		});
	}

	function filterRecipients(component, data) {
		var recipients = component.state.recipients;
		component.setState({
			recipients: recipients.concat(data)
		});
		storeStore.stores.urgencies.searchRecipients(data);
		userMetrics.trackEvent("user-urgency-history: filter by recipient", {
			"recipient": data
		});
	}


	function filterStatus(data) {
		storeStore.stores.urgencies.searchStatus(data);
	}

	function filterDate(start, end) {
		storeStore.stores.urgencies.searchDate(start, end);
	}

	function filterClear(name) {
		storeStore.stores.urgencies.clearSearch(name);
	}

	function closeCallerSearch(component) {
		component.setState({
			callersFilter: false
		});
		userMetrics.trackEvent("user-urgency-history: close callers filter popup");
	}

	function closeRecipientSearch(component) {
		component.setState({
			recipientsFilter: false
		});
		userMetrics.trackEvent("user-urgency-history: close recipients filter popup");
	}
}

module.exports = make_ui;
