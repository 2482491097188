"use strict";
var frameDebounce = require("frame-debounce");
var par = require("par");

module.exports = makeStore;

function makeStore(store) {
	return function (api, events, config) {
		var builtStore = buildStore(store, api, events, config);

		return {
			store: builtStore,
			listen: buildListen(builtStore)
		};
	};
}

function buildListen(store) {
	return function listen(fn) {
		var update = frameDebounce(par(fn, store));
		store.on("change", update);
	};
}

function buildStore(store, api, events, config) {
	return store(api, events, config);
}
