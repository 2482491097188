/* © 2014 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, June 19, 2014
 * For information or permission request, email info@aetonixsystems.com
 */

var prettyMs = require("pretty-ms");
var par = require("par");
var React = require("react");
var config = require("../../configs/config.json");
var isEmpty = require("is-empty");

var Table = require("@material-ui/1.5.1/Table").default;
var TableHeader = require("@material-ui/1.5.1/TableHead").default;
var TableHeaderColumn = require("@material-ui/1.5.1/TableCell").default;
var TableBody = require("@material-ui/1.5.1/TableBody").default;
var TableRow = require("@material-ui/1.5.1/TableRow").default;
var TableRowColumn = require("@material-ui/1.5.1/TableCell").default;

var List = require("@material-ui/1.5.1/List").default;
var ListItem = require("@material-ui/1.5.1/ListItem").default;
var ListItemText = require("@material-ui/1.5.1/ListItemText").default;

var Button = require("@material-ui/1.5.1/Button").default;
var Dialog = require("../shared/Dialog");
var TextField = require("@material-ui/1.5.1/TextField").default;
var MenuItem = require("@material-ui/1.5.1/MenuItem").default;
var Chip = require("@material-ui/1.5.1/Chip").default;
var Avatar = require("@material-ui/1.5.1/Avatar").default;
var FontIcon = require("@material-ui/1.5.1/Icon").default;
var DropDownMenu = require("@material-ui/1.5.1/Select").default;

/**
 * User Interface elements Aetonix
 */
var Header = require("../shared/Header.js");
var Utility = require("../shared/Utility.js");
var Scroll = require("../shared/InfiniScroll.jsx");
var SearchTable = require("../shared/SearchTable.jsx");
var DatePicker = require("../shared/DatePicker");

var Colors = require("../shared/AetonixTheme").palette;

var styles = {
	icon: {
		fontSize: 20,
		color: Colors.canvas
	},
	avatar: {
		backgroundColor: Colors.primary.dark
	},
	back: {
		backgroundColor: Colors.primary.light,
		color: Colors.primary.main
	},
	backPrimary: {
		backgroundColor: "#E0E0E0"
	},
	avatarPrimary: {
		backgroundColor: "#BCBCBC"
	},
	inputProp: {
		style: {
			color: Colors.primary.main,
		}
	},
};
/*
Row data should be processed into a list of the following before processing:
{
	state: "calling",
	name: "Bob Smith",
	meta: "Room 101",
	time: "9:45 AM (2 minutes)",
	who: "Nurse Joy"
}
 */

module.exports = render;

function render() {
	var component = this;
	var state = component.state;
	var localization = state.localization;
	var careplanNoticeList = state.careplanChangeList.all();
	var careplanChanges = !!careplanNoticeList.length;
	var offline = state.connection.get("offline");
	var currentPerson = state.currentPerson;
	var people = state.people;
	var history = state.history;

	var rowData = generate_data(localization, people, history);
	var detailsData = state.urgencies.get(state.details);
	var details = detailsData ? detailsData.history : [];
	var detailing = state.detailing;

	var startDate = state.startDate;
	var endDate = state.endDate;
	var report = state.report;
	var fileName = state.fileName;
	var loadMore = state.more;
	// Search related variables
	var choose_date = localization.get("choose_date");
	var callersFilter = state.callersFilter;
	var recipientsFilter = state.recipientsFilter;
	var statusFilter = state.statusFilter;
	var dateFilter = state.dateFilter;
	var callstatus = state.callstatus;
	var searchCaller = state.searchCaller;
	var searchRecipient = state.searchRecipient;
	var filterCallers = state.filterCallers;
	var filterRecipients = state.filterRecipients;
	var closeCallerSearch = state.closeCallerSearch;
	var closeRecipientSearch = state.closeRecipientSearch;
	var titleKey = "urgencyhistory_title";

	var headers = [
		{
			name: "state",
			content: localization.get("urgency_headers_state")
		}, {
			name: "name",
			content: localization.get("urgency_headers_name")
		}, {
			name: "time",
			content: localization.get("urgency_headers_time")
		}, {
			name: "duration",
			content: localization.get("urgency_headers_duration")
		}, {
			name: "who",
			content: localization.get("urgency_headers_who")
		}
	];

	var modal_actions = [
		(<Button key={"buttonok"} onClick={par(hide_details, component)}>OK</Button >)
	];

	var report_actions = [
		csvReport(component, localization), (
			<Button key={"buttoncancel"} onClick={par(hideReport, component)}>{localization.get("report_cancel")}</Button >
		)
	];

	var date_actions = [
		<Button key={"buttonapply"}
			onClick={par(handle_date, component, "time")}>
			{localization.get("search_apply")}
		</Button >,
		<Button key={"buttoncancel"}
			onClick={par(hideSearch, component, "time")}>
			{localization.get("form_submissions_history_cancel")}
		</Button >
	];

	var status_actions = [
		<Button key={"buttondone"}
			onClick={par(hideSearch, component, "status")}>
			{localization.get("search_done")}
		</Button >
	];

	if (dataHasChanged(state.history, state.previousHistory)) {
		component.state.userMetrics.trackEvent("user-urgency-history: viewed urgency history", {
			records: state.history.map(u => u._id),
		});
		component.setState({
			previousHistory: state.history,
		});
	}

	return (
		<div className="flex-vertical flex-1">
			<Header
				careplanChanges={careplanChanges}
				offline={offline}
				currentPerson={currentPerson}
				localization={localization}
				titleKey={titleKey}
			/>

			<div className="flex-horizontal ae-empty">
				{renderReportChip(component)}
				{renderStatusFilterChip(component)}
				{renderCallerFilterChip(component)}
				{renderDateFilterChip(component)}
				{renderRecipientFilterChip(component)}

			</div>

			<Scroll loadMore={loadMore}>
				<Table>
					{renderHeader(headers)}
					{renderBody(component, headers, rowData)}
				</Table>
			</Scroll>

			<Dialog actions={modal_actions} open={detailing} title={localization.get("urgency_details")}>
				{render_details(state.people, details, localization)}
			</Dialog>

			<Dialog actions={report_actions} open={report} title={localization.get("urgencycallhistory_report_title")}>
				<div>
					<DatePicker invalid={choose_date}
						labelStart={localization.get("report_start")}
						labelEnd={localization.get("report_end")}
						startDate={startDate}
						endDate={endDate}
						updateStart={par(updateChanged, component, "startDate")}
						updateEnd={par(updateChanged, component, "endDate")}
					/>
					<TextField fullWidth placeholder={localization.get("report_filename")} inputProps={{"aria-label": localization.get("report_filename")}} value={fileName} onChange={par(updateFileNameChanged, component)} InputProps={styles.inputProp} />
				</div>
			</Dialog>

			<SearchTable action={par(filterCallers, component)} ref="searchDialog" search={searchCaller}
				localization={localization}
				people={people}
				showing={callersFilter}
				onClose={closeCallerSearch}
				title={localization.get("search_urgency_callerTitle")} />

			<SearchTable action={par(filterRecipients, component)} ref="searchDialog" search={searchRecipient}
				localization={localization}
				people={people}
				showing={recipientsFilter}
				onClose={closeRecipientSearch}
				title={localization.get("search_urgency_recipientTitle")} />

			<Dialog actions={date_actions} open={dateFilter} title={localization.get("search_time")}>
				<DatePicker invalid={choose_date}
					labelStart={localization.get("report_start")}
					labelEnd={localization.get("report_end")}
					startDate={startDate}
					endDate={endDate}
					updateStart={par(updateChanged, component, "startDate")}
					updateEnd={par(updateChanged, component, "endDate")}
				/>
			</Dialog>

			<Dialog actions={status_actions} open={statusFilter} title={localization.get("search_status")}>
				<DropDownMenu value={callstatus} onChange={par(handle_status, component)}>
					<MenuItem value={"cancel"}>{localization.get("search_cancelled")}</MenuItem>
					<MenuItem value={"accept"}>{localization.get("search_accepted")}</MenuItem>
				</DropDownMenu>
			</Dialog>

		</div>
	);
}

function dataHasChanged(records, previousRecords) {
	records = records || [];
	previousRecords = previousRecords || [];
	if (records.length !== previousRecords.length) {
		return true;
	}
	for (let i = 0; i < records.length; i++) {
		if (records[i]._id !== previousRecords[i]._id) {
			return true;
		}
	}
	return false;
}

function renderReportChip(component) {
	var state = component.state;
	return (
		<Chip className="ae-icon ae-fonticon"
			style={styles.back}
			onClick={par(showReport, component)}
			avatar={<Avatar style={styles.avatar}>
				<FontIcon className="fa fa-floppy-o fa-2x" style={styles.icon} />
			</Avatar>}
			label={state.localization.get("groupcall_report_button")}
		/>
	);
}

function renderDateFilterChip(component) {
	var state = component.state;
	var deleteOn = noop();
	var styleavatar = styles.avatarPrimary;
	var styleback = styles.backPrimary;

	if (!!state.startDate && !!state.endDate) {
		deleteOn = par(clearSearch, component, "time");
		styleavatar = styles.avatar;
		styleback = styles.back;
	}
	return (
		<Chip className="ae-icon ae-fonticon"
			onDelete={deleteOn}
			style={styleback}
			onClick={par(showSearch, component, "time")}
			avatar={<Avatar style={styleavatar}>
				<FontIcon className="fa fa-filter fa-2x" style={styles.icon} />
			</Avatar>}
			label={renderFilterLabel(component, "time")}
		/>);
}

function renderCallerFilterChip(component) {
	var state = component.state;
	var deleteOn = noop();
	var styleavatar = styles.avatarPrimary;
	var styleback = styles.backPrimary;

	if (state.callers.length) {
		deleteOn = par(clearSearch, component, "callers");
		styleavatar = styles.avatar;
		styleback = styles.back;
	}
	return (
		<Chip className="ae-icon ae-fonticon"
			onDelete={deleteOn}
			style={styleback}
			onClick={par(showSearch, component, "callers")}
			avatar={<Avatar style={styleavatar}>
				<FontIcon className="fa fa-filter fa-2x" style={styles.icon} />
			</Avatar>}
			label={renderFilterLabel(component, "callers")}
		/>
	);
}

function renderRecipientFilterChip(component) {
	var state = component.state;
	var deleteOn = noop();
	var styleavatar = styles.avatarPrimary;
	var styleback = styles.backPrimary;

	if (state.recipients.length) {
		deleteOn = par(clearSearch, component, "recipients");
		styleavatar = styles.avatar;
		styleback = styles.back;
	}
	return (
		<Chip className="ae-icon ae-fonticon"
			onDelete={deleteOn}
			style={styleback}
			onClick={par(showSearch, component, "recipients")}
			avatar={<Avatar style={styleavatar}>
				<FontIcon className="fa fa-filter fa-2x" style={styles.icon} />
			</Avatar>}
			label={renderFilterLabel(component, "recipients")}
		/>
	);
}

function renderStatusFilterChip(component) {
	var state = component.state;
	var deleteOn = noop();
	var styleavatar = styles.avatarPrimary;
	var styleback = styles.backPrimary;

	if (state.callstatus) {
		deleteOn = par(clearSearch, component, "status");
		styleavatar = styles.avatar;
		styleback = styles.back;
	}
	return (
		<Chip className="ae-icon ae-fonticon"
			onDelete={deleteOn}
			style={styleback}
			onClick={par(showSearch, component, "status")}
			avatar={<Avatar style={styleavatar}>
				<FontIcon className="fa fa-filter fa-2x" style={styles.icon} />
			</Avatar>}
			label={renderFilterLabel(component, "status")}
		/>);
}

function render_details(people, details, localization) {
	return (
		<List>
			{details.map(function (detail) {
				return (
					<ListItem >
						<ListItemText primary={detail_title(people, detail, localization)} secondary={timestamp_history(detail)} />
					</ListItem>
				);
			})}
		</List>
	);
}

function renderHeader(headerdata) {
	return (
		<TableHeader>
			<TableRow>
				{headerdata.map(renderHeaderColumn)}
			</TableRow>
		</TableHeader>
	);
}

function renderHeaderColumn(column) {
	return (
		<TableHeaderColumn key={column.content}>{column.content}</TableHeaderColumn>
	);
}

function renderBody(component, headers, rows) {
	return (
		<TableBody>
			{rows.map(par(renderRow, component, headers))}
		</TableBody>
	);
}

function renderRow(component, headers, row, index) {
	return (
		<TableRow key={index} onClick={par(dispay_details, component, row)} >
			{headers.map(par(renderRowColumn, row))}
		</TableRow>
	);
}

function renderRowColumn(row, column, index) {
	return (
		<TableRowColumn key={index}>
			{row[column.name]}
		</TableRowColumn>
	);
}

function detail_title(people, detail, localization) {
	var type = detail.type;
	var person;
	if (type === "start") {
		return localization.get("urgency_started");
	}
	if (type === "call") {
		person = people.get(detail.who);
		return localization.get("urgency_calling") + Utility.format_name(person);
	}
	if (type === "cycle") {
		return localization.get("urgency_searching");
	}
	if (type === "cancel") {
		return localization.get("urgency_cancel");
	}
	if (type === "accept") {
		person = people.get(detail.who);
		return localization.get("urgency_accepted") + Utility.format_name(person);
	}

	return "Unknown event";
}

function hide_details(component) {
	component.setState({
		detailing: false
	});
	component.state.userMetrics.trackEvent("user-urgency-history: close urgency details popup");
}

/**
 * Fetches the _id of the urgency call history for use in rendering the details
 * of the row that has been clicked.	The takes the row _as displayed_ and finds
 * the appropriate history object.	Note the rows displayed are filtered so there
 * is not a one-to-one correspondance between the history array and what is
 * being displayed to the user.
 * @param	{Object} component the component state
 * @param	{Object} rowData	 data from the row just clicked
 * @param	{Number} row			 index of the row just clicked
 * @return {Object}					 sets values of details and detailing
 */
function dispay_details(component, rowData) {
	var hist_id = rowData.hist_id;
	component.setState({
		details: hist_id,
		detailing: true
	});
	component.state.userMetrics.trackEvent("user-urgency-history: open urgency details popup");
}

function generate_data(localization, people, data_list) {
	return data_list.map(function (data) {
		var last = last_event(data);
		var who_id = last.who;
		var hist_id = data._id;
		return {
			hist_id: hist_id,
			time: timestamp_text(data),
			duration: duration(data),
			name: Utility.format_name(people.get(data.from)),
			state: history_item_type(localization, data),
			who: (who_id
				? Utility.format_name(people.get(who_id))
				: localization.get("urgency_notindb")) + " (" + last.online.length + ")"
		};
	}).filter(par(filter_for, localization));
}

function filter_for(localization, value) {
	if (value.state !== localization.get("urgency_calling"))
		return value;
}

function history_item_type(localization, item) {
	var type = localization.get("urgency_calling");
	item.history.forEach(function function_name(event) {
		if (event.type === "accept")
			type = localization.get("urgency_accepted");
		if (event.type === "cancel")
			type = localization.get("urgency_cancelled");
	});
	return type;
}

function time_delta(start, end) {
	var start_time = (new Date(start)).getTime();
	var end_time = (new Date(end)).getTime();
	var delta = end_time - start_time;
	return delta;
}

function duration(item) {
	return prettyMs(time_delta(item.created_at, item.updated_at));
}

function last_event(item) {
	return item.history[item.history.length - 1];
}

function timestamp_history(item) {
	var delta = time_delta(item.timestamp, Date.now());

	return Utility.timestamp(item.timestamp, delta);
}

function timestamp_text(item) {
	var delta = time_delta(item.created_at, Date.now());

	return Utility.timestamp(item.created_at, delta);
}

function csvReport(component, localization) {

	var comp = component.state;
	var start = comp.startDate;
	let end = comp.endDate;
	end = adjust_date(comp.endDate);
	var filename = comp.fileName;

	if (!filename) filename = localization.get("default_file_urgencycalls");

	var language = comp.currentPerson.get("personal").language;
	var timezone = new Date().getTimezoneOffset();

	var url = config.host +
		"/v2/org/urgency/history/report?token=" +
		config.token +
		"&start=" +
		encodeURIComponent(start) +
		"&end=" +
		encodeURIComponent(end) +
		"&filename=" +
		encodeURIComponent(filename) +
		"&language=" +
		encodeURIComponent(language) +
		"&timezone=" +
		encodeURIComponent(timezone);

	return (
		<a
			key={url}
			className="ae-report-save"
			href={url}
			download={filename}
			onClick={() =>
				component.state.userMetrics.trackEvent("user-urgency-history: download report", {
					start,
					end,
					filename,
					language,
					timezone
				})
			}
		>
			{localization.get("report_save")}
		</a>
	);
}

function hideReport(component) {
	component.state.userMetrics.trackEvent("user-urgency-history: close report popup");
	return (
		component.setState({
			report: false,
		})
	);
}

function showReport(component) {
	component.state.userMetrics.trackEvent("user-urgency-history: open report popup");
	return (
		component.setState({
			report: true
		})
	);
}

function updateFileNameChanged(component, event) {
	event.persist();
	var value = event.target.value;
	component.setState({
		fileName: value
	});
}

function updateChanged(component, name, event, date) {
	var value = date;
	if (event) {
		value = event._d;
	}
	var update = {};
	update[name] = value;
	component.setState(update);
}

/**
 * A function to reset a date by 1 day ahead.	Used to adjust the end date
 * on the date selector function because the default value is midnight of the
 * day selected instead of say, 23:59 of the day chosen or midnight of the next
 * day
 * @param	{String} date Date string
 * @return {String}			Date string
 */
function adjust_date(date) {
	if (isEmpty(date)) return "";
	var end = new Date(date);
	end.setDate(end.getDate() + 1);
	return end;
}


function showSearch(component, name) {
	if (name === "callers") component.setState({
		callersFilter: true
	});
	if (name === "recipients") component.setState({
		recipientsFilter: true
	});
	if (name === "status") component.setState({
		statusFilter: true
	});
	if (name === "time") component.setState({
		dateFilter: true
	});
	const event = {
		"time": "user-urgency-history: open date filter popup",
		"callers": "user-urgency-history: open callers filter popup",
		"recipients": "user-urgency-history: open recipients filter popup",
		"status": "user-urgency-history: open status filter popup",
	}[name];
	component.state.userMetrics.trackEvent(event);
}

function hideSearch(component, name) {
	if (name === "callers") component.setState({
		callersFilter: false
	});
	if (name === "recipients") component.setState({
		recipientsFilter: false
	});
	if (name === "status") component.setState({
		statusFilter: false
	});
	if (name === "time") component.setState({
		dateFilter: false
	});
	const event = {
		"time": "user-urgency-history: close date filter popup",
		"callers": "user-urgency-history: close callers filter popup",
		"recipients": "user-urgency-history: close recipients filter popup",
		"status": "user-urgency-history: close status filter popup",
	}[name];
	component.state.userMetrics.trackEvent(event);
}

function clearSearch(component, name) {
	const eventText = {
		"time": "user-urgency-history: clear date filter",
		"callers": "user-urgency-history: clear callers filter",
		"recipients": "user-urgency-history: clear recipients filter",
		"status": "user-urgency-history: clear status filter",
	}[name] || "user-urgency-history: clear filter";
	component.state.userMetrics.trackEvent(eventText);

	var clear = component.state.filterClear;
	if (name === "callers") {
		component.setState({
			callers: []
		});
		return clear("callers");
	}
	if (name === "recipients") {
		component.setState({
			recipients: []
		});
		return clear("recipients");
	}
	if (name === "status") {
		component.setState({
			callstatus: ""
		});
		return clear("status");
	}
	if (name === "time") {
		component.setState({
			startDate: "",
			endDate: new Date()
		});
		return clear("time");
	}
}

function renderFilterLabel(component, name) {
	var state = component.state;
	var localization = state.localization;
	if (name === "time") {
		var start = state.startDate;
		var end = state.endDate;
		if (isEmpty(start) || isEmpty(end)) {
			return localization.get("search_time");
		} else {
			start = shortDate(start);
			end = shortDate(end);
			const label = start + " - " + end;
			return label;
		}
	}
	if (name === "callers") {
		const length = state.callers.length;
		if (!length) return (localization.get("search_callers"));
		const label = localization.get("search_callers") + " (" + length + ")";
		return label;
	}
	if (name === "recipients") {
		const length = state.recipients.length;
		if (!length) return (localization.get("search_recipients"));
		const label = localization.get("search_recipients") + " (" + length + ")";
		return label;
	}
	if (name === "status") {
		var status = state.callstatus;
		if (!status) return localization.get("search_status");
		const label = localization.get("search_status_value")[status];
		return label;
	}
}

function shortDate(date) {
	var day = date.getDate();
	var month = date.getMonth() + 1;
	var year = date.getFullYear();
	return (day + "/" + month + "/" + year);

}

function handle_status(component, e) {
	e.persist();
	var value = e.target.value;
	component.setState({
		callstatus: value
	});
	hideSearch(component, "status");
	component.state.userMetrics.trackEvent("user-urgency-history: filter by status", {
		"status": value
	});
	return component.state.filterStatus(value);
}

function handle_date(component, value) {
	var state = component.state;
	hideSearch(component, value);
	var start = state.startDate;
	var end = adjust_date(state.endDate);
	component.state.userMetrics.trackEvent("user-urgency-history: filter by date", {
		start,
		end
	});
	return state.filterDate(start, end);
}

function noop() { }
