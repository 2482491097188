
/* © 2017 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, January 01, 2017
 * For information or permission request, email info@aetonixsystems.com
 */

var par = require("par");

var defineUI = require("../shared/define");
var render = require("./render.jsx");

var PersonStore = require("../stores/person_store");
var ReminderHistoryStore = require("../stores/reminder_history_store");
var ReminderDataStore = require("../stores/reminder_data_store");
var LocalizationStore = require("../stores/localization_store");
var CurrentPersonInfoStore = require("../stores/current_person_info_store");
var CareplanNotificationStore = require("../stores/careplan_notice_store");
var ConnectionStore = require("../stores/connection_store");

var makeStores = require("../shared/stores")({
	people: PersonStore,
	history: ReminderHistoryStore,
	reminders: ReminderDataStore,
	currentPerson: CurrentPersonInfoStore,
	localization: LocalizationStore,
	careplanChangeList: CareplanNotificationStore,
	connection: ConnectionStore
});

function make_ui(api, events, config, userMetrics) {
	var storeStore = makeStores(api, events, config);

	window.stores = storeStore.store;

	userMetrics.trackEvent("page view", {
		"page": "reminders-history",
		"category": "history",
		"permission level": "staff",
	});

	return defineUI({
		getInitialState: getInitialState,
		componentDidMount: listen_for_changes,
		render: render,
		componentWillUnmount: removeListeners
	});

	function removeListeners() {
		events.off();
	}

	function listen_for_changes() {
		storeStore.listen(par(handle_change, this));
	}

	function handle_change(component, stores) {
		component.setState({
			people: stores.people,
			reminders: stores.reminders,
			history: stores.history.sorted(),
			currentPerson: stores.currentPerson,
			localization: stores.localization,
			careplanChangeList: stores.careplanChangeList,
			connection: stores.connection
		});
	}

	function getInitialState() {
		return {
			people: storeStore.stores.people,
			currentPerson: storeStore.stores.currentPerson,
			reminders: storeStore.stores.reminders,
			history: storeStore.stores.history.sorted(),
			localization: storeStore.stores.localization,
			more: storeStore.stores.history.more,

			filterUsers: filterUsers,
			filterClear: filterClear,
			filterDateShown: filterDateShown,
			filterDateRespond: filterDateRespond,
			filterStatus: filterStatus,
			searchUsers: searchUsers,
			closeUserSearch: par(closeUserSearch, this),
			shownFilter: false,
			respondFilter: false,
			userFilter: false,
			statusFilter: false,
			report: false,
			startDate: "",
			endDate: new Date(),
			startShown: "",
			endShown: new Date(),
			startRespond: "",
			endRespond: new Date(),
			fileName: "",
			users: [],
			status: [],
			careplanChangeList: storeStore.stores.careplanChangeList,
			connection: storeStore.stores.connection,
			tabValue: 0,
			userMetrics: userMetrics,
		};
	}

	/**
	 * The first function returns a list of the group's users while the second
	 * is a list of _any_ person in the system matching the criteria
	 * @param  {objectId} query User_id
	 * @return {array}       User _id's
	 */
	function searchUsers(query) {
		return api.org.users.list(query);
	}

	function filterUsers(component, data) {
		var users = component.state.users;
		if(!users.includes(data)) {
			component.setState({
				users: users.concat(data)
			});

			storeStore.stores.history.searchUsers(data);
		}
		component.state.userMetrics.trackEvent("reminders-history: filter by user", {
			"user": data
		});
	}

	function filterDateShown(start, end) {
		storeStore.stores.history.searchShown(start, end);
	}

	function filterDateRespond(start, end) {
		storeStore.stores.history.searchRespond(start, end);
	}

	function filterStatus(component, e) {
		e.persist();
		var data = e.target.value;
		var formatted = data.map(function(item){
			var check_num = parseInt(item, 10);
			return isNaN(check_num) ? item : check_num;
		});
		component.setState({
			status: formatted
		});
		storeStore.stores.history.searchState(formatted);
		component.state.userMetrics.trackEvent("reminders-history: filter by status", {
			"status": formatted
		});
	}

	function filterClear(name) {
		storeStore.stores.history.clearSearch(name);
	}

	function closeUserSearch(component) {
		component.setState({
			userFilter: false
		});
		component.state.userMetrics.trackEvent("reminders-history: close filter by user popup");
	}

}

module.exports = make_ui;
