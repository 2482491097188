/* © 2014 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, June 19, 2014
 * For information or permission request, email info@aetonixsystems.com
 */

"use strict";

var Store = require("./store");
var map = require("fj-map");
var compose = require("fj-compose");
var pluck = require("fj-pluck");
var getWho = pluck("who");
var Promise = require("promise");

import * as Sentry from '@sentry/react';

module.exports = UserStore;

function UserStore(api, events, config) {
	var store = new Store();

	api.org.group.current.data()
		.then(pluck("_id"))
		.then(listen_for_changes)
		.catch(error => {
			Sentry.captureException(error);
			console.error.bind(consoleo, error);
		});

	var query = "";
	var page = 0;
	var status = "";
	var loading = null;
	var atEnd = false;

	store.more = more;
	store.search = search;
	store.searchStatus = searchStatus;

	reset();

	return store;

	function reset() {
		page = 0;
		atEnd = false;
		store.clear();
		return api.org.users
			.assigned(query, page, status)
			.then(map(add_person));
	}

	function more() {
		if (atEnd)
			return Promise.resolve();
		if (loading)
			return loading;
		page += 1;
		return api.org.users
			.list(query, page, status)
			.then(handle_users);
	}

	function handle_users(users) {
		if (!users.length) {
			atEnd = true;
			return;
		}

		users.map(add_person);

		loading = null;
	}

	function search(newQuery) {
		query = newQuery;
		return reset();
	}

	// Does a search on a user's status ie. active/inactive.  Null or empty string
	// argument applies no filter
	function searchStatus(newStatus) {
		status = newStatus;
		return reset();
	}

	function add_person(id) {
		store.set(id, id);
	}

	function remove_person(id) {
		store.remove(id);
	}

	function listen_for_changes(group) {
		events.on("mqtt:orggroup/" + group + "/users/added", compose(add_person, getWho));
		events.on("mqtt:orggroup/" + group + "/users/removed", compose(remove_person, getWho));
	}
}
