/* © 2017 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, May 08, 2018
 * For information or permission request, email info@aetonixsystems.com
 */

"use strict";


var Store = require("./store");
var par = require("par");
var xtend = require("xtend");

module.exports = AdminWorkflowStore;

function AdminWorkflowStore(api, events, config) {
	var store = new Store();

	store.raw_get = store.get;
	store.get = get;

	var tracking = [];

	return store;

	function get(id) {
		var existing = store.raw_get(id);
		if (existing) return existing;

		if (!isTracking(id))
			track(id);

		return [];
	}

	function isTracking(id) {
		return tracking.indexOf(id) !== -1;
	}

	function track(id) {
		tracking.push(id);
		api.admin.workflow.list(id).then(par(setWorkflow, id));
		events.on("mqtt:org/" + id + "/workflow/definitions/removed", par(removeWorkflow, id));
		events.on("mqtt:org/" + id + "/workflow/definitions/created", par(addWorkflow, id));
		events.on("mqtt:org/" + id + "/workflow/definitions/updated", par(updateWorkflow, id));
	}

	function removeWorkflow(id, data) {
		var workflow = data;
		var existing = store.get(id);
		if (!existing) return;

		var filtered = existing.filter(function (existingWorkflow) {
			return existingWorkflow._id !== workflow._id;
		});

		store.set(id, filtered);
	}

	function addWorkflow(id, data) {
		var workflow = data;
		var existing = store.get(id);
		if (!existing) return;

		var added = existing.concat(workflow);

		store.set(id, added);
	}

	function updateWorkflow(id, data) {
		var workflow = data;
		var existing = store.get(id);
		if (!existing) return;

		var updated = existing.map(function (current) {
			if (workflow._id === current._id)
				return xtend(current, workflow);
			return current;
		});

		store.set(id, updated);
	}

	function setWorkflow(id, workflow) {
		var filteredWorkflow = workflow.filter(function(workflow){
			return workflow.organization !== undefined;
		});
		store.set(id, filteredWorkflow);
	}

}
