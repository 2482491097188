/* © 2017 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, January 01, 2017
 * For information or permission request, email info@aetonixsystems.com
 */

"use strict";
var Store = require("./store");
var par = require("par");

module.exports = AdminActiveCountStore;

function AdminActiveCountStore(api) {
	var store = new Store();
	store.raw_get = store.get;
	store.get = get;

	var tracking = [];

	return store;

	function get(id){
		var data = store.raw_get(id);
		if(data) return data;

		if(!isTracking(id))
			track(id);

		return {
			groups: "-",
			patients: "-",
			inactive: "-",
			active: "-",
			staff: "-",
			managers: "-",
			users: "-"
		};
	}

	function isTracking(id){
		return tracking.indexOf(id) !== -1;
	}

	function track(id){
		var prefix = api.admin.stats.org;
		return Promise.all([
			prefix.groupCount(id),
			prefix.patientCount(id),
			prefix.inactiveCount(id),
			prefix.activeCount(id),
			prefix.staffCount(id),
			prefix.managersCount(id),
			prefix.userCount(id)
		]).then(par(handle_count, id));
	}

	function handle_count(id, results){
		store.set(id, {
			groups: results[0],
			patients: results[1],
			inactive: results[2],
			active: results[3],
			staff: results[4],
			managers: results[5],
			users: results[6]
		});
	}
}
