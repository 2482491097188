
"use strict";
var Store = require("./store");

module.exports = ManagerDefaultPermissions;

function ManagerDefaultPermissions(api, events, config) {
	var store = new Store();

	api.org.manage.presetPermissions.list()
		.then(setPresetPermissions)
		.then(trackChanges);

	return store;

	function setPresetPermissions(permissionsList) {
		store.set("preset", permissionsList);
	}

	function trackChanges() {
		var orgGroup = config.orgGroup;
		if (!orgGroup) return;
		events.on("mqtt:orggroup/" + orgGroup + "/preset/manager/permissions/updated", handle_update);
		events.on("mqtt:orggroup/" + orgGroup + "/preset/manager/permissions/created", handle_created);
		events.on("mqtt:orggroup/" + orgGroup + "/preset/manager/permissions/removed", handle_removed);
	}

	function handle_update(data) {
		const oldList = store.get("preset") || []
		const updatedList = [...oldList]

		const index = updatedList.findIndex((item) => item._id === data._id)

		if (index >= 0) {
			const oldItem = updatedList[index];
			const newItem = { ...oldItem, ...data.data }

			updatedList[index] = newItem
		}
		setPresetPermissions(updatedList);
	}

	function handle_created(data) {
		const oldList = store.get("preset") || []
		const updatedList = [...oldList]
		updatedList.push(data)
		setPresetPermissions(updatedList);
	}

	function handle_removed(id) {
		const oldList = store.get("preset") || []
		const updatedList = [...oldList]
		const index = updatedList.findIndex((item) => item._id === id)
		if (index >= 0) {
			updatedList.splice(index, 1)
			setPresetPermissions(updatedList);
		}
	}
}