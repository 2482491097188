
/* © 2017 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, January 01, 2017
 * For information or permission request, email info@aetonixsystems.com
 */


var React = require("react");
var par = require("par");

/**
 * User Interface elements material-ui
 */
var Header = require("../shared/Header.js");

var Table = require("@material-ui/1.5.1/Table").default;
var TableHeader = require("@material-ui/1.5.1/TableHead").default;
var TableHeaderColumn = require("@material-ui/1.5.1/TableCell").default;
var TableBody = require("@material-ui/1.5.1/TableBody").default;
var TableRow = require("@material-ui/1.5.1/TableRow").default;
var TableRowColumn = require("@material-ui/1.5.1/TableCell").default;
var Utility = require("../shared/Utility");
var Colors = require("../shared/AetonixTheme").palette;

var styles = {
	header: {
		color: Colors.primary.dark,
	}
};

module.exports = render;

function render() {
	var component = this;
	var state = component.state;
	var localization = state.localization;
	var careplanNoticeList = state.careplanChangeList.all();
	var careplanChanges = !!careplanNoticeList.length;
	var offline = state.connection.get("offline");
	var titleKey = "user_wander_live";

	var people = state.people;
	var currentPerson = state.currentPerson;
	var machinegroup = state.machinegroup;
	var bracelet = state.devicepref;
	var wandering = state.wandering;
	var rowData = wandering.map(par(convertData, localization, people, bracelet, machinegroup));

	var headers = [{
		name: "user",
		content: localization.get("user_wander_history_user")
	}, {
		name: "status",
		content: localization.get("user_wander_history_status")
	}, {
		name: "time",
		content: localization.get("user_wander_history_time")
	}, {
		name: "location",
		content: localization.get("user_wander_history_location")
	}];

	if (dataHasChanged(wandering, state.previousWandering)) {
		component.state.userMetrics.trackEvent("user-wander-live: view wander data", {
			wanders: wandering
		});
		component.setState({
			previousWandering: wandering
		});
	}

	return (
		<div className="flex-vertical flex-1">

			<Header
				careplanChanges={careplanChanges}
				offline={offline}
				currentPerson={currentPerson}
				localization={localization}
				titleKey={titleKey}
			/>
			<div className="flex-1">
				<Table>
					{renderHeader(headers)}
					{renderBody(localization, headers, rowData)}
				</Table>
			</div>
		</div>
	);
}

function dataHasChanged(wandering, previousWandering) {
	wandering = wandering || [];
	previousWandering = previousWandering || [];
	if (wandering.length !== previousWandering.length) {
		return true;
	}
	for (var i = 0; i < wandering.length; i++) {
		if (wandering[i].time !== previousWandering[i].time) {
			return true;
		}
	}
	return false;
}

function renderHeader(headers) {
	return (
		<TableHeader>
			<TableRow style={styles.header}>
				{headers.map(renderHeaderColumn)}
			</TableRow>
		</TableHeader>
	);
}

function renderHeaderColumn(column, index) {
	return (
		<TableHeaderColumn key={index}>{column.content}</TableHeaderColumn>
	);
}

function renderBody(localization, headers, rows) {
	var sorted = rows.sort(by_name);
	return (
		<TableBody>
			{sorted.map(par(renderRow, localization, headers))}
		</TableBody>
	);
}

function renderRow(localization, headers, row, index) {
	if (!row || row.relay) return;
	return (
		<TableRow key={index}>
			{headers.map(par(renderRowColumn, localization, row))}
		</TableRow>
	);
}

function renderRowColumn(localization, row, column, index) {
	var entry = row[column.name];
	if (column.name === "status") entry = translate(localization, entry);
	if (column.name === "status" && row.care && row.care !== ("" || " ...  "))
		entry = entry + " [" + row.care + "]";

	if (row.status === ("disconnect" || "wandering")) {
		return (
			<TableRowColumn style={{ color: "red" }}>
				{entry}
			</TableRowColumn>
		);
	}
	if (row.status === "acknowledge") {
		return (
			<TableRowColumn style={{ color: "orange" }}>
				{entry}
			</TableRowColumn>
		);
	}
	if (row.status === "connect") {
		return (
			<TableRowColumn style={{ color: "green" }}>
				{entry}
			</TableRowColumn>
		);
	}
	if (row.status === "invalid") {
		return (
			<TableRowColumn style={{ color: "blue" }}>
				{entry}
			</TableRowColumn>
		);
	}
	if (row.status === "wander") {
		return (
			<TableRowColumn style={{ color: "orange" }}>
				{entry}
			</TableRowColumn>
		);
	}
	return (
		<TableRowColumn key={index}>
			{entry}
		</TableRowColumn>
	);
}

function translate(localization, entry) {
	var zones = {
		"disconnect": localization.get("wander_out_zone"),
		"wandering": localization.get("wander_out_zone"),
		"acknowledge": localization.get("wander_acknowledge"),
		"invalid": localization.get("wander_false"),
		"wander": localization.get("wander_wander"),
		"connect": localization.get("wander_in_zone")
	};
	return zones[entry];
}

function convertData(localization, people, bracelet, machines, record) {

	var zone = record.status;
	var bracelet_user = Utility.format_name(people.get(record.who));
	var time_of_event = Utility.timestamp(record.time);
	var machine = machines.get(record.location);
	var machine_of_user = localization.get("user_wander_live_unknown");

	//check valid machine serial?
	if (machine) machine_of_user = record.location;

	// If the user is not currently assigned a bracelet, state that in status
	// and do not show anything for 'time', or 'last connected machine'
	if (!bracelet.get(record.who).mac) {
		return;
	}

	// When there is an acknowledge, display on 'live' who it was else, don't
	var care = null;
	if (record.care && record.care !== "No acknowledgement.")
		care = Utility.format_name(people.get(record.care));

	return {
		"user": bracelet_user,
		"status": zone,
		"time": time_of_event,
		"location": machine_of_user,
		"relay": bracelet.get(record.who).relay,
		"care": care
	};
}

function by_name(x, y) {
	if (x.user < y.user) return -1;
	if (x.user > y.user) return 1;
	return 0;
}