/* © 2017 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, January 01, 2017
 * For information or permission request, email info@aetonixsystems.com
 */

var xtend = require("xtend");

var API = require("ae-api");
var Dispatcher = require("ae-dispatcher");
var config = require("../../configs/config.json");

var token = localStorage.token;
var region = localStorage.region || "Canada";

import * as Sentry from '@sentry/react';

if (!token) go_login();

config.token = token;

var hostElement = (config.hosts || []).find(host => host.name === region) || {};
config.host = config.host || hostElement.host;
config.registration = config.registration || hostElement.registration;

var api = API(config);

module.exports = api.auth
	.auth()
	.then(build_dispatcher)
	.then(build_resources)
	.catch(error => {
		Sentry.captureException(error);
		go_login();
	});

function build_dispatcher(user) {
	return Dispatcher.createDispatcher(
		xtend(config, {
			id: user._id,
			quiet: true
		})
	);
}

async function build_resources(events) {
	var debugEvents = localStorage.getItem("DEBUG_EVENTS");
	if (debugEvents)
		events.onAny(function(event, data) {
			console.log("Event:", event, data);
		});

	let resources = {
		config: config,
		events: events,
		api: api
	};
	try {
		let group = await api.org.group.current.id();
		let newConfig = xtend(resources.config, {
			orgGroup: group
		});
		resources.config = newConfig;
	} catch (err) {
		//doesnt matter
	}
	try {
		let authData = await api.auth.auth();
		let newConfig = xtend(resources.config, {
			auth: authData
		});
		resources.config = newConfig;

		Sentry.setUser({ id: newConfig.auth._id, name: 'Dashboard User' });
	} catch (err) {
		//doesnt matter
	}

	try {
		let orgId = await api.org.id();
		let newConfig = xtend(resources.config, {
			organization: orgId
		});
		resources.config = newConfig;
	} catch (err) {
		//doesnt matter
	}
	return resources;
}

function go_login() {
	return;
}
