import { Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import Form from "ae-forms";
import React from "react";
import { useLocalize } from "../../../shared/contexts/LocalizeContext";
import { useLocalizeFrom } from "../../../shared/contexts/LocalizeFromContext";

export function EditFormDialog({ isOpen, onClose, submission, submitForm }) {
	const localize = useLocalize();
	const localizeFrom = useLocalizeFrom();

	async function handleSubmit(data) {
		const response = await submitForm({
			formId: submission.schema._id,
			submissionId: submission._id,
			data
		});
		if (response.status === "success") {
			onClose();
			return;
		}
	}

	return (
		<>
			<Dialog aria-labelledby="alert-dialog-title" fullWidth maxWidth="md" onClose={onClose} open={isOpen}>
				<DialogTitle>{localizeFrom(submission.schema.localization, ".") || ""}</DialogTitle>
				<DialogContent>
					<Form
						schema={submission.schema.schema}
						defaultData={submission.data}
						onCancel={onClose}
						cancelText={localize("groupforms.discardchanges")}
						submitText={localize("common.save")}
						onSubmit={handleSubmit}
					></Form>
				</DialogContent>
			</Dialog>
		</>
	);
}
