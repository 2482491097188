/* © 2014 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, June 19, 2014
 * For information or permission request, email info@aetonixsystems.com
 */

var par = require("par");

var defineUI = require("../shared/define");
var render = require("./render.jsx");

var PersonStore = require("../stores/person_store");
var LocalizationStore = require("../stores/localization_store");
var CurrentPersonInfoStore = require("../stores/current_person_info_store");
var CareplanNotificationStore = require("../stores/careplan_notice_store");
var ConnectionStore = require("../stores/connection_store");
var WorkflowDefinitionStore = require("../stores/workflow_definitions_store");
var WorkflowAutostart = require("../stores/group_workflow_autostart_store.js");

var makeStores = require("../shared/stores")({
	people: PersonStore,
	currentPerson: CurrentPersonInfoStore,
	localization: LocalizationStore,
	careplanChangeList: CareplanNotificationStore,
	connection: ConnectionStore,
	workflow: WorkflowDefinitionStore,
	autostart: WorkflowAutostart
});

function make_ui(api, events, config, userMetrics) {
	var storeStore = makeStores(api, events, config);

	window.stores = storeStore.store;

	userMetrics.trackEvent("page view", {
		"page": "group-pathway-autostart",
		"category": "group",
		"permission level": "staff",
	});

	return defineUI({
		getInitialState: getInitialState,
		componentDidMount: listen_for_changes,
		render: render,
		componentWillUnmount: removeListeners
	});

	function removeListeners() {
		events.off();
	}

	function listen_for_changes() {
		storeStore.listen(par(handle_change, this));
	}

	function handle_change(component, stores) {
		component.setState({
			people: stores.people,
			currentPerson: stores.currentPerson,
			localization: stores.localization,
			careplanChangeList: stores.careplanChangeList,
			connection: stores.connection,
			workflow: stores.workflow,
			autostart: stores.autostart.get("settings")
		});
	}

	function getInitialState() {
		return {
			people: storeStore.stores.people,
			currentPerson: storeStore.stores.currentPerson,
			localization: storeStore.stores.localization,
			careplanChangeList: storeStore.stores.careplanChangeList,
			connection: storeStore.stores.connection,
			workflow: storeStore.stores.workflow,
			autostart: storeStore.stores.autostart,
			settings: null,
			search: doSearch,
			save: saveSettings,
			toast: false,
			remove: removeSettings,
			userMetrics: userMetrics,
		};
	}

	function doSearch(query) {
		var filter = {
			type: {
				exclude: ["atouchaway", "user:mobile", "simplified"]
			}
		};
		return api.people.search(query, filter);
	}

	function removeSettings(){
		return api.org.workflow.autostart.remove();
	}

	function saveSettings(component){
		var settings = component.state.settings;
		return api.org.workflow.autostart.set(settings);
	}
}

module.exports = make_ui;
