/* © 2014 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, June 19, 2014
 * For information or permission request, email info@aetonixsystems.com
 */

var React = require("react");
var par = require("par");
var config = require("../../configs/config.json");
var escape_regex = require("escape-regexp");
var xtend = require("xtend");

var Header = require("../shared/Header.js");
var SubHeader = require("@material-ui/1.5.1/ListSubheader").default;

var IconButton = require("@material-ui/1.5.1/IconButton").default;
var Avatar = require("@material-ui/1.5.1/Avatar").default;
var ListItem = require("@material-ui/1.5.1/ListItem").default;
var ListItemText = require("@material-ui/1.5.1/ListItemText").default;
var Dialog = require("../shared/Dialog");
var MenuItem = require("@material-ui/1.5.1/MenuItem").default;
var Select = require("@material-ui/1.5.1/Select").default;
var Checkbox = require("@material-ui/1.5.1/Checkbox").default;

var Button = require("@material-ui/1.5.1/Button").default;
var TextField = require("@material-ui/1.5.1/TextField").default;

var Utility = require("../shared/Utility");
var LazyList = require("../shared/LazyList.jsx");
var Search = require("../shared/Search.jsx");
var Colors = require("../shared/AetonixTheme").palette;

var PermissionsList = require("../shared/PermissionsList.js");
var SETTINGS_PERMISSIONS = PermissionsList.SettingsPermissions;
const GROUP_NAME_MAX_LENGTH = 50;
var iconButtonClasses = {
	root: "ae-inline"
};

var styles = {
	dropUnderline: {
		borderTop: 0,
	},
	dropDown: {
		alignItems: "baseline",
		marginLeft: "30px",
		padding: "0 5px",
		color: Colors.primary.main
	},
	search: {
		color: Colors.primary.main,
		minWidth: "30%",
		padding: 8
	},
	tabsRoot: {
		backgroundColor: Colors.primary.main,
		color: "white"
	},
	subHeader: {
		display: "flex",
		flexDirection: "row",
		paddingLeft: "1em",
		alignItems: "baseline"
	},
	input: {
		color: "grey",
		marginTop: "8px",
		marginBottom: "8px"
	},
	inputProp: {
		style: {
			color: Colors.primary.main,
		}
	},
	formControlStyle: {
		marginTop: "8px",
		marginBottom: "8px"
	},
	userInactive: {
		color: "red"
	},
	snackbar: {
		style: {
			backgroundColor: "red"
		}
	},
	uploadButton: {
		margin: "10px"
	},
	inputFile: {
		display: "none"
	},
	image: {
		margin: "10px 0px",
		width: "200px",
		height: "200px"
	}
};

var selectClasses = {
	root: "ae-width"
};


module.exports = render;

function render() {
	var component = this;
	var state = component.state;
	var people = state.people;
	var localization = state.localization;
	var careplanNoticeList = state.careplanChangeList.all();
	var careplanChanges = !!careplanNoticeList.length;
	var offline = state.connection.get("offline");
	var creating = state.creating;
	var updating = state.updating;
	var currentPerson = state.currentPerson;
	var managerGroups = state.managerGroups;
	var managersStore = state.managerGroupManagers;
	var viewing = state.viewing;
	var removingManager = state.removingManager;
	var removing = state.removing;
	var locking = state.locking;
	var unlocking = state.unlocking;
	var message = state.message;
	var filteredManagers = state.filteredManagers;
	var filteredManagerGroups = state.filteredManagerGroups;
	var userForms = state.userForms;
	var permissionDialog = state.openPermissions;

	var updateName = state.updateName;
	var updateDescription = state.updateDescription;

	var titleKey = "managergroups_title";
	var managersRaw = [];
	var managers = [];
	var shownManagers = [];
	var allManagerGroups = managerGroups.all().sort(byManagerGroupsName);
	var shownManagerGroups = filteredManagerGroups && filteredManagerGroups.length ? filteredManagerGroups : allManagerGroups;

	const isOrgManager = state.currentPerson.get("personal").type?.includes("org:manager");
	const isAdmin = state.currentPerson.get("personal").type?.includes("admin");
	const lockPermission = isOrgManager || isAdmin;

	if(viewing){
		managersRaw = managersStore.get(viewing);
		managers = managersRaw.map(people.get).sort(by_name);
		shownManagers = filteredManagers && filteredManagers.length ? filteredManagers : managers;
	}

	var currentName = null;
	var currentDescription = null;
	var updateTitle = null;
	if(updating){
		var currentGroup = managerGroups.get(updating);
		updateTitle = currentGroup.name;
		currentName = updateName || currentGroup.name;
		currentDescription = updateDescription || currentGroup.description;
	}

	var userFormList = userForms.map(function (form) {
		return form._id;
	});

	var create_actions = [(
		<Button onClick={par(doCreate, component)}>{localization.get("users_create")}</Button >
	), (
		<Button onClick={par(hideCreate, component)}>{localization.get("users_cancel")}</Button >
	)];

	var update_actions = [(
		<Button onClick={par(doUpdate, component)}>{localization.get("users_update")}</Button >
	), (
		<Button onClick={par(hideUpdate, component)}>{localization.get("users_cancel")}</Button >
	)];

	var remove_actions_manager = [(
		<Button key={"user_ok"} onClick={par(confirmRemoveManager, component)}>{localization.get("users_ok")}</Button >
	), (
		<Button key={"users_cancel"} onClick={par(hideRemovingManager, component)}>{localization.get("users_cancel")}</Button >
	)];

	const lock_actions = [(
		<Button key={"user_ok"} onClick={par(confirmLock, component)}>{localization.get("users_ok")}</Button >
	), (
		<Button key={"users_cancel"} onClick={par(hideLockingPatientGroup, component)}>{localization.get("users_cancel")}</Button >
	)];

	const message_actions = [(
		<Button key={"user_ok"} onClick={() => {
			component.state.userMetrics.trackEvent("group-careplans: close message popup");
			component.setState({message: false});
		}}>{localization.get("users_ok")}</Button >
	)];

	const unlock_actions = [(
		<Button key={"user_ok"} onClick={par(confirmUnlock, component)}>{localization.get("users_ok")}</Button >
	), (
		<Button key={"users_cancel"} onClick={par(hideUnlockingPatientGroup, component)}>{localization.get("users_cancel")}</Button >
	)];

	var remove_actions = [(
		<Button key={"user_ok"} onClick={par(confirmRemove, component)}>{localization.get("users_ok")}</Button >
	), (
		<Button key={"users_cancel"} onClick={par(hideRemovingManagerGroup, component)}>{localization.get("users_cancel")}</Button >
	)];


	var renderManagersLazyList = () => {
		if (!managers.length) return null;
		return (viewing ? (
			<LazyList className="ae-scrollable" renderItem={par(renderManager, component)} items={shownManagers} />
		) : null);
	};

	var renderAddNewManager = () => {
		var group = component.state.selectedGroup;
		if(group){
			var groupData = state.managerGroups.get(group);
			var isLocked = groupData?.isLocked;
			if(isLocked && !lockPermission) return null;
			return ( viewing && <Button variant="raised" onClick={par(startAdding, component)} color="secondary" >{localization.get("managergroups_addManager")}</Button>);
		}
	};

	var renderSearchManager = () => {
		if (!managers.length) return null;
		return (viewing ? (
			<TextField key={viewing} placeholder={localization.get("managergroups_searchManager")} onChange={par(search, component, managers, "Managers")} style={styles.search} InputProps={styles.inputProp} />
		) : null);
	};

	const permissionActions = () => {
		var group = component.state.selectedGroup;
		if(!group) return;
	    var groupData = state.managerGroups.get(group);
		if(!groupData) return;
		const isLocked = groupData?.isLocked;

		if(isLocked && !lockPermission){
			return (<Button key={"permissions_done"} color="primary" onClick={par(closePermissions, component)}>{localization.get("permissions_done")}</Button>);
		}
		return ([
		<Button key={"permissions_submit"} color="primary" onClick={par(handle_changePermissions, component, userFormList)} >{localization.get("permissions_submit")}</Button>,
		<Button key={"permissions_done"} color="primary" onClick={par(closePermissions, component)}>{localization.get("permissions_done")}</Button>
	]);
};

	return (
		<div className="flex-vertical flex-1">
			<Header
				careplanChanges={careplanChanges}
				offline={offline}
				currentPerson={currentPerson}
				localization={localization}
				titleKey={titleKey}
			>
			</Header>

			<div className="flex-horizontal flex-1 ae-scrollable">
				<div className="flex-vertical flex-1 ae-left-margin ">
					<SubHeader>{localization.get("managergroups_subtitle")}</SubHeader>
					<TextField placeholder={localization.get("managergroups_search")} onChange={par(search, component, allManagerGroups, "ManagerGroups")} style={styles.search} InputProps={styles.inputProp} />
					<LazyList className=" ae-scrollable" renderItem={par(renderManagerGroup, component)} items={shownManagerGroups} />
					<Button variant="raised" onClick={par(startCreating, component)} color="secondary">{localization.get("managergroups_create")}</Button>
				</div>
				<div className="flex-vertical flex-1">
					<div className="flex-vertical flex-1 ae-scrollable">
						<SubHeader>{localization.get("user_manage_managers")}</SubHeader>
						{renderSearchManager()}
						{renderManagersLazyList()}
					</div>
					{renderAddNewManager()}
				</div>
			</div>

			<Search selection={managersRaw} action={par(addManager, component)} ref="searchDialog" search={state.search} localization={localization} />

			<Dialog title={localization.get("managergroups_create")} actions={create_actions} modal={true} open={!!creating}>
				<div className="flex-vertical">
					<TextField placeholder={localization.get("callgroups_name")} value={state.newName} onChange={par(updateActionChanged, component, "newName")} label={localization.get("callgroups_name")} style={styles.input}  inputProps={{ maxlength: GROUP_NAME_MAX_LENGTH}} />
					<TextField placeholder={localization.get("callgroups_description")} value={state.newDescription} onChange={par(updateActionChanged, component, "newDescription")} label={localization.get("callgroups_description")} style={styles.input} InputProps={styles.inputProp} />
				</div>
			</Dialog>

			<Dialog title={updateTitle} actions={update_actions} modal={true} open={!!updating}>
				<div className="flex-vertical">
					<TextField defaultValue={currentName} placeholder={localization.get("callgroups_name")} value={currentName} onChange={par(updateActionChanged, component, "updateName")} label={localization.get("callgroups_name")} style={styles.input}  inputProps={{ maxlength: GROUP_NAME_MAX_LENGTH}} />
					<TextField defaultValue={currentDescription} placeholder={localization.get("callgroups_description")} value={currentDescription} onChange={par(updateActionChanged, component, "updateDescription")} label={localization.get("callgroups_description")} style={styles.input} InputProps={styles.inputProp} />
				</div>
			</Dialog>

			<Dialog actions={remove_actions_manager} open={!!removingManager} title={localization.get("managergroups_removeManager")}>
				{localization.get("callgroups_warning") + " " + Utility.format_name(people.get(removingManager)) + "?"}
			</Dialog>

			<Dialog actions={lock_actions} open={!!locking} title={"Lock Patient Group"}>
				{localization.get("callgroups_locking") + " " + (locking ? managerGroups.get(locking).name : "") + "?"}
			</Dialog>

			<Dialog actions={message_actions} open={!!message}>
				{localization.get("callgroups_message")}
			</Dialog>

			<Dialog actions={unlock_actions} open={!!unlocking} title={"Unlock Patient Group"}>
				{localization.get("callgroups_unlocking") + " " + (unlocking ? managerGroups.get(unlocking).name : "") + "?"}
			</Dialog>

			<Dialog actions={remove_actions} open={!!removing} title={localization.get("managergroups_remove")}>
				{localization.get("callgroups_warning") + " " + (removing ? managerGroups.get(removing).name : "") + "?"}
			</Dialog>

			<Dialog title={permissionsTitle(localization, component)} actions={permissionActions(component)} modal={true} open={!!permissionDialog}>
				<div className="flex-vertical">
					{renderPermissionToggle(component, localization, userForms, userFormList)}
				</div>
			</Dialog>
		</div>
	);
}

function renderManagerGroup(component, group){
	var text = group.name;
	var secondaryText = group.description;
	var groupId = group._id;
	var background_colour = "ae-plain";
	var localization = component.state.localization;
	var viewing = component.state.viewing;
	var groupData = component.state.managerGroups.get(groupId);
	var isLocked = groupData.isLocked;
	const isAdmin = component.state.currentPerson.get("personal").type?.includes("admin") ;
	const isOrgManager = component.state.currentPerson.get("personal").type?.includes("org:manager") ;
	if (groupId === viewing)
		background_colour = "ae-hover-color";

	if(isLocked){
		if(isOrgManager || isAdmin){
			return(
				<ListItem key={groupId} className={background_colour} onClick={par(viewGroup, component, groupId)}>
				<ListItemText primary={text} secondary={secondaryText} />
				<IconButton classes={iconButtonClasses} disableRipple aria-label={localization.get("lock_button")} title={localization.get("lock_button")} role="button" color="primary" className="fa fa-lock" onClick={par(unlockPatientGroup, component, groupId)}/>
				<IconButton classes={iconButtonClasses} disableRipple aria-label={localization.get("change_permission_button")} title={localization.get("change_permission_button")} role="button" color="primary" className="fa fa-gear" onClick={par(openPermissions, component, groupId)} />
				<IconButton classes={iconButtonClasses} disableRipple aria-label={localization.get("edit_button")}  title={localization.get("edit_button")}  role="button" color="primary" className="fa fa-pencil" onClick={par(startUpdate, component, groupId)} />
				<IconButton classes={iconButtonClasses} disableRipple aria-label={localization.get("remove_button")} title={localization.get("remove_button")} role="button" color="primary" className="fa fa fa-times-circle" onClick={par(startRemoveManagerGroup, component, groupId)} />
		</ListItem>
			);
		} else {
			return(
				<ListItem key={groupId} className={background_colour} onClick={par(viewGroup, component, groupId)}>
					<ListItemText primary={text} secondary={secondaryText} />
					<IconButton classes={iconButtonClasses} disableRipple aria-label={localization.get("lock_button")} title={localization.get("lock_button")} role="button" color="primary" className="fa fa-lock" onClick={par(unlockPatientGroup, component, groupId)}/>
					<IconButton classes={iconButtonClasses} disableRipple aria-label={localization.get("change_permission_button")} title={localization.get("change_permission_button")} role="button" color="primary" className="fa fa-gear" onClick={par(openPermissions, component, groupId)} />
				</ListItem>
				);
		}
	} else {
		return (
			<ListItem key={groupId} className={background_colour} onClick={par(viewGroup, component, groupId)}>
				<ListItemText primary={text} secondary={secondaryText} />
				<IconButton classes={iconButtonClasses} disableRipple aria-label={localization.get("lock_button")} title={localization.get("lock_button")} role="button" color="primary" className="fa fa-unlock" onClick={par(lockPatientGroup, component, groupId)}/>
				<IconButton classes={iconButtonClasses} disableRipple aria-label={localization.get("change_permission_button")} title={localization.get("change_permission_button")} role="button" color="primary" className="fa fa-gear" onClick={par(openPermissions, component, groupId)} />
				<IconButton classes={iconButtonClasses} disableRipple aria-label={localization.get("edit_button")}  title={localization.get("edit_button")}  role="button" color="primary" className="fa fa-pencil" onClick={par(startUpdate, component, groupId)} />
				<IconButton classes={iconButtonClasses} disableRipple aria-label={localization.get("remove_button")} title={localization.get("remove_button")} role="button" color="primary" className="fa fa fa-times-circle" onClick={par(startRemoveManagerGroup, component, groupId)} />
			</ListItem>
		);
	}
}

function viewGroup(component, group){
	component.setState({
		viewing: group,
		filteredManagers: [],
		selectedGroup: group
	});
	component.state.userMetrics.trackEvent("group-careplans: view patient group", {
		group
	});
}

function startCreating(component){
	component.setState({
		creating: true
	});
	component.state.userMetrics.trackEvent("group-careplans: open create patient group popup");
}

function hideCreate(component){
	component.setState({
		creating: false,
		newName: null,
		newDescription: null
	});
	component.state.userMetrics.trackEvent("group-careplans: close create patient group popup");
}

function doCreate(component){
	var name = component.state.newName;
	var description = component.state.newDescription;
	var file = component.state.file;
	var create = component.state.create;

	create(name, description, file);
	hideCreate(component);

	component.state.userMetrics.trackEvent("group-careplans: create patient group", {
		name,
		description,
		file,
	});
}

function doUpdate(component){
	var name = component.state.updateName;
	var description = component.state.updateDescription;
	var group = component.state.updating;
	var update = component.state.update;
	var file = component.state.file;

	update(group, name, description, file);
	hideUpdate(component);

	component.state.userMetrics.trackEvent("group-careplans: edit patient group", {
		name,
		description,
		file,
	});
}

function updateActionChanged(component, name, event) {
	event.persist();
	var value = event.target.value;
	var update = {};
	update[name] = value;
	component.setState(update);
}

function renderManager(component, manager){
	var avatar = <Avatar alt="Manager Avatar Image" src={config.image_cdn + manager.image} />;
	var text = Utility.format_name(manager);
	var localization = component.state.localization;
	var managerId = manager._id;
	var background_colour = "ae-plain";
	var state = component.state;
	var group = state.selectedGroup;

	if( group){
	var groupData = state.managerGroups.get(group);
	const isAdmin = state.currentPerson.get("personal").type?.includes("admin");
	const isLocked = groupData?.isLocked;
	const isOrgManager = state.currentPerson.get("personal").type?.includes("org:manager");
	const lockPermission = isOrgManager || isAdmin;

	return (
		<ListItem key={managerId} className={background_colour}>
			{avatar}
			<ListItemText primary={text} />
			{isLocked && !lockPermission ?  null : <IconButton classes={iconButtonClasses} disableRipple aria-label={localization.get("remove_button")} title={localization.get("remove_button")} role="button" color="primary" className="fa fa-user-times" onClick={par(removeManager, component, managerId)} /> }
		</ListItem>
	);
	}

	return (
		<ListItem key={managerId} className={background_colour}>
			{avatar}
			<ListItemText primary={text} />
			 <IconButton classes={iconButtonClasses} disableRipple aria-label={localization.get("remove_button")} title={localization.get("remove_button")} role="button" color="primary" className="fa fa-user-times" onClick={par(removeManager, component, managerId)} />
		</ListItem>
	);
}

function startAdding(component){
	component.refs.searchDialog.show();
	component.state.userMetrics.trackEvent("group-careplans: open add manager popup");
}

function removeManager(component, manager){
	component.setState({
		removingManager: manager
	});
	component.state.userMetrics.trackEvent("group-careplans: open remove manager popup", {
		user: manager,
	});
}

function hideRemovingManager(component){
	component.setState({
		removingManager: false
	});
	component.state.userMetrics.trackEvent("group-careplans: close remove manager popup");
}

function confirmRemoveManager(component){
	var remove = component.state.removeManager;
	var group = component.state.viewing;
	var manager = component.state.removingManager;
	remove(group, manager);
	hideRemovingManager(component);
	component.state.userMetrics.trackEvent("group-careplans: remove manager", {
		user: manager,
	});
}



function addManager(component, manager){
	var group = component.state.viewing;
	var add = component.state.addManager;

	add(group, manager);
}

function hideUpdate(component){
	component.setState({
		updating: false,
		updateName: "",
		updateDescription: ""
	});
	component.state.userMetrics.trackEvent("group-careplans: close patient group edit popup");
}

function hideUnlockingPatientGroup(component){
	component.setState({
		unlocking: false
	});
	component.state.userMetrics.trackEvent("group-careplans: close unlock patient group popup");
}

function hideLockingPatientGroup(component){
	component.setState({
		locking: false
	});
	component.state.userMetrics.trackEvent("group-careplans: close lock patient group popup");
}

function hideRemovingManagerGroup(component){
	component.setState({
		removing: false
	});
	component.state.userMetrics.trackEvent("group-careplans: close remove patient group popup");
}

function startUpdate(component, group){
	component.setState({
		updating: group
	});
	component.state.userMetrics.trackEvent("group-careplans: open edit patient group popup");
}

function unlockPatientGroup(component, group){
	const isAdmin = component.state.currentPerson.get("personal").type?.includes("admin");
	const isOrgManager = component.state.currentPerson.get("personal").type?.includes("org:manager");
	if(isAdmin || isOrgManager){
		component.setState({
			unlocking: group
		});
	} else {
		component.setState({
			message: group
		});
	}
	component.state.userMetrics.trackEvent("group-careplans: open unlock patient group popup", {
		group,
	});
}

async function lockPatientGroup(component, group){
	const isAdmin = component.state.currentPerson.get("personal").type?.includes("admin");
	const isOrgManager = component.state.currentPerson.get("personal").type?.includes("org:manager");
	if(isAdmin || isOrgManager){
		component.setState({
			locking: group
		});
	} else {
		component.setState({
			message: group
		});
	}
	component.state.userMetrics.trackEvent("group-careplans: open lock patient group popup", {
		group,
	});
}

function startRemoveManagerGroup(component, group){
	component.setState({
		removing: group
	});
	component.state.userMetrics.trackEvent("group-careplans: open remove patient group popup");
}

function confirmLock(component){
	const group = component.state.locking;
	const lockGroup = component.state.lockGroup;
	lockGroup(group);
	component.setState({
		locking: false,
	});
	component.state.userMetrics.trackEvent("group-careplans: lock patient group", {
		group,
	});
}

function confirmUnlock(component){
	const group = component.state.unlocking;
	const unlockGroup = component.state.unlockGroup;
	unlockGroup(group);
	component.setState({
		unlocking: false,
	});
	component.state.userMetrics.trackEvent("group-careplans: unlock patient group", {
		group,
	});
}

function confirmRemove(component){
	var group = component.state.removing;
	var remove = component.state.remove;
	remove(group);

	component.setState({
		removing: false,
		viewing: false
	});

	component.state.userMetrics.trackEvent("group-careplans: remove patient group", {
		group,
	});
}

function search(component, array, key, event) {
	event.persist();
	var query = event.target.value;
	var terms = escape_regex(query).split(" ").join("");
	var regex = new RegExp(terms, "i");

	var filtered = array.filter(function (item) {
		var matches = getName(item).split(" ").join("").match(regex);
		if (matches) return item;
	});

	var update = {};
	update["filtered" + key] = filtered;

	component.setState(update);
}

function getName(item){
	var name = item.name || item.fname + item.lname;
	return name;
}

function openPermissions(component){
	component.setState({
		openPermissions: true,
		permissionUpdates: {}
	});
	component.state.userMetrics.trackEvent("group-careplans: open edit patient group permissions popup");
}

function closePermissions(component){
	component.setState({
		openPermissions: false,
		permissionUpdates: {}
	});
	component.state.userMetrics.trackEvent("group-careplans: close edit patient group permissions popup");
}

function handle_changePermissions(component, forms) {
	var state = component.state;
	var group = state.selectedGroup;
	if(!group) return;

	// Get new permissions
	var newPermissions = state.permissionUpdates;

	var groupForms = {};
	forms.forEach(function (form) {
		if (newPermissions.hasOwnProperty(form)) {
			groupForms[form] = newPermissions[form];
			delete newPermissions[form];
		}
	});

	var groupData = state.managerGroups.get(group);
	if(!groupData) return;

	var oldPermissions = groupData.permissions;
	var oldGroupForms = oldPermissions.groupforms;

	if (Object.keys(groupForms).length) {
		var newGroupForms = xtend(oldGroupForms, groupForms);
		newPermissions.groupforms = newGroupForms;
	}
	// Merge old and new permissions
	var modPermissions = xtend(oldPermissions, newPermissions);

	state.updatePermissions(group, modPermissions);
	closePermissions(component);

	component.state.userMetrics.trackEvent("group-careplans: change patient group permissions", {
		group,
		permissions: modPermissions,
	});
}

function permissionsTitle(localization, component) {
	var state = component.state;
	var group = state.selectedGroup;
	if(!group) return;

	var groupData = state.managerGroups.get(group);
	if(!groupData) return;

	var name = groupData.name;

	var title_1 = localization.get("permissions_title1");
	var title = title_1 + name;
	return (title);
}

function handle_updatePermissions(component, key, e, changeCheck) {
	e.persist();
	var changeDrop = e.target.value;
	var state = component.state;
	var group = state.selectedGroup;
	if(!group) return;
	var updates = state.permissionUpdates;
	var update = {};

	// If key is not a "careplan"-related or the "manage" key convert the T/F values to 0/1
	if (SETTINGS_PERMISSIONS.indexOf(key) !== -1 && !key.includes("manage") && !key.includes("resources") && !key.includes("devices")) {
		update[key] = changeCheck ? 1 : 0;
	} else {
		update[key] = changeDrop;
	}

	//  This part takes the 'notes' key and translates
	//	it into two identical permissions for 'files' and 'forms'.
	if (key === "notes") {
		update["files"] = update["notes"];
		update["forms"] = update["notes"];
	}

	var newPermissions = xtend(updates, update);
	component.setState({
		permissionUpdates: newPermissions
	});

}

function renderPermissionToggle(component, localization, forms, formsList) {
	var state = component.state;
	var group = state.selectedGroup;
	if(!group) return;

	var groupData = state.managerGroups.get(group);
	if(!groupData) return;

	var permissions = groupData.permissions;
	const isLocked = groupData.isLocked;
	const isOrgManager = state.currentPerson.get("personal").type?.includes("org:manager");
	const isAdmin = state.currentPerson.get("personal").type?.includes("admin");
	const lockPermission = isOrgManager || isAdmin;
	const lockCheck = isLocked && !lockPermission;

	var permissionUpdates = state.permissionUpdates;

	return renderPermissionsInputs(component, permissions, permissionUpdates, localization, forms, formsList, true, handle_updatePermissions, lockCheck);
}

function renderPermissionsInputs(component, permissions, permissionUpdates, localization, forms, formsList, shouldUseDefaults, handle_update, lockCheck){
	var expected_aetonixPermissions = SETTINGS_PERMISSIONS;

	var renderFormsPermissions = forms.length ? (
		<div>
			{renderPermissionTitle(component, localization, "forms", formsList, shouldUseDefaults, lockCheck)}
			{renderFormPermissions(component, localization, forms, permissions, permissionUpdates, handle_update, lockCheck)}
		</div>
	) : null;

	return (
		<div>
			{renderPermissionTitle(component, localization, "aetonix", {}, shouldUseDefaults, lockCheck)}
			{renderPermissions(component, localization, expected_aetonixPermissions, permissions, permissionUpdates, handle_update, lockCheck)}
			{renderFormsPermissions}
		</div>
	);
}


function renderFormPermissions(component, localization, forms, permissions, permissionUpdates, handle_update, lockCheck) {
	var state = component.state;
	var person = state.currentPerson.get("personal");
	var language = person.language;
	var groupFormPermissions = permissions.groupforms || {};

	return forms.map(function (form) {
		var access = groupFormPermissions[form._id] || 0;
		if (typeof permissionUpdates === "object" && permissionUpdates.hasOwnProperty(form._id))
			access = permissionUpdates[form._id];
		var localized_key = form.localization[language] || form.localization["en"];
		return (
			<div key={form._id} className="flex-horizontal ae-justify ae-align-centre ae-bottom-margin">
				{localized_key}
				<Select classes={selectClasses} key={localized_key} value={access} onChange={par(handle_update, component, form._id)} disabled={lockCheck} className={lockCheck ? "ae-hover-color" : ""}>
					<MenuItem value={0}>{localization.get("permissions_noaccess")}</MenuItem>
					<MenuItem value={1}>{localization.get("permissions_readonly")}</MenuItem>
					<MenuItem value={2}>{localization.get("permissions_readwrite")}</MenuItem>
				</Select>
			</div>
		);
	});
}

function renderPermissions(component, localization, expected_permission, permissions, permissionUpdates, handle_update, isLocked) {
	var permission = expected_permission;

	return (permission.map(function (key) {
		if (key === "_id" || key === "careplan_DocumentControl")
			return;

		/* This portion is a temporary insert allowing the use of 'notes'
			vice 'files' and 'forms'
			if the key is "files" or "notes" skip the rendering */
		if (key === "files" || key === "notes")
			return;

		/* if the permissions for files or forms is 'true', then create
			a key 'notes' and 'notes =  true' ... and vice versa */
		if (key === "files" || key === "forms") {
			key = "notes";
			if (permissions["files"] || permissions["forms"])
				permissions[key] = true;
		}

		// compose a key for localizations
		var composeKey = "permissions_" + key;
		var localized_key = localization.get(composeKey);

		// retrieve the permission value
		var access = permissions[key];

		if (typeof permissionUpdates === "object" && permissionUpdates.hasOwnProperty(key))
			access = permissionUpdates[key];

		// Normalize for backward compatibility and undefined values
		// Older versions may be either boolean or string
		// If a string then anything except "true" is deemed false and assigned '0'
		if (!access)
			access = 0;
		if (typeof access !== "number") {
			if (typeof access === "boolean")
				access ? access = 1 : access = 0;
			if (typeof access === "string")
				access === "true" ? access = 1 : access = 0;
		}

		var dropdown = null;
		if(key === "manage")
			dropdown = [
				(<MenuItem value={0}>{localization.get("permissions_noaccess")}</MenuItem>),
				(<MenuItem value={1}>{localization.get("permissions_viewonly")}</MenuItem>),
				(<MenuItem value={3}>{localization.get("permissions_viewmodify")}</MenuItem>)
			];
		else if (key === "resources")
			dropdown = [
				(<MenuItem value={0}>{localization.get("permissions_noaccess")}</MenuItem>),
				(<MenuItem value={1}>{localization.get("permissions_view")}</MenuItem>),
				(<MenuItem value={2}>{localization.get("permissions_readadjustaccess")}</MenuItem>)
			];
		else if(key === "devices")
			dropdown = [
				(<MenuItem value={0}>{localization.get("permissions_noaccess")}</MenuItem>),
				(<MenuItem value={1}>{localization.get("permissions_view")}</MenuItem>),
				(<MenuItem value={2}>{localization.get("permissions_viewedit")}</MenuItem>)
			];

		var checkornot = (key !== "manage" && key !== "resources" && key !== "devices")
			? (<Checkbox className={isLocked ? "ae-hover-color ae-widthauto" : "ae-widthauto"} inputProps={{"aria-label": localized_key, "role": "checkbox"}} defaultChecked={!!access} key={localized_key + access} onChange={par(handle_update, component, key)} disabled={isLocked}/>)
			: (
				<div aria-label={localized_key} key={localized_key} className="ae-bottom-margin">
					<Select classes={selectClasses} inputProps={{"aria-label": localized_key}} key={localized_key} value={access} onChange={par(handle_update, component, key)} disabled={isLocked} className={isLocked ? "ae-hover-color" : ""}>
						{dropdown}
					</Select>
				</div>
			);

		return (
			<div key={key} className="flex-horizontal flex-1 ae-justify ae-align-centre">
				{localized_key}
				{checkornot}
			</div>
		);
	}));
}

function renderPermissionTitle(component, localization, title, forms, shouldUseDefaults, lockCheck) {
	var section_title = localization.get("permissions_" + title + "_section");
	var select = localization.get("permissions_select_all");
	return (
		<div className="flex-vertical flex-1">
			<div className="ae-permission-section">
				{section_title}
			</div>
			<div className="ae-selectall">
				<span>
					<Button  className={lockCheck ? "ae-hover-color ae-widthauto" : "ae-widthauto"} color="primary" onClick={par(setAll, component, localization, title, forms, shouldUseDefaults)} disabled={lockCheck}>
						{select}
					</Button>
				</span>
			</div>
		</div>
	);
}

function setAll(component, localization, title, forms, shouldUseDefaults) {
	if (!title) {
		return;
	}
	var permissions;
	var change;

	var updater = shouldUseDefaults ? "permissionUpdates" : "defaultPermissionUpdates";
	var updates = component.state[updater];

	if (title === "aetonix") {
		change = 1;
		permissions = SETTINGS_PERMISSIONS;
	}
	if (title === "forms") {
		change = 2;
		permissions = forms;
	}

	var update = permissions.reduce(function (curr, permission) {
		// temporary to until 'files' and 'forms' are used
		if (permission === "files" || permission === "forms") {
			curr[permission] = change;
			curr["notes"] = change;
			return curr;
		}
		if (permission !== "_id") {
			curr[permission] = change;
			return curr;
		}
		return curr;
	}, {});

	var newPermissions = xtend(updates, update);

	var stateUpdate = {};

	stateUpdate[updater] = newPermissions;

	component.setState(stateUpdate);

	component.state.userMetrics.trackEvent("group-careplans: set all permissions", {
		group: component.state.selectedGroup,
	});
}

function by_name(x, y){
	return order(x.lname.toLowerCase() + x.fname.toLowerCase(), y.lname.toLowerCase() + y.fname.toLowerCase());
}

function byManagerGroupsName(x, y) {
	return order(x.name.toLowerCase(), y.name.toLowerCase());
}

function order(x, y){
	if(x < y)
		return -1;
	if(x > y)
		return 1;
	return 0;
}