
/* © 2017 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, May 3, 2018
 * For information or permission request, email info@aetonixsystems.com
 */

var React = require("react");
var par = require("par");
var xtend = require("xtend");

var Header = require("../shared/Header.js");
var SubHeader = require("@material-ui/1.5.1/ListSubheader").default;
var TextField = require("@material-ui/1.5.1/TextField").default;
var Select = require("@material-ui/1.5.1/Select").default;
var MenuItem = require("@material-ui/1.5.1/MenuItem").default;
var ListItem = require("@material-ui/1.5.1/ListItem").default;
var ListItemText = require("@material-ui/1.5.1/ListItemText").default;
var Dialog = require("../shared/Dialog.js");
var Button = require("@material-ui/1.5.1/Button").default;
var InputLabel = require("@material-ui/1.5.1/InputLabel").default;
var FormControl = require("@material-ui/1.5.1/FormControl").default;
var Tabs = require("@material-ui/1.5.1/Tabs").default;
var Tab = require("@material-ui/1.5.1/Tab").default;
var IconButton = require("@material-ui/1.5.1/IconButton").default;
var FontIcon = require("@material-ui/1.5.1/Icon").default;

var LazyList = require("../shared/LazyList.jsx");
var Colors = require("../shared/AetonixTheme.js").palette;

var styles = {
	padding: {
		padding: "5px"
	},
	search: {
		color: Colors.primary.main,
		minWidth: "30%",
		padding: "8px"
	},
	input: {
		color: Colors.primary.main,
		padding: "8px"
	},
	icon: {color: Colors.primary.main},
	menuItemIcon: {
		color: Colors.primary.main,
		marginRight: "8px"
	},
	inputProp: {style: {color: Colors.primary.main, }},
	noMatch: {
		padding: "1em",
		display: "inherit",
		justifyContent: "center",
		color: Colors.primary.main
	},
	duplicationHeader: {textAlign: "center"}
};

module.exports = render;

function render() {
	var component = this;
	var state = component.state;
	var removing = state.removing;

	var orgs = state.orgs;
	var updating = state.updating;
	var creating = state.creating;
	var localization = state.localization;
	var careplanNoticeList = state.careplanChangeList.all();
	var careplanChanges = !!careplanNoticeList.length;
	var offline = state.connection.get("offline");
	var currentPerson = state.currentPerson;
	var tabValue = state.tabValue;
	var viewing = state.viewing;
	var tables = [];

	var sortedOrgs = orgs.sort(by_name);
	var loadMore = state.loadMore;
	var searchOrg = state.searchOrg;

	var externals = findExternals(component);
	var actions = findActions(component);

	var renderOrgListItem = par(renderOrg, component, localization);
	var dialogTitle = updating ? getTableTitle(component, updating) : localization.get("admin_table_new");
	var cancel = updating ? par(cancelUpdating, component) : par(cancelCreating, component);

	if(viewing){
		tables = state.tables.get(viewing);
	}

	var addActions = [
		(<Button key={"buttonok"} color="primary" onClick={par(submitTable, component)}>{localization.get("org_create_submit")}</Button>),
		(<Button key={"buttoncancel"} color="primary" onClick={cancel}>{localization.get("org_create_cancel")}</Button>)
	];

	var remove_actions = [
		(<Button key={"buttonok"} onClick={par(confirmRemove, component)}>{localization.get("users_ok")}</Button>),
		(<Button key={"buttoncancel"} onClick={par(hideRemove, component)}>{localization.get("users_cancel")}</Button>)
	];

	var titleKey = "admin_tables";

	var externalTab = externals ? (
		<Tab label={localization.get("org_workflow_externals")} />
	) : null;

	var actionsTab = actions ? (
		<Tab label={localization.get("org_tables_actions")} />
	) : null;

	var removingTitle = removing ? getTableTitle(component, removing) : "";

	return (
		<div className="flex-vertical flex-1">
			<Header
				careplanChanges={careplanChanges}
				offline={offline}
				currentPerson={currentPerson}
				localization={localization}
				titleKey={titleKey}
			/>
			<div className="flex-horizontal flex-1 ae-scrollable">
				<div className="flex-vertical flex-1 ae-left-margin ">
					<SubHeader>{localization.get("admin_form_org")}</SubHeader>
					<TextField placeholder={localization.get("admin_workflow_search")} inputProps={{"aria-label": localization.get("admin_workflow_search")}} onChange={searchOrg} style={styles.search} InputProps={styles.inputProp} />
					<LazyList loadMore={loadMore} renderItem={renderOrgListItem} items={sortedOrgs} />
				</div>
				<div className="flex-vertical flex-1">
					<div className="flex-vertical flex-1 ae-scrollable">
						<SubHeader>{localization.get("admin_tables_text")}</SubHeader>
						{renderLazyList(component, tables)}
					</div>
					{renderAdd(component)}
				</div>
			</div>

			<Dialog actions={remove_actions} open={!!removing} title={localization.get("org_workflow_removeTitle")} >
				{localization.get("user_thresholds_areyousure") + " " + removingTitle + "?"}
			</Dialog>

			<Dialog actions={addActions} open={!!creating || !!updating} title={dialogTitle}>
				<Tabs fullWidth value={tabValue} indicatorColor="primary" onChange={par(handleTabValueChanged, component)}>
					<Tab label={localization.get("org_workflow_schema")} />
					{externalTab}
					{actionsTab}
				</Tabs>
				{renderTabs(component)}
			</Dialog>
		</div>
	);
}

function confirmRemove(component){
	var viewing = component.state.viewing;
	var remove = component.state.remove;
	var removing = component.state.removing;
	var tableId = removing._id;

	remove(tableId, viewing);
	hideRemove(component);

	component.state.userMetrics.trackEvent("admin-tables: remove table", {
		table: tableId,
		org: viewing
	});
}

function hideRemove(component){
	component.state.userMetrics.trackEvent("admin-tables: close remove table popup");
	component.setState({
		removing: false
	});
}

function renderLazyList(component, tables){
	var state = component.state;
	var localization = state.localization;
	var show = [];
	if(!tables.length) return null;
	var renderTableItem = par(renderTable, component);
	var filtered = component.state.filteredTables;
	if(filtered[0] === "none")
		return (
			<div style={styles.noMatch}>{localization.get("search_noMatch")}</div>
		);

	if(!filtered.length)
		show = tables;
	else
		show = filtered;

	return (<LazyList loadMore={function(){}} renderItem={renderTableItem} items={show} />);
}

function renderTable(component, table){
	var title = getTableTitle(component, table);
	var localization = component.state.localization;
	var button = (
		<div className="flex-horizontal">
			<IconButton className="fa fa-pencil" aria-label={localization.get("edit_button")}  title={localization.get("edit_button")}  role="button" style={styles.icon} onClick={par(startUpdating, component, table)} />
			<IconButton className="fa fa-times-circle" aria-label={localization.get("remove_button")} title={localization.get("remove_button")} role="button" style={styles.icon} onClick={par(startRemoving, component, table)} />
		</div>
	);

	var icon = (<FontIcon className={"fa fa-table"} style={styles.icon} />);

	return (
		<ListItem key={table._id} className="ae-plain">
			{icon}
			<ListItemText primary={title} />
			{button}
		</ListItem>
	);
}

function startRemoving(component, table){
	component.state.userMetrics.trackEvent("admin-tables: open remove table popup", {
		table: table._id,
		org: component.state.viewing
	});
	component.setState({
		removing: table
	});
}

function startUpdating(component, table){
	var schema = table.schema;
	if(typeof schema !== "string")
		table.schemaText = JSON.stringify(schema, null, 4);
	var changes = xtend({}, table);
	component.setState({
		updating: changes,
		schemaError: false,
		tabValue: 0
	});

	component.state.userMetrics.trackEvent("admin-tables: open edit table popup", {
		table: table._id,
		org: component.state.viewing
	});
}

function renderTabs(component){
	var tabValue = component.state.tabValue;
	if(tabValue === 0)
		return renderSchemaTab(component);
	else if(tabValue === 1)
		return renderExternalsTab(component);
	else if(tabValue === 2)
		return renderActionsTab(component);
}

function renderExternalsTab(component){
	var state = component.state;
	var updating = state.updating;
	var creating = state.creating;
	var viewing = state.viewing;
	var currentPerson = state.currentPerson;
	var workflows = [];
	var forms = [];

	var currentExternals = (updating ? updating.externals : creating.externals) || {};
	var externals = findExternals(component);

	if(viewing){
		workflows = state.workflow.get(viewing);
		forms = state.forms.get(viewing);
	}

	var formsMenu = forms.map(function(form){
		return (<MenuItem key={form._id} value={form._id}>{getFormName(currentPerson, form)}</MenuItem>);
	});

	var workflowsMenu = workflows.map(function(workflow){
		return (<MenuItem key={workflow._id} value={workflow._id}>{getWorkflowTitle(component, currentPerson, workflow)}</MenuItem>);
	});

	return externals ? Object.keys(externals).map(function(key){
		var menu = null;
		if(externals[key] === "form")
			menu = formsMenu;
		else if(externals[key] === "workflow")
			menu = workflowsMenu;
		else return null;

		return (
			<FormControl fullWidth>
				<InputLabel>{key}</InputLabel>
				<Select fullWidth value={currentExternals[key] || ""} onClick={par(updateExternal, component, key)}>
					{menu}
				</Select>
			</FormControl>
		);
	}) : null;
}

function renderActionsTab(component){
	var state = component.state;
	var localization = state.localization;
	var updating = state.updating;
	var creating = state.creating;
	var currentActions = (updating ? updating.actions : creating.actions) || [];
	var actions = findActions(component);

	if(!actions) return;

	var actionMenu = actions.map(function(action){
		return (<MenuItem key={action} value={action}>{action}</MenuItem>);
	});

	var renderActions = currentActions.map(function(action, index){
		return (
			<div className="flex-horizontal ae-align-centre" style={styles.padding}>
				<FormControl fullWidth>
					<Select fullWidth value={action} onClick={par(updateAction, component, index)}>
						{actionMenu}
					</Select>
				</FormControl>
				<IconButton color="primary" className="fa fa-times" onClick={par(removeAction, component, action)} />
			</div>
		);
	});

	return (
		<div>
			{renderActions}
			<Button onClick={par(addNewAction, component)}>
				{localization.get("admin_table_action_add")}
			</Button>
		</div>
	);
}

function removeAction(component, action){
	var state = component.state;
	var updating = state.updating;
	var creating = state.creating;
	var currentActions = (updating ? updating.actions : creating.actions) || [];

	var newActions = currentActions.filter((e) => {
		return e !== action;
	});

	var update = component.state.creating || updating;
	update.actions = newActions;

	if(updating)
		updateUpdatingState(component, update);
	else
		updateCreatingState(component, update);

	component.state.userMetrics.trackEvent("admin-tables: remove actions", {
		table: update._id,
		org: component.state.viewing,
		actions: update.actions
	});
}

function updateAction(component, index, e){
	e.persist();
	var payload = e.target.value;
	var updating = component.state.updating;
	var creating = component.state.creating;
	var currentActions = (updating ? updating.actions : creating.actions) || [];

	var newActions =  currentActions.map(function(action, i){
		if(i === index)
			return payload;
		return action;
	});

	var update = component.state.creating || updating;
	update.actions = newActions;

	if(updating)
		updateUpdatingState(component, update);
	else
		updateCreatingState(component, update);

	component.state.userMetrics.trackEvent("admin-tables: edit actions", {
		table: update._id,
		org: component.state.viewing,
		actions: update.actions
	});
}

function addNewAction(component){
	var state = component.state;
	var updating = state.updating;
	var creating = state.creating;
	var currentActions = (updating ? updating.actions : creating.actions) || [];
	var newActions = currentActions.concat("");

	var update = component.state.creating || updating;
	update.actions = newActions;

	if(updating)
		updateUpdatingState(component, update);
	else
		updateCreatingState(component, update);

	component.state.userMetrics.trackEvent("admin-tables: create new action", {
		table: update._id,
		org: component.state.viewing,
		actions: update.actions
	});
}

function renderSchemaTab(component){
	var state = component.state;
	var schemaError = state.schemaError;
	var localization = state.localization;
	var updating = state.updating;
	var creating = state.creating;
	var errorText = schemaError ? localization.get("org_form_schemaerror") : null;
	var defaultSchema = updating ? updating.schemaText : (creating ? creating.schemaText : null);
	return (
		<div>
			<TextField
				fullWidth
				defaultValue={defaultSchema}
				errorText={errorText}
				multiline
				aria-label={localization.get("org_workflow_schema")}
				inputProps={{"aria-label": localization.get("org_workflow_schema"), "role": "tab"}}
				role={"tablist"}
				rows="18"
				onChange={par(updateSchema, component)}
				label={localization.get("org_workflow_schema")}
				InputProps={styles.inputProp} />
		</div>
	);
}

function submitTable(component){
	var updating = component.state.updating;
	var table = component.state.creating || updating;
	var viewing = component.state.viewing;
	var submit = updating ? component.state.update : component.state.create;
	var hide = updating ? cancelUpdating : cancelCreating;

	try {
		table.schema = JSON.parse(table.schemaText);
		submit(table, viewing);
		hide(component);
		component.state.userMetrics.trackEvent("admin-tables: submit table", {
			actions: table.actions,
			"table name": table.name,
			"table description": table.description,
			"table schema JSON": table.schemaText,
			"existing table id": table._id,
		});
	} catch(err) {
		component.setState({schemaError: true});
	}
}

function handleTabValueChanged(component, event, value) {
	component.setState({tabValue: value});
	const eventText = [
		"admin-tables: navigate to schema tab in edit table popup",
		"admin-tables: navigate to external tab in edit table popup",
		"admin-tables: navigate to actions tab in edit table popup"
	];
	const eventProperties = component.state.updating._id ? {
		table: component.state.updating._id,
		org: component.state.viewing,
	} : {
		org: component.state.viewing,
		creating: true,
	};
	component.state.userMetrics.trackEvent(eventText[value], eventProperties);
}

function updateCreatingState(component, update) {
	component.setState({creating: update});
}

function updateUpdatingState(component, update) {
	var updating = component.state.updating;
	component.setState({updating: xtend(updating, update)});
}

function updateSchema(component, e) {
	e.persist();
	var text = e.target.value;
	var updating = component.state.updating;
	var update = component.state.creating || updating;
	update.schemaText = text;

	if (updating)
		updateUpdatingState(component, update);
	else
		updateCreatingState(component, update);
}

function updateExternal(component, key, e){
	e.persist();
	var payload = e.target.value;
	var updating = component.state.updating;
	var update = component.state.creating || updating;
	if(!update.externals)
		update.externals = {};
	update.externals[key] = payload;

	if(updating)
		updateUpdatingState(component, update);
	else
		updateCreatingState(component, update);

	component.state.userMetrics.trackEvent("admin-tables: edit externals", {
		table: update._id,
		org: component.state.viewing,
		"key of updated external": key,
		"new value": payload
	});
}

function cancelUpdating(component) {
	component.state.userMetrics.trackEvent("admin-tables: close edit table popup");
	component.setState({
		updating: false,
		schemaError: false,
		tabValue: 0
	});
}

function showAddTable(component) {
	component.state.userMetrics.trackEvent("admin-tables: open create table popup");
	component.setState({
		creating: {},
		schemaError: false,
		tabValue: 0
	});
}

function cancelCreating(component) {
	component.state.userMetrics.trackEvent("admin-tables: close create table popup");
	component.setState({
		creating: false,
		schemaError: false,
		tabValue: 0
	});
}

function renderAdd(component) {
	if (component.state.viewing)
		return (
			<Button variant="raised"
					onClick={par(showAddTable, component)}
					color="secondary"
				>
				{component.state.localization.get("admin_form_add")}
			</Button>
		);
}

function by_name(x, y) {
	var X = x.name.toLowerCase();
	var Y = y.name.toLowerCase();
	if (X < Y) return -1;
	if (X > Y) return 1;
	return 0;
}

function renderOrg(component, localization, org) {
	var name = org.name;
	var description = org.description;
	var orgId = org._id;

	var background_colour = "ae-plain";
	var viewing = component.state.viewing;
	if (viewing && orgId === viewing) background_colour = "ae-hover-color";

	var onClick = par(viewTablesFor, component, orgId);
	return (
		<ListItem button key={orgId} className={background_colour} onClick={onClick}>
			<ListItemText primary={name} secondary={description} />
		</ListItem>
	);
}

function viewTablesFor(component, org) {
	component.state.userMetrics.trackEvent("admin-tables: view tables for org", {
		org
	});
	component.setState({
		viewing: org,
		filteredTables: []
	});
}

function getWorkflowTitle(component, currentPerson, workflow) {
	var state = component.state;
	var localization = state.localization;
	var person = currentPerson.get("personal");
	var language = person.language;
	var description = localization.get("org_workflow_new");
	var schemaDescription = workflow.schema ? workflow.schema.description : null;
	if (schemaDescription) {
		var keys = Object.keys(schemaDescription);
		description = keys.length !== 0 ? schemaDescription[language] || schemaDescription[keys[0]] : localization.get("org_workflow_untitled");
	}
	return workflow.name || description;
}

function getFormName(currentPerson, form) {
	var person = currentPerson.get("personal");
	var language = person.language;
	var localization = form.localization;
	var name = localization[language] || localization[Object.keys(localization)[0]];
	return name;
}

function findExternals(component){
	var workflow = component.state.updating || component.state.creating;
	var schemaText = workflow.schemaText;
	var schema = null;

	try {
		schema = JSON.parse(schemaText);
		if(schema.externals)
			return schema.externals;
		return null;
	} catch (err) {
		return null;
	}
}

function findWorkflowExternals(component){
	var workflow = component.state.updating || component.state.creating;
	var schemaText = workflow.schemaText;
	var schema = null;

	try {
		schema = JSON.parse(schemaText);
		if(schema.externals){
			return Object.keys(schema.externals).reduce((a, e) => {
				var current = schema.externals[e];
				if(current === "workflow")
					a.push(e);
				return a;
			}, []);
		}
		return null;
	} catch(err) {
		return null;
	}
}

function findActions(component){
	var state = component.state;
	var updating = state.updating;
	var creating = state.creating;
	var workflows = [];

	if(state.viewing){
		workflows = state.workflow.get(state.viewing);
	}

	var currentExternals = (updating ? updating.externals : creating.externals);
	if(!currentExternals) return null;

	var workflowExternalKeys = findWorkflowExternals(component);
	if(!workflowExternalKeys || !workflowExternalKeys.length) return null;

	var workflowExternalIDs = findWorkflowIDs(currentExternals, workflowExternalKeys);
	if(!workflowExternalIDs || !workflowExternalIDs.length) return null;

	return getDefinitionActions(workflowExternalIDs, workflows);
}

function findWorkflowIDs(externals, keys){
	return keys.reduce((acc, key) => {
		var current = externals[key];
		if(current) acc = acc.concat(current);
		return acc;
	}, []);
}

function getDefinitionActions(keys, workflows){
	return workflows.reduce((a, workflow) => {
		if(keys.indexOf(workflow._id) !== -1)
			a = a.concat(Object.keys(workflow.schema.actions));
		return a;
	}, []);
}

function getTableTitle(component, table){
	var state = component.state;
	var localization = state.localization;
	var currentPerson = state.currentPerson;
	var person = currentPerson.get("personal");
	var language = person.language;
	var schema = table.schema || {};
	var titleLocale = schema.localization || {};
	var keys = Object.keys(titleLocale);
	return titleLocale[language] || titleLocale[language[keys[0]]] || localization.get("org_workflow_untitled");
}
