/* © 2017 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, January 01, 2017
 * For information or permission request, email info@aetonixsystems.com
 */

var par = require("par");

var defineUI = require("../shared/define");
var render = require("./render.jsx");

var PersonStore = require("../stores/person_store");
var LocalizationStore = require("../stores/localization_store");
var WanderHistoryStore = require("../stores/wander_history_store");
var CurrentPersonInfoStore = require("../stores/current_person_info_store");
var MachineGroupStore = require("../stores/org_group_machines_store");
var CareplanNotificationStore = require("../stores/careplan_notice_store");
var ConnectionStore = require("../stores/connection_store");

var makeStores = require("../shared/stores")({
	people: PersonStore,
	localization: LocalizationStore,
	wanderhistoryStore: WanderHistoryStore,
	currentPerson: CurrentPersonInfoStore,
	machinegroup: MachineGroupStore,
	careplanChangeList: CareplanNotificationStore,
	connection: ConnectionStore
});

function make_ui(api, events, config, userMetrics) {

	var storeStore = makeStores(api, events, config);

	window.stores = storeStore.stores;

	userMetrics.trackEvent("page view", {
		"page": "user-wander-history",
		"category": "history",
		"permission level": "staff",
	});

	return defineUI({
		getInitialState: getInitialState,
		componentDidMount: listen_for_changes,
		render: render,
		componentWillUnmount: removeListeners
	});

	function removeListeners() {
		events.off();
	}

	function listen_for_changes() {
		storeStore.listen(par(handle_change, this));
	}

	function handle_change(component, stores) {
		component.setState({
			people: stores.people,
			localization: stores.localization,
			currentPerson: stores.currentPerson,
			wandering: stores.wanderhistoryStore.sorted(),
			machinegroup: stores.machinegroup,
			careplanChangeList: stores.careplanChangeList,
			connection: stores.connection
		});
	}

	function getInitialState() {
		return {
			people: storeStore.stores.people,
			localization: storeStore.stores.localization,
			currentPerson: storeStore.stores.currentPerson,
			wandering: storeStore.stores.wanderhistoryStore.sorted(),
			machinegroup: storeStore.stores.machinegroup,
			careplanChangeList: storeStore.stores.careplanChangeList,
			connection: storeStore.stores.connection,
			more: more,
			filterUser: filterUser,
			filterClear: filterClear,
			filterDate: filterDate,
			filterStatus: filterStatus,
			closeSearch: par(closeSearch, this),
			search: searchName,
			report: false,
			userFilter: false,
			statusFilter: false,
			dateFilter: false,
			query: [],
			wanderstatus: "",
			startDate: "",
			endDate: new Date(),
			fileName: "",
			userMetrics: userMetrics,
		};
	}

	function more() {
		storeStore.stores.wanderhistoryStore.more();
	}

	/**
	 * Returns a list of the groups users
	 * @param  {objectId} query User_id
	 * @return {array}       User _id's
	 */
	function searchName(query) {
		return api.org.users.list(query);
	}

	function filterUser(component, data) {
		var query = component.state.query;
		component.setState({
			query: query.concat(data)
		});
		storeStore.stores.wanderhistoryStore.searchQuery(data);
		userMetrics.trackEvent("user-wander-history: filter by user", {
			"user": data
		});
	}

	function filterStatus(data) {
		storeStore.stores.wanderhistoryStore.searchStatus(data);
		userMetrics.trackEvent("user-wander-history: filter by status", {
			"status": data
		});
	}

	function filterDate(start, end) {
		storeStore.stores.wanderhistoryStore.searchDate(start, end);
	}

	function filterClear(name) {
		storeStore.stores.wanderhistoryStore.clearSearch(name);
	}

	function closeSearch(component) {
		component.setState({
			userFilter: false
		});
		component.state.userMetrics.trackEvent("user-wander-history: close user filter");
	}
}

module.exports = make_ui;
