/* © 2017 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, January 01, 2017
 * For information or permission request, email info@aetonixsystems.com
 */

import React, { useMemo, useCallback, useRef } from "react";
import { getNameForLanguage, getDefaultIndicator } from "../org-custom-indicators/library";
import { convertData, getAdjustedDate, getFilterLabel } from "./library";
import { useUserMetrics } from "@aetonix/user-metrics";

var par = require("par");
var config = require("../../configs/config.json");

/**
 * User Interface elements material-ui
 */
var Button = require("@material-ui/1.5.1/Button").default;
var Dialog = require("../shared/Dialog");

var TextField = require("@material-ui/1.5.1/TextField").default;
var Chip = require("@material-ui/1.5.1/Chip").default;
var Avatar = require("@material-ui/1.5.1/Avatar").default;
var FontIcon = require("@material-ui/1.5.1/Icon").default;

var Table = require("@material-ui/1.5.1/Table").default;
var TableHeader = require("@material-ui/1.5.1/TableHead").default;
var TableHeaderColumn = require("@material-ui/1.5.1/TableCell").default;
var TableBody = require("@material-ui/1.5.1/TableBody").default;
var TableRow = require("@material-ui/1.5.1/TableRow").default;
var TableRowColumn = require("@material-ui/1.5.1/TableCell").default;

/**
 * User Interface elements Aetonix
 */
var Header = require("../shared/Header.js");

var Scroll = require("../shared/InfiniScroll.jsx");
var SearchTable = require("../shared/SearchTable.jsx");
var DatePicker = require("../shared/DatePicker");
var Colors = require("../shared/AetonixTheme").palette;

var styles = {
	icon: {
		fontSize: 20,
		color: Colors.canvas
	},
	avatar: {
		backgroundColor: Colors.primary.dark
	},
	back: {
		backgroundColor: Colors.primary.light,
		color: Colors.primary.main
	},
	backPrimary: {
		backgroundColor: "#E0E0E0"
	},
	avatarPrimary: {
		backgroundColor: "#BCBCBC"
	},
	inputProp: {
		style: {
			color: Colors.primary.main
		}
	}
};

module.exports = render;

const ActionMenu = ({ component, onShowReport, onClearSearch, onShowSearch }) => {
	const { state } = component;
	const { localization } = state;

	return (
		<div className="flex-horizontal ae-empty">
			<Chip
				className="ae-icon ae-fonticon"
				style={styles.back}
				onClick={onShowReport}
				avatar={
					<Avatar style={styles.avatar}>
						<FontIcon className="fa fa-floppy-o fa-2x" style={styles.icon} />
					</Avatar>
				}
				label={localization.get("groupfall_report_button")}
			/>

			<UserFilterChip component={component} onClearSearch={onClearSearch} onShowSearch={onShowSearch} />
			<DateFilterChip component={component} onClearSearch={onClearSearch} onShowSearch={onShowSearch} />
		</div>
	);
};

const DateFilterChip = props => {
	const { component, onClearSearch, onShowSearch } = props;
	const { state } = component;
	const { startDate, endDate, localization, query } = state;

	var onDelete = () => null;
	var styleavatar = styles.avatarPrimary;
	var styleback = styles.backPrimary;

	if (!!startDate && !!endDate) {
		onDelete = par(onClearSearch, "time");
		styleavatar = styles.avatar;
		styleback = styles.back;
	}

	const label = useMemo(() => getFilterLabel("time", startDate, endDate, localization, query), [
		startDate,
		endDate,
		localization,
		query
	]);

	return (
		<Chip
			className="ae-icon ae-fonticon"
			onDelete={onDelete}
			style={styleback}
			onClick={par(onShowSearch, "time")}
			avatar={
				<Avatar style={styleavatar}>
					<FontIcon className="fa fa-filter fa-2x" style={styles.icon} />
				</Avatar>
			}
			label={label}
		/>
	);
};

const UserFilterChip = props => {
	const { component, onClearSearch, onShowSearch } = props;
	const { state } = component;
	const { startDate, endDate, localization, query } = state;

	var onDelete = () => null;
	var styleavatar = styles.avatarPrimary;
	var styleback = styles.backPrimary;

	if (query.length) {
		onDelete = par(onClearSearch, "user");
		styleavatar = styles.avatar;
		styleback = styles.back;
	}

	const label = useMemo(() => getFilterLabel("user", startDate, endDate, localization, query), [
		startDate,
		endDate,
		localization,
		query
	]);

	console.log(label);

	return (
		<Chip
			className="ae-icon ae-fonticon"
			onDelete={onDelete}
			style={styleback}
			onClick={par(onShowSearch, "user")}
			avatar={
				<Avatar style={styleavatar}>
					<FontIcon className="fa fa-filter fa-2x" style={styles.icon} />
				</Avatar>
			}
			label={label}
		/>
	);
};

const HistoryTable = ({ loadMore, headers, rowData }) => {
	const columns = useMemo(
		() =>
			headers.map(column => (
				<TableHeaderColumn key={column.content} className="flex-spread">
					{column.content}
				</TableHeaderColumn>
			)),
		[headers]
	);

	const rows = useMemo(
		() =>
			rowData.map((row, index) => (
				<TableRow key={`history-row-${index}`}>
					{headers.map(column => (
						<TableRowColumn key={`history-row-column-${index}`}>{row[column.name]}</TableRowColumn>
					))}
				</TableRow>
			)),
		[rowData, headers]
	);

	return (
		<Scroll loadMore={loadMore}>
			<Table>
				<TableHeader>
					<TableRow>{columns}</TableRow>
				</TableHeader>
				<TableBody>{rows}</TableBody>
			</Table>
		</Scroll>
	);
};

const UserCustomIndicatorHistory = props => {
	const { component } = props;

	const state = component.state;

	const {
		localization,
		currentPerson,
		customIndicators,
		indicatorId,
		orgIndicators,
		careplanChangeList,
		connection,
		filterClear,
		filterDate,
		startDate,
		endDate,
		report,
		fileName,
		people,
		userFilter,
		dateFilter,
		filterUser,
		closeSearch,
		more
	} = state;

	const { userMetrics } = useUserMetrics();
	const [previousCustomIndicators, setPreviousCustomIndicators] = React.useState([]);

	const careplanNoticeList = careplanChangeList?.all?.() ?? [];
	const careplanChanges = !!careplanNoticeList.length;
	const offline = connection.get("offline");

	const language = currentPerson?.get?.("personal")?.language ?? "en";

	const indicators = orgIndicators?.all?.() ?? [];

	const indicator = useMemo(
		() => indicators?.find?.(indic => indic._id == indicatorId) ?? getDefaultIndicator(),
		[indicators, indicatorId, getDefaultIndicator]
	);

	const rowData = useMemo(() => customIndicators.map(par(convertData, people)), [
		customIndicators,
		convertData,
		people
	]);

	React.useEffect(() => {
		if (customIndicators.length !== previousCustomIndicators.length) {
			userMetrics.trackEvent("user-custom-indicator-history: viewed custom indicator history", {
				records: customIndicators.map(c => c._id),
			});
		}
		setPreviousCustomIndicators(customIndicators);
	}, [customIndicators, previousCustomIndicators, userMetrics]);


	const chooseDate = localization.get("choose_date");

	const onHideReport = useCallback(() => {
		userMetrics.trackEvent("user-custom-indicator-history: close report");
		return component.setState({ report: false });
	}, [component]);

	const onShowReport = useCallback(() => {
		userMetrics.trackEvent("user-custom-indicator-history: open report");
		return component.setState({ report: true });
	}, [component]);

	const onShowSearch = useCallback(
		name => {
			if (name === "user") {
				component.setState({ userFilter: true });
			}

			if (name === "time") {
				component.setState({ dateFilter: true });
			}
			userMetrics.trackEvent(`user-custom-indicator-history: open ${name} filter`);
		},
		[component]
	);

	const onHideSearch = useCallback(
		name => {
			if (name === "user") {
				component.setState({ userFilter: false });
			}

			if (name === "status") {
				component.setState({ statusFilter: false });
			}

			if (name === "time") {
				component.setState({ dateFilter: false });
			}
			userMetrics.trackEvent(`user-custom-indicator-history: close ${name} filter`);
		},
		[component]
	);

	const onClearSearch = useCallback(
		name => {
			var clear = filterClear;
			if (name === "user") {
				component.setState({
					query: []
				});
				return clear("user");
			}
			if (name === "time") {
				component.setState({
					startDate: "",
					endDate: new Date()
				});
				return clear("time");
			}
		},
		[component, filterClear]
	);

	const onHandleDate = useCallback(
		name => {
			onHideSearch(name);
			var end = getAdjustedDate(endDate);
			userMetrics.trackEvent("user-custom-indicator-history: filter date", {
				start: startDate,
				end,
			});
			return filterDate(startDate, end);
		},
		[onHideSearch, getAdjustedDate, endDate, startDate, filterDate]
	);

	const onUpdateReportFileName = useCallback(
		event => {
			event.persist();
			var value = event.target.value;
			component.setState({ fileName: value });
		},
		[component]
	);

	const onUpdateDate = useCallback(
		(name, event, date) => {
			var value = date;
			if (event) {
				value = event._d;
			}
			var update = {};
			update[name] = value;
			component.setState(update);
		},
		[component]
	);

	const headers = useMemo(
		() => [
			{
				name: "user",
				content: localization.get("user_steps_history_user")
			},
			{
				name: "value",
				content: `${localization.get("org_custom_indicators_value")} (${indicator?.unit})`
			},
			{
				name: "time",
				content: localization.get("user_steps_history_time")
			}
		],
		[localization, indicator]
	);

	const reportActions = [
		csvReport(component, localization, indicatorId),
		<Button key={"buttoncancel"} onClick={onHideReport}>
			{localization.get("report_cancel")}
		</Button>
	];

	const dateActions = useMemo(
		() => [
			<Button key={"buttoncancel"} onClick={par(onHandleDate, "time")}>
				{localization.get("search_apply")}
			</Button>,
			<Button key={"buttoncancel"} onClick={par(onHideSearch, "time")}>
				{localization.get("form_submissions_history_cancel")}
			</Button>
		],
		[localization, onHandleDate, onHideSearch]
	);

	const title = useMemo(() => `${getNameForLanguage(indicator, language)}`, [
		localization,
		getNameForLanguage,
		indicator,
		language
	]);

	const reportTitle = useMemo(
		() => `${localization.get("custom_history_report_title")} (${getNameForLanguage(indicator, language)})`,
		[localization, getNameForLanguage, indicator, language]
	);

	const searchRef = useRef(null);

	return (
		<div className="flex-vertical flex-1">
			<Header
				careplanChanges={careplanChanges}
				offline={offline}
				currentPerson={currentPerson}
				localization={localization}
				title={title}
			/>

			<ActionMenu
				component={component}
				onShowReport={onShowReport}
				onClearSearch={onClearSearch}
				onShowSearch={onShowSearch}
			/>

			<HistoryTable component={component} loadMore={more} headers={headers} rowData={rowData} />

			<Dialog actions={reportActions} open={report} title={reportTitle}>
				<div>
					<DatePicker
						invalid={chooseDate}
						labelStart={localization.get("report_start")}
						labelEnd={localization.get("report_end")}
						startDate={startDate}
						endDate={endDate}
						updateStart={par(onUpdateDate, "startDate")}
						updateEnd={par(onUpdateDate, "endDate")}
					/>
					<TextField
						fullWidth
						placeholder={localization.get("report_filename")}
						inputProps={{ "aria-label": localization.get("report_filename") }}
						value={fileName}
						onChange={onUpdateReportFileName}
						InputProps={styles.inputProp}
					/>
				</div>
			</Dialog>

			<SearchTable
				action={par(filterUser, component)}
				ref={searchRef}
				search={state.search}
				localization={localization}
				people={people}
				showing={userFilter}
				onClose={closeSearch}
				title={localization.get("search_panel_title_patient")}
			/>

			<Dialog actions={dateActions} open={dateFilter} title={localization.get("dashboards_stepsHistory_title")}>
				<DatePicker
					invalid={chooseDate}
					labelStart={localization.get("report_start")}
					labelEnd={localization.get("report_end")}
					startDate={startDate}
					endDate={endDate}
					updateStart={par(onUpdateDate, "startDate")}
					updateEnd={par(onUpdateDate, "endDate")}
				/>
			</Dialog>
		</div>
	);
};

function render() {
	const component = this;
	return <UserCustomIndicatorHistory component={component} />;
}

function csvReport(component, localization, indicatorId) {
	var comp = component.state;
	var start = comp.startDate;
	var end = getAdjustedDate(comp.endDate);
	var filename = comp.fileName;

	if (!filename) {
		filename = localization.get("default_file_steps");
	}

	var language = comp.currentPerson.get("personal").language;
	var timezone = new Date().getTimezoneOffset();

	var url =
		config.host +
		`/v2/org/observations/indicators/${indicatorId}/cumulative/report?token=` +
		config.token +
		"&start=" +
		encodeURIComponent(start) +
		"&end=" +
		encodeURIComponent(end) +
		"&filename=" +
		encodeURIComponent(filename) +
		"&language=" +
		encodeURIComponent(language) +
		"&timezone=" +
		encodeURIComponent(timezone);

	return (
		<a
			key={url}
			className="ae-report-save"
			href={url}
			download={filename}
			onClick={() =>
				component.state.userMetrics.trackEvent("user-custom-indicator-history: download csv report", {
					start,
					end,
					filename,
					language,
					timezone
				})
			}
		>
			{localization.get("report_save")}
		</a>
	);
}
