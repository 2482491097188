/* © 2014 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, June 19, 2014
 * For information or permission request, email info@aetonixsystems.com
 */

"use strict";

/**
 * This store derives its data from an api call to /org/machines.  It contains
 * data concerning the machines associated with members of a group.
 */

var Store = require("./store");
var map = require("fj-map");

import * as Sentry from '@sentry/react';

module.exports = GroupMachinesStore;

function GroupMachinesStore(api, events, config) {

	var store = new Store();

	machines();

	listeners();

	return store;


  /**
   * Sets listeners for association events involving group members
   * @return {[type]} [description]
   */
	function listeners() {
		api.org.users.list()
			.then(map(listen_for_associations))
			.catch(error => {
				Sentry.captureException(error);
				console.error.bind(console, error);
			});
	}

  /**
   * Retrieves an array of machines associated with the group
   * sets those a values in the store.
   * @return {Promise} Resolves to a list of users and machines
   */
	function machines() {
		api.org.machines.listStaff()
			.then(map(handle_create))
			.catch(error => {
				Sentry.captureException(error);
				console.error.bind(console, error);
			});
	}

	function listen_for_associations(id) {
		events.on("mqtt:/" + id + "/machine/association", handle_association);
	}

	function handle_association(who, serial, apk) {
		machines();
	}

	function handle_create(data) {
		var id = data.serial;
		store.set(id, data);
	}

}
