/* © 2014 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, June 19, 2014
 * For information or permission request, email info@aetonixsystems.com
 */

"use strict";

/**
* This store derives its data from an api call to /org/devices.  It contains
* data concerning the bracelet connection history of members of a group.
*/

var Store = require("./store");
var map = require("fj-map");

import * as Sentry from '@sentry/react';

module.exports = WanderHistoryStore;

function WanderHistoryStore(api, events, config) {
	var store = new Store();

	var loading = null;
	var page = 0;
	var	status = "";
	var query = [];
	var start = "";
	var end = "";

	store.sorted = sorted;
	store.more = more;
	store.searchQuery = searchQuery;
	store.clearSearch = clearSearch;
	store.searchDate = searchDate;
	store.searchStatus = searchStatus;

	reset();

	return store;

	function reset() {

		page = 0;
		store.clear();
		return api.org.devices.wander.history(query, page, start, end, status)
			.then(map(handle_create));
	}

	function more() {
		if(loading) return loading;
		loading = api.org.devices.wander.history(query, page, start, end, status)
			.then(map(handle_create))
			.then(next_page)
			.catch(error => {
				Sentry.captureException(error);
				store.error(error);
			})
			.then(finish);
	}

	function finish() {
		loading = null;
	}

	function sorted() {
		return store.all().sort(by_time);
	}

	function handle_create(data) {
		var id = data._id;
		store.set(id, data);
	}

	function next_page() {
		page += 1;
	}

	function searchQuery(filterquery) {
		query = query.concat(filterquery);
		return reset();
	}

	function searchDate(startdate, enddate) {
		start = startdate;
		end = enddate;
		if (!!start && !!end){
			return reset();
		} else {
			return;
		}
	}

	function searchStatus(filterstatus) {
		status = filterstatus;
		return reset();
	}

	function clearSearch(name) {
		if (name === "user") query = [];
		if (name === "status") status = "";
		if (name === "time") {
			start = "";
			end = "";
		}
		return reset();
	}

}

function by_time(prev, next) {
	var prev_time = (new Date(prev.created_at)).getTime();
	var next_time = (new Date(next.created_at)).getTime();

	var time_delta = next_time - prev_time;
	return time_delta;
}
