/* © 2014 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, June 19, 2014
 * For information or permission request, email info@aetonixsystems.com
 */


import * as Sentry from '@sentry/react';

var Store = require("./store");

module.exports = CurrentPersonInfoStore;

function CurrentPersonInfoStore(api, events, config) {
	var store = new Store();

	store._get = store.get;
	store.get = get;

	resetGroup();
	resetOrg();
	resetData();

	events.on("mqtt:$/orggroup/staff/removed", unset("group"));
	events.on("mqtt:$/org/managers/removed", unset("org"));

	events.on("mqtt:$/orggroup/staff/added", resetGroup);
	events.on("mqtt:$/org/managers/added", resetOrg);
	events.on("mqtt:$/settings", resetData);

	const organization = config.organization;
	if(organization){
		events.on(`mqtt:org/${organization}/retention/updated`, resetOrg);
	}

	return store;

	function get(key) {
		var existing = this._get(key);
		if (existing) return existing;
		return {};
	}

	function resetGroup() {
		api.org.group.current.data().then(set("group")).catch(error => {
			Sentry.captureException(error);
			err(error);
		});
	}

	function resetOrg() {
		api.org.data().then(set("org")).catch((error => {
			err(error);
			Sentry.captureException(error);

			api.org.staff.orgData().then(set("org")).catch(e => {
				Sentry.captureException(e);
				err(e);
			});
		}));
	}

	function resetData() {
		api.people.data().then(data => {

			const { timezone, language, autologout, available } = data;

			set("personal")(data);

			Sentry.setContext("config", {
				timezone,
				language,
				autologout,
				available,
			});
		}).catch(error => {
			Sentry.captureException(error);
			err(error);
		});
	}

	function unset(name) {
		return function () {
			store.remove(name);
		};
	}

	function set(name) {
		return function (data) {
			store.set(name, data);
		};
	}

	function err(e) {
		console.error(e);
	}
}
