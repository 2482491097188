var React = require("react");
const ResourceProvider = require("./contexts/ResourceContext").ResourceProvider;
var MuiThemeProvider = require("@material-ui/1.5.1/styles/MuiThemeProvider").default;
var AetonixTheme_mui_151 = require("./AetonixTheme_mui_1.5.1.js");
const { useUserMetrics } = require("@aetonix/user-metrics");

import {
	useResources,
} from "@aetonix/hooks";

module.exports = function makeAppComponent(make_ui) {
	const AppComponent = (props) => {
		const { api, events, config } = useResources();
		if (!api || !events || !config) return null;
		const { userMetrics } = useUserMetrics();
		const ComponentToRender = make_ui(
			api,
			events.createTracker(),
			config,
			userMetrics,
			props,
			events,
		);
		if (props.init) props.init();

		if (!ComponentToRender) return null;

		return (
			<MuiThemeProvider theme={AetonixTheme_mui_151}>
				<ResourceProvider>
					<ComponentToRender />
				</ResourceProvider>
			</MuiThemeProvider>
		);
	};

	return AppComponent;
};
