/* © 2014 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, June 19, 2014
 * For information or permission request, email info@aetonixsystems.com
 */

var React = require("react");
var par = require("par");
var config = require("../../configs/config.json");
var escape_regex = require("escape-regexp");

var Header = require("../shared/Header.js");
var SubHeader = require("@material-ui/1.5.1/ListSubheader").default;

var IconButton = require("@material-ui/1.5.1/IconButton").default;
var Avatar = require("@material-ui/1.5.1/Avatar").default;
var ListItem = require("@material-ui/1.5.1/ListItem").default;
var ListItemText = require("@material-ui/1.5.1/ListItemText").default;
var Dialog = require("../shared/Dialog");

var Button = require("@material-ui/1.5.1/Button").default;
var TextField = require("@material-ui/1.5.1/TextField").default;

var Utility = require("../shared/Utility");
var LazyList = require("../shared/LazyList.jsx");
var Search = require("../shared/Search.jsx");
var Colors = require("../shared/AetonixTheme").palette;


var iconButtonClasses = {
	root: "ae-inline"
};

var styles = {
	dropUnderline: {
		borderTop: 0,
	},
	dropDown: {
		alignItems: "baseline",
		marginLeft: "30px",
		padding: "0 5px",
		color: Colors.primary.main
	},
	search: {
		color: Colors.primary.main,
		minWidth: "30%",
		padding: 8
	},
	tabsRoot: {
		backgroundColor: Colors.primary.main,
		color: "white"
	},
	subHeader: {
		display: "flex",
		flexDirection: "row",
		paddingLeft: "1em",
		alignItems: "baseline"
	},
	input: {
		color: "grey",
		marginTop: "8px",
		marginBottom: "8px"
	},
	inputProp: {
		style: {
			color: Colors.primary.main,
		}
	},
	formControlStyle: {
		marginTop: "8px",
		marginBottom: "8px"
	},
	userInactive: {
		color: "red"
	},
	snackbar: {
		style: {
			backgroundColor: "red"
		}
	},
	uploadButton: {
		margin: "10px"
	},
	inputFile: {
		display: "none"
	},
	image: {
		margin: "10px 0px",
		width: "200px",
		height: "200px"
	}
};

module.exports = render;

function render() {
	var component = this;
	var state = component.state;
	var people = state.people;
	var localization = state.localization;
	var careplanNoticeList = state.careplanChangeList.all();
	var careplanChanges = !!careplanNoticeList.length;
	var offline = state.connection.get("offline");
	var creating = state.creating;
	var updating = state.updating;
	var currentPerson = state.currentPerson;
	var callgroups = state.callgroups;
	var viewing = state.viewing;
	var removingMember = state.removingMember;
	var removing = state.removing;
	var filteredMembers = state.filteredMembers;
	var filteredCallGroups = state.filteredCallGroups;

	var titleKey = "callgroups_title";
	var current = null;
	var membersRaw = null;
	var members = [];
	var shownMembers = [];
	var allCallGroups = callgroups.all();
	var shownCallGroups = filteredCallGroups && filteredCallGroups.length ? filteredCallGroups : allCallGroups;

	if(viewing){
		current = callgroups.get(viewing);
		membersRaw = current.members;
		members = membersRaw.map(people.get).sort(by_name);
		shownMembers = filteredMembers && filteredMembers.length ? filteredMembers : members;
	}

	var currentName = null;
	var currentDescription = null;
	var currentImageURL = null;
	if(updating){
		var currentGroup = callgroups.get(updating);
		var currentImage = currentGroup.image;
		currentName = currentGroup.name;
		currentDescription = currentGroup.description;
		if(currentImage !== "default.png")
			currentImageURL = config.image_cdn + currentGroup.image;
	}

	var create_actions = [(
		<Button onClick={par(doCreate, component)}>{localization.get("users_create")}</Button >
	), (
		<Button onClick={par(hideCreate, component)}>{localization.get("users_cancel")}</Button >
	)];

	var update_actions = [(
		<Button onClick={par(doUpdate, component)}>{localization.get("users_update")}</Button >
	), (
		<Button onClick={par(hideUpdate, component)}>{localization.get("users_cancel")}</Button >
	)];

	var remove_actions_member = [(
		<Button key={"user_ok"} onClick={par(confirmRemoveMember, component)}>{localization.get("users_ok")}</Button >
	), (
		<Button key={"users_cancel"} onClick={par(hideRemovingMember, component)}>{localization.get("users_cancel")}</Button >
	)];

	var remove_actions = [(
		<Button key={"user_ok"} onClick={par(confirmRemove, component)}>{localization.get("users_ok")}</Button >
	), (
		<Button key={"users_cancel"} onClick={par(hideRemovingCallGroup, component)}>{localization.get("users_cancel")}</Button >
	)];


	var renderMembersLazyList = component.state.viewing ? (
		<LazyList className="ae-scrollable" renderItem={par(renderMember, component)} items={shownMembers} />
	) : null;

	var renderAddNewMember = component.state.viewing ? (
		<Button variant="raised" onClick={par(startAdding, component)} color="secondary" >{localization.get("callgroups_addmember")}</Button>
	) : null;

	var renderSearchMember = component.state.viewing ? (
		<TextField placeholder={localization.get("callgroups_searchMember")} inputProps={{"aria-label": localization.get("callgroups_searchMember")}} onChange={par(search, component, members, "Members")} style={styles.search} InputProps={styles.inputProp} />
	) : null;

	return (
		<div className="flex-vertical flex-1">
			<Header
				careplanChanges={careplanChanges}
				offline={offline}
				currentPerson={currentPerson}
				localization={localization}
				titleKey={titleKey}
			/>

			<div className="flex-horizontal flex-1 ae-scrollable">
				<div className="flex-vertical flex-1 ae-left-margin ">
					<SubHeader>{localization.get("callgroups_subtitle")}</SubHeader>
					<TextField placeholder={localization.get("callgroups_search")} inputProps={{"aria-label": localization.get("callgroups_search")}} onChange={par(search, component, allCallGroups, "CallGroups")} style={styles.search} InputProps={styles.inputProp} />
					<LazyList className=" ae-scrollable" renderItem={par(renderCallGroup, component)} items={shownCallGroups} />
					<Button variant="raised" onClick={par(startCreating, component)} color="secondary" >{localization.get("callgroups_create")}</Button>
				</div>
				<div className="flex-vertical flex-1">
					<div className="flex-vertical flex-1 ae-scrollable">
						<SubHeader>{localization.get("callgroups_members")}</SubHeader>
						{renderSearchMember}
						{renderMembersLazyList}
						{renderAddNewMember}
					</div>
				</div>
			</div>

			<Search selection={membersRaw} action={par(addMember, component)} ref="searchDialog" search={state.search} localization={localization} />

			<Dialog title={localization.get("callgroups_create")} actions={create_actions} modal={true} open={!!creating}>
				<div className="flex-vertical">
					<TextField placeholder={localization.get("callgroups_name")} value={state.newName} onChange={par(updateActionChanged, component, "newName")} label={localization.get("callgroups_name")} style={styles.input}  InputProps={styles.inputProp} />
					<TextField placeholder={localization.get("callgroups_description")} value={state.newDescription} onChange={par(updateActionChanged, component, "newDescription")} label={localization.get("callgroups_description")} style={styles.input} InputProps={styles.inputProp} />
					<div className="flex-vertical">
						{"Select an icon image"}
						{renderImagePicker(component, currentImageURL)}
					</div>
			</div>
			</Dialog>

			<Dialog title={currentName} actions={update_actions} modal={true} open={!!updating}>
				<div className="flex-vertical">
					<TextField defaultValue={currentName} placeholder={localization.get("callgroups_name")} value={state.updateName} onChange={par(updateActionChanged, component, "updateName")} label={localization.get("callgroups_name")} style={styles.input}  InputProps={styles.inputProp} />
					<TextField defaultValue={currentDescription} placeholder={localization.get("callgroups_description")} value={state.updateDescription} onChange={par(updateActionChanged, component, "updateDescription")} label={localization.get("callgroups_description")} style={styles.input} InputProps={styles.inputProp} />
					<div className="flex-vertical">
						{"Select an icon image"}
						{renderImagePicker(component, currentImageURL)}
					</div>
			</div>
			</Dialog>

			<Dialog actions={remove_actions_member} open={!!removingMember} title={localization.get("callgroups_removeMember")}>
				{localization.get("callgroups_warning") + " " + Utility.format_name(people.get(removingMember)) + "?"}
			</Dialog>

			<Dialog actions={remove_actions} open={!!removing} title={localization.get("callgroups_remove")}>
				{localization.get("callgroups_warning") + " " + (removing ? callgroups.get(removing).name : "") + "?"}
			</Dialog>
		</div>
	);
}

function by_name(x, y) {
	return order(x.lname.toLowerCase() + x.fname.toLowerCase(), y.lname.toLowerCase() + y.fname.toLowerCase());
}

function order(x, y){
	if(x < y)
		return -1;
	if(x > y)
		return 1;
	return 0;
}

function renderCallGroup(component, group){
	var avatar = <Avatar alt="Call Group Avatar Image" src={config.image_cdn + group.image} />;
	var text = group.name;
	var secondaryText = group.description;
	var groupId = group._id;
	var background_colour = "ae-plain";
	var localization = component.state.localization;
	var viewing = component.state.viewing;
	if (groupId === viewing)
		background_colour = "ae-hover-color";

	return (
		<ListItem key={groupId} className={background_colour} onClick={par(viewGroup, component, groupId)}>
			{avatar}
			<ListItemText primary={text} secondary={secondaryText} />
			<IconButton classes={iconButtonClasses} disableRipple aria-label={localization.get("edit_button")} title={localization.get("edit_button")} role="button" color="primary" className="fa fa-pencil" onClick={par(startUpdate, component, groupId)} />
			<IconButton classes={iconButtonClasses} disableRipple aria-label={localization.get("remove_button")} title={localization.get("remove_button")} role="button" color="primary" className="fa fa fa-times-circle" onClick={par(startRemoveCallGroup, component, groupId)} />
		</ListItem>
	);
}

function viewGroup(component, group){
	component.state.userMetrics.trackEvent("group-callgroups: view call group", {
		"call group": group
	});
	component.setState({
		viewing: group
	});
}

function startCreating(component){
	component.setState({
		creating: true
	});
	component.state.userMetrics.trackEvent("group-callgroups: open create call group popup");
}

function hideCreate(component){
	component.state.userMetrics.trackEvent("group-callgroups: close create call group popup");
	component.setState({
		creating: false,
		newName: null,
		newDescription: null,
		file: null,
		fileView: null
	});
}

function doCreate(component){
	var name = component.state.newName;
	var description = component.state.newDescription;
	var file = component.state.file;
	var create = component.state.create;

	create(name, description, file);
	hideCreate(component);

	component.state.userMetrics.trackEvent("group-callgroups: create", {
		"name": name,
		"description": description
	});
}

function doUpdate(component){
	var name = component.state.updateName;
	var description = component.state.updateDescription;
	var group = component.state.updating;
	var update = component.state.update;
	var file = component.state.file;

	update(group, name, description, file);
	hideUpdate(component);

	component.state.userMetrics.trackEvent("group-callgroups: edit call group", {
		"name": name,
		"description": description,
		"call group": group
	});
}

function updateActionChanged(component, name, event) {
	event.persist();
	var value = event.target.value;
	var update = {};
	update[name] = value;
	component.setState(update);
}

function renderMember(component, member){
	var avatar = <Avatar alt="Member Avatar Image" src={config.image_cdn + member.image} />;
	var text = Utility.format_name(member);
	var memberId = member._id;
	var localization = component.state.localization;
	var background_colour = "ae-plain";

	return (
		<ListItem key={memberId} className={background_colour}>
			{avatar}
			<ListItemText primary={text} />
			<IconButton classes={iconButtonClasses} disableRipple aria-label={localization.get("remove_button")} title={localization.get("remove_button")} role="button" color="primary" className="fa fa-user-times" onClick={par(removeMember, component, memberId)} />
		</ListItem>
	);
}

function startAdding(component){
	component.state.userMetrics.trackEvent("group-callgroups: open add member popup", {
		"call group": component.state.viewing,
	});
	component.refs.searchDialog.show();
}

function removeMember(component, member){
	component.state.userMetrics.trackEvent("group-callgroups: remove member from call group", {
		"member": member,
		"call group": component.state.viewing,
	});
	component.setState({
		removingMember: member
	});
}

function hideRemovingMember(component){
	component.state.userMetrics.trackEvent("group-callgroups: close remove member popup");
	component.setState({
		removingMember: false
	});
}

function confirmRemoveMember(component){
	var remove = component.state.removeMember;
	var group = component.state.viewing;
	var member = component.state.removingMember;
	remove(group, member);
	hideRemovingMember(component);
	component.state.userMetrics.trackEvent("group-callgroups: remove user from call group", {
		"member": member,
		"call group": group,
	});
}

function addMember(component, member){
	var group = component.state.viewing;
	var add = component.state.addMember;

	component.state.userMetrics.trackEvent("group-callgroups: add member to call group", {
		"member": member,
		"call group": group
	});
	add(group, member);
}

function hideUpdate(component){
	component.state.userMetrics.trackEvent("group-callgroups: close edit call group popup");
	component.setState({
		updating: false,
		file: null,
		fileView: null
	});
}

function hideRemovingCallGroup(component){
	component.state.userMetrics.trackEvent("group-callgroups: close remove call group popup");
	component.setState({
		removing: false
	});
}

function startUpdate(component, group){
	component.state.userMetrics.trackEvent("group-callgroups: open edit call group popup", {
		"call group": group
	});
	component.setState({
		updating: group
	});
}

function startRemoveCallGroup(component, group){
	component.state.userMetrics.trackEvent("group-callgroups: open remove call group popup", {
		"call group": group
	});
	component.setState({
		removing: group
	});
}

function confirmRemove(component){
	var group = component.state.removing;
	var remove = component.state.remove;
	remove(group);

	component.setState({
		removing: false,
		viewing: false
	});
	component.state.userMetrics.trackEvent("group-callgroups: remove call group", {
		"call group": group
	});
}

function search(component, array, key, event) {
	event.persist();
	var query = event.target.value;
	var terms = escape_regex(query).split(" ").join("");
	var regex = new RegExp(terms, "i");

	var filtered = array.filter(function (item) {
		var matches = getName(item).split(" ").join("").match(regex);
		if (matches) return item;
	});

	var update = {};
	update["filtered" + key] = filtered;

	component.setState(update);
}

function getName(item){
	var name = item.name || item.lname + ", " + item.fname;
	return name;
}

function renderImagePicker(component, currentImageURL){
	var state = component.state;
	var file = state.fileView;
	var localization = component.state.localization;
	var renderURL = file || currentImageURL || null;

	var renderFile = renderURL ? (
		<img alt="Selected Icon Image" style={styles.image} src={renderURL} />
	) : null;

	return (
		<div className="flex-vertical">
			{renderFile}
			<input aria-label={localization.get("select_image")} accept="image/*" type="file" onChange={par(getFile, component)}/>
		</div>
	);
}

function getFile(component, e){
	var file = e.target.files[0];
	if(file)
		return loadImage(file).then(function(readable){
			component.setState({
				file: file,
				fileView: readable
			});
		});
}

function loadImage(file){
	// eslint-disable-next-line no-undef
	var reader = new FileReader();
	return new Promise((resolve, reject) => {
		reader.onload = function(e) {
			resolve(e.target.result);
		};
		reader.onerror = function(error){
			reader.abort();
			reject(error);
		};
		reader.readAsDataURL(file);
	});
}