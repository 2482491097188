

module.exports = [
	"emailSearch",
	"hideEmrId",
	"hideCreationToken",
	"allowControl",
	"disableNotification",
	"callConfirmation",
	"isNewCallingEnabled",
	"shouldDisconnectMeetingOnAdminLeave",
	"isCustomPropetyUpdated",
	"isPinningEnabled",
	"isActiveSpeakerEnabled",
	"isRestrictedModeEnabled",
	"isEnrolmentFormEnabled"
];