import React from "react";
import { useMeasureUnits } from "@aetonix/hooks";
import { getDataTransformer } from "./DataTransformer";
import { TableComponent } from "../../../shared/TableComponent";

export function HealthTable(props){
	const measureUnits = useMeasureUnits();
	const { localization, state, component, days, allIndicators } = props;

	const dataTransformer = getDataTransformer(localization, component);
	const headerColumns = Object.keys(dataTransformer)
		.filter(key => key !== "indicatorTransformer")
		.concat(
			allIndicators.map(indicator => {
				return {
					key: indicator._id,
					indicator
				};
			})
		);

	return (
		<TableComponent
			pageName={"user-overview"}
			userMetricsLabel={"health tab"}
			ignorePageLocalStorage={true}
			headerColumns={headerColumns}
			dataTransformer={dataTransformer}
			localization={localization}
			localizationPrefix={"user_overview_"}
			tableName={"user_overview"}
			measureUnits={measureUnits}
			records={days}
			state={state}
		/>
	);
}