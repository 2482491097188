
/* © 2017 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, January 01, 2017
 * For information or permission request, email info@aetonixsystems.com
 */

var React = require("react");
var par = require("par");
var config = require("../../configs/config.json");

/**
* User Interface elements material-ui
*/
var Header = require("../shared/Header.js");

var Card = require("@material-ui/1.5.1/Card").default;
var CardHeader = require("@material-ui/1.5.1/CardHeader").default;

var List = require("@material-ui/1.5.1/List").default;
var ListItem = require("@material-ui/1.5.1/ListItem").default;
var ListItemText = require("@material-ui/1.5.1/ListItemText").default;

var Avatar = require("@material-ui/1.5.1/Avatar").default;
var SubHeader = require("@material-ui/1.5.1/ListSubheader").default;

var Utility = require("../shared/Utility.js");
var titleKey = "live_monitoring_bracelets";

module.exports = render;

function render() {
	var component = this;
	var state = component.state;
	var localization = state.localization;
	var offline = state.connection.get("offline");
	var careplanChanges = null;

	var people = state.people;
	var machineStore = state.machines;
	var machines = machineStore.sorted();
	var currentPerson = state.currentPerson;

	if (machinesHasChanged(machines, this.state.previousMachines)) {
		machines.length && this.state.userMetrics.trackEvent("admin-bracelets-live: viewed bracelets", {
			"bracelet serials": machines.map((m) => m.serial),
		});
		this.setState({
			previousMachines: machines
		});
	}

	return (
		<div className="flex-vertical flex-1">

			<Header
				careplanChanges={careplanChanges}
				offline={offline}
				currentPerson={currentPerson}
				localization={localization}
				titleKey={titleKey}
			/>

			{machines.map(par(renderMachine, people, machineStore))}
		</div>
	);
}

function machinesHasChanged(machines, previousMachines) {
	machines = machines || [];
	previousMachines = previousMachines || [];
	if (machines.length !== previousMachines.length)
		return true;
	for (var i = 0; i < machines.length; i++) {
		if (machines[i].serial !== previousMachines[i].serial)
			return true;
	}
	return false;
}

function renderMachine(people, machineStore, machine) {
	var machineOwner = people.get(machine.who);
	return (
		<div className="ae-machine" key={machine.serial}>
			<Card>
				<CardHeader title={Utility.format_name(machineOwner)} subheader={machine.state} avatar={<Avatar alt="User Avatar Image" src={config.image_cdn + machineOwner.image}/>}/>
				{renderTake(people, machineStore, machine)}
				{renderAllFound(people, machineStore, machine)}
			</Card>
		</div>
	);
}

function renderAllFound(people, machineStore, machine) {
	var found = machine.found;
	var taken = machine.taken;
	if (!found.length || taken)
		return;

	return (
		<List >
			<SubHeader>Found By</SubHeader>
			{found.map(par(renderFound, people, machineStore, machine.serial))}
		</List>
	);
}

function renderTake(people, machineStore, machine) {
	var taker,
		title;
	if (machine.taken) {
		taker = machine.taken;
		title = "Connected to";
	} else if (machine.take) {
		taker = machine.take;
		title = "Connecting to";
	}
	if (!taker)
		return;

	var takerOwner = people.get(machineStore.get(taker).who);

	return (
		<List subheader={title}>
			<ListItem >
				<Avatar src={config.image_cdn + takerOwner.image} />
				<ListItemText primary={Utility.format_name(takerOwner)} />
			</ListItem>
		</List>
	);
}

function renderFound(people, machineStore, serial, found) {
	var finder = found.finder;
	var timestamp = found.timestamp;
	var finderOwner = people.get(machineStore.get(finder).who);

	return (
		<ListItem key={serial}>
			<Avatar src={config.image_cdn + finderOwner.image} />
			<ListItemText primary={Utility.format_name(finderOwner)} secondary={Utility.timestamp(timestamp)} />
		</ListItem>
	);
}
