/* © 2014 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, June 19, 2014
 * For information or permission request, email info@aetonixsystems.com
 */

"use strict";
var Store = require("./store");
var xtend = require("xtend");

module.exports = WorkflowAutostart;

function WorkflowAutostart(api, events, config) {
	var store = new Store();

	reset();

	return store;

	function reset(){
		return api.org.workflow.autostart.get()
			.then(function(settings){
				store.set("settings", settings);
				trackChanges();
			});
	}

	function trackChanges(){
		var orgGroup = config.orgGroup;
		if(!orgGroup) return;
		events.on("mqtt:orggroup/" + orgGroup + "/workflow/autostart/updated", handle_update);
		events.on("mqtt:orggroup/" + orgGroup + "/workflow/autostart/removed", handle_remove);
	}

	function handle_update(data){
		store.set("settings", data);
	}

	function handle_remove(){
		store.set("settings", null);
	}
}
