/* © 2014 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, June 19, 2014
 * For information or permission request, email info@aetonixsystems.com
 */

"use strict";

/**
 * This store derives its data from an api call to /org/reminders.  It contains
 * data concerning the reminders history of members of a group.
 */

var Store = require("./store");
var xtend = require("xtend");

module.exports = UserTableData;

function UserTableData(api) {
	var store = new Store();
	var tracking = [];
	var perPage = 25;
	var page = 0;
	var start = null;
	var end = null;

	var currentTableId = null;
	var currentId = null;

	store.raw_get = store.get;
	store.get = get;
	store.changePerPage = changePerPage;
	store.changePage = changePage;
	store.filter = filter;
	
	store.getAllTableData = getAllTableData;

	return store;

	function filter(startDate, endDate){
		start = startDate;
		end = endDate;
		updateTable();
	}

	function changePerPage(newPages){
		perPage = newPages;
		updateTable();
	}

	function changePage(newPage){
		page = newPage;
		updateTable();
	}

	function updateTable(){
		return getTableData(currentId, currentTableId);
	}

	function get(id, tableId){
		currentId = id;
		currentTableId = tableId;

		var trackId = `${id}_${tableId}`;
		if(tracking.indexOf(trackId) !== -1){
			var tableData = store.raw_get(id) || {};
			return tableData[tableId] || {};
		}

		getTableData(id, tableId);
		return {
			loading: true
		};
	}

	function getTableData(id, tableId){
		var currentPerson = store.raw_get(id) || {};

		return api.org.tables.data(tableId, id, page, perPage, start, end)
			.then(results => {
				var processed = results.reduce(processTable, {});
				var update = {};
				update[tableId] = processed;
				if(tracking.indexOf(`${id}+${tableId}`) === -1)
					tracking.push(`${id}_${tableId}`);
				store.set(id, xtend(currentPerson, update));
			});
	}

	
	function getAllTableData(id, tableId){

		return api.org.tables.data(tableId, id, undefined, undefined, start, end)
			.then(results => {
				var processed = results.reduce(processTable, {});
				return processed
			});
	}

	function processDayList(list){
		return list.reduce((acc, listItem) => {
			var isForm = listItem.schema;
			var isWorkflow = listItem.workflow;

			if(isForm){
				var hasFormData = acc[isForm];
				if(!hasFormData)
					acc[isForm] = listItem.data;
			}

			if(isWorkflow){
				var hasWorkflowData = acc[isWorkflow];
				if(!hasWorkflowData)
					hasWorkflowData = {};

				var hasActionData = hasWorkflowData[listItem.action];
				if(!hasActionData){
					var update = {};
					update[listItem.action] = listItem.data;
					acc[isWorkflow] = xtend(hasWorkflowData, update);
				}
			}
			return acc;
		}, {});
	}

	function processTable(accumulator, element){
		accumulator[element._id] = processDayList(element.list || []);
		return accumulator;
	}

	
}

