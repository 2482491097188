"use strict";

var Store = require("./store");

import * as Sentry from '@sentry/react';

module.exports = OrgMultifactorAuth;

function OrgMultifactorAuth(api, events, config) {
	var store = new Store;

	api.org.managers.multifactor.get()
		.then(handle_get_multifactor)
		.then(listen)
		.catch(error => {
			Sentry.captureException(error);
			console.error(error);
		});

	return store;

	function listen() {
		var organization = config.organization;
		if (!organization) return;
		events.on("mqtt:org/" + organization + "/multifactor", handle_set_multifactor);
	}

	function handle_get_multifactor(multifactor) {
		store.set("multifactor", multifactor);
	}

	function handle_set_multifactor(multifactor) {
		store.set("multifactor", multifactor);
	}
}
