/* © 2014 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, June 19, 2014
 * For information or permission request, email info@aetonixsystems.com
 */

var Store = require("./store");
var map = require("fj-map");
const { getLocalStorageArray } = require("../shared/Utility.js");
module.exports = UserManagedStore;

function UserManagedStore(api, events, config) {
	var store = new Store();

	var page = 0;
	var loading = null;
	var atEnd = false;
	const pagination = {
		sortBy: "current_status",
		orderBy: "desc",
	};

	var userLocalStorageKey = localStorage.getItem("userLocalStorageKey");
	store.activity = getActivity(getLocalStorageArray(`${userLocalStorageKey}stateFilter`));
	store.manageGroups = getLocalStorageArray(`${userLocalStorageKey}manageGroups`);
	store.more = more;
	store.reset = reset;
	store.trackUser = trackUser;
	store.search = search;
	store.changeActivity = changeActivity;
	store.changeManageGroups = changeManageGroups;
	store.query = "";
	store.inactive = null;

	reset();

	return store;

	function reset() {
		page = 0;
		atEnd = false;
		store.clear();
		return api.manage
			.listManagedUsers({
				queryString: store.query,
				state: store.activity,
				manageGroups: store.manageGroups,
				inactive: store.inactive,
				orgGroup: config.orgGroup,
			}, { page, ...pagination })
			.then(map(add_person))
			.then(listen_for_changes);
	}

	function more() {
		if (atEnd)
			return Promise.resolve();
		if (loading)
			return loading;
		page += 1;
		return api.manage
			.listManagedUsers({
				queryString: store.query,
				state: store.activity,
				manageGroups: store.manageGroups,
				orgGroup: config.orgGroup,
			}, { page, ...pagination })
			.then(handle_users);
	}

	function handle_users(users) {
		if (!users.length) {
			atEnd = true;
			return;
		}

		users.map(add_person);

		loading = null;
	}

	function add_person(data) {
		var id = data.person || data;
		store.set(id, id);
	}

	function remove_person(data){
		var id = data.person;
		store.remove(id);
	}

	function listen_for_changes() {
		events.on("mqtt:$/manage/added", add_person);
		events.on("mqtt:$/manage/removed", remove_person);
	}

	function trackUser(){}

	function search(newQuery) {
		store.query = newQuery;
		return reset();
	}

	function changeActivity(newActivity) {
		store.activity = getActivity(newActivity);
		return reset();
	}

	function changeManageGroups(newManageGroups) {
		store.manageGroups = newManageGroups;
		return reset();
	}

	function getActivity(activity){
		activity = [activity].flat();
		store.inactive = null;
		const hasAllActive = activity.includes("all_active");
		const hasInActive = activity.includes("inactive");
		if (hasAllActive) {
			activity = [];
			if (!hasInActive) {
				// if "all_active" is selected but "inactive" is not, then all users with
				// inactive=false should be listed
				store.inactive = false;
			}
		}
		return activity;
	}
}
