/* © 2017 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, January 01, 2017
 * For information or permission request, email info@aetonixsystems.com
 */

var par = require("par");

var defineUI = require("../shared/define");
var render = require("./render.jsx");

// Boilerplate required stores
var PersonStore = require("../stores/person_store");
var LocalizationStore = require("../stores/localization_store");
var CareplanNotificationStore = require("../stores/careplan_notice_store");
var ConnectionStore = require("../stores/connection_store");

// Stores unique to this UI
var FormDefinitionStore = require("../stores/org_forms_store");
var FormSubmissionsChangeStore = require("../stores/org_forms_submission_change_store");
var CurrentPersonInfoStore = require("../stores/current_person_info_store");

var makeStores = require("../shared/stores")({
	people: PersonStore,
	localization: LocalizationStore,
	careplanChangeList: CareplanNotificationStore,
	connection: ConnectionStore,
	currentPerson: CurrentPersonInfoStore,

	formdefinitionStore: FormDefinitionStore,
	submissionchangeStore: FormSubmissionsChangeStore,
});

function make_ui(api, events, config, userMetrics) {

	var storeStore = makeStores(api, events, config);

	window.stores = storeStore.stores;

	userMetrics.trackEvent("page view", {
		"page": "org-forms-changes-history",
		"category": "group",
		"permission level": "staff",
	});

	return defineUI({
		getInitialState: getInitialState,
		componentDidMount: listen_for_changes,
		render: render,
		componentWillUnmount: removeListeners
	});

	function removeListeners() {
		events.off();
	}

	function listen_for_changes() {
		storeStore.listen(par(handle_change, this));
	}

	function handle_change(component, stores) {
		component.setState({
			people: stores.people,
			localization: stores.localization,
			careplanChangeList: stores.careplanChangeList,
			connection: stores.connection,
			currentPerson: stores.currentPerson,
			last_viewed: stores.submissionchangeStore.last_viewed,
			definitions: stores.formdefinitionStore,
			changes: stores.submissionchangeStore.sorted(),
		});
	}

	function getInitialState() {
		return {
			people: storeStore.stores.people,
			localization: storeStore.stores.localization,
			careplanChangeList: storeStore.stores.careplanChangeList,
			connection: storeStore.stores.connection,
			currentPerson: storeStore.stores.currentPerson,
			definitions: storeStore.stores.formdefinitionStore,
			changes: storeStore.stores.submissionchangeStore.sorted(),
			openingDataView: false,
			chosen_form: "",
			filterUser: filterUser,
			filterEditor: filterEditor,
			filterForms: filterForms,
			filterDate: filterDate,
			filterClear: filterClear,
			closeSearch: closeSearch,
			search: searchName,
			latest: {},
			last_viewed: null,
			handleConfirmChanges: handleConfirmChanges,
			handleConfirmSingleChange: handleConfirmSingleChange,
			report: false,
			userFilter: false,
			editorFilter: false,
			formsFilter: false,
			dateFilter: false,
			user: [],
			editor: [],
			forms: [],
			startDate: "",
			endDate: new Date(),
			fileName: "",
			userMetrics: userMetrics,
		};
	}

	/**
	 * Returns a list of the groups users
	 * @param  {objectId} query User_id
	 * @return {array}       User _id's
	 */
	function searchName(query) {
		return api.org.users.list(query);
	}

	function filterUser(component, data) {
		var user = component.state.user;
		component.setState({
			user: user.concat(data)
		});
		storeStore.stores.submissionchangeStore.searchUser(data);
		userMetrics.trackEvent("org-forms-changes-history: add user to filter", {
			"user": data,
			"current filter": component.state.user,
		});
	}

	function filterEditor(component, data) {
		var editor = component.state.editor;
		component.setState({
			editor: editor.concat(data)
		});
		storeStore.stores.submissionchangeStore.searchEditor(data);
	}

	function filterForms(component, data) {
		var forms = component.state.forms;
		component.setState({
			forms: forms.concat(data)
		});
		storeStore.stores.submissionchangeStore.searchForms(data);
	}

	function filterDate(start, end) {
		storeStore.stores.submissionchangeStore.searchDate(start, end);
	}

	function filterClear(name) {
		storeStore.stores.submissionchangeStore.clearSearch(name);
	}


	function closeSearch(component, name) {
		if (name === "user")
			component.setState({
				userFilter: false
			});

		if (name === "editor")
			component.setState({
				editorFilter: false
			});

		if (name === "form")
			component.setState({
				formsFilter: false
			});
		userMetrics.trackEvent(`org-forms-changes-history: close ${name} filter`);
	}

	function handleConfirmChanges() {
		storeStore.stores.submissionchangeStore.confirmViewed();
	}

	function handleConfirmSingleChange(component) {
		var submission = component.state.dataToView.submission;
		var owner = component.state.dataToView.owner;
		storeStore.stores.submissionchangeStore.confirmSubmissionViewed(submission, owner);
	}
}

module.exports = make_ui;