/* © 2017 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, January 01, 2017
 * For information or permission request, email info@aetonixsystems.com
 */

import React, { useMemo, useCallback } from "react";
import { getNameForLanguage } from "../org-custom-indicators/library.js";


var ReactRouterDom = require("react-router-dom");
var Link = ReactRouterDom.Link;

var par = require("par");

var Header = require("../shared/Header.js");
var List = require("@material-ui/1.5.1/List").default;
var Button = require("@material-ui/1.5.1/Button").default;
var Divider = require("@material-ui/1.5.1/Divider").default;
var SubHeader = require("@material-ui/1.5.1/ListSubheader").default;
var titleKey = "dashboards_header";

var styles = {
	styleButtons: {
		color: "#007E8F"
	},
	styleHeaders: {
		color: "#6F6F6F"
	},
	styleTitle: {
		fontSize: 24
	},
	styleAppBar: {
		position: "inherit"
	}
};

const IGNORE_FEATURE_FLAG_TYPE = [
	"logistics",
	"admin"
];

const PAGE_TYPES = [
	"admin",
	"logistics",
	"org",
	"group",
	"user",
	"monitor",
	"history",
	"myhealth",
];

const PAGE_TYPE_HEADERS = {
	admin: "dashboards_header_admin",
	logistics: "dashboards_header_logistics",
	org: "dashboards_header_orgmanage",
	group: "dashboards_header_groupmanage",
	user: "dashboards_header_usermanage",
	monitor: "dashboards_header_livemonitor",
	history: "dashboards_header_history",
	myhealth: "dashboards_header_myhealth",
  };

const Dashboard = props => {
	const { component } = props;

	const { localization, careplanChangeList, currentPerson, offline } = component.state;

	const careplanNoticeList = careplanChangeList?.all?.() ?? [];

	const careplanChanges = !!careplanNoticeList.length;
	const language = currentPerson.get("personal")?.language ?? "en";

	const organization = currentPerson?.get?.("org");
	const customIndicators = organization?.indicators ?? [];

	const personal = currentPerson.get("personal") || {};
	const userTypes = personal?.type || [];

	const renderCustomIndicators = useCallback(
		() => {
			return customIndicators.map(indicator => {
				const to = `/user-custom-indicator-history/${indicator._id}`;

				return (
					<Link key={`${indicator.name}${indicator._id}`} to={to} style={{ textDecoration: "none" }}>
						<Button style={styles.styleButtons}>{getNameForLanguage(indicator, language)}</Button>
					</Link>
				);
			});
		},
		[language, customIndicators, getNameForLanguage]
	);

	const RoutesFunction = require("./routes.js");
	const routes = RoutesFunction();

	const customLinksMap = {
		"myhealth": renderCustomIndicators
	};

	const dashboardLinks = useMemo(() => PAGE_TYPES.reduce((result, pageType) => {
		if(pageType === "dashboard") return result;
		else {
			const featureFlag = organization?.featureFlag ?? {};
			const linksToRender = Object.entries(routes).reduce((accumulator, [userType, subRoutes]) => {
				if(userTypes.includes(userType)){
					accumulator = accumulator.concat(subRoutes.filter(route => route.type === pageType && (IGNORE_FEATURE_FLAG_TYPE.includes(userType) || featureFlag[route.key] > 0)));
				}
				return accumulator;
			}, []);

			if(linksToRender.length){
				result = result.concat(renderDashboard(localization, localization.get(PAGE_TYPE_HEADERS[pageType]), linksToRender, customLinksMap[pageType]));
			}
		}
		return result;
	}, []), [localization, routes, organization, userTypes, customLinksMap]);

	return (
		<div className="ae-fill">
			<Header
				careplanChanges={careplanChanges}
				offline={offline}
				currentPerson={currentPerson}
				localization={localization}
				titleKey={titleKey}
			/>

			<div className="ae-padded ae-margined ae-center-text ae-scroll-div">{dashboardLinks.length ? dashboardLinks : localization.get("dashboards_error")}</div>
		</div>
	);
};

module.exports = render;

function render() {
	var component = this;
	return <Dashboard component={component} />;
}

function renderDashboard(localization, header, list, customLinks = () => null) {
	if (!list || !list[0]) return;

	return (
		<div>
			<List component="div">
				<SubHeader component="div" style={styles.styleHeaders}>{header}</SubHeader>
				{list.map(par(renderLink, localization))}
				{customLinks()}
				</List>
			<Divider />
		</div>
	);
}

function renderLink(localization, link) {
	var route = link.url;
	return (
		<Link key={link.url} to={route} aria-label={localization.get(link.name)} style={{ textDecoration: "none" }}>
			<Button key={"button" + route} style={styles.styleButtons}>
				{localization.get(link.name)}
			</Button>
		</Link>
	);
}
