
"use strict";

var Store = require("./store");
var map = require("fj-map");

module.exports = OrgTables;

function OrgTables(api, events, config){
	var store = new Store();

	api.org.tables.list().then(map(add_table)).then(listen_for_changes);

	return store;

	function add_table(table){
		var id = table._id;
		store.set(id, table);
	}

	function remove_table(table){
		var id = table._id;
		store.remove(id);
	}

	function listen_for_changes(){
		var orgGroup = config.orgGroup;
		if(!orgGroup) return;

		events.on("mqtt:orggroup/" + orgGroup + "/table/created", add_table);
		events.on("mqtt:orggroup/" + orgGroup + "/table/removed", remove_table);
		events.on("mqtt:orggroup/" + orgGroup + "/table/updated", add_table);
	}
}
