/* © 2014 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, June 19, 2014
 * For information or permission request, email info@aetonixsystems.com
 */

"use strict";

var Store = require("./store");
var par = require("par");

module.exports = ManagerPermissionsStore;

function ManagerPermissionsStore(api, events, config) {

	var store = new Store();

	store.raw_get = store.get;
	store.get = get;

	var tracking = [];
	var trackingManagers = [];

	return store;

	/**
	 * Retrieves the permissions list of a manager for a particular user
	 * @param  {String} id String concatenation of manager/user id e.g. 123213@@213546
	 * @return {Oject}    Persmissions of manager for that user.
	 */
	function get(manager, user) {
		if (!manager || !user) return;
		var id = makeId(manager, user);
		var existing = store.raw_get(id);
		if (existing) return existing;

		if (!isTracking(id))
			track(manager, user, id);

		return {};
	}

	function isTracking(id) {
		return tracking.indexOf(id) !== -1;
	}

	function isTrackingManager(manager) {
		return trackingManagers.indexOf(manager) !== -1;
	}

	function track(manager, user, id) {
		if (!manager || !user || !id) return;
		api.org.manage.permissions.list(user, manager)
			.then(par(setPermissions, id));

		if(!isTrackingManager(manager))
			trackManager(manager, user);
	}

	function trackManager(manager, user) {
		var orgGroup = config.orgGroup;
		if(!orgGroup) return;
		trackingManagers.push(manager);
		events.on("mqtt:orggroup/" + orgGroup + "/u/" + user + "/manage/manager/permissions/updated", par(updatePermissions, user));
		events.on("mqtt:orggroup/" + orgGroup + "/u/" + user + "/manage/manager/removed", par(stopTrack, user));
	}

	function updatePermissions(user, data) {
		var manager = data._id;
		var id = makeId(manager, user);
		store.set(id, data);
	}

	function setPermissions(id, data) {
		store.set(id, data);
	}

	function stopTrack(user, data) {
		var manager = data.manager;
		var id = makeId(manager, user);
		trackingManagers = trackingManagers.filter(function (item) {
			return item !== manager;
		});
		store.remove(id);
	}

}

function makeId(manager, user) {
	return manager + "@" + user;
}
