import React, { useMemo, useCallback } from "react";
import { Dialog, DialogContent, Typography, DialogTitle, IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import {
	useLocalize,
	useAllPatientContacts,
	useAllPatientManagers,
	usePatientGroupManagers,
	useOwnerOngoingWorkflows,
	usePatientsPatientGroup,
	useOwnContacts,
	useCurrentPerson
} from "@aetonix/hooks";
import { createSchema, getActors } from "./WorkflowHelpers";

import Form from "ae-forms";
import { useUserMetrics } from "@aetonix/user-metrics";

export const WorkflowEditModal = ({ open, onClose, schema, defaults, ongoingId }) => {
	const { localize } = useLocalize();
	const { personal: { language }} = useCurrentPerson();
	const { updateWorkflow } = useOwnerOngoingWorkflows();
	const { userMetrics } = useUserMetrics();

	const { contacts: ownContacts } = useOwnContacts();
	const { contacts: patientAllContacts } = useAllPatientContacts();
	const { managers: patientAllManagers } = useAllPatientManagers();
	const { managers: patientGroupManagers } = usePatientGroupManagers();
	const { patientGroups: patientGroups } = usePatientsPatientGroup();

	const creationFormSchema = useMemo(() => createSchema(schema, "edit", localize, language), [schema]);
	const formSources = useMemo(
		() => ({
			ownContacts: createKeyValuePeople(ownContacts),
			manageGroups: createKeyValueGroup(patientGroups),
			patientAllContacts: createKeyValuePeople(patientAllContacts),
			patientAllManagers: createKeyValuePeople(patientAllManagers),
			patientGroupManagers: createKeyValuePeople(patientGroupManagers)
		}),
		[patientGroups, patientAllContacts, patientAllManagers, patientGroupManagers]
	);

	const onSubmit = useCallback((data) => {
		const actors = getActors(data);
		updateWorkflow(actors, data.defaults, ongoingId);
		onClose();
		userMetrics.trackEvent("user-overview: edit workflow", { workflowId: ongoingId });
	}, [ongoingId]);

	return (
		<Dialog open={open} scroll="paper" onClose={onClose} fullWidth maxWidth="md">
			<DialogTitle disableTypography>
				<Typography variant="body1" align="center">
					{localize("workflow.create")}
				</Typography>
				<IconButton onClick={onClose} style={{ padding: "0px" }}>
					<CloseIcon color="secondary" aria-label="Close Button" />
				</IconButton>
			</DialogTitle>
			<DialogContent>
				<Form
					schema={creationFormSchema}
					onSubmit={onSubmit}
					onCancel={onClose}
					defaultData={defaults}
					sources={formSources}
					cancelText={localize("common.cancel")}
				/>
			</DialogContent>
		</Dialog>
	);
};

const createKeyValuePeople = list => {
	return list.map(person => ({ label: `${person.fname} ${person.lname}`, value: person._id }));
};

const createKeyValueGroup = list => {
	return list.map(group => ({ label: group.name, value: group._id }));
};

