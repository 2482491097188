/* © 2014 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, June 19, 2014
 * For information or permission request, email info@aetonixsystems.com
 */

var React = require("react");
var par = require("par");

var Header = require("../shared/Header.js");
var InputLabel = require("@material-ui/1.5.1/InputLabel").default;
var FormControl = require("@material-ui/1.5.1/FormControl").default;
var Button = require("@material-ui/1.5.1/Button").default;
var TextField = require("@material-ui/1.5.1/TextField").default;
var Select = require("@material-ui/1.5.1/Select").default;
var MenuItem = require("@material-ui/1.5.1/MenuItem").default;
var IconButton = require("@material-ui/1.5.1/IconButton").default;

var ListItem = require("@material-ui/1.5.1/ListItem").default;
var ListItemText = require("@material-ui/1.5.1/ListItemText").default;

var SubHeader = require("@material-ui/1.5.1/ListSubheader").default;

var LazyList = require("../shared/LazyList.jsx");
var Dialog = require("../shared/Dialog");

var Colors = require("../shared/AetonixTheme").palette;

var listItemClasses = {
	root: "ae-padding",
	gutters: "ae-padding",
	default: "ae-padding"
};

var styles = {
	icon: { color: Colors.primary.main },
};

module.exports = render;

function render() {
	var component = this;
	var state = component.state;
	var careplanNoticeList = state.careplanChangeList.all();
	var careplanChanges = !!careplanNoticeList.length;
	var offline = state.connection.get("offline");
	var currentPerson = state.currentPerson;
	var localization = state.localization;
	var creating = state.creating;
	var titleKey = "admin_promo_code_title";
	var registrationUrl = state.config.registration;

	var viewCode = component.state.viewCode;
	var code = component.state.code;
	var org = component.state.org;
	var group = component.state.group;

	var orgGroups = org ? component.state.orgGroups.get(org) : [];
	var viewingOrgGroups = orgGroups.map(renderGroupMenuItem);
	var viewingOrgs = component.state.orgs.all().sort(by_name).map(renderGroupMenuItem);

	var codes = state.codes.all();

	var create_actions = [
		(<Button key={"user_ok"} onClick={par(confirmAdd, component)}>{localization.get("users_ok")}</Button >),
		(<Button key={"users_cancel"} onClick={par(cancelAdd, component)}>{localization.get("users_cancel")}</Button >)
	];

	var registration_actions = [
		(<Button key={"user_ok"} onClick={par(closeUrl, component)}>{localization.get("users_ok")}</Button >)
	];

	return (
		<div className="flex-vertical flex-1">
			<Header
				careplanChanges={careplanChanges}
				offline={offline}
				currentPerson={currentPerson}
				localization={localization}
				titleKey={titleKey}
			/>
			<div className="flex-vertical flex-1 ae-scrollable">
				<div className="flex-vertical flex-1 ae-left-margin ">
					<SubHeader>{localization.get("admin_promo_code")}</SubHeader>
					<LazyList className="ae-scrollable" renderItem={par(renderCode, this)} items={codes} />
				</div>
				<Button variant="raised" onClick={par(showAddPromoCode, component)} color="secondary" >{localization.get("admin_promo_code_add_new")}</Button>
			</div>

			<Dialog actions={create_actions} open={!!creating} title={localization.get("admin_promo_code_add")}>
				<TextField fullWidth defaultValue={code} onChange={par(updateCode, component)} inputProps={{ "aria-label": localization.get("admin_promo_code_code") }} placeholder={localization.get("admin_promo_code_code")} />
				<FormControl fullWidth={true}>
					<InputLabel>{localization.get("admin_promo_code_org")}</InputLabel>
					<Select fullWidth inputProps={{"aria-label": localization.get("admin_promo_code_org")}} onChange={par(updateDropdown, component, "org")} value={org}>
						{viewingOrgs}
					</Select>
				</FormControl>
				<FormControl fullWidth={true}>
					<InputLabel>{localization.get("admin_promo_code_group")}</InputLabel>
					<Select fullWidth inputProps={{"aria-label": localization.get("admin_promo_code_group")}} onChange={par(updateDropdown, component, "group")} value={group}>
						{viewingOrgGroups}
					</Select>
				</FormControl>
			</Dialog>

			<Dialog actions={registration_actions} open={!!viewCode} title={localization.get("admin_promo_code_registration")}>
				{localization.get("admin_promo_code_url")}
				<b className="ae-selectable">{registrationUrl + "?code=" + viewCode} </b>
			</Dialog>
		</div>
	);
}

function openUrl(component, code){
	component.state.userMetrics.trackEvent("admin-promo-code: open promo code popup", {
		code
	});
	component.setState({viewCode: code});
}

function closeUrl(component){
	component.state.userMetrics.trackEvent("admin-promo-code: close promo code popup");
	component.setState({viewCode: null});
}

function updateDropdown(component, name, e){
	e.persist();
	var payload = e.target.value;

	var update = {};
	update[name] = payload;
	component.setState(update);
}

function renderGroupMenuItem(e){
	var id = e._id;
	var name = e.name;

	return (
		<MenuItem key={id} value={id}>
			{name}
		</MenuItem>
	);
}

function updateCode(component, e){
	e.persist();
	var text = e.target.value;

	component.setState({code: text});
}

function confirmAdd(component){
	var code = component.state.code;
	var group = component.state.group;
	var add = component.state.add;

	component.state.userMetrics.trackEvent("admin-promo-code: create promo code", {
		"code": code,
		"group": group
	});
	add(code, group);
	cancelAdd(component);
}

function showAddPromoCode(component){
	component.setState({creating: true});
	component.state.userMetrics.trackEvent("admin-promo-code: open create promo code popup");
}

function cancelAdd(component){
	component.state.userMetrics.trackEvent("admin-promo-code: close promo code popup");
	component.setState({
		creating: false,
		code: "",
		org: "",
		group: ""
	});
}

function removePromoCode(component, codeId){
	var remove = component.state.remove;
	component.state.userMetrics.trackEvent("admin-promo-code: remove promo code", {
		"code": codeId
	});
	remove(codeId);
}

function renderCode(component, code){
	var state = component.state;
	var codeId = code._id;
	var name = code.code;

	var orgGroup = code.orgGroup;
	var group = state.groups.get(orgGroup) || {};
	var groupName = group.name;
	var localization = component.state.localization;
	var org = state.orgs.get(group.organization) || {};
	var orgName = org.name;

	var secondary = "";
	if(orgName)
		secondary += orgName + " - ";
	if(groupName)
		secondary += groupName;

	return (
		<ListItem classes={listItemClasses} key={codeId} >
			<ListItemText primary={name} secondary={secondary} />
			<IconButton className="fa fa-link" aria-label= {localization.get("promo_link")} title= {localization.get("promo_link")} role="button"  style={styles.icon} onClick={par(openUrl, component, name)} />
			<IconButton className="fa fa-times-circle" aria-label= {localization.get("remove_button")} title={localization.get("remove_button")} role="button"  style={styles.icon} onClick={par(removePromoCode, component, codeId)} />
		</ListItem>
	);
}

function by_name(x, y) {
	var X = x.name.toLowerCase();
	var Y = y.name.toLowerCase();
	if (X < Y) return -1;
	if (X > Y) return 1;
	return 0;
}