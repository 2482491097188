var dotty = require("dotty");

function fetchHeaders(schema, language) {
	var properties = schema.properties || {};
	return getPropertiesSort(schema).map((property) => {
		var propertySchema = properties[property];
		var localization = propertySchema.localization;
		return localization[language] || localization[Object.keys(localization)[0]] || localization.get("org_workflow_untitled");
	});
}

function getPropertiesSort(schema) {
	var properties = schema.properties || {};
	return Object.keys(properties).sort((a, b) => {
		var orderA = properties[a].order;
		var orderB = properties[b].order;

		return (orderA - orderB) || 0;
	});
}


function parseTableBody(schema, data, language, externals) {

	function renderTableBody(schema, data, externals, language) {
		var days = Object.keys(data);

		return (renderRows(days, schema, data, externals, language));
	}

	function renderRows(days, schema, data, externals, language) {
		days.sort((a, b) => (new Date(b) - new Date(a)));
		return days.map((day) => {
			var dayData = data[day];
			let retData = [{data:day}]
			return (retData.concat(renderRowCells(schema, dayData, externals, language)));
		});
	}

	function renderRowCells(schema, data, externals, language) {
		var properties = schema.properties || {};
		return getPropertiesSort(schema).map((property) => {
			var propertySchema = properties[property];
			var isWorkflow = externals[propertySchema.workflow];
			var isForm = externals[propertySchema.form];

			var propertyData = {};
			if (isWorkflow) {
				var workflowData = data[isWorkflow] || {};
				propertyData = workflowData[propertySchema.action] || {};
			} else if (isForm) {
				var formData = data[isForm] || {};
				propertyData = formData;
			}
			return renderData(propertySchema, propertyData, language);
		});
	}

	function renderData(schema, data, language) {
		var displayType = schema.displayType;

		if (!displayType)
			return renderText(schema, data, language);
		else if (displayType === "threshold"){
			return renderThreshold(schema, data, language);}
	}

	function renderThreshold(schema, data) {
		var accessor = schema.data;
		var rawData = dotty.get(data, accessor);

		var number = parseInt(rawData, 10);
		return {type:"THRESHOLD", data: number, thresholds: schema.thresholds || []};
	}

	function renderText(schema, data, language) {
		var accessor = schema.data;
		var rawData = dotty.get(data, accessor);
		var renderedData = rawData;

		var value_localization = schema.value_localization;
		if (value_localization) {
			var languageLocale = value_localization[language];
			renderedData = languageLocale[rawData] || rawData;
		}

		if (!renderData)
			renderedData = "-";

		return {type:"TEXT", data: renderedData};
	}

	return  renderTableBody(schema, data, externals, language)
}
module.exports.fetchHeaders = fetchHeaders
module.exports.getPropertiesSort = getPropertiesSort
module.exports.parseTableBody = parseTableBody

