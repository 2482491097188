/* © 2014 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, June 19, 2014
 * For information or permission request, email info@aetonixsystems.com
 */

var par = require("par");

var defineUI = require("../shared/define");
var render = require("./render.jsx");

var FormStore = require("../stores/org_forms_store");
var CurrentPersonInfoStore = require("../stores/current_person_info_store");
var LocalizationStore = require("../stores/localization_store");
var ConnectionStore = require("../stores/connection_store");
var CareplanNotificationStore = require("../stores/careplan_notice_store");
var OptionStore = require("../stores/org_option_schema_store");

var makeStores = require("../shared/stores")({
	forms: FormStore,
	currentPerson: CurrentPersonInfoStore,
	localization: LocalizationStore,
	connection: ConnectionStore,
	careplanChangeList: CareplanNotificationStore,
	options: OptionStore
});

module.exports = make_ui;

function make_ui(api, events, config, userMetrics){
	var storeStore = makeStores(api, events, config);

	window.stores =  storeStore.store;

	userMetrics.trackEvent("page view", {
		"page": "org-forms",
		"category": "org",
		"permission level": "org:manager",
	});

	return defineUI({
		getInitialState: getInitialState,
		componentDidMount: listen_for_changes,
		render: render,
		componentWillUnmount: removeListeners
	});

	function removeListeners() {
		events.off();
	}

	function listen_for_changes(){
		storeStore.listen(par(handle_change, this));
	}

	function getInitialState(){
		return {
			forms: storeStore.stores.forms,
			currentPerson: storeStore.stores.currentPerson,
			localization: storeStore.stores.localization,
			connection: storeStore.stores.connection,
			careplanChangeList: storeStore.stores.careplanChangeList,
			options: storeStore.stores.options,
			updating: false,
			creating: false,
			schemaError: false,
			formError: {},
			prepopulateError: {},
			addForm: addForm,
			updateForm: updateForm,
			removeForm: removeForm,
			userMetrics: userMetrics,
		};
	}

	function handle_change(component, stores){
		component.setState({
			forms: stores.forms.all(),
			options: stores.options.all(),
			currentPerson: stores.currentPerson,
			localization: stores.localization,
			connection: stores.connection,
			careplanChangeList: stores.careplanChangeList
		});
	}

	function addForm(form){
		var icon = form.icon;
		var formType = form.formType;
		var schema = form.schema;
		var localization = form.localization;

		if(!icon || !formType || !schema || Object.keys(localization) <= 0){
			return;
		}

		return api.org.forms.create(form);
	}

	function updateForm(form){
		var formId = form._id;
		var icon = form.icon;
		var formType = form.formType;
		var schema = form.schema;
		var localization = form.localization;

		if(!icon || !formType || !schema || Object.keys(localization) <= 0){
			return;
		}

		return api.org.forms.update(formId, form);
	}

	function removeForm(form){
		var formId = form._id;

		return api.org.forms.remove(formId);
	}
}
