

var Store = require("./store");
var map = require("fj-map");
var debounce = require("debounce");

import * as Sentry from '@sentry/react';

module.exports = GroupOptionStore;

function GroupOptionStore(api, events, config) {
	var store = new Store();
	var page = 0;
	var schema = null;
	var fields = [];
	var query = "";
	refresh();

	store.loadMore = more;
	store.search = debounce(search, 1500);
	store.setSchema = setSchema;

	return store;

	function refresh(){
		if(!schema) return;
		return api.org.options.searchOption(schema, fields, query, page)
			.then(map(setOptions))
			.then(listenChanges)
			.catch(error => {
				Sentry.captureException(error);
				console.error(error);
			});
	}

	function setOptions(option){
		store.set(option._id, option);
	}

	function listenChanges(){
		const orgGroup = config.orgGroup;
		if(!orgGroup) return;
		events.on(`mqtt:orggroup/${orgGroup}/option/added`, setOptions);
		events.on(`mqtt:orggroup/${orgGroup}/option/updated`, setOptions);
		events.on(`mqtt:orggroup/${orgGroup}/option/removed`, removeOption);
	}

	function removeOption(data){
		store.remove(data._id);
	}

	function more(){
		page++;
		refresh();
	}

	function search(queryString, searchFields){
		if(!queryString || !searchFields || !Array.isArray(searchFields))
			return store.clear();

		store.clear();
		query = queryString;
		fields = searchFields;
		refresh();
	}

	function setSchema(schemaId){
		schema = schemaId;
		store.clear();
		refresh();
	}
}
