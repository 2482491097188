/* © 2023 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, January 01, 2017
 * For information or permission request, email info@aetonixsystems.com
 */

const defineUI = require("../shared/define");
const render = require("./render.jsx");
const par = require("par");

import { getDefaultIndicator } from "./library";

const PersonStore = require("../stores/person_store");
const LocalizationStore = require("../stores/localization_store");
const CurrentPersonInfoStore = require("../stores/current_person_info_store");
const ConnectionStore = require("../stores/connection_store");
const OrgAutoLogout = require("../stores/org_autologout_store.js");
const OrgCustomIndicatorStore = require("../stores/org_custom_indicators_store.js");

const makeStores = require("../shared/stores")({
	people: PersonStore,
	currentPerson: CurrentPersonInfoStore,
	localization: LocalizationStore,
	connection: ConnectionStore,
	logout: OrgAutoLogout,
	indicators: OrgCustomIndicatorStore
});

function make_ui(api, events, config, userMetrics) {
	var storeStore = makeStores(api, events, config);

	userMetrics.trackEvent("page view", {
		"page": "org-custom-indicators",
		"category": "org",
		"permission level": "org:manager",
	});

	return defineUI({
		getInitialState: getInitialState,
		componentDidMount: listen_for_changes,
		render,
		componentWillUnmount: removeListeners
	});

	function removeListeners() {
		events.off();
	}

	function handle_change(component, stores) {
		component.setState({
			people: stores.people,
			currentPerson: stores.currentPerson,
			localization: stores.localization,
			connection: stores.connection,
			logout: stores.logout,
			customIndicators: stores.indicators
		});
	}

	function listen_for_changes() {
		storeStore.listen(par(handle_change, this));
	}

	function getInitialState() {
		const customIndicators = storeStore.stores.indicators;
		const currentPerson = storeStore.stores.currentPerson;

		return {
			people: storeStore.stores.people,
			currentPerson,
			localization: storeStore.stores.localization,
			isCreating: false,
			indicatorToUpdate: null,
			indicatorToRemove: null,
			isUnitError: false,
			isNameError: false,
			searchTerm: "",
			editedIndicator: getDefaultIndicator(),
			customIndicators,
			userMetrics: userMetrics,
		};
	}
}

module.exports = make_ui;
