/* © 2017 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, January 01, 2017
 * For information or permission request, email info@aetonixsystems.com
 */

import * as Sentry from "@sentry/react";

const OrgIndicatorStore = require("./org_custom_indicators_store");

var Store = require("./store");
var xtend = require("xtend");
var par = require("par");

const makeStore = require("../shared/make_store");

module.exports = UserObservationsLatestStore;

var INDICATORS = ["activity", "bloodsugar", "bloodpressure", "bodytemperature", "bloodoxygen", "weight"];

function UserObservationsLatestStore(api, events, config) {
	var store = new Store();

	store.users = [];
	store.indicators = [];
	store.addUsers = addUsers;

	const orgIndicatorStore = makeStore(OrgIndicatorStore)(api, events, config);
	orgIndicatorStore.listen(updateIndicators);

	return store;

	function updateIndicators(indicatorStore) {
		const indicators = indicatorStore?.all?.() ?? [];
		store.indicators = indicators;
		store?.users?.forEach?.(user => {
			trackUser(user);
		});
	}

	function addUsers(data) {
		return getLatests(data).then(function(latests) {
			store.setAll(latests);
		});
	}

	function getLatests(users) {
		if (!users || !users.length) {
			return Promise.resolve([]);
		}

		store.users = users;

		return api.org.users.recentReadings
			.ofUsers(users)
			.then(readings => {
				var reducedList = users.reduce((acc, userId) => {
					var userReadings = readings.find(userReading => userReading.who === userId) || {
						who: userId,
						healthData: {},
						invalid: false
					};
					var reducedUserReadings = {};


					INDICATORS.forEach(indicator => {
						var readingData = {};
						var currentIndicator = userReadings.healthData[indicator];
						if (currentIndicator) {
							readingData = {
								updated_at: currentIndicator.updated_at,
								invalid: currentIndicator.invalid,
								...currentIndicator.history
							};

							if (
								(indicator === "activity" && currentIndicator.cumulative !== undefined) ||
								currentIndicator.cumulative !== null
							)
								readingData.cumulative = currentIndicator.cumulative;
						}
						reducedUserReadings[indicator] = readingData;
					});

					const customHealthData = userReadings?.healthData?.customHealthData ?? [];

					acc[userId] = xtend(
						{},
						{
							activity: reducedUserReadings.activity,
							bloodsugar: reducedUserReadings.bloodsugar,
							bloodpressure: reducedUserReadings.bloodpressure,
							bodytemperature: reducedUserReadings.bodytemperature,
							bloodoxygen: reducedUserReadings.bloodoxygen,
							weight: reducedUserReadings.weight,
							customIndicators: customHealthData
						}
					);
					trackUser(userId);
					return acc;
				}, {});
				return reducedList;
			})
			.catch(error => {
				Sentry.captureException(error);
				store.error(error);

				return [];
			});
	}

	function trackUser(user) {
		var orgGroup = config.orgGroup;

		if (!orgGroup) {
			return;
		}

		events.on(
			"mqtt:orggroup/" + orgGroup + "/u/" + user + "/observations/blood_oxygen",
			par(handleUpdate, "bloodoxygen", user)
		);
		events.on(
			"mqtt:orggroup/" + orgGroup + "/u/" + user + "/observations/blood_pressure",
			par(handleUpdate, "bloodpressure", user)
		);
		events.on(
			"mqtt:orggroup/" + orgGroup + "/u/" + user + "/observations/bloodsugar",
			par(handleUpdate, "bloodsugar", user)
		);
		events.on(
			"mqtt:orggroup/" + orgGroup + "/u/" + user + "/observations/body_temperature",
			par(handleUpdate, "bodytemperature", user)
		);
		events.on("mqtt:orggroup/" + orgGroup + "/u/" + user + "/observations/weight", par(handleUpdate, "weight", user));
		events.on(
			"mqtt:orggroup/" + orgGroup + "/u/" + user + "/observations/steps/cumulant",
			par(handleUpdate, "activity", user)
		);

		store?.indicators?.forEach(indicator => {
			events.on(
				`mqtt:orggroup/${orgGroup}/u/${user}/observations/indicators/${indicator._id}`,
				par(handleUpdate, "customIndicators", user)
			);
		});
	}

	function handleUpdate(key, user, data) {
		var current = store.get(user);
		var update = {};

		if (key === "customIndicators") {
			if (data?.indicator) {
				update[key] = { ...(current[key] || {}), [data.indicator]: data };
			}
		} else {
			update[key] = data;
		}

		store.set(user, xtend(current, update));
	}
}
