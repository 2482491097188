/* © 2014 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, June 19, 2014
 * For information or permission request, email info@aetonixsystems.com
 */

var par = require("par");

var defineUI = require("../shared/define");
var render = require("./render.jsx");

var PersonStore = require("../stores/person_store");
var LocalizationStore = require("../stores/localization_store");
var CurrentPersonInfoStore = require("../stores/current_person_info_store");
var LiveChatStore = require("../stores/org_group_livechat_store");
var CareplanNotificationStore = require("../stores/careplan_notice_store");
var ConnectionStore = require("../stores/connection_store");
var StaffStore = require("../stores/staff_store");

var makeStores = require("../shared/stores")({
	people: PersonStore,
	currentPerson: CurrentPersonInfoStore,
	localization: LocalizationStore,
	connection: ConnectionStore,
	livechat: LiveChatStore,
	careplanChangeList: CareplanNotificationStore,
	staff: StaffStore
});

function make_ui(api, events, config, userMetrics) {
	var storeStore = makeStores(api, events, config);

	window.stores = storeStore.store;

	userMetrics.trackEvent("page view", {
		"page": "group-livechats",
		"category": "group",
		"permission level": "staff",
	});

	return defineUI({
		getInitialState: getInitialState,
		componentDidMount: listen_for_changes,
		render: render
	});

	function listen_for_changes() {
		storeStore.listen(par(handle_change, this));
	}

	function handle_change(component, stores) {
		component.setState({
			currentPerson: stores.currentPerson,
			localization: stores.localization,
			people: stores.people,
			livechat: stores.livechat,
			careplanChangeList: stores.careplanChangeList,
			connection: stores.connection,
			staff: stores.staff
		});
	}

	function getInitialState() {
		return {
			currentPerson: storeStore.stores.currentPerson,
			localization: storeStore.stores.localization,
			people: storeStore.stores.people,
			connection: storeStore.stores.connection,
			careplanChangeList: storeStore.stores.careplanChangeList,
			livechat: storeStore.stores.livechat,
			staff: storeStore.stores.staff,
			viewing: null,
			creating: false,
			updating: false,
			adding: false,
			removing: false,
			removingMember: false,
			formError: {},
			newSunday: true,
			newMonday: true,
			newTuesday: true,
			newWednesday: true,
			newThursday: true,
			newFriday: true,
			newSaturday: true,
			newStartTime: "08:00",
			newEndTime: "17:00",
			newName: null,
			newDescription: null,
			newHasSchedule: null,
			updateName: null,
			updateDescription: null,
			updateHasSchedule: null,
			updateSunday: true,
			updateMonday: true,
			updateTuesday: true,
			updateWednesday: true,
			updateThursday: true,
			updateFriday: true,
			updateSaturday: true,
			updateStartTime: "08:00",
			updateEndTime: "17:00",
			filteredMembers: null,
			create: createLiveChat,
			remove: removeLiveChat,
			update: updateLiveChat,
			removeMember: removeMember,
			addMember: addMember,
			search: doSearch,
			userMetrics: userMetrics,
		};
	}

	function createLiveChat(name, description, hasSchedule, schedule){
		if(!name){
			console.error("Name must be provided");
			return;
		}
		return api.org.livechat.create(name, description, hasSchedule, schedule);
	}

	function removeLiveChat(group){
		return api.org.livechat.remove(group);
	}

	function updateLiveChat(name, description, hasSchedule, schedule, group){
		return api.org.livechat.update(name, description, hasSchedule, schedule, group);
	}

	function removeMember(group, member){
		return api.org.livechat.members.remove(group, member);
	}

	function addMember(group, member){
		return api.org.livechat.members.add(group, member);
	}

	function doSearch(query) {
		var filter = {
			type: {
				exclude: ["atouchaway", "user:mobile", "simplified"]
			}
		};

		return api.people.search(query, filter);
	}
}


module.exports = make_ui;
