/* © 2014 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, June 19, 2014
 * For information or permission request, email info@aetonixsystems.com
 */

"use strict";

var Store = require("./store");
var par = require("par");
var later = require("call-later");
var map = require("fj-map");
var head = require("array-head");

import * as Sentry from '@sentry/react';

module.exports = ReminderDataStore;

var defaultReminder = {
	content: "Loading.."
};

function ReminderDataStore(api, events, config) {
	var store = new Store();
	var tracking_list = [];

	store.raw_get = store.get;
	store.get = get;

	var fetch = later(fetchAllData);

	return store;

	function get(id) {
		var data = store.raw_get(id);
		if (data) return data;

		if (!isTracking(id)) {
			tracking_list.push(id);
			fetch(id);
		}

		return defaultReminder;
	}

	function isTracking(id) {
		return tracking_list.indexOf(id) !== -1;
	}

	function fetchAllData(ids) {
		ids = ids.map(head);
		api.org.reminders
			.groupData(ids)
			.then(map(setReminder))
			.catch(error => {
				Sentry.captureException(error);
				store.error(error);
			});
	}

	function setReminder(reminder) {
		var id = reminder._id;
		store.set(id, reminder);
	}
}
