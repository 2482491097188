import moment from "moment";

export function toDefinitionlist(list) {
	let definitionList = list.map(function(data) {
		return {
			label: data.schema.description || data.name,
			value: data._id
		};
	});

	return definitionList;
}

export const formatTime = time => {
	return moment(time)
		.format("DD/MM/YYYY @ HH:mm")
		.padEnd(25, " ");
};

export const workflowEditDefaults = (ongoing, definition) => {
	if(!ongoing) return {};
	const actors = ongoing.actors;
	const data = ongoing.state;

	const formattedActors = Object.keys(definition.actors).reduce((result, actorLabel) => {
		const actorLocalization = definition.actors[actorLabel];
		if (actorLocalization?.type === "ManageGroup") {
			result[actorLabel] = {
				patientGroups: actors.filter(actor => actor.actor === actorLabel && actor.type === "ManageGroup").map(e => e.who),
				people: actors.filter(actor => actor.actor === actorLabel && !actor.type).map(e => e.who)
			};
		} else {
			result[actorLabel] = getActorTypes(actorLabel, actors);
		}
		return result;
	}, {});

	return {
		actors: formattedActors,
		defaults: data
	};
};

export const createSchema = (selectedWorkflow, actionProperty, localize, language) => {
	if (selectedWorkflow) {
		const workflowSchema = selectedWorkflow?.schema;
		const optionalActors = workflowSchema?.optionalActors || [];
		const actorsToRender = Object.keys(workflowSchema.actors)?.filter(actor => actor !== "Patient");
		const requiredActors = actorsToRender?.filter(actor => !optionalActors.includes(actor));

		const actorSchemaProperties = actorsToRender?.reduce((result, actorLabel) => {
			const actorLocalization = workflowSchema?.actors[actorLabel];
			if (actorLocalization?.type === "ManageGroup") {
				result[actorLabel] = {
					type: "object",
					properties: {
						label: {
							localization: actorLocalization,
							inputType: "output",
							displayType: "title"
						},
						patientGroups: {
							localization: {
								[language]: localize("workflow.patientGroups")
							},
							type: "array",
							minItems: 1,
							ignoreObjectStyle: true,
							items: {
								type: "string",
								inputType: "dropdown",
								sources: ["manageGroups"],
								minLength: 1
							}
						},
						people: {
							localization: {
								[language]: localize("workflow.managers")
							},
							type: "array",
							minItems: 1,
							ignoreObjectStyle: true,
							items: {
								type: "string",
								inputType: "dropdown",
								sources: ["ownContacts", "patientAllContacts", "patientAllManagers", "patientGroupManagers"],
								minLength: 1
							}
						}
					}
				};
			} else {
				result[actorLabel] = {
					localization: actorLocalization,
					type: "array",
					minItems: 1,
					items: {
						type: "string",
						inputType: "dropdown",
						sources: ["ownContacts", "patientAllContacts", "patientAllManagers", "patientGroupManagers"],
						minLength: 1
					}
				};
			}
			return result;
		}, {});

		let defaults = null;
		if (actionProperty === "start") {
			const start = workflowSchema?.start;
			if (!start?.actor && start?.action)
				defaults = workflowSchema?.actions?.[start?.action];
		} else if (actionProperty === "edit") {
			const edit = workflowSchema?.edit;
			if (edit?.action)
				defaults = workflowSchema?.actions?.[edit?.action];
		}

		const creationSchema = {
			type: "object",
			required: [
				"actors"
			],
			properties: {
				...(actionProperty === "start" && {
					label: {
						type: "string",
						description: "workflow.label",
						placeholder: "workflow.placeholder"
					}
				}),
				actors: {
					description: "workflow.actors",
					type: "object",
					required: requiredActors,
					properties: actorSchemaProperties,
					default: {}
				}
			}
		};

		if (defaults)
			creationSchema.properties.defaults = defaults;

		return creationSchema;
	} else {
		return null;
	}
};

function getActorTypes(type, list) {
	return list.filter(function (actor) {
		return actor.actor === type;
	}).map(function (actor) {
		return actor.who;
	});
}

export const getActors = (data) => {
	return Object.keys(data.actors).reduce((result, actorLabel) => {
		const suppliedActors = data.actors[actorLabel];
		if (!Object.keys(suppliedActors).length) {
		  return result;
		}
		return result.concat(Object.keys(suppliedActors).reduce((acc, actorKey) => {
		  const actor = suppliedActors[actorKey];
		  if (actor) {
			[].concat(actor).forEach(a => {
				if(!a) return acc;
				if(actorKey === "patientGroups") {
					acc.push({
						who: a,
						actor: actorLabel,
						type: "ManageGroup"
					});
				} else {
					acc.push({
						who: a,
						actor: actorLabel
					});
				}
			});
		  }
		  return acc;
		}, []));
	}, []);
};

export default { toDefinitionlist };
