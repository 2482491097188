/* © 2014 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, June 19, 2014
 * For information or permission request, email info@aetonixsystems.com
 */

var par = require("par");
var debounce = require("debounce");

var defineUI = require("../shared/define");
var render = require("./render.jsx");

var PersonStore = require("../stores/person_store");
var UserStore = require("../stores/user_store");
var UserManagerStore = require("../stores/user_manager_store");
var LocalizationStore = require("../stores/localization_store");
var CurrentPersonInfoStore = require("../stores/current_person_info_store");
var PermissionsStore = require("../stores/permissions_store");
var CarePlanChangeStore = require("../stores/careplan_change_store");
var CarePlanStore = require("../stores/careplan_store");
var CareplanNotificationStore = require("../stores/careplan_notice_store");
var ConnectionStore = require("../stores/connection_store");

var makeStores = require("../shared/stores")({
	people: PersonStore,
	currentPerson: CurrentPersonInfoStore,
	localization: LocalizationStore,
	users: UserStore,
	managers: UserManagerStore,
	permissions: PermissionsStore,
	changes: CarePlanChangeStore,
	careplan: CarePlanStore,
	careplanChangeList: CareplanNotificationStore,
	connection: ConnectionStore
});

function make_ui(api, events, config, userMetrics) {
	var storeStore = makeStores(api, events, config);

	window.stores = storeStore.store;

	userMetrics.trackEvent("page view", {
		"page": "group-careplans",
		"category": "group",
		"permission level": "staff",
	});

	return defineUI({
		getInitialState: getInitialState,
		componentDidMount: listen_for_changes,
		render: render,
		componentWillUnmount: removeListeners
	});

	function removeListeners() {
		events.off();
	}

	function listen_for_changes() {
		storeStore.listen(par(handle_change, this));
	}

	function handle_change(component, stores) {
		component.setState({
			people: stores.people,
			users: stores.users.all(),
			currentPerson: stores.currentPerson,
			localization: stores.localization,
			changes: stores.changes,
			careplan: stores.careplan,
			careplanChangeList: stores.careplanChangeList,
			connection: stores.connection
		});
	}

	function getInitialState() {
		return {
			people: storeStore.stores.people,
			currentPerson: storeStore.stores.currentPerson,
			loadMore: par(loadMore, storeStore.stores.users),
			localization: storeStore.stores.localization,
			changes: storeStore.stores.changes,
			careplan: storeStore.stores.careplan,
			viewing: null,
			toRemove: null,
			users: [],
			committed_sections: [],
			search: doSearch,
			sectionCommitted: sectionCommitted,
			searchExist: debounce(par(searchExist, storeStore.stores.users), 200),
			showCareplanPending: true,
			careplanChangeList: storeStore.stores.careplanChangeList,
			connection: storeStore.stores.connection,
		};
	}

	function loadMore(user){
		return user.more();
	}

	function searchExist(users, query){
		return users.search(query);
	}

	function doSearch(query) {
		return api.people.search(query);
	}

	function sectionCommitted(user, section, change) {
		if (!user) return;
		return api.org.users.careplan.changes.committed(user, section, change);
	}
}

module.exports = make_ui;
