/* © 2014 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, June 19, 2014
 * For information or permission request, email info@aetonixsystems.com
 */


var React = require("react");
var par = require("par");
var xtend = require("xtend");

var Header = require("../shared/Header.js");
var Button = require("@material-ui/1.5.1/Button").default;
var Checkbox = require("@material-ui/1.5.1/Checkbox").default;

var ListItem = require("@material-ui/1.5.1/ListItem").default;
var ListItemText = require("@material-ui/1.5.1/ListItemText").default;

var SubHeader = require("@material-ui/1.5.1/ListSubheader").default;
var TextField = require("@material-ui/1.5.1/TextField").default;
var Paper = require("@material-ui/1.5.1/Paper").default;

var Snackbar = require("@material-ui/1.5.1/Snackbar").default;
var FontIcon = require("@material-ui/1.5.1/Icon").default;
var IconButton = require("@material-ui/1.5.1/IconButton").default;

var LazyList = require("../shared/LazyList.jsx");
var Colors = require("../shared/AetonixTheme").palette;
var Dialog = require("../shared/Dialog");

var SETTINGS = require("../shared/SettingList");
const { Select, MenuItem } = require("@material-ui/1.5.1");
const UserTypes = require("../shared/UserTypes");

import { useOrgForms, OrgFormsProviders } from "@aetonix/hooks";
import { LocalizeFromConsumer, LocalizeFromProvider, useLocalizeFrom } from "../shared/contexts/LocalizeFromContext";

const CustomPropertiesExpansionPanel = require("./CustomPropertiesExpansionPanel.jsx").default;


var styles = {
	search: {
		color: Colors.primary.main,
		minWidth: "30%",
		padding: "8px"
	},
	inputProp: {style: {color: Colors.primary.main, }},
	icon: {
		fontSize: 20,
		color: Colors.canvas,
		margin: "5px"
	},
	toast: {
		margin: "100px",
		padding: "10px",
		backgroundColor: "rgb(0, 172, 193)", //rgb(0, 199, 26)
		borderRadius: "10px"
	},
	toastText: {
		color: Colors.canvas,
		margin: "5px"
	},
	snackbar: {style: {backgroundColor: "red"}}
};

var listItemClasses = {
	root: "ae-padding",
	gutters: "ae-padding",
	default: "ae-padding"
};

module.exports = render;

function render() {
	var component = this;
	var state = component.state;

	var localization = state.localization;
	var careplanNoticeList = state.careplanChangeList.all();
	var careplanChanges = !!careplanNoticeList.length;
	var offline = state.connection.get("offline");
	var currentPerson = state.currentPerson;
	var loadMore = state.loadMore;
	var titleKey = "admin_group_settings_title";

	var groups = component.state.groups;
	var allGroups = groups.all().sort(byName);

	var viewing = state.viewing;

	var renderButton = viewing ? (
		<Button variant="raised" onClick={par(confirmSettings, component)} color="secondary">{localization.get("admin_group_settings_save")}</Button>
	) : null;

	return (
		<ProvidersWrapper>
			<div className="flex-vertical flex-1">
				<Header
					careplanChanges={careplanChanges}
					offline={offline}
					currentPerson={currentPerson}
					localization={localization}
					titleKey={titleKey}
				/>

				<div className="flex-horizontal flex-1 ae-scrollable">
					<div className="flex-vertical flex-1 ae-left-margin ">
						<SubHeader>{localization.get("admin_group_settings_group")}</SubHeader>
						<TextField
							placeholder={localization.get("admin_group_settings_search")}
							inputProps={{ "aria-label": localization.get("admin_group_settings_search") }}
							onChange={par(searchGroup, component)}
							style={styles.search}
							InputProps={styles.inputProp}
						/>
						<LazyList
							className="ae-scrollable"
							loadMore={loadMore}
							renderItem={par(renderGroup, component)}
							items={allGroups}
						/>
					</div>
					<div className="flex-vertical flex-1">
						<SubHeader>{localization.get("admin_group_settings_settings")}</SubHeader>
						<div className="flex-vertical flex-1 ae-scrollable">
							{renderSettings(component)}
						</div>
						{renderButton}
						{renderCustomPropertiesDialog(component)}
					</div>
					<Snackbar open={state.openToast} autoHideDuration={3500} onClose={par(hideToast, component)}>
						<div className="flex-horizontal ae-dropshadow" style={styles.toast}>
							<FontIcon className="fa fa-check fa-2x" style={styles.icon} />
							<div style={styles.toastText}>{state.toastMessage}</div>
						</div>
					</Snackbar>
					<Snackbar
						ContentProps={styles.snackbar}
						open={state.errorToast}
						autoHideDuration={3500}
						onClose={par(hideToast, component)}
						message={state.errorToast}
						action={<IconButton className="fa fa-times" onClick={par(hideToast, component)}></IconButton>}
					/>
				</div>
			</div>
		</ProvidersWrapper>
	);
}

function ProvidersWrapper({ children }) {
	return (
		<LocalizeFromProvider>
			<LocalizeFromConsumer>{() => <OrgFormsProviders>{children}</OrgFormsProviders>}</LocalizeFromConsumer>
		</LocalizeFromProvider>
	);
}


function hideToast(component) {
	component.state.userMetrics.trackEvent("admin-group-settings: hide toast");
	component.setState({
		errorToast: false,
		openToast: false,
		toastMessage: ""
	});
}

function updateCustomProp(component, name, newCustomProperty){
	var newVal = {};
	newVal[name] = newCustomProperty.key;
	var update = newVal;
	component.setState({update: update});
}

function updateBool(component, name, event) {
	event.persist();
	var viewing = component.state.viewing;
	var value = event.target.checked;
	var current = component.state.update || component.state.settings.get(viewing) || {};

	var newVal = {};
	newVal[name] = value;

	var update = xtend(current, newVal);
	component.setState({update: update});
}

function updateVal(component, name, event) {
	event.persist();
	var viewing = component.state.viewing;
	var value = event.target.value;
	var current = component.state.update || component.state.settings.get(viewing) || {};

	var newVal = {};
	newVal[name] = value;

	var update = xtend(current, newVal);
	component.setState({update: update});
}

function renderSettings(component) {
	var viewing = component.state.viewing;
	if (!viewing) return null;

	var currentSettings =
		component.state.update || component.state.settings.get(viewing);

	var localization = component.state.localization;
	var renderedSettings = SETTINGS.map(function(setting) {
		var currentSetting = currentSettings[setting];
		if(setting === "isCustomPropetyUpdated") return null;
		return (
			<ListItem key={setting} classes={listItemClasses}>
				<ListItemText
					primary={localization.get(`admin_group_settings_${setting}`)}
				/>
				<Checkbox
					className="ae-widthauto"
					defaultChecked={currentSetting}
					inputProps={{
						"aria-label": localization.get(`admin_group_settings_${setting}`),
						"role": "checkbox"
					}}
					key={"group_setting" + viewing + setting + currentSetting}
					onChange={par(updateBool, component, setting)}
				/>
			</ListItem>
		);
	});

	const defaultUserTypeKey = "defaultUserType";
	const renderDefaultUserType = (
		<ListItem key={defaultUserTypeKey} classes={listItemClasses}>
			<ListItemText
				primary={localization.get(`admin_group_settings_${defaultUserTypeKey}`)}
			/>
			<Select
				value={currentSettings[defaultUserTypeKey]}
				inputProps={{"aria-label": "Select " + localization.get(`admin_group_settings_${defaultUserTypeKey}`) }}
				onChange={par(updateVal, component, defaultUserTypeKey)}
				style={{minWidth: 200}}
			>
				{UserTypes.map(type => {
					return (
						<MenuItem value={type.value}   key={type.value}>
							{localization.get(type.locLabel)}
						</MenuItem>
					);
				})}
			</Select>
		</ListItem>
	);
	const defaultMeetingView = "defaultMeetingView";
	const renderDefaultMeetingView = (
		<ListItem key={defaultMeetingView} classes={listItemClasses}>
			<ListItemText
				primary={localization.get(`admin_group_settings_${defaultMeetingView}`)}
			/>
			<Select
				value={currentSettings[defaultMeetingView]}
				inputProps={{"aria-label": "Select " + localization.get(`admin_group_settings_${defaultMeetingView}`) }}
				onChange={par(updateVal, component, defaultMeetingView)}
				style={{minWidth: 200}}
			>
				{[{ name: "Presenter", type: "teams" }, { name: "Grid", type: "grid" }].map(({ name, type }) => (
					<MenuItem value={type} key={type}>
						{ name }
					</MenuItem>
				))}
			</Select>
		</ListItem>
	);

	var groupCustomProperties = component.state.customProperties.get(viewing) || [];
	const renderCustomPropertiesButton = (
		<ListItem key={"customProperties"} classes={listItemClasses}>
			<ListItemText
				primary={localization.get("admin_group_settings_customProperties.buttonText")}
			/>
			<Button onClick={par(openCustomPropertiesDialog, component, groupCustomProperties)}>
				{localization.get("admin_group_settings_customProperties.openButton")}
			</Button>
		</ListItem>
	);

	return (
		<Paper zdepth={1} className="ae-padded">
			{renderedSettings}
			{currentSettings.isEnrolmentFormEnabled && (
				<>
					<RenderFormSelect setting="consentForm" component={component} currentSettings={currentSettings} />
					<RenderFormSelect setting="dischargeForm" component={component} currentSettings={currentSettings} />
				</>
			)}
			{renderDefaultMeetingView}
			{renderDefaultUserType}
			{renderCustomPropertiesButton}
		</Paper>
	);
}

function RenderFormSelect(props) {
	const { component, currentSettings, setting } = props;
	const { localization, groups, viewing } = component.state;
	const { orgForms, trackOrgs } = useOrgForms();
	const localizeFrom = useLocalizeFrom();

	let organization;
	if (viewing) {
		const orgData = groups.get(viewing);
		organization = orgData?.organization;
	}

	React.useEffect(() => {
		if (organization) {
			trackOrgs(organization);
		}
	}, [organization, trackOrgs]);

	const forms = orgForms[organization] || [];

	function renderValue(selected) {
		if (!selected) return "";
		const form = forms.find(f => f._id === selected);
		return localizeFrom(form?.localization, ".") || "...";
	}

	return (
		<ListItem classes={listItemClasses}>
			<ListItemText primary={localization.get(`admin_group_settings_${setting}`)} />
			<Select
				value={currentSettings[setting]}
				inputProps={{ "aria-label": "Select " + localization.get(`admin_group_settings_${setting}`) }}
				onChange={par(updateVal, component, setting)}
				style={{ minWidth: 200 }}
				renderValue={renderValue}
			>
				{forms.length ? (
					forms.map(form => (
						<MenuItem value={form._id} key={form._id}>
							<ListItemText primary={localizeFrom(form.localization, ".") || ""} />
						</MenuItem>
					))
				) : (
					<MenuItem value="">
						<em>{localization.get("admin_group_settings_customProperties.no_forms")}</em>
					</MenuItem>
				)}
			</Select>
		</ListItem>
	);
}

function openCustomPropertiesDialog(component, groupCustomProperties) {
	component.state.userMetrics.trackEvent("admin-group-settings: open custom properties popup");
	component.setState({
		isCustomPropertiesDialogOpen: true,
		groupCustomProperties: groupCustomProperties
	});
}

function updateCustomPropertiesDialog(component) {
	const state = component.state;
	const groupId = state.viewing;
	const willUpdateCustomProperties = state.willUpdateCustomProperties;
	const groupCustomProperties = state.groupCustomProperties;
	const updateCustomProperties = state.updateCustomProperties;

	component.state.userMetrics.trackEvent("admin-group-settings: edit custom properties popup", {
		group: groupId,
		"custom properties": groupCustomProperties,
	});
	if (willUpdateCustomProperties) {
		updateCustomProperties(component, groupId, groupCustomProperties);
	}
}

function hideCustomPropertiesDialog(component) {
	component.state.userMetrics.trackEvent("admin-group-settings: close custom properties popup");
	component.setState({
		isCustomPropertiesDialogOpen: false,
		willUpdateCustomProperties: null
	});
}

function searchGroup(component, e){
	e.persist();
	var text = e.target.value;
	var search = component.state.search;

	search(text);
}

function confirmSettings(component){
	var settings = component.state.update;
	var group = component.state.viewing;
	var saveSettings = component.state.save;

	component.state.userMetrics.trackEvent("admin-group-settings: save settings", {
		group: group,
		"new settings": settings
	});
	saveSettings(component, group, settings);
}

function viewGroup(component, groupId){
	component.state.userMetrics.trackEvent("admin-group-settings: view settings for group", {
		group: groupId
	});
	component.setState({
		viewing: groupId,
		update: null
	});
}

function renderGroup(component, group) {
	if(!group._id) return;
	var groupId = group._id;
	var groupName = group.name;

	var org = component.state.orgs.get(group.organization) || {};
	var orgName = org.name;

	var background_colour = "ae-plain";
	var viewing = component.state.viewing;
	if (viewing && viewing === groupId)
		background_colour = "ae-hover-color";

	return (
		<ListItem key={groupId} button classes={listItemClasses} className={background_colour} onClick={par(viewGroup, component, groupId)}>
			<ListItemText primary={groupName} secondary={orgName} />
		</ListItem>
	);
}

function renderCustomPropertiesDialog(component) {
	var state = component.state;
	var open = state.isCustomPropertiesDialogOpen;
	var localization = state.localization;
	var group = state.viewing;
	const willUpdateCustomProperties = state.willUpdateCustomProperties;

	var cancelAction = [(
		willUpdateCustomProperties ? <Button key={"buttonsubmit"} onClick={par(updateCustomPropertiesDialog, component)}>{localization.get("common.submit")}</Button >
	: null), (
		<Button key={"buttoncancel"} onClick={par(hideCustomPropertiesDialog, component)}>{localization.get("common.cancel")}</Button >
	)];

	return group ? (
		<Dialog actions={cancelAction} open={!!open} title={localization.get("admin_group_settings_customProperties.title")}>
			<CustomPropertiesExpansionPanel component={component}  updateCustomProp={par(updateCustomProp, component)} onChange={par(handleUpdateCustomProperties, component)}></CustomPropertiesExpansionPanel>
		</Dialog>
	) : null;
}

function handleUpdateCustomProperties(component, payload) {
	const toUpdate = payload.newState;
	const updateHasError = payload.updateHasError;
	const willUpdateCustomProperties = component.state.willUpdateCustomProperties;
	if (updateHasError) {
		component.setState({
			willUpdateCustomProperties: false
		});
	} else if (updateHasError === false) {
		component.setState({
			willUpdateCustomProperties: true
		});
	} else if (willUpdateCustomProperties !== false) {
		component.setState({
			willUpdateCustomProperties: true
		});
	}
	if (toUpdate) {
		component.setState({
			groupCustomProperties: toUpdate
		});
	}
}

function byName(x, y) {
	var X = x.name.toLowerCase();
	var Y = y.name.toLowerCase();

	if (X < Y) return -1;
	if (X > Y) return 1;
	return 0;
}
