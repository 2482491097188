/* © 2014 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, June 19, 2014
 * For information or permission request, email info@aetonixsystems.com
 */


var par = require("par");

var config = require("../../configs/config.json");

var React = require("react");
var CreateReactClass = require("create-react-class");
var ReactPropTypes = require("prop-types");
var AetonixTheme = require("./AetonixTheme.js");

var List = require("@material-ui/1.5.1/List").default;
var ListItem = require("@material-ui/1.5.1/ListItem").default;
var ListItemText = require("@material-ui/1.5.1/ListItemText").default;
var TextField = require("@material-ui/1.5.1/TextField").default;
var Avatar = require("@material-ui/1.5.1/Avatar").default;
var Paper = require("@material-ui/1.5.1/Paper").default;

var Utility = require("./Utility.js");
var Colors = require("../shared/AetonixTheme").palette;

import * as Sentry from "@sentry/react";

var styles = {
	paper: {
		position: "absolute",
		right: 10,
		left: 10,
		maxHeight: 300,
		margin: "0px 8px",
		overflow: "scroll",
		zIndex: 100
	},
	text: {
		fontSize: "0.8rem"
	}
};

module.exports = CreateReactClass({
	displayName: "SearchMenu",
	childContextTypes: {
		muiTheme: ReactPropTypes.object
	},
	getChildContext: getChildContext,
	getInitialState: getInitialState,

	render: render,
	show: show,
	dismiss: dismiss,
	doSearch: doSearch,
	setResults: setResults
});

function getChildContext() {
	return {
		muiTheme: AetonixTheme
	};
}

function getInitialState() {
	return {
		showing: false,
		results: [],
		query: ""
	};
}

function render() {
	var searchResults = this.state.results;
	var searchName = this.props.localization.get("search_person_auto");
	var people = this.props.people;
	var selection = this.props.selection;

	var orgGroup = this.props.orgGroup;
	var org = this.props.org;

	searchResults = searchResults.map((id) => {
		if(typeof id === "string")
			return people.get(id);
		else if (typeof id === "object")
			return people.get(id._id);
	});

	return (
		<div className="flex-vertical">
			<TextField placeholder={searchName} inputProps={{"aria-label": searchName }} onChange={this.doSearch} value={this.state.query} />
			<div>
			{this.state.showing ? <Paper style={styles.paper}>
				<List>
					{searchResults.sort(by_name).map(par(renderSearchItem, this, selection, orgGroup, org))}
				</List>
			</Paper> : null}
			</div>
		</div>
	);
}

function by_name(x, y) {
	var X = x.fname.toLowerCase();
	var Y = y.fname.toLowerCase();
	if (X === Y && x.lname && y.lname) {
		X = x.lname.toLowerCase();
		Y = y.lname.toLowerCase();
	}
	if(X === Y && x._id && y._id) {
		X = x._id.toLowerCase();
		Y = y._id.toLowerCase();
	}

	if (X < Y)
		return -1;
	if (X > Y)
		return 1;
	return 0;
}

function renderSearchItem(component, selection, orgGroup, org, person) {
	var disabled = false;
	var avatar = <Avatar src={config.image_cdn + person.image}/>;
	var text = Utility.format_name(person);

	var personInfo = {
		fname: person.fname,
		lname: person.lname
	};
	if(person.patientNumber) {
		personInfo.patientNumber = person.patientNumber;
	}

	var style = {
		backgroundColor: "transparent"
	};

	var clickAction = () => {
		component.setState({
			results: [],
			showing: false,
			query: `${person.fname} ${person.lname}`
		});
		if(component.props.action)
			component.props.action(person);
	};

	if (selection && selection.length > 0) {
		if (selection.indexOf(person._id) >= 0)	{
			disabled = true;
			style.backgroundColor = Colors.primary.light;
		}
	}

	var subTitle = [];

	if(org && person.organization){
		var orgData = org.get(person.organization) || {};
		var orgName = orgData.name;
		subTitle = subTitle.concat(orgName);
	}

	if (orgGroup && person.group) {
		var groupData = orgGroup.get(person.group) || {};
		var groupName = groupData.name;
		subTitle = subTitle.concat(groupName);
	} else if (orgGroup && person.orgGroup) {
		var orgGroupData = orgGroup.get(person.orgGroup) || {};
		var orgGroupName = orgGroupData.name;
		subTitle = subTitle.concat(orgGroupName);
	}

	return (
		<ListItem key={person._id} button disabled={disabled} onClick={clickAction} style={style} >
			{avatar}
			<ListItemText>
				<div className="flex-vertical">
					<div>{text}</div>
					<div style={styles.text}>{subTitle && subTitle.length ? subTitle.join(" - ") : null}</div>
				</div>
			</ListItemText>
		</ListItem>
	);
}

function doSearch(e) {
	var query = e.target.value;

	this.setState({
		query: query
	});

	var search = this.props.search;
	if(!search) return;
	if(!query) return this.setState({
		results: [],
		showing: false,
		query: query
	});
	var result = search(query);
	if(result)
		result.then(par(this.setResults, query)).catch(error => {
			Sentry.captureException(error);
			console.error.bind(console, error);
		});
}

function setResults(query, results) {
	this.setState({
		results: results,
		showing: true
	});
}

function show() {
	this.setState({
		showing: true,
		results: []
	});
}

function dismiss() {
	this.setState({
		results: [],
		showing: false
	});
}
