import React, { useCallback, useRef, useEffect } from "react";
import { useDrag, useDrop } from "react-dnd";
import { PropTypes } from "prop-types";

import { TableHead, TableRow, Icon, TableCell } from "@material-ui/1.5.1";
import update from "immutability-helper";
var par = require("par");

import { palette as Colors } from "../AetonixTheme";
import { getHeaderLocalization, getHeaderPropertyText, getHeaderUnitString } from "./helpers";
import { IconButton } from "@material-ui/core";

const styles = {
	draggableColumnHeaderDisabled: {
		opacity: 1,
		textAlign: "left",
		fontSize: "0.75rem",
		fontWeight: "500",
		color: "rgba(0, 0, 0, 0.54)",
		borderBottom: "1px solid rgba(224, 224, 224, 1)",
		verticalAlign: "center",
		padding: "4px 56px 4px 24px",
	},
	draggableColumnsHeaderContainer: {
		cursor: "move",
		fontSize: "0.75rem",
		fontWeight: "500",
		color: "rgba(0, 0, 0, 0.54)",
		borderBottom: "1px solid rgba(224, 224, 224, 1)",
		verticalAlign: "center",
		padding: "4px 20px 4px 20px"
	},
	draggableColumnsHeaderContents: {
		display: "flex"
	},
	icon: {
		color: Colors.primary.main
	},
};

const DraggableTypes = {
	COLUMN: "column"
};

/**
 * Renders a draggable column header
 * @param {Object} props - required props object
 * @param {String} props.id - required props object
 * @param {Function} props.moveCard - required moveCard function
 * @param {Boolean} props.disabled - should disable column?????????
 * @param {Boolean} props.children - children to render inside of component
 */
function DraggableColumnHeader(props) {
	let { id, index, moveCard, disabled } = props;
	if (disabled) {
		return <th style={styles.draggableColumnHeaderDisabled}>{props.children}</th>;
	}

	const ref = useRef(null);
	const [{ handlerId }, drop] = useDrop(() => ({
		accept: DraggableTypes.COLUMN,
		collect(monitor) {
			return { handlerId: monitor.getHandlerId() };
		},
		hover(item, monitor) {
			if (!ref.current) {
				return;
			}
			const dragIndex = item.index;
			const hoverIndex = index;

			if (dragIndex === hoverIndex) {
				return;
			}

			const hoverBoundingRect = ref.current?.getBoundingClientRect();
			const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
			const clientOffset = monitor.getClientOffset();
			const hoverClientY = clientOffset.y - hoverBoundingRect.top;
			if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
				return;
			}
			if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
				return;
			}
			moveCard(dragIndex, hoverIndex);
			item.index = hoverIndex;
		}
	}), [id, index]);
	const [{ isDragging }, drag] = useDrag(() => ({
		type: DraggableTypes.COLUMN,
		item: () => {
			return { id, index };
		},
		collect: monitor => ({ isDragging: monitor.isDragging() })
	}), [id, index]);
	drag(drop(ref));

	return (
		<th
			data-handler-id={handlerId}
			style={{
				...styles.draggableColumnsHeaderContainer,
				opacity: isDragging ? 0.5 : 1
			}}
			ref={ref}
		>
			<span style={styles.draggableColumnsHeaderContents}>
				<Icon className="fa fa-caret-left" style={styles.icon} />
				{props.children}
				<Icon className="fa fa-caret-right" style={styles.icon} />
			</span>
		</th>
	);
};

/**
 * Renders the header component
 * @param {Object} props - The required props object
 * @param {Array} props.headers - Array of headers
 * @param {Object} props.localization - The localization object
 * @param {String} props.localizationPrefix - Localization Prefix e.g. "group_users_overview_"
 * @param {Function} props.updateHeadersOrder - Update the order of the header
 * @param {Object} props.currentPerson - Current person
 * @param {Boolean} props.headersLocked - Determine if the headers should be draggable or locked
 * @param {Function} props.columnsFilterFn - Optional function to filter columns from the table.
 * 	if passed headers will only appear if the function returns true for the header.
 * @param {Map} props.hiddenColumns - Map of hidden columns.
 */
function HeaderComponent(props) {
	const {
		headers,
		localizationPrefix = "",
		updateHeadersOrder,
		currentPerson,
		headersLocked,
		localization,
		columnsFilterFn,
		hiddenColumns,
		measureUnits,
		sortingFn,
		pageName
	} = props;

	const headersRef = useRef(headers);

	useEffect(() => {
		headersRef.current = headers;
	}, [headers]);

	const personal = currentPerson.get("personal") || {};
	const { language = "en" } = personal;

	const moveCard = useCallback(
		(dragIndex, hoverIndex) => {
			const headerCurrent = headersRef.current;
			const dragColumn = headerCurrent[dragIndex];
			updateHeadersOrder(
				update(headerCurrent, {
					$splice: [[dragIndex, 1], [hoverIndex, 0, dragColumn]]
				})
			);
		},
		[headers],
	);

	const headerCells = headers.reduce((acc, header, index) => {
		const propertyText = getHeaderPropertyText(header);
		if (hiddenColumns.get(propertyText)) return acc;
		if (columnsFilterFn && !columnsFilterFn(header)) return acc;

		const localizedText = getHeaderLocalization({
			header: header,
			localization,
			language,
			localizationPrefix,
		});
		const unitsString = getHeaderUnitString({
			header: header,
			...measureUnits
		});

		const title = unitsString ? `${localizedText} ${unitsString}` : localizedText;
		const cell = header.isNotDraggable
			? (
				<TableCell key={propertyText}>
					<div style={{display:"flex", alignItems:'center'}}>
						{localization.get(propertyText)}
						<div style={{display:"flex",flexDirection:'column'}}>
							<IconButton style={{height: 10}} className="fa fa-sort-asc" size='small' aria-label= {"sort_up"} title={"sort_up"} onClick={par(sortingFn, propertyText, 'up')}/>
							<IconButton style={{height: 10}} className="fa fa-sort-desc" size='small' aria-label= {"sort_down"} title={"sort_down"} onClick={par(sortingFn, propertyText, 'down')}/>
						</div>
					</div>
				</TableCell>
			) : (
				<DraggableColumnHeader
					index={index}
					id={propertyText}
					moveCard={moveCard}
					disabled={headersLocked}
					key={propertyText}
				>	
					{pageName=== "group-users-overview"? (
						<div style={{display:"flex", alignItems:'center'}}>
						<div style={styles.innerDraggableHeaderCell}>{title}</div>
						<div style={{display:"flex",flexDirection:'column'}}>
							<IconButton style={{height: 10}} className="fa fa-sort-asc" size='small' aria-label= {"sort_up"} title={"sort_up"} onClick={par(sortingFn, propertyText, 'up')}/>
							<IconButton style={{height: 10}} className="fa fa-sort-desc" size='small' aria-label= {"sort_down"} title={"sort_down"} onClick={par(sortingFn, propertyText, 'down')}/>
						</div>
					</div>
					) : (
						<div style={styles.innerDraggableHeaderCell}>{title}</div>
					)}
					
				</DraggableColumnHeader>
			);
		acc.push(cell);
		return acc;
	}, []);

	return (
		<TableHead>
			<TableRow>
				{headerCells}
			</TableRow>
		</TableHead>
	);
}

HeaderComponent.propTypes = {
	headers: PropTypes.array,
	localization: PropTypes.object,
	localizationPrefix: PropTypes.string,
	updateHeadersOrder: PropTypes.func,
	currentPerson: PropTypes.object,
	headersLocked: PropTypes.bool,
	columnsFilterFn: PropTypes.func,
};

export { HeaderComponent };
