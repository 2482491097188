
function byName(x, y) {
	var X = "";
	var Y = "";
	if (x.fname)
		X += x.fname.toLowerCase();
	if (x.lname)
		X += x.lname.toLowerCase();
	if (y.fname)
		Y += y.fname.toLowerCase();
	if (y.lname)
		Y += y.lname.toLowerCase();

	if (X < Y) return -1;
	if (X > Y) return 1;
	return 0;
}

export default {
	byName
}