/* © 2017 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, January 01, 2017
 * For information or permission request, email info@aetonixsystems.com
 */

"use strict";
var Store = require("./store");
var map = require("fj-map");

module.exports = AdminGroupStore;

function AdminGroupStore(api) {
	var store = new Store();

	var page = 0;
	var query = "";
	var atEnd = false;

	reset();
	store.loadMore = loadMore;
	store.search = search;
	return store;

	function reset(){
		api.admin.orgGroup.list(query, page)
			.then(setEnd)
			.then(map(set_group));
	}

	function loadMore(){
		if(atEnd) return;
		page++;
		reset();
	}

	function search(searchQuery){
		store.clear();
		atEnd = false;
		page = 0;
		query = searchQuery;
		reset();
	}

	function setEnd(list){
		if(list.length === 0) atEnd = true;
		return list;
	}

	function set_group(group){
		store.set(group._id, group);
	}
}
