import {
	OwnerOngoingWorkflowsProvider,
	OngoingWorkflowActionsProvider,
	OwnContactsProvider,
	PatientAllContactsProvider,
	PatientAllManagersProvider,
	PatientGroupManagersProvider,
	useLocalize,
	useOwnerOngoingWorkflows,
	useOngoingWorkflowActions,
	useWorkflowDefinitions,
	useAllPatientManagers,
	useAllPatientContacts,
	usePatientGroupManagers,
	PatientsPatientGroupProvider
} from "@aetonix/hooks";
import {
	Grid,
	List,
	ListItem,
	ListItemText,
	Paper,
	Typography,
	Button,
	ThemeProvider,
	CircularProgress,
	ListItemIcon,
	Dialog,
	DialogContent,
	DialogTitle,
	IconButton,
	DialogActions
} from "@material-ui/core";

import CloseIcon from "@material-ui/icons/Close";
import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import { themes } from "@aetonix/web-ui";

import { WorkflowActions } from "./WorkflowActions";

import { formatTime, workflowEditDefaults } from "./WorkflowHelpers";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";

import { WorkflowCreateModal } from "./WorkflowCreateModal";
import { WorkflowEditModal } from "./WorkflowEditModal";
import { useUserMetrics } from "@aetonix/user-metrics";

const styles = {
	titleContainer: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "center",
		alignItems: "center",
		padding: 7
	},
	action: {
		padding: 5,
		borderBottom: 1,
		borderBottomStyle: "solid",
		borderColor: "lightgray"
	}
};

const useStyles = makeStyles(theme => ({
	button: {
		marginRight: theme.spacing(1)
	}
}));

const WorkflowTabContent = ({ owner }) => {
	const { isLoading } = useWorkflowDefinitions();
	const {
		ongoingWithDefinitions,
		clearList: clearOngoingList,
		nextPage,
		finishWorkflow
	} = useOwnerOngoingWorkflows();
	const { setOngoingWorkflow, clearList: clearActionList, actionsWithOwners, nextPage: nextActionsPage } = useOngoingWorkflowActions();
	const { localizeFrom, localize } = useLocalize();
	const [activeViewing, setActiveViewing] = useState(null);
	const [startingWorkflow, setStartingWorkflow] = useState(false);
	const [editingWorkflow, setEditingWorkflow] = useState(null);
	const [stopping, setStopping] = useState(null);
	const [activeWorkflows, setActiveWorkflows] = useState([]);
	const [finishedWorkflows, setFinishedWorkflows] = useState([]);
	const buttonStyles = useStyles();
	const { setPatient: setPatientAllManagers } = useAllPatientManagers();
	const { setPatient: setPatientContacts } = useAllPatientContacts();
	const { setPatient: setPatientGroupManagers } = usePatientGroupManagers();
	const { userMetrics } = useUserMetrics();

	useEffect(() => {
		if (owner) {
			setPatientAllManagers(owner);
			setPatientContacts(owner);
			setPatientGroupManagers(owner);
		}
		return () => {
			clearOngoingList();
		};
	}, [owner, clearOngoingList]);

	useEffect(() => {
		if (activeViewing) setOngoingWorkflow(activeViewing._id);

		return () => {
			clearActionList();
		};
	}, [activeViewing, setOngoingWorkflow, clearActionList]);

	useEffect(() => {
		if(activeViewing){
			setActiveViewing(ongoingWithDefinitions.find(ongoing => ongoing._id === activeViewing._id));
		}
	}, [ongoingWithDefinitions, activeViewing]);

	useEffect(() => {
		if (ongoingWithDefinitions) {
			setActiveWorkflows(ongoingWithDefinitions.filter(ongoing => ongoing.active));
			setFinishedWorkflows(ongoingWithDefinitions.filter(ongoing => !ongoing.active));
		}
	}, [ongoingWithDefinitions]);

	return (
		<Grid container>
			<Grid item xs={4}>
				<Typography align="center" style={{ fontSize: 20 }} color="primary">
					{localize("workflow.ongoingworkflows")}
				</Typography>
				<List
					style={{
						borderRight: 1,
						borderRightStyle: "solid",
						borderColor: "lightgray"
					}}
				>
					{!isLoading && (
						<ListItem button onClick={() => {
							setStartingWorkflow(true);
							userMetrics.trackEvent("user-overview: open create workflow popup");
						}}>
							<ListItemIcon>
								<AddCircleOutlineIcon />
							</ListItemIcon>
							<ListItemText primary={localize("workflow.create")} />
						</ListItem>
					)}
					{!isLoading ? (
						activeWorkflows.map(ongoing => {
							const ongoingTitle = localizeFrom(ongoing?.workflowData?.schema?.description, ".");

							return (
								<ListItem
									button
									key={ongoing._id}
									selected={!!(activeViewing && ongoing._id === activeViewing?._id)}
									onClick={() => {
										setActiveViewing(ongoing);
										userMetrics.trackEvent("user-overview: view active workflow details", {
											workflow: ongoing._id
										});
									}}
								>
									<ListItemText
										primary={
											<div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
												<Typography>{ongoing?.label || ongoingTitle}</Typography>
												<Typography style={{ color: "rgb(75, 128, 0)" }} >
													{localize("workflow.active")}
												</Typography>
											</div>
										}
										secondary={`${formatTime(ongoing?.updated_at)} | ${ongoingTitle}`}
									/>
								</ListItem>
							);
						})
					) : (
						<ListItem>
							<div style={{ display: "flex", flex: 1, justifyContent: "center" }}>
								<CircularProgress />
							</div>
						</ListItem>
					)}
					{finishedWorkflows.length ? (
						<Typography align="center" style={{ fontSize: 20 }} color="primary">
							{localize("workflow.historytitle")}
						</Typography>
					) : null}

					{!isLoading && finishedWorkflows.length ? (
						finishedWorkflows.map(ongoing => {
							const ongoingTitle = localizeFrom(ongoing?.workflowData?.schema?.description, ".");
							return (
								<ListItem
									button
									key={ongoing._id}
									selected={!!(activeViewing && ongoing._id === activeViewing?._id)}
									onClick={() => {
										setActiveViewing(ongoing);
										userMetrics.trackEvent("user-overview: view stopped workflow details", {
											workflow: ongoing._id
										});
									}}
								>
									<ListItemText
										primary={
											<div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
												<Typography>{ongoing?.label || ongoingTitle}</Typography>
												<Typography
													style={{ color: "rgba(0, 0, 0, 0.54)" }}
												>
													{localize("workflow.stopped")}
												</Typography>
											</div>
										}
										secondary={`${formatTime(ongoing?.updated_at)} | ${ongoingTitle}`}
									/>
								</ListItem>
							);
						})
					) : null}
					{ongoingWithDefinitions.length ? (
						<Button variant="raised" fullWidth color="secondary" onClick={() => {
							nextPage();
							userMetrics.trackEvent("user-overview: load more workflows");
						}}>
							{localize("user_overview_loadmore")}
						</Button>
					) : null}
				</List>
			</Grid>
			<Grid item xs={8}>
				{!!activeViewing && (
					<Paper style={{ padding: 10 }}>
						<div style={styles.titleContainer}>
							<Typography style={{ fontSize: 20 }}>{localize("workflow.workflowhistory")}</Typography>
						</div>
						{!!activeViewing?.active && <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>
							<Button
								variant="contained"
								color="primary"
								className={buttonStyles.button}
								onClick={() => {
									setEditingWorkflow(activeViewing);
									userMetrics.trackEvent("user-overview: open edit workflow popup");
								}}
							>
								{localize("workflow.edit")}
							</Button>
							<Button
								variant="contained"
								color="primary"
								className={buttonStyles.button}
								onClick={() => {
									setStopping(activeViewing?._id);
									userMetrics.trackEvent("user-overview: open finish workflow popup");
								}}
							>
								{localize("workflow.stop")}
							</Button>
						</div>}
						<WorkflowActions
							actions={actionsWithOwners}
							workflow={activeViewing?.workflowData}
							nextPage={nextActionsPage}
						/>
					</Paper>
				)}
			</Grid>
			<WorkflowCreateModal open={!!startingWorkflow} onClose={() => {
				userMetrics.trackEvent("user-overview: close create workflow popup");
				return setStartingWorkflow(false);
			}} />
			<WorkflowEditModal
				open={!!editingWorkflow}
				onClose={() => {
					setEditingWorkflow(null);
					userMetrics.trackEvent("user-overview: close edit workflow popup");
				}}
				schema={editingWorkflow?.workflowData}
				defaults={workflowEditDefaults(editingWorkflow, activeViewing?.workflowData?.schema)}
				ongoingId={editingWorkflow?._id}
			/>
			<Dialog open={!!stopping} scroll="paper" onClose={() => setStopping(null)} fullWidth maxWidth="md">
				<DialogTitle disableTypography>
					<Typography variant="body1" align="center">
						{localize("common.areyousure")}
					</Typography>
					<IconButton onClick={() => {
						setStopping(null);
						userMetrics.trackEvent("user-overview: close finish workflow popup");
					}} style={{ padding: "0px" }}>
						<CloseIcon color="secondary" aria-label="Close Button" />
					</IconButton>
				</DialogTitle>
				<DialogContent>{localize("workflow.finishConfirmation")}</DialogContent>
				<DialogActions>
					<Button key={"buttoncancel"} onClick={() => setStopping(null)}>
						{localize("workflow.finishCancel")}
					</Button>
					<Button
						key={"buttondate"}
						onClick={() => {
							finishWorkflow(stopping);
							setStopping(null);
							userMetrics.trackEvent("user-overview: finish workflow", {
								workflow: stopping
							});
						}}
					>
						{localize("workflow.finishConfirm")}
					</Button>
				</DialogActions>
			</Dialog>
		</Grid>
	);
};

export const WorkflowTab = (props) => {
	return (
	<ThemeProvider theme={themes.DashboardsTheme}>
		<OwnerOngoingWorkflowsProvider owner={props.owner}>
			<OngoingWorkflowActionsProvider>
				<OwnContactsProvider>
					<PatientsPatientGroupProvider owner={props.owner}>
						<PatientAllContactsProvider>
							<PatientAllManagersProvider>
								<PatientGroupManagersProvider>
									<WorkflowTabContent {...props} />
								</PatientGroupManagersProvider>
							</PatientAllManagersProvider>
						</PatientAllContactsProvider>
					</PatientsPatientGroupProvider>
				</OwnContactsProvider>
			</OngoingWorkflowActionsProvider>
		</OwnerOngoingWorkflowsProvider>
	</ThemeProvider>
);};
