
"use strict";

var Store = require("./store");
var map = require("fj-map");

module.exports = OrgWorkflow;

function OrgWorkflow(api, events, config){
	var store = new Store();

	api.org.workflow.listOrg().then(map(add_workflow));
	api.org.id().then(listen_for_changes);

	return store;

	function add_workflow(workflow){
		var id = workflow._id;
		store.set(id, workflow);
	}

	function remove_workflow(workflow){
		var id = workflow._id;
		store.remove(id);
	}

	function listen_for_changes(org){
		events.on("mqtt:org/" + org + "/workflow/definitions/created", add_workflow);
		events.on("mqtt:org/" + org + "/workflow/definitions/removed", remove_workflow);
		events.on("mqtt:org/" + org + "/workflow/definitions/updated", add_workflow);
	}
}
