/* © 2014 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, June 19, 2014
 * For information or permission request, email info@aetonixsystems.com
 */
"use strict";

var EventEmitter = require("eventemitter2").EventEmitter2;
var xtend = require("xtend");

module.exports = Store;

function Store() {
	this._data = Object.create(null);
	this._events = new EventEmitter();
	this.on("change", relay_change.bind(this));
	this.error = error.bind(this);
}

Store.prototype = {
	all: all,
	clear: clear,
	get: get,
	off: off,
	on: on,
	remove: remove,
	search: search,
	set: set,
	setAll: setAll,
	update: update,
	keys: keys,
	getAll: getAll
};

function keys(){
	var data = this._data;
	return Object.keys(data);
}

// Converts object values to an array
function all() {
	var data = this._data;
	return Object.keys(this._data).map(function (key) {
		return data[key];
	});
}

function search(fn) {
	var data = this._data;
	return Object.keys(data).reduce(function (result, key) {
		var value = data[key];
		if (fn(key, value, data)) result[key] = value;
		return result;
	}, {});
}

function get(key) {
	return this._data[key];
}

function getAll(){
	return this._data;
}

function set(key, value) {
	var oldvalue = this._data[key];
	this._data[key] = value;
	this._events.emit("change", key, value, oldvalue);

	return this;
}

function setAll(data) {
	var oldData = this._data;
	this._data = xtend(oldData, data);
	this._events.emit("change", null, data, oldData);

	return this;
}

function update(key, value) {
	var oldvalue = this.get(key);
	var newvalue = xtend(oldvalue, value);
	this.set(key, newvalue);

	return this;
}

function remove(key) {
	var oldvalue = this.get(key);
	delete this._data[key];
	this._events.emit("delete", key, oldvalue);
	this._events.emit("change", key, undefined, oldvalue);
}

function on(event, fn) {
	this._events.on(event, fn);
}

function off(event, fn) {
	if (!fn) this._events.off(event);
	else this._events.removeListener(event, fn);
	return this;
}

function relay_change(key, value, oldvalue) {
	this._events.emit("change:" + key, value, oldvalue);
}

function clear() {
	this._data = {};
	this.setAll({});
}

function error(err) {
	this.emit("error", err);
}