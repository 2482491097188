import React, { useState } from "react";

import {
	FormControlLabel,
	Checkbox,
	Paper,
	TextField,
	FormGroup,
	ClickAwayListener,
	Button,
} from "@material-ui/1.5.1";
import { Box, Divider, IconButton, Tooltip, Accordion, AccordionSummary, AccordionDetails, Typography } from "@material-ui/core";
import { ExpandMore, MoreVert } from "@material-ui/icons";

import { palette as Colors } from "../../shared/AetonixTheme";
import { getHeaderLocalization, getHeaderPropertyText } from "./helpers";
import { useUserMetrics } from "@aetonix/user-metrics";

const styles = {
	"positionRelative": {
		position: "relative",
	},
	columnsMenuPaper: {
		position: "absolute",
		right: 0,
		padding: "10px",
		zIndex: 1000,
		overflowY: "scroll",
		height: "50vh",
		width: "15vw",
		color: "rgba(0, 0, 0, 0.54)"
	},
	columnPresetAccordion: {
		display: "grid"
	},
	search: {
		color: Colors.primary.main,
		minWidth: "30%"
	},
	inputProp: { style: { color: Colors.primary.main } },
	columnsMenuFormGroup: {},
	flexDirectionColumn: {
		display: "flex",
		flexDirection: "column",
	},
};

function createCheckBoxes(options) {
	const {
		headers,
		language,
		localization,
		localizationPrefix,
		hiddenColumns,
		updateHiddenColumns,
		searchQuery,
	} = options;
	const { userMetrics } = useUserMetrics();

	return headers.reduce((acc, header) => {
		const localizedText = getHeaderLocalization({
			header,
			localization,
			language,
			localizationPrefix,
		});

		// Filter column by searchQuery
		if (searchQuery && !localizedText.toLowerCase().includes(searchQuery.toLowerCase())) {
			return acc;
		}
		const propertyText = getHeaderPropertyText(header);
		acc.push(
			<FormControlLabel
				control={
					<Checkbox
						checked={!hiddenColumns.get(propertyText)}
						inputProps={{"aria-label": localizedText }}
						onChange={(event) => {
							const isHidden = !event.target.checked;
							updateHiddenColumns(propertyText, isHidden);
							userMetrics.trackEvent(
								`${options.pageName}: ${isHidden ? "hide" : "show"} "${localizedText}" column in ${
									options.userMetricsLabel
								}`
							);
						}}
						value={propertyText}
					/>
				}
				label={localizedText}
				key={`${propertyText}_checkbox`}
				test-id={`${propertyText}_checkbox`}
				data-testid={`table_edit_menu_${propertyText}_checkbox`}
			/>
		);
		return acc;
	}, []);
}

function ColumnPresetButton(props) {
	return (
		<div style={styles.columnPresetButton}>
			{props.deletable ? (
				<IconButton aria-label="morevert">
					<MoreVert fontSize="small" />
				</IconButton>
			) : null}
			<Button {...props}>{props.title}</Button>
		</div>
	);
}

function getHeaderGroup(options) {
	const {
		localization,
		headerGroups,
		pageName,
		userMetricsLabel,
	} = options;

	return headerGroups.map((group, index) => {
		const { title, columns } = group;
		return (
			<div key={`table_headergroup_${title}_${index}`} style={styles.flexDirectionColumn}>
				<Divider />
				<Box m={0.5} />
				{localization.get(title)}
				{createCheckBoxes({ ...options, headers: columns, pageName, userMetricsLabel })}
			</div>
		);
	});
}

function getHeaderMenu(options) {
	const { headerGroups, localization } = options;

	if (headerGroups) {
		return getHeaderGroup(options);
	}

	return (
		<>
			<Divider />
			<Box m={0.5} />
			{localization.get("table_edit_menu_sub_title")}
			<div style={styles.flexDirectionColumn}>
				{createCheckBoxes(options)}
			</div>
		</>
	);
}

function toggleMultipleColumns(options) {
	const { updateHiddenColumns, columnsToShow, columnsToHide } = options;

	columnsToShow.forEach((header) => {
		const propertyText = getHeaderPropertyText(header);
		updateHiddenColumns(propertyText, false);
	});

	columnsToHide.forEach((header) => {
		const propertyText = getHeaderPropertyText(header);
		updateHiddenColumns(propertyText, true);
	});
}

function getColumnsToHide(headerGroups, indexToRemove) {
	return headerGroups.reduce((acc, group, index) => {
		if (indexToRemove === index) return acc;
		return acc.concat(group.columns);
	}, []);
}


function getPresetsMenu(options) {
	const { headers, headerGroups, localization, updateHiddenColumns } = options;
	const { userMetrics } = useUserMetrics();
	const showAllMenuItem = (
		<ColumnPresetButton
			onClick={() => {
				toggleMultipleColumns({
					updateHiddenColumns,
					columnsToShow: headers,
					columnsToHide: [],
				});
				userMetrics.trackEvent(`${options.pageName}: show all columns in ${options.userMetricsLabel}`);
			}}
			test-id="show_all_columns"
			title={localization.get("table_show_all_columns")}
		/>
	);
	if (!headerGroups) return showAllMenuItem;

	const presetButtons = headerGroups.map((group, index) => {
		const { title, columns, presetTitle } = group;
		const localizedTitle = localization.get(presetTitle);
		return (
			<ColumnPresetButton
				onClick={() => {
					toggleMultipleColumns({
						updateHiddenColumns,
						columnsToShow: columns,
						columnsToHide: getColumnsToHide(headerGroups, index),
					});
					userMetrics.trackEvent(`${options.pageName}: edit table to use preset "${localizedTitle}" in ${options.userMetricsLabel}`);
				}}
				title={localizedTitle}
				key={`table_edit_menu_preset_${title}_${index}`}
			/>
		);
	});

	return (
		<Accordion elevation={0} onChange={(_event, expanded) => {
			const eventText = `${options.pageName}: ${expanded ? "unfold" : "fold"} table presets in ${options.userMetricsLabel}`;
			userMetrics.trackEvent(eventText);
		}}>
			<AccordionSummary expandIcon={<ExpandMore />}>
				<div>
					<Typography>{localization.get("table_column_presets")}</Typography>
				</div>
			</AccordionSummary>
			<AccordionDetails style={styles.columnPresetAccordion}>
				{showAllMenuItem}
				{presetButtons}
			</AccordionDetails>
		</Accordion>
	);
}

/**
 * Creates the edit menu for the table.
 * @param {Object} props - props
 * @param {Array} props.headers - The table headers.
 * @param {Array} [props.headerGroups] - optional array of header groups.
 * @param {String} props.language - The users language.
 * @param {Object} props.localization - Localization object.
 * @param {String} props.localizationPrefix - Localization prefix for the table.
 * @param {Map} props.hiddenColumns - Map containing the hidden columns.
 * @param {Function} props.updateHiddenColumns - Function to update the hidden columns map.
 * @param {Boolean} props.headersLocked - Determines if the headers are locked or unlocked.
 * @param {Function} props.setHeadersLocked - Function to update the value of headers locked.
 * @param {Function} props.resetHeadersOrder - Function to reset the order of the headers.
 */
function EditMenuComponent(props) {
	const {
		headers,
		language,
		localization,
		localizationPrefix,
		hiddenColumns,
		updateHiddenColumns,
		headerGroups,
		headersLocked,
		setHeadersLocked,
		resetHeadersOrder,
		pageName,
		userMetricsLabel,
	} = props;
	const { userMetrics } = useUserMetrics();

	const [searchQuery, setSearchQuery] = useState("");
	const [isOpen, setIsOpen] = useState(false);

	const updateState = (newQuery, newIsOpen) => {
		setSearchQuery(newQuery);
		setIsOpen(newIsOpen);
	};

	const menu = getHeaderMenu({
		headers,
		language,
		localization,
		localizationPrefix,
		hiddenColumns,
		updateHiddenColumns,
		searchQuery,
		headerGroups,
		pageName,
		userMetricsLabel,
	});

	return (
		<ClickAwayListener onClickAway={() => {
			isOpen && userMetrics.trackEvent(`${pageName}: close table editor in ${userMetricsLabel}`);
			return updateState("", false);
		}}>
			<div style={styles.positionRelative}>
				<Button
					onClick={() => {
						const eventText = isOpen ?
							`${pageName}: close table editor in ${userMetricsLabel}` :
							`${pageName}: open table editor in ${userMetricsLabel}`;
						userMetrics.trackEvent(eventText);
						return updateState("", !isOpen);
					}}
					data-testid={"table_edit_menu_edit_table"}
					test-id={"edit_table_menu"}>
					{localization.get("table_edit_table")}
				</Button>
				{
					isOpen && (
						<Paper style={styles.columnsMenuPaper}>
							<div>{localization.get("table_edit_table")}</div>
							<FormControlLabel
								control={
									<Checkbox
										checked={headersLocked}
										inputProps={{"aria-label": localization.get("table_lock_columns")}}
										onChange={() => {
											const eventText = headersLocked ?
												`${pageName}: unlock table columns in ${userMetricsLabel}` :
												`${pageName}: lock table columns in ${userMetricsLabel}`;
											userMetrics.trackEvent(eventText);
											setHeadersLocked(!headersLocked);
										}}
									/>
								}
								label={localization.get("table_lock_columns")}
								data-testid={"table_edit_menu_lock_columns"}
								test-id={"lock_columns"}
							/>
							<Tooltip
								disableFocusListener
								disableTouchListener
								disableHoverListener={!headersLocked}
								arrow
								placement="left"
								title={localization.get("table_reset_columns_tooltip")}
							>
								<span>
									<ColumnPresetButton
										disabled={headersLocked}
										onClick={() => {
											userMetrics.trackEvent(`${pageName}: reset table columns in ${userMetricsLabel}`);
											return resetHeadersOrder();
										}}
										title={localization.get("table_reset_column_order")}
										test-id={"reset_column_order"}
									/>
								</span>
							</Tooltip>
							<Box m={0.5} />
							<Divider />
							{getPresetsMenu({ headers, headerGroups, localization, updateHiddenColumns, pageName, userMetricsLabel })}
							<Divider />
							<Box m={0.5} />
							<TextField
								placeholder={localization.get("table_search_columns")}
								inputProps={{"aria-label": localization.get("table_search_columns") }}
								onChange={e => setSearchQuery(e.target.value)}
								style={styles.search}
								InputProps={styles.inputProp}
							/>
							<FormGroup style={styles.columnsMenuFormGroup}>
								{menu}
							</FormGroup>
						</Paper>
					)
				}
			</div>
		</ClickAwayListener>
	);
}

export { EditMenuComponent };
