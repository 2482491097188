/* © 2014 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, June 19, 2014
 * For information or permission request, email info@aetonixsystems.com
 */

var React = require("react");
var par = require("par");
var find = require("array-find");

var config = require("../../configs/config.json");

// Added for redirection
var ReactRouterDom = require("react-router-dom");
var Link = ReactRouterDom.Link;

var IconButton = require("@material-ui/1.5.1/IconButton").default;
var Icon = require("@material-ui/1.5.1/Icon").default;
var Avatar = require("@material-ui/1.5.1/Avatar").default;
var List = require("@material-ui/1.5.1/List").default;
var ListItem = require("@material-ui/1.5.1/ListItem").default;
var ListItemText = require("@material-ui/1.5.1/ListItemText").default;

var Utility = require("../shared/Utility.js");
var Header = require("../shared/Header.js");

module.exports = render;

function render() {
	var component = this;
	var state = component.state;
	var users = state.users;
	var people = state.people;
	var localization = state.localization;
	var careplanNoticeList = state.careplanChangeList.all();
	var careplanChanges = !!careplanNoticeList.length;
	var offline = state.connection.get("offline");
	var currentPerson = state.currentPerson;
	var userData = users.map(people.get).sort(by_name);

	var titleKey = "live_monitoring_overview";

	if (dataHasChanged(users, state.oldUsers)) {
		users.length && component.state.userMetrics.trackEvent("overview: viewed bracelet overview", {
			"patients": users,
		});
		component.setState({oldUsers: users});
	}

	return (
		<div className="flex-vertical flex-1">
			<Header
				careplanChanges={careplanChanges}
				offline={offline}
				currentPerson={currentPerson}
				localization={localization}
				titleKey={titleKey}
			/>
			<List className="flex-1 ae-scrollable">
				{userData.map(par(renderUser, component))}
			</List>
		</div>
	);
}

function dataHasChanged(users, oldUsers) {
	users = users || [];
	oldUsers = oldUsers || [];
	if (users.length !== oldUsers.length) return true;
	return users.some((r) => oldUsers.indexOf(r) === -1);
}

function by_name(x, y) {
	var X = "";
	var Y = "";
	if(x.fname)
		X += x.fname.toLowerCase();
	if(x.lname)
		X += x.lname.toLowerCase();
	if(y.fname)
		Y += y.fname.toLowerCase();
	if(y.lname)
		Y += y.lname.toLowerCase();

	if (X < Y) return -1;
	if (X > Y) return 1;
	return 0;
}

function renderUser(component, user, index) {
	// if the stores are not populated correctly return
	if (!user._id) return;

	var avatar = <Avatar alt="Avatar Image" src={config.image_cdn + user.image}/>;
	var text = Utility.format_name(user);
	var statusButton = renderStatus(user, component);

	return (
		<div key={text + index} className="ae-distribute">
			<ListItem>
				{avatar}
				<ListItemText primary={text} />
			</ListItem>
			{statusButton}
		</div>
	);
}

function renderStatus(user, component) {

	var state = component.state;
	var userId = user._id;
	if(!userId)
	return;
	var connectivity = state.connectivity.get(userId);
	var bracelet = state.braceletpref.get(userId);
	var battery = state.battery.get(userId);
	var relay = bracelet.relay;
	var braceletexist = bracelet.mac;
	var localization = component.state.localization;
	if (!braceletexist) braceletexist = false;
	if (!relay) relay = false;

	var wandericon = "fa fa-question-circle ae-white";
	var fallicon = "fa fa-male ae-white";
	var urgencyicon = "fa fa-phone-square ae-white";
	var batteryicon = "fa fa-battery-full ae-white";

	//TODO: better way of changing icons eg. a function call can
	//be used to add " ae-swell ae-red" to the base icons
	//Also, maybe a single 'generatData' function would be more
	//effective

	if (connectivity) {
		var connectivityicon = "fa fa-bolt ae-green";

		var fall = state.fall.get(userId);

		if (!!fall && braceletexist && !relay) {
			if (fall.status === "") {
				fallicon = "fa fa-male ae-green";
			} else {
				fallicon = "fa fa-male ae-rotate-60 ae-status-swell ae-red";
			}
		}

		var wander = state.wander.get(userId);
		if (!!wander && braceletexist && !relay) {
			if (wander.status === "connect") {
				wandericon = "fa fa-question-circle ae-green";
			}
			if (wander.status === "disconnect") {
				wandericon = "fa fa-question-circle ae-status-swell ae-red";
			}
			if (wander.status === "acknowledge") {
				wandericon = "fa fa-question-circle ae-status-swell ae-orange";
			}
		}

		var urgency = latestUrgency(user, component);
		if (!urgency && !relay) {
			urgencyicon = "fa fa-phone-square ae-green";
		} else {
			urgencyicon = "fa fa-phone-square ae-swell ae-red";
		}
		if (relay) urgencyicon = "fa fa-share-alt ae-swell ae-white ae-relay";

		battery = state.battery.get(userId).level;
		if (battery !== "undefined" && braceletexist && !relay) {
			batteryicon = "fa fa-battery-full ae-green";
			if (battery <= 30)
				batteryicon = "fa fa-battery-empty ae-red";
		}

	} else {
		connectivityicon = "fa fa-bolt ae-red";
	}

	var braceletStatii = braceletexist ? (
		<div style={{ "display": "inherit" }}>
			<Link key={"fall-live"} to={"/user-fall-live"} style={{ textDecoration: "none" }}>
				<IconButton aria-label={localization.get("user_fall_live_title")} title={localization.get("user_fall_live_title")} role="button" ><Icon className={fallicon}></Icon></IconButton>
			</Link>
			<Link key={"user-wander-live"} to={"/user-wander-live"} style={{ textDecoration: "none" }}>
				<IconButton aria-label={localization.get("user_wander_live")}  title={localization.get("user_wander_live")} role="button"><Icon className={wandericon}></Icon></IconButton>
			</Link>
			<IconButton aria-label={localization.get("user_battery_live_title")}  title={localization.get("user_battery_live_title")}  role="button"><Icon className={batteryicon}></Icon></IconButton>
		</div>
	) : null;

	var callStatus = connectivity ? (
		<div style={{ "display": "inherit" }}>
			<Link key={"urgency-live"} to={"/urgency-live"} style={{ textDecoration: "none" }}>
				<IconButton aria-label={localization.get("user_bracelets_urgencycall")} title={localization.get("user_bracelets_urgencycall")} role="button"><Icon className={urgencyicon}></Icon></IconButton>
			</Link>
		</div>
	) : null;

	return (
		<div className="ae-icons">
			{braceletStatii}
			{callStatus}
			<IconButton aria-label={localization.get("user_connectivity_title")}  title={localization.get("user_connectivity_title")}  role="button"><Icon className={connectivityicon}></Icon></IconButton>
		</div>
	);
}

/**
 * looks for latest urgency call by a given user in a pull
 * of the history of urgency calls ie. last 16 urgency calls
 * @param  {[type]} user      [description]
 * @param  {[type]} component [description]
 * @return {[type]}           [description]
 */
function latestUrgency(user, component) {
	var state = component.state;
	var userId = user._id;
	var urgencyHistory = state.urgency;

	// This can break very easily.  The 'find' function is a ponyfill from npm to
	// allow IE 10+ compatibility.  Yeah, that's not a typo.
	var latest = find(urgencyHistory, function(item) {
		return item.from === userId;
	});

	var urgencystate = false;

	if (latest) {
		var last = latest.history[latest.history.length - 1];

		if  (last.type !== "cancel" && last.type !== "accept") {
			urgencystate = true;
		} else {urgencystate = false;}
	}

	return urgencystate;
}
