
"use strict";

var Store = require("./store");
var pluck = require("fj-pluck");
var map = require("fj-map");
var xtend = require("xtend");

module.exports = CallGroups;

function CallGroups(api, events){
	var store = new Store();
	var orgGroup = null;

	api.org.group.current.data()
		.then(pluck("_id"))
		.then(listenForAdditions)
		.then(api.org.callgroups.list)
		.then(map(addCallGroup));

	return store;

	function listenForAdditions(group){
		orgGroup = group;
		events.on("mqtt:orggroup/" + group + "/callgroups/created", addCallGroup);
	}

	function listenForChanges(group){
		events.on("mqtt:orggroup/" + orgGroup + "/callgroups/" + group + "/members/added", memberAdd);
		events.on("mqtt:orggroup/" + orgGroup + "/callgroups/" + group + "/members/removed", memberRemove);
		events.on("mqtt:orggroup/" + orgGroup + "/callgroups/" + group + "/updated", updateCallGroup);
		events.on("mqtt:orggroup/" + orgGroup + "/callgroups/" + group + "/removed", removeCallGroup);
	}

	function addCallGroup(group){
		var id = group._id;
		store.set(id, group);
		listenForChanges(id);
	}

	function memberAdd(data){
		var id = data.group;
		var existing = store.get(id);

		var current = existing.members;
		var newMembers = current.concat(data.who);

		var newGroup = xtend(existing, {
			members: newMembers
		});

		store.set(id, newGroup);
	}

	function memberRemove(data){
		var id = data.group;
		var existing = store.get(id);

		var current = existing.members;
		var newMembers = current.filter(function(member){
			return member !== data.who;
		});

		var newGroup = xtend(existing, {
			members: newMembers
		});

		store.set(id, newGroup);
	}

	function updateCallGroup(group){
		var id = group.group;
		store.update(id, group);
	}

	function removeCallGroup(group){
		var id = group.group;
		store.remove(id);
	}
}