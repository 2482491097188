/* © 2014 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, June 19, 2014
 * For information or permission request, email info@aetonixsystems.com
 */

var par = require("par");

var defineUI = require("../shared/define");
var render = require("./render.jsx");

var PersonStore = require("../stores/person_store");
var LocalizationStore = require("../stores/localization_store");
var CurrentPersonInfoStore = require("../stores/current_person_info_store");
var CareplanNotificationStore = require("../stores/careplan_notice_store");
var ConnectionStore = require("../stores/connection_store");
var StaffStore = require("../stores/staff_store");

var ManagerGroupsStore = require("../stores/group_manager_groups_store");
var ManagerGroupsManagersStore = require("../stores/group_manager_group_managers_store");
var UserFormsStore = require("../stores/user_form_store");

var makeStores = require("../shared/stores")({
	people: PersonStore,
	currentPerson: CurrentPersonInfoStore,
	localization: LocalizationStore,
	connection: ConnectionStore,
	careplanChangeList: CareplanNotificationStore,
	staff: StaffStore,
	managergroups: ManagerGroupsStore,
	managergroupsmanagers: ManagerGroupsManagersStore,
	userForms: UserFormsStore
});

function make_ui(api, events, config, userMetrics) {
	var storeStore = makeStores(api, events, config);

	userMetrics.trackEvent("page view", {
		"page": "group-manage-groups",
		"category": "group",
		"permission level": "staff",
	});

	return defineUI({
		getInitialState: getInitialState,
		componentDidMount: listen_for_changes,
		render: render
	});

	function listen_for_changes() {
		storeStore.listen(par(handle_change, this));
	}

	function handle_change(component, stores) {
		component.setState({
			currentPerson: stores.currentPerson,
			localization: stores.localization,
			people: stores.people,
			careplanChangeList: stores.careplanChangeList,
			connection: stores.connection,
			staff: stores.staff,
			managerGroups: stores.managergroups,
			managerGroupManagers: stores.managergroupsmanagers,
			userForms: stores.userForms.all(),
		});
	}

	function getInitialState() {
		return {
			currentPerson: storeStore.stores.currentPerson,
			localization: storeStore.stores.localization,
			people: storeStore.stores.people,
			connection: storeStore.stores.connection,
			careplanChangeList: storeStore.stores.careplanChangeList,
			staff: storeStore.stores.staff,
			managerGroups: storeStore.stores.managergroups,
			managerGroupManagers: storeStore.stores.managergroupsmanagers,
			viewing: null,
			creating: false,
			updating: false,
			adding: false,
			removing: false,
			locking: false,
			unlocking: false,
			removingManager: false,
			newName: null,
			newDescription: null,
			updateName: null,
			updateDescription: null,
			filteredManagers: null,
			filteredManagerGroups: null,
			create: createManagerGroup,
			remove: removeManagerGroup,
			update: updateManagerGroup,
			removeManager: removeManager,
			addManager: addManager,
			search: doSearch,
			updatePermissions: updatePermissions,
			lockGroup: lockGroup,
			unlockGroup: unlockGroup,
			permissionUpdates: {},
			userForms: [],
			message: false,
			currentPersonId: storeStore.stores.currentPerson.get("personal")._id,
			userMetrics: userMetrics,
		};
	}

	function updatePermissions(group, permissions){
		return api.org.managergroups.permissions(group, permissions);
	}


	function createManagerGroup(name, description){
		return api.org.managergroups.create(name, description);
	}

	function lockGroup (group){
		return api.org.managergroups.lock(group);
	}

	function unlockGroup (group){
		return api.org.managergroups.unlock(group);
	}

	function removeManagerGroup(group){
		return api.org.managergroups.remove(group);
	}

	function updateManagerGroup(group, name, description){
		return api.org.managergroups.update(group, name, description);
	}

	function removeManager(group, member){
		return api.org.managergroups.managers.remove(group, member);
	}

	function addManager(group, member){
		return api.org.managergroups.managers.add(group, member);
	}

	function doSearch(query) {
		var filter = {
			type: {
				exclude: ["atouchaway", "user:mobile", "simplified"]
			}
		};

		return api.people.search(query, filter);
	}
}


module.exports = make_ui;
