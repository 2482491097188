/* © 2014 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, June 19, 2014
 * For information or permission request, email info@aetonixsystems.com
 */

"use strict";

var Store = require("./store");
var later = require("call-later");
var map = require("fj-map");
var flatten = require("array-flatten");
var Promise = require("promise");

import * as Sentry from '@sentry/react';

var MAX_AT_ONCE = 300;

module.exports = PersonStore;

function PersonStore(api, events, config) {
	var store = new Store();

	store.raw_get = store.get;
	store.get = get;

	store.track = later(track_all);
	store.tracking = is_tracking;

	store.fetch = later(fetch_all_data);

	var tracking = [];

	return store;

	function get(id) {
		var data = store.raw_get(id);
		if (data) return data;

		if (!store.tracking(id))
			store.track(id);

		return {
			fname: " ... ",
			lname: "",
			type: ["loading"]
		};
	}

	function fetch_all_data(buffer) {
		if (!buffer || buffer.length === 0 || buffer[0][0] === null) return;
		var ids = flatten(buffer);

		var chunks = [];
		var total = ids.length;
		var group = 0;
		while (group < total) {
			chunks.push(ids.slice(group, group + MAX_AT_ONCE));
			group += MAX_AT_ONCE;
		}

		Promise
			.all(chunks.map(function(chunk) {
				return api.people
					.public(chunk);
			}))
			.then(flatten)
			.then(map(set_person))
			.catch(error => {
				Sentry.captureException(error);
				console.error.bind(console, "Couldn't fetch people's data");
			});
	}

	function set_person(person) {
		var id = person._id;
		store.set(id, person);
	}

	function track_all(buffer) {
		var ids = flatten(buffer);
		ids.map(track_person);
	}

	function track_person(id) {
		if (store.tracking(id))
			return;

		tracking.push(id);
		var orgGroup = config.orgGroup;
		if(orgGroup)
			events.on("mqtt:orggroup/" + orgGroup + "/u/" + id + "/settings", fetch_person);

		fetch_person();

		function fetch_person() {
			store.fetch(id);
		}
	}

	function is_tracking(id) {
		return tracking.indexOf(id) !== -1;
	}
}
