/* © 2014 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, June 19, 2014
 * For information or permission request, email info@aetonixsystems.com
 */

var Store = require("./store");
var map = require("fj-map");
var compose = require("fj-compose");
var pluck = require("fj-pluck");
var getWho = pluck("who");
var Promise = require("promise");

module.exports = UserActiveStore;

function UserActiveStore(api, events, config) {
	var store = new Store();

	listen_for_changes();

	var query = "";
	var page = 0;
	var loading = null;
	var atEnd = false;
	var loadingAll = true;

	store.more = more;
	store.search = search;
	store.getAtEnd = getAtEnd;
	store.loadAllUser = loadAllUser;

	reset();

	return store;

	function reset() {
		page = 0;
		atEnd = false;
		store.clear();
		return api.org.users
			.list(query, page, "false")
			.then(map(add_person));
	}

	function more() {
		if (atEnd){
			return Promise.resolve();
		}

		if (loading){
			return loading;
		}

		page += 1;
		loading = true;

		return api.org.users
			.list(query, page, "false")
			.then(handle_users);
	}

	function handle_users(users) {
		loading = false;

		if (!users.length) {
			atEnd = true;
			loadingAll = false;
			return;
		}

		users.map(add_person);

		if(loadingAll) {
			more();
		}
	}

	function search(newQuery) {
		query = newQuery;
		return reset();
	}

	function add_person(id) {
		store.set(id, id);
	}

	function remove_person(id) {
		if(store.get(id))
			store.remove(id);
	}

	function listen_for_changes() {
		if (!config?.orgGroup) {
			return;
		}

		const { orgGroup } = config;

		events.on("mqtt:orggroup/" + orgGroup + "/users/added", compose(add_person, getWho));
		events.on("mqtt:orggroup/" + orgGroup + "/users/removed", compose(remove_person, getWho));
	}

	function getAtEnd() {
		return atEnd;
	}

	function loadAllUser() {
		loadingAll = true;
		more();
	}
}
