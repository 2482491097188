import React, {useEffect, useState} from "react";
import { PropTypes } from "prop-types";
import { TablePagination } from "@material-ui/core";

function Pagination (props) {

    const DEFAULT_ROWS_PER_PAGE = 10;
    const rowsPerPageOptions = [10, 20, 30];


    const isFiltered = props.isFiltered;
    const recordCount = props.recordCount;
    const state = props.state;
    const tableName = props.tableName;
    const localization = props.localization;
    const page = props.page;
    const setPage = props.setPage;
    const rowsPerPage = props.rowsPerPage;
    const setRowsPerPage = props.setRowsPerPage;
    const currentRecordsSize = props.currentRecordsSize;
    const storedPage = props.storedPage;
    const ignorePageLocalStorage = props.ignorePageLocalStorage;
    const { currentPerson } = state;
    const userLocalStorageKey = `${currentPerson.get("personal")._id}_${props.tableName}`;
	const rowsPerPageKey = `${userLocalStorageKey}_rowsPerPage`;
    const storedRowsPerPage = localStorage.getItem(rowsPerPageKey) ? Number(localStorage.getItem(rowsPerPageKey)) : DEFAULT_ROWS_PER_PAGE;
    let paginationCount = recordCount;
    let previousPageCount = recordCount;

    const [isLastPage, setIsLastPage] = useState(false);


    const handleChangePage = async (event, newPage) => {
		const topOfNextPage = (newPage * rowsPerPage) + 1;
		const bottomOfNextPage = (newPage + 1) * rowsPerPage;

		if (bottomOfNextPage >= currentRecordsSize) {
			let newRecords = await state.loadMore();

			if (!newRecords && tableName !== "group_users_overview" || previousPageCount === currentRecordsSize) {
				paginationCount = currentRecordsSize;
				setPage(newPage - 1);
			}

			else {
				setPage(newPage);
			}

			previousPageCount = currentRecordsSize;
		}
		if (topOfNextPage <= currentRecordsSize) {
			if(!ignorePageLocalStorage)
				localStorage.setItem(`${userLocalStorageKey}_tablepage`, newPage);
			setPage(newPage);
		}
	};

    const handleChangeRowsPerPage = event => {
		const newRowsPerPage = Number(event.target.value);
		localStorage.setItem(rowsPerPageKey, newRowsPerPage);
		setRowsPerPage(newRowsPerPage);
		if(!ignorePageLocalStorage)
			localStorage.setItem(`${userLocalStorageKey}_tablepage`, 0);
		setPage(0);
	};

    const rowCountLabelFunction = ({ from, to, count, }) => {
        // const startIndex = page * rowsPerPage;
        const endIndex = Math.min((page + 1) * rowsPerPage, currentRecordsSize);

		if (endIndex < to) {
			to = endIndex;
			count = endIndex;
		}

        if(currentRecordsSize === endIndex) {
            setIsLastPage(true);
        } else setIsLastPage(false);

		return `${from}-${to} ${
			count !== -1
				? `${localization.get("table_pagination_of")} ${count}`
				: `${localization.get("table_pagination_more_than")} ${endIndex}`
		}`;
	};

    if (rowsPerPage != storedRowsPerPage) {
		setRowsPerPage(storedRowsPerPage);
	}

    if (isFiltered) {
        paginationCount = currentRecordsSize <= (page + 1) * rowsPerPage
            ? currentRecordsSize
            : -1;
    }

    useEffect(() => {
		if(storedPage === 0){
			setPage(0);
		}

        if(page === 0 && page !== storedPage && !ignorePageLocalStorage ) {
            state.loadMore();
            setPage(storedPage);
        }

	}, [storedPage, page]);

    useEffect(() => {
		if(currentRecordsSize < recordCount){
			state.loadMore();
		}
	}, [currentRecordsSize, recordCount]);

    return (
        <TablePagination
            component="div"
            count={paginationCount}
            page={page || 0}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={rowsPerPageOptions}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage={localization.get("table_pagination_rows_per_page")}
            labelDisplayedRows={rowCountLabelFunction}
            nextIconButtonProps={{disabled: isLastPage}}
        />
    );
};

Pagination.propTypes = {
    currentRecordsSize: PropTypes.number,
    userLocalStorageKey: PropTypes.string,
    localization: PropTypes.object,
    records: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.object),
		PropTypes.instanceOf(Map)
	]),
};

export {Pagination};