/* © 2017 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, January 01, 2017
 * For information or permission request, email info@aetonixsystems.com
 */

import React, { useMemo, useCallback } from "react";
import {
	addCustomIndicator,
	getDefaultIndicator,
	isIncludedInSearch,
	removeCustomIndicator,
	updateCustomIndicator,
	getNameForLanguage,
	hasNameError
} from "./library";
import Header from "../shared/Header.js";
import IconButton from "@material-ui/1.5.1/IconButton";
import ListItem from "@material-ui/1.5.1/ListItem";
import ListItemText from "@material-ui/1.5.1/ListItemText";
import Dialog from "../shared/Dialog.js";
import Button from "@material-ui/1.5.1/Button";
import TextField from "@material-ui/1.5.1/TextField";
import { palette as Colors } from "../shared/AetonixTheme";
import InputLabel from "@material-ui/1.5.1/InputLabel";
import { useUserMetrics } from "@aetonix/user-metrics";

const styles = {
	search: {
		color: Colors.primary.main,
		minWidth: "30%",
		padding: 8
	},
	input: {
		color: "grey",
		marginTop: "8px",
		marginBottom: "8px"
	},
	inputProp: {
		style: {
			color: Colors.primary.main
		}
	},
	inputlabel: {
		color: "black"
	},
	emptyStateLabel: {
		color: "black",
		fontSize: "20px",
		padding: "1em",
		marginTop: "0.5em"
	}
};

const iconButtonClasses = {
	root: "ae-inline"
};

const CustomIndicatorEmptyState = ({ localization }) => (
	<div>
		<p style={styles.emptyStateLabel}>{localization.get("org_custom_indicators_empty")}</p>
	</div>
);

const maxLengthInput = { maxLength: 15 };

const IndicatorEditModal = ({
	isActive,
	localization,
	onSave,
	onCancel,
	setEditedIndicator,
	indicators,
	indicator,
	component,
	mode,
	isUnitError,
	isNameError
}) => {
	const { userMetrics } = useUserMetrics();

	if(typeof indicator.name === 'object') {
		indicator.nameCnS = indicator.name.cn_s;
		indicator.nameCnT = indicator.name.cn_t;
		indicator.nameEs = indicator.name.es;
		indicator.nameFr = indicator.name.fr;
		indicator.namePt = indicator.name.pt;
		indicator.nameTa = indicator.name.ta;
		indicator.name = indicator.name.en;
	}

	return (
		<Dialog
			open={isActive}
			title={
				mode === "create"
					? localization.get("org_custom_indicators_add")
					: localization.get("org_custom_indicators_edit")
			}
			actions={[
				<Button
					key={"buttonupdate"}
					onClick={() => {
						onSave(component, indicators);
						userMetrics.trackEvent("org-custom-indicator: save custom indicator", {
							indicator
						});
					}}
				>
					{localization.get("common.save")}
				</Button>,
				<Button key={"buttoncancel"} onClick={onCancel}>
					{localization.get("common.cancel")}
				</Button>
			]}
		>
			<InputLabel style={styles.inputlabel}>{localization.get("org_custom_indicators_edit_names")}</InputLabel>

			<TextField
				defaultValue={indicator.name}
				fullWidth
				placeholder={localization.get("org_custom_indicators_localize_name.en")}
				inputProps={{ "aria-label": localization.get("org_custom_indicators_localize_name.en"), ...maxLengthInput }}
				onChange={event => setEditedIndicator({ name: event.target.value })}
				InputProps={styles.input}
				style={styles.input}
				error={isNameError}
			/>
			<TextField
				defaultValue={indicator.nameCnT}
				fullWidth
				placeholder={localization.get("org_custom_indicators_localize_name.cn-t")}
				inputProps={{ "aria-label": localization.get("org_custom_indicators_localize_name.cn-t"), ...maxLengthInput }}
				onChange={event => setEditedIndicator({ nameCnT: event.target.value })}
				InputProps={styles.input}
				style={styles.input}
				error={isNameError}
			/>

			<TextField
				defaultValue={indicator.nameCnS}
				fullWidth
				placeholder={localization.get("org_custom_indicators_localize_name.cn-s")}
				inputProps={{ "aria-label": localization.get("org_custom_indicators_localize_name.cn-s"), ...maxLengthInput }}
				onChange={event => setEditedIndicator({ nameCnS: event.target.value })}
				InputProps={styles.input}
				style={styles.input}
				error={isNameError}
			/>

			<TextField
				defaultValue={indicator.nameFr}
				fullWidth
				placeholder={localization.get("org_custom_indicators_localize_name.fr")}
				inputProps={{ "aria-label": localization.get("org_custom_indicators_localize_name.fr"), ...maxLengthInput }}
				onChange={event => setEditedIndicator({ nameFr: event.target.value })}
				InputProps={styles.input}
				style={styles.input}
				error={isNameError}
			/>

			<TextField
				defaultValue={indicator.nameEs}
				fullWidth
				placeholder={localization.get("org_custom_indicators_localize_name.es")}
				inputProps={{ "aria-label": localization.get("org_custom_indicators_localize_name.es"), ...maxLengthInput }}
				onChange={event => setEditedIndicator({ nameEs: event.target.value })}
				InputProps={styles.input}
				style={styles.input}
				error={isNameError}
			/>

			<TextField
				defaultValue={indicator.namePt}
				fullWidth
				placeholder={localization.get("org_custom_indicators_localize_name.pt")}
				inputProps={{ "aria-label": localization.get("org_custom_indicators_localize_name.pt"), ...maxLengthInput }}
				onChange={event => setEditedIndicator({ namePt: event.target.value })}
				InputProps={styles.input}
				style={styles.input}
				error={isNameError}
				helperText={isNameError && localization.get("org_custom_indicators_edit_name_error")}
			/>

			<TextField
				defaultValue={indicator.nameTa}
				fullWidth
				placeholder={localization.get("org_custom_indicators_localize_name.ta")}
				inputProps={{ "aria-label": localization.get("org_custom_indicators_localize_name.ta"), ...maxLengthInput }}
				onChange={event => setEditedIndicator({ nameTa: event.target.value })}
				InputProps={styles.input}
				style={styles.input}
				error={isNameError}
				helperText={isNameError && localization.get("org_custom_indicators_edit_name_error")}
			/>

			<InputLabel style={styles.inputlabel}>{localization.get("org_custom_indicators_edit_unit")}</InputLabel>

			<TextField
				defaultValue={indicator.unit}
				fullWidth
				placeholder={localization.get("org_custom_indicators_edit_unit")}
				onChange={event => setEditedIndicator({ unit: event.target.value })}
				InputProps={styles.input}
				inputProps={{ "aria-label": localization.get("org_custom_indicators_edit_unit"), ...maxLengthInput }}
				style={styles.input}
				error={isUnitError}
				helperText={isUnitError && localization.get("org_custom_indicators_edit_unit_error")}
			/>
		</Dialog>
	);
};

const IndicatorCreateModal = IndicatorEditModal;

const IndicatorDeleteModal = ({ localization, isRemoving, indicator, component, deleteIndicator, language }) => {
	const { userMetrics } = useUserMetrics();
	const onClickCancel = useCallback(() => {
		component.setState({ indicatorToRemove: null });
		userMetrics.trackEvent("org-custom-indicator: close remove custom indicator popup", {
			indicator
		});
	}, [component.setState]);
	const onClickAccept = useCallback(() => {
		removeCustomIndicator(component, indicator, deleteIndicator);
		userMetrics.trackEvent("org-custom-indicator: remove custom indicator and close popup", {
			indicator
		});
	}, [
		component,
		indicator,
		deleteIndicator
	]);

	return (
		<Dialog
			actions={[
				<Button key={"buttonok"} onClick={onClickAccept}>
					{localization.get("generic_ok")}
				</Button>,
				<Button key={"buttoncancel"} onClick={onClickCancel}>
					{localization.get("org_managers_cancel")}
				</Button>
			]}
			open={isRemoving}
			title={localization.get("org_custom_indicators_remove")}
		>
			{localization.get("org_managers_warning")}
			{getNameForLanguage(indicator, language)}?
		</Dialog>
	);
};

const CustomIndicatorPlayground = props => {
	const {
		isCreating,
		searchTerm,
		customIndicators,
		indicatorToRemove,
		indicatorToUpdate,
		editedIndicator,
		currentPerson,
		localization,
		component,
		isUnitError,
		isNameError
	} = props;

	const { userMetrics } = useUserMetrics();

	// Seems like an issue with the store library we are using
	// Very unpredicatable when this value updates and React memoization
	// doesn't seem to be cooperating
	const allIndicators = customIndicators?.all?.() ?? [];
	const language = currentPerson?.get?.("person")?.language ?? "en";

	const cancelUpdateIndicator = useCallback(
		() => {
			component.setState({
				indicatorToUpdate: null,
				editedIndicator: getDefaultIndicator(),
				isNameError: false,
				isUnitError: false
			});
			userMetrics.trackEvent("org-custom-indicator: close edit custom indicator popup");
		},
		[component]
	);

	const filteredIndicators = useMemo(
		() => {
			return allIndicators.filter(indicator => isIncludedInSearch(searchTerm, indicator, language));
		},
		[allIndicators, searchTerm, language]
	);

	const indicatorList = useMemo(
		() =>
			filteredIndicators.length ? (
				filteredIndicators.map((indicator, index) => {
					const setUpdateIndicator = () => {
						component.setState({
							indicatorToUpdate: indicator,
							editedIndicator: {
								name: indicator?.name,
								nameCnT: indicator?.nameCnT,
								nameCnS: indicator?.nameCnS,
								namePt: indicator?.namePt,
								nameEs: indicator?.nameEs,
								nameFr: indicator?.nameFr,
								nameTa: indicator?.nameTa,
								unit: indicator?.unit
							}
						});
						userMetrics.trackEvent("org-custom-indicator: open edit custom indicator popup", {
							indicator,
						});
					};

					const setDeleteIndicator = () => {
						component.setState({ indicatorToRemove: indicator });
						userMetrics.trackEvent("org-custom-indicator: open remove custom indicator popup", {
							indicator,
						});
					};

					return (
						<ListItem className="ae-plain" key={`${indicator._id}-${index}`}>
							<ListItemText primary={getNameForLanguage(indicator, language)} secondary={indicator.unit} />
							<IconButton
								classes={iconButtonClasses}
								disableRipple
								color="primary"
								className="fa fa-pencil"
								aria-label= {localization.get("edit_button")}
								title= {localization.get("edit_button")}
								role="button"
								onClick={setUpdateIndicator}
							/>
							<IconButton
								classes={iconButtonClasses}
								disableRipple
								color="primary"
								aria-label={localization.get("remove_button")}
								title={localization.get("remove_button")}
								role="button"
								className="fa fa fa-times-circle"
								onClick={setDeleteIndicator}
							/>
						</ListItem>
					);
				})
			) : (
				<CustomIndicatorEmptyState localization={localization} />
			),
		[filteredIndicators, localization]
	);

	const setEditedIndicator = useCallback(
		updatedIndicator => {
			const { unit, ...nameFields } = editedIndicator;
			const { unit: updatedUnit, ...updatedNameFields } = updatedIndicator;
			const allNameFields = { ...nameFields, ...updatedNameFields };

			const isExitingUnitEmpty = !!(unit && unit?.length);
			const isUpdatedUnitEmpty = !!(updatedUnit && updatedUnit?.length);
			const isUnitFilled = isExitingUnitEmpty || isUpdatedUnitEmpty;

			component.setState({
				editedIndicator: {
					...editedIndicator,
					...updatedIndicator
				},
				isUnitError: isUnitError && !isUnitFilled,
				isNameError: isNameError && hasNameError(allNameFields)
			});
		},
		[component, editedIndicator, isUnitError, isNameError]
	);

	const onCancelCreateIndicator = useCallback(
		() => {
			component.setState({
				isCreating: false,
				editedIndicator: getDefaultIndicator(),
				isNameError: false,
				isUnitError: false
			});
			userMetrics.trackEvent("org-custom-indicator: close create custom indicator popup");
		},
		[component]
	);

	const onSearch = useCallback(event => component.setState({ searchTerm: event.target.value }), [component.setState]);
	const onClickCreateIndicator = useCallback(() => {
		component.setState({ isCreating: true });
		userMetrics.trackEvent("org-custom-indicator: open create custom indicator popup");
	}, [component.setState]);

	return (
		<div className="flex-vertical flex-1">
			<Header
				currentPerson={currentPerson}
				localization={localization}
				titleKey="dashboards_org_custom_indicators"
			/>

			<div className="ae-left-margin">
				{!!allIndicators.length && (
					<TextField
						placeholder={localization.get("org_custom_indicator_search")}
						inputProps={{"aria-label": localization.get("org_custom_indicator_search")}}
						onChange={onSearch}
						style={styles.search}
						InputProps={styles.inputProp}
					/>
				)}
			</div>

			<div className="flex-vertical flex-1 ae-scrollable">{indicatorList}</div>

			<Button variant="raised" onClick={onClickCreateIndicator} color="secondary" >
				{localization.get("org_custom_indicators_add")}
			</Button>

			<IndicatorCreateModal
				isActive={isCreating}
				localization={localization}
				onSave={() => addCustomIndicator(component, customIndicators.createIndicator)}
				onCancel={onCancelCreateIndicator}
				setEditedIndicator={setEditedIndicator}
				indicator={editedIndicator}
				component={component}
				mode="create"
				isUnitError={isUnitError}
				isNameError={isNameError}
			/>

			<IndicatorEditModal
				isActive={!!indicatorToUpdate}
				localization={localization}
				onSave={() => updateCustomIndicator(component, indicatorToUpdate, customIndicators.updateIndicator)}
				onCancel={cancelUpdateIndicator}
				setEditedIndicator={setEditedIndicator}
				indicator={editedIndicator}
				component={component}
				mode="update"
				isUnitError={isUnitError}
				isNameError={isNameError}
			/>

			<IndicatorDeleteModal
				localization={localization}
				isRemoving={!!indicatorToRemove}
				indicator={indicatorToRemove}
				indicators={filteredIndicators}
				component={component}
				deleteIndicator={customIndicators.deleteIndicator}
				language={language}
			/>
		</div>
	);
};

function render() {
	var component = this;
	var state = component.state;

	return <CustomIndicatorPlayground {...state} component={component} />;
}

module.exports = render;
