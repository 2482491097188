/* © 2014 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, June 19, 2014
 * For information or permission request, email info@aetonixsystems.com
 */

"use strict";

var Store = require("./store");
var par = require("par");

module.exports = UserBraceletPreferenceStore;

function UserBraceletPreferenceStore(api, events, config) {
	var store = new Store();

	store.raw_get = store.get;
	store.get = get;

	var tracking = [];

	return store;

	function get(id) {
		var existing = store.raw_get(id);
		if (existing) return existing;

		if (!isTracking(id))
			track(id);

		return {
			loading: true
		};
	}

	function isTracking(id) {
		return tracking.indexOf(id) !== -1;
	}

	function track(id) {
		var orgGroup = config.orgGroup;
		if (!orgGroup) return;
		tracking.push(id);
		api.org.devices.data(id)
			.then(par(setPreferences, id));
		events.on("mqtt:orggroup/" + orgGroup + "/u/" + id + "/device/updated", par(updatePreferences, id));
		events.on("mqtt:orggroup/" + orgGroup + "/u/" + id + "/device/mac/updated", par(updatePreferences, id));
	}

	function updatePreferences(id, data) {
		store.update(id, data);
	}

	function setPreferences(id, data) {
		store.set(id, data);
	}
}
