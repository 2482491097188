
"use strict";

var Store = require("./store");
var pluck = require("fj-pluck");
var map = require("fj-map");
var xtend = require("xtend");

module.exports = LiveChats;

function LiveChats(api, events){
	var store = new Store();
	var orgGroup = null;

	api.org.group.current.data()
		.then(pluck("_id"))
		.then(listenForAdditions)
		.then(api.org.livechat.list)
		.then(map(addLiveChat));

	return store;

	function listenForAdditions(group){
		orgGroup = group;
		events.on("mqtt:orggroup/" + group + "/livechat/created", addLiveChat);
	}

	function listenForChanges(group){
		events.on("mqtt:orggroup/" + orgGroup + "/livechat/" + group + "/members/added", memberAdd);
		events.on("mqtt:orggroup/" + orgGroup + "/livechat/" + group + "/members/removed", memberRemove);
		events.on("mqtt:orggroup/" + orgGroup + "/livechat/" + group + "/updated", updateLiveChat);
		events.on("mqtt:orggroup/" + orgGroup + "/livechat/" + group + "/removed", removeLiveChat);
	}

	function addLiveChat(group){
		var id = group._id;
		store.set(id, group);
		listenForChanges(id);
	}

	function memberAdd(data){
		var id = data.group;
		var existing = store.get(id);

		var current = existing.members;
		var newMembers = current.concat(data.who);

		var newGroup = xtend(existing, {
			members: newMembers
		});

		store.set(id, newGroup);
	}

	function memberRemove(data){
		var id = data.group;
		var existing = store.get(id);

		var current = existing.members;
		var newMembers = current.filter(function(member){
			return member !== data.who;
		});

		var newGroup = xtend(existing, {
			members: newMembers
		});

		store.set(id, newGroup);
	}

	function updateLiveChat(data){
		var id = data.group;
		var current = store.get(id);
		var newGroup = xtend(current, data);
		store.update(id, newGroup);
	}

	function removeLiveChat(group){
		var id = group;
		store.remove(id);
	}
}