/* © 2023 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, January 01, 2017
 * For information or permission request, email info@aetonixsystems.com
 */

var React = require("react");
var par = require("par");
var xtend = require("xtend");

var config = require("../../configs/config.json");
var Header = require("../shared/Header.js");
var Avatar = require("@material-ui/1.5.1/Avatar").default;
var Button = require("@material-ui/1.5.1/Button").default;
var Dialog = require("../shared/Dialog");

var IconButton = require("@material-ui/1.5.1/IconButton").default;
var ListItem = require("@material-ui/1.5.1/ListItem").default;
var ListItemText = require("@material-ui/1.5.1/ListItemText").default;
var TextField = require("@material-ui/1.5.1/TextField").default;
var SubHeader = require("@material-ui/1.5.1/ListSubheader").default;
var Select = require("@material-ui/1.5.1/Select").default;
var MenuItem = require("@material-ui/1.5.1/MenuItem").default;
var InputLabel = require("@material-ui/1.5.1/InputLabel").default;
var Chip = require("@material-ui/1.5.1/Chip").default;

var Search = require("../shared/Search.jsx");
var Utility = require("../shared/Utility");
var LazyList = require("../shared/LazyList.jsx");
var Colors = require("../shared/AetonixTheme").palette;

import { MeasureUnitsProvider, useMeasureUnits } from "@aetonix/hooks";

var styles = {
	search: {
		color: Colors.primary.main,
		minWidth: "30%",
		padding: "8px"
	},
	input: {
		color: Colors.primary.main,
		padding: "8px"
	},
	inputProp: {
		style: {
			textAlign: "center"
		}
	},
	inputWidth: {
		width: "70px",
		alignSelf: "center"
	},
	colorContainer: {
		padding: "20px 0px"
	},
	inputNumber: {
		margin: "0px 10px"
	},
	header: {
		alignItems: "center",
		justifyContent: "center",
		display: "flex",
		padding: "20px",
		fontSize: "1.1em"
	},
	subHeader: {
		alignItems: "center",
		justifyContent: "center",
		display: "flex",
		padding: "10px",
		fontSize: "1.0em"
	},
	redBorder: {
		backgroundColor: "#faa0a0",
		margin: "5px"
	},
	yellowBorder: {
		backgroundColor: "#ffe694",
		margin: "5px"
	},
	greenBorder: {
		backgroundColor: "#c5e09e",
		width: "100px",
		height: "auto"
	},
	inputRedContainer: {
		padding: "10px",
		margin: "5px",
		backgroundColor: "#FABCBC"
	},
	inputYellowContainer: {
		padding: "10px",
		margin: "5px",
		backgroundColor: "#ffe694"
	},
	inputGreenContainer: {
		padding: "10px",
		margin: "5px",
		backgroundColor: "#c5e09e"
	},
	viewing: {
		fontSize: "15px"
	},
	errorMessage: {
		color: "#e41d1d",
		textAlign: "center",
		padding: "16px"
	}
};

var listItemClasses = {
	root: "ae-padding",
	gutters: "ae-padding",
	default: "ae-padding"
};

var iconButtonClasses = {
	root: "ae-inline"
};

var VALID_THRESHOLDS = [
	"bloodoxygen",
	"bloodpressure",
	"bloodsugar",
	"bodytemperature",
	"weight",
	"steps"
];

module.exports = render;

function render(){
	var component = this;
	var state = this.state;

	var users = state.users;
	var localization = state.localization;
	var careplanNoticeList = state.careplanChangeList.all();
	var careplanChanges = !!careplanNoticeList.length;
	var offline = state.connection.get("offline");
	var currentPerson = state.currentPerson;
	var people = state.people;
	var searchExist = state.searchExist;
	var loadMore = state.loadMore;
	var adding = state.adding;
	var sortedUsers = users.map(people.get).sort(by_name);
	var titleKey = "user_thresholds";
	var viewing = state.viewing;
	var thresholds = state.userThresholds;
	var deleting = state.deleting;
	var editing = state.editing;
	var deletingContact = state.deletingContact;
	var selecting = state.patientSelect;
	var personal = component.state.currentPerson.get("personal");
	var contactSelect = state.contactSelect;
	var error = state.error;

	var contacts = [];
	var viewingThresholds = {};
	var listThresholds = [];

	if(viewing){
		if(viewing === "group")	viewingThresholds = state.groupThresholds || {};
		else viewingThresholds = thresholds.get(viewing) || {};
		contacts = (viewingThresholds || {}).contacts || [];
		listThresholds = Object.keys(viewingThresholds).filter(function(key){
			return key !== "contacts";
		}).reduce(reduceThresholds(viewingThresholds), []);
	}

	var renderThresholdsLazyList = viewing ? (
  <LazyList className="ae-scrollable" renderItem={par(renderThreshold, this, viewingThresholds)} items={listThresholds} />
  ) : null;

	var renderContactsLazyList = viewing ? (
	<LazyList className="ae-scrollable" renderItem={par(renderContact, this)} items={contacts} />
	) : null;

	var renderAddNewThreshold = viewing ? (
  <Button variant="raised" onClick={par(startAdding, this)} color="secondary" >{localization.get("user_thresholds_addnewthreshold")}</Button>
  ) : null;

	var createActions = [
		<SubmitButton localization={localization} component={component} />,
		<Button key={"thresholds_cancel"} color="primary" onClick={par(cancelCreate, component)}>{localization.get("permissions_done")}</Button>
	];

	var editActions = [
		<SubmitButton localization={localization} component={component} edit />,
		<Button key={"edit_cancel"} color="primary" onClick={par(cancelCreate, component)}>{localization.get("permissions_done")}</Button>
	];

	var removeActions = [
		<Button key={"removing_submit"} color="primary" onClick={par(submitDelete, component)}>{localization.get("permissions_submit")}</Button>,
		<Button key={"removing_cancel"} color="primary" onClick={par(cancelDelete, component)}>{localization.get("permissions_done")}</Button>
	];

	var removeContactActions = [
		<Button key={"remove_submit"} color="primary" onClick={par(submitDeleteContact, component)}>{localization.get("permissions_submit")}</Button>,
		<Button key={"remove_cancel"} color="primary" onClick={par(cancelDeleteContact, component)}>{localization.get("permissions_done")}</Button>
	];

	var mobileContactsActions = [
		<Button key={"contact_cancel"} color="primary" onClick={par(showSearch, component)}>{localization.get("user_thresholds_addnewcontact")}</Button>,
		<Button key={"contact_cancel"} color="primary" onClick={par(closeSelectContacts, component)}>{localization.get("search_close")}</Button>
	];

	var renderLoadMore = state.atEnd ? null : (
		<div className="flex-1 ae-vertical-center ae-horizontal-center">
			<Button onClick={loadMore}>{"Load More"}</Button>
		</div>
	);

	var personInfo = viewing ? people.get(viewing) : null;
	var renderViewing = viewing ? `Viewing: ${viewing === "group" ? "Group Defaults" : personInfo.lname + ", " + personInfo.fname}` : null;

	return (
		<MeasureUnitsProvider value={personal}>
			<div className="flex-vertical flex-1">
				<Header
					careplanChanges={careplanChanges}
					offline={offline}
					currentPerson={currentPerson}
					localization={localization}
					titleKey={titleKey}
				/>

				<div className="flex-vertical flex-1 ae-scrollable">
					<div className="flex-horizontal ae-small-padding-top">
						<Chip className="ae-icon ae-fonticon" onClick={par(selectPatient, component)} label={"Change Patient"} />
						<Chip
							className="ae-icon ae-fonticon"
							onClick={par(selectContacts, component)}
							label={"Mobile Alert Contacts"}
						/>
						<div className="ae-vertical-center ae-horizontal-center" style={styles.viewing}>
							{renderViewing}
						</div>
					</div>
					<div className="flex-vertical flex-1 ae-scrollable">
						<SubHeader>{localization.get("user_thresholds_thresholds")}</SubHeader>
						{renderThresholdsLazyList}
						{renderAddNewThreshold}
					</div>
				</div>

				<Search
					selection={contacts}
					action={par(state.addContact, viewing, viewingThresholds)}
					ref="searchDialog"
					search={state.search}
					localization={localization}
					onDismiss={() => component.state.userMetrics.trackEvent("user-thresholds: close search for mobile alert contact popup")}
				/>

				<Dialog actions={removeActions} open={!!deleting} title={localization.get("user_resources_removeTitle")}>
					{localization.get("user_thresholds_areyousure") +
						" " +
						(deleting ? localization.get("user_thresholds_" + deleting) : "") +
						"?"}
				</Dialog>

				<Dialog
					actions={removeContactActions}
					open={!!deletingContact}
					title={localization.get("user_resources_removeTitle")}
				>
					{localization.get("user_thresholds_areyousure") +
						" " +
						Utility.format_name(people.get(deletingContact)) +
						"?"}
				</Dialog>

				<Dialog
					title={localization.get("user_thresholds_" + editing)}
					actions={editActions}
					modal={true}
					open={!!editing}
				>
					<div className="flex-vertical">
						<Creator component={component} />
						{error && <div style={styles.errorMessage}>{error}</div>}
					</div>
				</Dialog>

				<Dialog open={!!selecting} title={"Select a patient"} scroll={"paper"}>
					<TextField
						placeholder={localization.get("search_onNameorpatientNum")}
						inputProps={{ "aria-label": localization.get("search_onNameorpatientNum") }}
						onChange={searchExist}
						style={styles.search}
						InputProps={styles.inputProp}
					/>
					<Button key={"select-group"} onClick={par(selectGroup, component)}>
						{"Group Defaults"}
					</Button>
					<LazyList noScroll renderItem={par(renderUser, this)} items={sortedUsers} />
					{renderLoadMore}
				</Dialog>

				<Dialog
					open={!!contactSelect}
					actions={mobileContactsActions}
					title={localization.get("user_thresholds_contacts")}
					scroll={"paper"}
				>
					<div className="flex-vertical flex-1 ae-scrollable">{renderContactsLazyList}</div>
				</Dialog>

				<Dialog
					title={localization.get("user_thresholds_addnewthreshold")}
					actions={createActions}
					modal={true}
					open={adding}
				>
					<div className="flex-vertical">
						<Select
							fullWidth
							onChange={par(updateDropdown, component)}
							inputProps={{ "aria-label": localization.get("user_thresholds_addnewthreshold") }}
							value={state.currentIndicator}
						>
							{renderItems(component)}
						</Select>
						<Creator component={component} />
						{error && <div style={styles.errorMessage}>{error}</div>}
					</div>
				</Dialog>
			</div>
		</MeasureUnitsProvider>
	);
}

function SubmitButton(props){
	const measureUnits = useMeasureUnits();
	const { localization, component, edit } = props;

	return (
		<Button key={edit ? "edit_submit" : "thresholds_submit"} color="primary" onClick={par(processIndicator, component, measureUnits)}>{localization.get("permissions_submit")}</Button>
	);
}

function reduceThresholds(viewingThresholds){
	return function(acc, key){
		var threshold = viewingThresholds[key];
		if(!threshold) return acc;
		var firstKey = Object.keys(threshold)[0];
		var checkThreshold = threshold[firstKey];

		if(Array.isArray(checkThreshold)){
			checkThreshold.forEach(function(e, index){
				acc.push(key + "_" + index);
			});
		} else if(key !== "weight"){
			acc.push(key);
		}
		return acc;
	};
}

function renderItems(component){
	var state = component.state;
	if(!state.viewing) return;

	var localization = state.localization;
	var userThresholds = state.userThresholds.get(state.viewing);
	if(state.viewing === "group") userThresholds = state.groupThresholds || {};
	return VALID_THRESHOLDS.reduce(function(acc, threshold){
		if(!userThresholds[threshold] || threshold === "weight")
			acc.push(<MenuItem key={threshold} value={threshold}>{localization.get("user_thresholds_" + threshold)}</MenuItem>);
		return acc;
	}, []);
}

function selectPatient(component){
	component.setState({
		patientSelect: true
	});
	component.state.userMetrics.trackEvent("user-thresholds: navigate to patient selection");
}

function selectContacts(component){
	component.setState({
		contactSelect: true
	});
	component.state.userMetrics.trackEvent("user-thresholds: open select mobile alert contacts popup");
}

function selectGroup(component){
	component.setState({
		patientSelect: false,
		viewing: "group"
	});
	component.state.userMetrics.trackEvent("user-thresholds: view group");
}

function closeSelectContacts(component){
	component.setState({
		contactSelect: false
	});
	component.state.userMetrics.trackEvent("user-thresholds: close select contacts popup");
}

function updateDropdown(component, e){
	e.persist();
	var payload = e.target.value;
	component.setState({
		currentIndicator: payload
	});
}

function updateDrop(component, key, e){
	e.persist();
	var payload = e.target.value;

	var update = {
		[key]: payload
	};
	component.setState(update);
}

function cancelCreate(component){
	component.setState({
		adding: false,
		editing: null,
		editingIndex: null,
		currentIndicator: null,
		bloodOxygenORA: null,
		bloodOxygenORB: null,
		bloodOxygenOYA: null,
		bloodOxygenOYB: null,
		bloodOxygenHRA: null,
		bloodOxygenHRB: null,
		bloodOxygenHYA: null,
		bloodOxygenHYB: null,
		bloodPressureSRA: null,
		bloodPressureSRB: null,
		bloodPressureSYA: null,
		bloodPressureSYB: null,
		bloodPressureDRA: null,
		bloodPressureDRB: null,
		bloodPressureDYA: null,
		bloodPressureDYB: null,
		bloodSugarRA: null,
		bloodSugarRB: null,
		bloodSugarYA: null,
		bloodSugarYB: null,
		bodyTempRA: null,
		bodyTempRB: null,
		bodyTempYA: null,
		bodyTempYB: null,
		weightType: null,
		weightValue: null,
		weightStatus: null,
		weightDays: null,
		stepRA: null,
		stepRB: null,
		stepYA: null,
		stepYB: null,
		error: null,
	});
	component.state.userMetrics.trackEvent("user-thresholds: close create threshold popup");
}

function updateText(component, type, e){
	e.persist();
	var text = e.target.value;
	var update = {};
	update[type] = text;
	component.setState(update);
}

function Creator({component}){
	var currentIndicator = component.state.currentIndicator;
	if(!currentIndicator) return null;
	const { weightUnits, glucometerUnits, temperatureUnits } = useMeasureUnits();

	if(currentIndicator === "bloodoxygen")
		return createBloodOxygen(component);
	else if(currentIndicator === "bloodpressure")
		return createBloodPressure(component);
	else if(currentIndicator === "bloodsugar")
		return createBloodSugar(component, glucometerUnits);
	else if(currentIndicator === "bodytemperature")
		return createBodyTemperature(component, temperatureUnits);
	else if(currentIndicator === "weight")
		return createWeight(component, weightUnits);
	else if(currentIndicator === "steps")
		return createStep(component);
	else return null;
}

function createBloodOxygen(component){
	var state = component.state;
	var localization = state.localization;

	var current = state.userThresholds.get(state.viewing) || {};
	if(state.viewing === "group") current = state.groupThresholds || {};
	var defaultOxygen = {};
	var defaultHeartRate = {};
	if(current.bloodoxygen){
		defaultOxygen = (current.bloodoxygen || {}).oxygensaturation;
		defaultHeartRate = (current.bloodoxygen || {}).heartrate;
	}
	return (
		<div>
			<div style={styles.header}>{localization.get("user_thresholds_bloodoxygen")}</div>
			<div style={styles.subHeader}>{localization.get("user_thresholds_oxygensaturation") + " (%)"}</div>
			<InputThreshold
				redAbove={par(updateText, component, "bloodOxygenORA")}
				redBelow={par(updateText, component, "bloodOxygenORB")}
				yellowAbove={par(updateText, component, "bloodOxygenOYA")}
				yellowBelow={par(updateText, component, "bloodOxygenOYB")}
				defaults={defaultOxygen}
				localization={localization}/>
			<div style={styles.subHeader}>{localization.get("user_thresholds_heartrate") + " (bpm)"}</div>
			<InputThreshold
				redAbove={par(updateText, component, "bloodOxygenHRA")}
				redBelow={par(updateText, component, "bloodOxygenHRB")}
				yellowAbove={par(updateText, component, "bloodOxygenHYA")}
				yellowBelow={par(updateText, component, "bloodOxygenHYB")}
				defaults={defaultHeartRate}
				localization={localization} />
		</div>
	);
}

function createBloodPressure(component){
	var state = component.state;
	var localization = state.localization;

	var current = state.userThresholds.get(state.viewing) || {};
	if(state.viewing === "group") current = state.groupThresholds || {};
	var defaultSystolic = {};
	var defaultDiastolic = {};
	var defaultBPPulserate = {};
	if(current.bloodpressure){
		defaultSystolic = (current.bloodpressure || {}).systolic;
		defaultDiastolic = (current.bloodpressure || {}).diastolic;
		defaultBPPulserate =  (current.bloodpressure || {}).bpPulserate;
	}
	return (
		<div>
			<div style={styles.header}>{localization.get("user_thresholds_bloodpressure")}</div>
			<div style={styles.subHeader}>{localization.get("user_thresholds_systolic") + " (mmHg)"}</div>
			<InputThreshold
				redAbove={par(updateText, component, "bloodPressureSRA")}
				redBelow={par(updateText, component, "bloodPressureSRB")}
				yellowAbove={par(updateText, component, "bloodPressureSYA")}
				yellowBelow={par(updateText, component, "bloodPressureSYB")}
				defaults={defaultSystolic}
				localization={localization}/>
			<div style={styles.subHeader}>{localization.get("user_thresholds_diastolic") + " (mmHg)"}</div>
			<InputThreshold
				redAbove={par(updateText, component, "bloodPressureDRA")}
				redBelow={par(updateText, component, "bloodPressureDRB")}
				yellowAbove={par(updateText, component, "bloodPressureDYA")}
				yellowBelow={par(updateText, component, "bloodPressureDYB")}
				defaults={defaultDiastolic}
				localization={localization} />
			<div style={styles.subHeader}>{localization.get("user_thresholds_bpPulserate") + " (bpm)"}</div>
			<InputThreshold
				redAbove={par(updateText, component, "bloodPressurePRA")}
				redBelow={par(updateText, component, "bloodPressurePRB")}
				yellowAbove={par(updateText, component, "bloodPressurePYA")}
				yellowBelow={par(updateText, component, "bloodPressurePYB")}
				defaults={defaultBPPulserate}
				localization={localization} />
		</div>
	);
}

function createBloodSugar(component, measureUnits){
	var state = component.state;
	var localization = state.localization;
	var displayUnits = measureUnits === "mmol" ? "mmol/L" : "mg/dL";
	var units = measureUnits === "mmol" ? "mmol" : "mg";
	var current = state.userThresholds.get(state.viewing) || {};
	if(state.viewing === "group") current = state.groupThresholds || {};
	var defaults = {};
	if(current.bloodsugar && current.bloodsugar[units])
		defaults = current.bloodsugar[units];
	return (
		<div>
			<div style={styles.header}>{localization.get("user_thresholds_bloodsugar") + " (" + displayUnits + ")"}</div>
				<InputThreshold
					redAbove={par(updateText, component, "bloodSugarRA")}
					redBelow={par(updateText, component, "bloodSugarRB")}
					yellowAbove={par(updateText, component, "bloodSugarYA")}
					yellowBelow={par(updateText, component, "bloodSugarYB")}
					defaults={defaults}
					localization={localization} />
		</div>
	);
}

function createBodyTemperature(component, measureUnits){
	var state = component.state;
	var localization = state.localization;
	var displayUnits = measureUnits === "celsius" ? "°C" : "°F";
	var units = measureUnits === "celsius" ? "celsius" : "fahrenheit";
	var current = state.userThresholds.get(state.viewing) || {};
	if(state.viewing === "group") current = state.groupThresholds || {};
	var defaults = {};
	if(current.bodytemperature && current.bodytemperature[units])
		defaults = current.bodytemperature[units];
	return (
		<div>
			<div style={styles.header}>{localization.get("user_thresholds_bodytemperature") + " (" + displayUnits + ")"}</div>
				<InputThreshold
					redAbove={par(updateText, component, "bodyTempRA")}
					redBelow={par(updateText, component, "bodyTempRB")}
					yellowAbove={par(updateText, component, "bodyTempYA")}
					yellowBelow={par(updateText, component, "bodyTempYB")}
					defaults={defaults}
					localization={localization} />
		</div>
	);
}


function createWeight(component, measureUnits){
	var state = component.state;
	var localization = state.localization;
	var units = measureUnits === "kg" ? "kg" : "lbs";

	var defaults = {
		type: state.weightType,
		value: state.weightValue,
		status: state.weightStatus,
		days: state.weightDays
	};

	return (
		<div>
			<div style={styles.header}>{localization.get("user_thresholds_weight") + " (" + units + ")"}</div>
				<InputThresholdArray
					type={par(updateDrop, component, "weightType")}
					value={par(updateText, component, "weightValue")}
					status={par(updateDrop, component, "weightStatus")}
					days={par(updateText, component, "weightDays")}
					defaults={defaults}
					localization={localization} />
		</div>
	);
}

function createStep(component){
	var state = component.state;
	var localization = state.localization;

	var current = state.userThresholds.get(state.viewing) || {};
	if(state.viewing === "group") current = state.groupThresholds || {};
	var defaults = {};
	if(current.steps)
		defaults = current.steps;
	return (
		<div>
			<div style={styles.header}>{localization.get("user_thresholds_steps")}</div>
				<InputThreshold
					redAbove={par(updateText, component, "stepRA")}
					redBelow={par(updateText, component, "stepRB")}
					yellowAbove={par(updateText, component, "stepYA")}
					yellowBelow={par(updateText, component, "stepYB")}
					defaults={defaults}
					localization={localization} />
		</div>
	);
}

function updateThreshold(component, threshold, type){
	var state = component.state;
	var create = state.create;
	var viewing = state.viewing;
	var currentThresholds = state.userThresholds.get(state.viewing);
	if(viewing === "group") currentThresholds = state.groupThresholds || {};
	create(currentThresholds, threshold, type, state.viewing);
	cancelCreate(component);
}

function processIndicator(component, measureUnits){
	var state = component.state;
	var currentIndicator = state.currentIndicator;
	if(!currentIndicator || !state.viewing) return;

	if(currentIndicator === "bloodoxygen")
		processBloodOxygen(component);
	else if(currentIndicator === "bloodpressure")
		processBloodPressure(component);
	else if(currentIndicator === "bloodsugar")
		processBloodSugar(component, measureUnits.glucometerUnits);
	else if(currentIndicator === "bodytemperature")
		processBodyTemperature(component, measureUnits.temperatureUnits);
	else if(currentIndicator === "weight")
		processWeight(component, measureUnits.weightUnits);
	else if(currentIndicator === "steps")
		processStep(component);

	component.state.userMetrics.trackEvent("user-thresholds: submit threshold", {
		indicator: currentIndicator,
		patient: state.viewing,
	});
}

/**
 * Validates if the threshold is following a crescent order
 *
 * @param {object} threshold contains user inputed values
 * @param {string=} units to identify the right object inside the threshold
 *
 * @return {boolean} indicate if the threshold is following the right order
 */
 function validThreshold(threshold, units) {
	const { redAbove, redBelow, yellowAbove, yellowBelow } = units ? threshold[units] : threshold;
	const orderArray = [redBelow, yellowBelow, yellowAbove, redAbove].filter(number => number);

	const isAscending = orderArray.every(function (x, i) {
        return i === 0 || parseFloat(x) > parseFloat(orderArray[i - 1]);
    });

	return isAscending;
}

function processBloodOxygen(component){
	var state = component.state;
	var localization = state.localization;

	var threshold = {
		oxygensaturation: {
			redAbove: state.bloodOxygenORA,
			redBelow: state.bloodOxygenORB,
			yellowAbove: state.bloodOxygenOYA,
			yellowBelow: state.bloodOxygenOYB
		},
		heartrate: {
			redAbove: state.bloodOxygenHRA,
			redBelow: state.bloodOxygenHRB,
			yellowAbove: state.bloodOxygenHYA,
			yellowBelow: state.bloodOxygenHYB,
		}
	};

	if (validThreshold(threshold, "oxygensaturation") && validThreshold(threshold, "heartrate")) {
		updateThreshold(component, threshold, "bloodoxygen");
		component.setState({ error: null });
	} else {
		component.setState({ error: localization.get("user_thresholds_error") });
	}
}

function processBloodPressure(component){
	var state = component.state;
	var localization = state.localization;

	var threshold = {
		systolic: {
			redAbove: state.bloodPressureSRA,
			redBelow: state.bloodPressureSRB,
			yellowAbove: state.bloodPressureSYA,
			yellowBelow: state.bloodPressureSYB,
		},
		diastolic: {
			redAbove: state.bloodPressureDRA,
			redBelow: state.bloodPressureDRB,
			yellowAbove: state.bloodPressureDYA,
			yellowBelow: state.bloodPressureDYB,
		},
		bpPulserate: {
			redAbove: state.bloodPressurePRA,
			redBelow: state.bloodPressurePRB,
			yellowAbove: state.bloodPressurePYA,
			yellowBelow: state.bloodPressurePYB
		}
	};

	if (validThreshold(threshold, "systolic") && validThreshold(threshold, "diastolic") && validThreshold(threshold, "bpPulserate")) {
		updateThreshold(component, threshold, "bloodpressure");
		component.setState({ error: null });
	} else {
		component.setState({ error: localization.get("user_thresholds_error") });
	}
}

function processBloodSugar(component, units) {
	var state = component.state;
	var localization = state.localization;

	var threshold = {};
	threshold[units] = {
		redAbove: state.bloodSugarRA,
		redBelow: state.bloodSugarRB,
		yellowAbove: state.bloodSugarYA,
		yellowBelow: state.bloodSugarYB
	};

	if (validThreshold(threshold, units)) {
		updateThreshold(component, threshold, "bloodsugar");
		component.setState({ error: null });
	} else {
		component.setState({ error: localization.get("user_thresholds_error") });
	}
}

function processBodyTemperature(component, units){
	var state = component.state;
	var localization = state.localization;

	var threshold = {};
	threshold[units] = {
		redAbove: state.bodyTempRA,
		redBelow: state.bodyTempRB,
		yellowAbove: state.bodyTempYA,
		yellowBelow: state.bodyTempYB,
	};

	if (validThreshold(threshold, units)) {
		updateThreshold(component, threshold, "bodytemperature");
		component.setState({ error: null });
	} else {
		component.setState({ error: localization.get("user_thresholds_error") });
	}
}

function processWeight(component, units){
	var state = component.state;
	var currentThresholds = state.userThresholds.get(state.viewing);
	var editingIndex = state.editingIndex;
	var viewing = state.viewing;
	var threshold = {};

	if(viewing === "group") currentThresholds = state.groupThresholds || {};
	var currentType = currentThresholds["weight"];
	if(!currentType) currentType = {
		[units]: []
	};

	var currentUnits = currentType[units];
	if(!Array.isArray(currentUnits)) currentUnits = [];

	var type = state.weightType;
	if(type === "days" && !state.weightDays) return;

	var newThreshold = {
		type: state.weightType,
		value: state.weightValue,
		status: state.weightStatus,
		days: state.weightDays
	};

	var newArray = [];
	if(editingIndex) newArray = currentUnits.map(updateIndex(editingIndex, newThreshold));
	else newArray = currentUnits.concat(newThreshold);

	threshold[units] = newArray;
	updateThreshold(component, threshold, "weight");
}

function updateIndex(checkIndex, newValue){
	return function(e, i){
		if(i === +checkIndex)
			return newValue;
		return e;
	};
}

function processStep(component) {
	var state = component.state;
	var localization = state.localization;

	var threshold = {
		redAbove: state.stepRA,
		redBelow: state.stepRB,
		yellowAbove: state.stepYA,
		yellowBelow: state.stepYB
	};

	if (validThreshold(threshold)) {
		updateThreshold(component, threshold, "steps");
		component.setState({ error: null });
	} else {
		component.setState({ error: localization.get("user_thresholds_error") });
	}
}

function InputThreshold(props){
	var redAbove = props.redAbove;
	var redBelow = props.redBelow;
	var yellowAbove = props.yellowAbove;
	var yellowBelow = props.yellowBelow;
	var localization = props.localization;
	var defaults = props.defaults || {};

	return (
			<div className="flex-horizontal flex-space-between">
				<div className="flex flex-vertical flex-1">
					<div style={styles.inputRedContainer} className="flex flex-1 ae-vertical-center">{"High Risk <"}</div>
				</div>
				<TextField inputProps={{"aria-label": localization.get("high_risk_threshold"), ...styles.inputProp}} style={styles.inputWidth} defaultValue={defaults.redBelow} type="number" onChange={redBelow}/>
				<div className="flex flex-vertical flex-1">
					<div style={styles.inputYellowContainer} className="flex flex-1 ae-vertical-center">{"< Low Risk <"}</div>
				</div>
				<TextField inputProps={{"aria-label": localization.get("low_risk_threshold"), ...styles.inputProp}}  style={styles.inputWidth} defaultValue={defaults.yellowBelow} type="number" onChange={yellowBelow} />
				<div className="flex flex-vertical flex-1">
					<div style={styles.inputGreenContainer} className="flex flex-1 ae-vertical-center">{"< Normal <"}</div>
				</div>
				<TextField inputProps={{"aria-label": localization.get("user_overview_normal"), ...styles.inputProp}}  style={styles.inputWidth} defaultValue={defaults.yellowAbove} type="number" onChange={yellowAbove}/>
				<div className="flex flex-vertical flex-1">
					<div style={styles.inputYellowContainer} className="flex flex-1 ae-vertical-center">{"< Low Risk <"}</div>
				</div>
				<TextField inputProps={{"aria-label": localization.get("high_risk_threshold"), ...styles.inputProp}}  style={styles.inputWidth} defaultValue={defaults.redAbove} type="number" onChange={redAbove}/>
				<div className="flex flex-vertical flex-1">
					<div style={styles.inputRedContainer} className="flex flex-1 ae-vertical-center">{"< High Risk"}</div>
				</div>
    </div>
	);
}

function InputThresholdArray(props){
	var defaults = props.defaults || {};

	var renderDays = defaults.type === "days" ? (
			<div className="flex flex-vertical flex-1 ae-padding-small">
				<InputLabel>{"Days"}</InputLabel>
				<TextField defaultValue={defaults.days} onChange={props.days} type="number"/>
			</div>
		) : null;

	var valueLabel = "Gain+/Loss-";
	if(defaults.type === "above")
		valueLabel = "Above Value";
	else if (defaults.type === "below")
		valueLabel = "Below Value";

	return (
			<div className="flex-horizontal flex-space-between">
				<div className="flex-vertical flex-1 ae-padding-small">
					<InputLabel>{"Type"}</InputLabel>
					<Select value={defaults.type} onChange={props.type}>
						<MenuItem value={"consecutive"}>{"Consecutive Readings"}</MenuItem>
						<MenuItem value={"days"}>{"Over a period of days"}</MenuItem>
						<MenuItem value={"above"}>{"Above"}</MenuItem>
						<MenuItem value={"below"}>{"Below"}</MenuItem>
					</Select>
				</div>
				<div className="flex flex-vertical flex-1 ae-padding-small">
					<InputLabel>{valueLabel}</InputLabel>
					<TextField defaultValue={defaults.value} onChange={props.value} type="number"/>
				</div>
				{renderDays}
				<div className="flex-vertical flex-1 ae-padding-small">
					<InputLabel>{"Color"}</InputLabel>
					<Select value={defaults.status} onChange={props.status}>
						<MenuItem value={0}>{"Green"}</MenuItem>
						<MenuItem value={1}>{"Yellow"}</MenuItem>
						<MenuItem value={2}>{"Red"}</MenuItem>
					</Select>
				</div>
			</div>
	);
}

function renderContact(component, contactId){
	var state = component.state;
	var contact = state.people.get(contactId);
	if(!contact) return;

	var avatar = <Avatar src={config.image_cdn + contact.image} />;
	var text = Utility.format_name(contact);

	return (
			<ListItem classes={listItemClasses}>
				{avatar}
				<ListItemText primary={text} />
				<IconButton classes={iconButtonClasses} disableRipple color="primary" className="fa fa-user-times" onClick={par(startDeleteContact, component, contactId)} />
			</ListItem>
	);
}

function renderThreshold(component, thresholds, thresholdKey){
	return (
		<Threshold component={component} thresholds={thresholds} thresholdKey={thresholdKey} />
	);
}

function Threshold({component, thresholds, thresholdKey}){
	const measureUnits = useMeasureUnits();
	var viewing = component.state.viewing;
	var localization = component.state.localization;
	if(!viewing || !thresholdKey) return;

	var split = thresholdKey.split("_");

	var key = split[0];
	var index = split[1];
	var formatted = formatThreshold(thresholds, key, index, measureUnits, localization);
	var name = localization.get("user_thresholds_" + key);

	return (
		<ListItem key={thresholdKey} classes={listItemClasses} className="ae-plain">
				<ListItemText primary={name} secondary={formatted} />
				<IconButton classes={iconButtonClasses} disableRipple color="primary" aria-label={localization.get("edit_button")} title={localization.get("edit_button")} role="button"  className="fa fa-pencil-square-o" onClick={par(startEdit, component, key, index, measureUnits)}/>
				<IconButton classes={iconButtonClasses} disableRipple color="primary" aria-label={localization.get("remove_button")} title={localization.get("remove_button")} role="button"  className="fa fa-times-circle" onClick={par(startDelete, component, key, index)}/>
		</ListItem>
	);
}

function startEdit(component, key, index, measureUnits){

	var state = component.state;
	var viewing = state.viewing;
	if(!viewing) return;

	var current = state.userThresholds.get(state.viewing) || {};
	var currentThresholds = current[key];

	if(viewing === "group") currentThresholds = state.groupThresholds[key] || {};

	if(!currentThresholds) return;

	var update = {
		editing: key,
		editingIndex: index,
		currentIndicator: key
	};

	var thresholdUpdate = {};
	if(key === "bloodoxygen")
		thresholdUpdate = editBloodOxygen(currentThresholds);
	else if(key === "bloodpressure")
		thresholdUpdate = editBloodPressure(currentThresholds);
	else if(key === "bloodsugar")
		thresholdUpdate = editBloodSugar(currentThresholds, measureUnits.glucometerUnits);
	else if(key === "bodytemperature")
		thresholdUpdate = editBodyTemperature(currentThresholds, measureUnits.temperatureUnits);
	else if(key === "weight")
		thresholdUpdate = editWeight(currentThresholds, index, measureUnits.weightUnits);
	else if(key === "steps")
		thresholdUpdate = editSteps(currentThresholds);

	component.setState(xtend(update, thresholdUpdate));

	component.state.userMetrics.trackEvent(`user-thresholds: open edit ${key} thresholds popup`);
}

function editBloodOxygen(thresholds){
	var currentOxygen = thresholds.oxygensaturation || {};
	var currentHeartRate = thresholds.heartrate || {};
	return {
		bloodOxygenORA: currentOxygen.redAbove,
		bloodOxygenORB: currentOxygen.redBelow,
		bloodOxygenOYA: currentOxygen.yellowAbove,
		bloodOxygenOYB: currentOxygen.yellowBelow,
		bloodOxygenHRA: currentHeartRate.redAbove,
		bloodOxygenHRB: currentHeartRate.redBelow,
		bloodOxygenHYA: currentHeartRate.yellowAbove,
		bloodOxygenHYB: currentHeartRate.yellowBelow,
	};
}

function editBloodPressure(thresholds){
	var currentSystolic = thresholds.systolic || {};
	var currentDiastolic = thresholds.diastolic || {};
	var currentBPPulserate = thresholds.bpPulserate || {};

	return {
		bloodPressureSRA: currentSystolic.redAbove,
		bloodPressureSRB: currentSystolic.redBelow,
		bloodPressureSYA: currentSystolic.yellowAbove,
		bloodPressureSYB: currentSystolic.yellowBelow,
		bloodPressureDRA: currentDiastolic.redAbove,
		bloodPressureDRB: currentDiastolic.redBelow,
		bloodPressureDYA: currentDiastolic.yellowAbove,
		bloodPressureDYB: currentDiastolic.yellowBelow,
		bloodPressurePRA: currentBPPulserate.redAbove,
		bloodPressurePRB: currentBPPulserate.redBelow,
		bloodPressurePYA: currentBPPulserate.yellowAbove,
		bloodPressurePYB: currentBPPulserate.yellowBelow,
	};
}

function editBloodSugar(thresholds, units){
	var current = thresholds[units];
	if(!current) return {};

	return {
		bloodSugarRA: current.redAbove,
		bloodSugarRB: current.redBelow,
		bloodSugarYA: current.yellowAbove,
		bloodSugarYB: current.yellowBelow,
	};
}

function editBodyTemperature(thresholds, units){
	var current = thresholds[units];
	if(!current) return {};

	return {
		bodyTempRA: current.redAbove,
		bodyTempRB: current.redBelow,
		bodyTempYA: current.yellowAbove,
		bodyTempYB: current.yellowBelow,
	};
}

function editWeight(thresholds, index, units){
	var current = thresholds[units];
	if(!current) return {};

	var indexThreshold = current[index];
	if(!indexThreshold) return {};

	return {
		weightType: indexThreshold.type,
		weightValue: indexThreshold.value,
		weightStatus: indexThreshold.status,
		weightDays: indexThreshold.days
	};
}

function editSteps(thresholds){
	return {
		stepRA: thresholds.redAbove,
		stepRB: thresholds.redBelow,
		stepYA: thresholds.yellowAbove,
		stepYB: thresholds.yellowBelow
	};
}

function submitDelete(component){
	var state = component.state;
	var remove = state.remove;
	var deleting = state.deleting;
	var deletingIndex = state.deletingIndex;
	var viewing = state.viewing;
	var current = state.userThresholds.get(viewing);
	if(viewing === "group") current = state.groupThresholds || {};

	if(!viewing || !deleting) return;
	remove(current, deleting, deletingIndex, viewing);
	cancelDelete(component);

	component.state.userMetrics.trackEvent("user-thresholds: remove threshold", {
		patient: viewing,
		threshold: deleting,
	});
}

function submitDeleteContact(component){
	var state = component.state;
	var remove = state.removeContact;
	var deleting = state.deletingContact;
	var viewing = state.viewing;
	var current = state.userThresholds.get(viewing);
	if(viewing === "group") current = state.groupThresholds || {};

	if(!viewing) return;
	remove(viewing, current, deleting);
	cancelDeleteContact(component);

	component.state.userMetrics.trackEvent("user-thresholds: remove mobile alert contact", {
		patient: viewing,
		contact: deleting,
	});
}

function startDeleteContact(component, contact){
	component.setState({
		deletingContact: contact
	});
	component.state.userMetrics.trackEvent("user-thresholds: open remove mobile alert contact popup");
}

function cancelDeleteContact(component){
	component.setState({
		deletingContact: null
	});
	component.state.userMetrics.trackEvent("user-thresholds: close remove mobile alert contact popup");
}

function startDelete(component, key, index){
	component.setState({
		deleting: key,
		deletingIndex: index
	});
	component.state.userMetrics.trackEvent("user-thresholds: open remove threshold popup");
}

function cancelDelete(component){
	component.setState({
		deleting: null,
		deletingIndex: null
	});
	component.state.userMetrics.trackEvent("user-thresholds: close remove threshold popup");
}

function startAdding(component){
	component.setState({
		adding: true
	});
	component.state.userMetrics.trackEvent("user-thresholds: open add threshold popup");
}

function setViewing(component, id){
	component.setState({
		viewing: id,
		patientSelect: false
	});
	component.state.userMetrics.trackEvent("user-thresholds: view thresholds for patient", {
		viewing: id
	});
}

function showSearch(component) {
	component.refs.searchDialog.show();
	component.state.userMetrics.trackEvent("user-thresholds: open search for mobile alert contact popup");
}

function formatThreshold(thresholds, thresholdKey, index, measureUnits, localization){

	if(thresholdKey === "weight")
		return formatWeight(thresholds[thresholdKey], measureUnits.weightUnits, index, localization);
	else if (thresholdKey === "bloodoxygen")
		return formatBloodOxygen(thresholds[thresholdKey], localization);
	else if (thresholdKey === "bodytemperature")
		return formatBodyTemperature(thresholds[thresholdKey], measureUnits.temperatureUnits, localization);
	else if (thresholdKey === "bloodsugar")
		return formatBloodSugar(thresholds[thresholdKey], measureUnits.glucometerUnits, localization);
	else if (thresholdKey === "steps")
		return formatSteps(thresholds[thresholdKey], localization);
	else if (thresholdKey === "bloodpressure")
		return formatBloodPressure(thresholds[thresholdKey], localization);
	else return localization.get("user_thresholds_" + thresholdKey);
}

function formatWeight(threshold, units, index, localization){
	var unitThreshold = threshold[units] || "kg";
	var indexThreshold = unitThreshold[index] || {};

	var gainLoss = indexThreshold.value < 0 ? localization.get("user_thresholds_lossof") : localization.get("user_thresholds_gainof");
	var type = "";
	if(indexThreshold.type === "days")
		type = localization.get("user_thresholds_days") + " " + indexThreshold.days + " " + localization.get("user_thresholds_daysText");
	else if(indexThreshold.type === "consecutive")
		type = localization.get("user_thresholds_consecutive");
	else
		gainLoss = localization.get("user_thresholds_" + indexThreshold.type);

	return `${gainLoss} ${indexThreshold.value} ${units} ${type}`;
}

function formatBloodOxygen(threshold){
	var oxygensat = threshold.oxygensaturation || {};
	var heartrate = threshold.heartrate || {};

	var oRedAbove = oxygensat.redAbove ? `> ${oxygensat.redAbove}%` : "";
	var oRedBelow = oxygensat.redBelow ? `${oxygensat.redBelow}% <` : "";
	var oYellowAbove = oxygensat.yellowAbove ? `> ${oxygensat.yellowAbove}%` : "";
	var oYellowBelow = oxygensat.yellowBelow ? `${oxygensat.yellowBelow}% <` : "";

	var hRedAbove = heartrate.redAbove ? `> ${heartrate.redAbove} bpm` : "";
	var hRedBelow = heartrate.redBelow ? `${heartrate.redBelow} bpm <` : "";
	var hYellowAbove = heartrate.yellowAbove ? `> ${heartrate.yellowAbove} bpm` : "";
	var hYellowBelow = heartrate.yellowBelow ? `${heartrate.yellowBelow} bpm <` : "";

	return `Oxygen: ${oRedBelow} 
				${oYellowBelow} 
				${oYellowAbove} 
				${oRedAbove}
				| Pulse: ${hRedBelow} 
				${hYellowBelow} 
				${hYellowAbove} 
				${hRedAbove}`;
}

function formatBodyTemperature(threshold, units){
	var unitThreshold = threshold[units] || {};

	var unitText = "°" + units[0].toUpperCase();

	var redAbove = unitThreshold.redAbove ? `> ${unitThreshold.redAbove} ${unitText}` : "";
	var redBelow = unitThreshold.redBelow ? `${unitThreshold.redBelow} ${unitText} <` : "";
	var yellowAbove = unitThreshold.yellowAbove ? `> ${unitThreshold.yellowAbove} ${unitText}` : "";
	var yellowBelow = unitThreshold.yellowBelow ? `${unitThreshold.yellowBelow} ${unitText} <` : "";

	return `${redBelow} ${yellowBelow} ${yellowAbove} ${redAbove}`;
}

function formatBloodSugar(threshold, units){
	var unitThreshold = threshold[units] || {};

	var redAbove = unitThreshold.redAbove ? `> ${unitThreshold.redAbove} ${units}` : "";
	var redBelow = unitThreshold.redBelow ? `${unitThreshold.redBelow} ${units} <` : "";
	var yellowAbove = unitThreshold.yellowAbove ? `> ${unitThreshold.yellowAbove} ${units}` : "";
	var yellowBelow = unitThreshold.yellowBelow ? `${unitThreshold.yellowBelow} ${units} <` : "";

	return `${redBelow} ${yellowBelow} ${yellowAbove} ${redAbove}`;
}

function formatSteps(threshold){
	var redAbove = threshold.redAbove ? `> ${threshold.redAbove}` : "";
	var redBelow = threshold.redBelow ? `${threshold.redBelow} <` : "";
	var yellowAbove = threshold.yellowAbove ? `> ${threshold.yellowAbove}` : "";
	var yellowBelow = threshold.yellowBelow ? `${threshold.yellowBelow} <` : "";
	return `${redBelow} ${yellowBelow} ${yellowAbove}  ${redAbove}`;
}

function formatBloodPressure(threshold){
	var systolic = threshold.systolic || {};
	var diastolic = threshold.diastolic || {};
	var bpPulserate = threshold.bpPulserate || {};

	var sRedAbove = systolic.redAbove ? `> ${systolic.redAbove} mmHg` : "";
	var sRedBelow = systolic.redBelow ? `${systolic.redBelow} mmHg <` : "";
	var sYellowAbove = systolic.yellowAbove ? `> ${systolic.yellowAbove} mmHg` : "";
	var sYellowBelow = systolic.yellowBelow ? `${systolic.yellowBelow} mmHg <` : "";

	var dRedAbove = diastolic.redAbove ? `> ${diastolic.redAbove} mmHg` : "";
	var dRedBelow = diastolic.redBelow ? `${diastolic.redBelow} mmHg <` : "";
	var dYellowAbove = diastolic.yellowAbove ? `> ${diastolic.yellowAbove} mmHg` : "";
	var dYellowBelow = diastolic.yellowBelow ? `${diastolic.yellowBelow} mmHg <` : "";

	var pRedAbove = bpPulserate.redAbove ? `> ${bpPulserate.redAbove} bpm` : "";
	var pRedBelow = bpPulserate.redBelow ? `${bpPulserate.redBelow} bpm <` : "";
	var pYellowAbove = bpPulserate.yellowAbove ? `> ${bpPulserate.yellowAbove} bpm` : "";
	var pYellowBelow = bpPulserate.yellowBelow ? `${bpPulserate.yellowBelow} bpm <` : "";

	return `Systolic: ${sRedBelow} 
				${sYellowBelow}  
				${sYellowAbove} 
				${sRedAbove} 
				| Diastolic: ${dRedBelow} 
				${dYellowBelow} 
				${dYellowAbove} 
				${dRedAbove} 
				| BP Pulse Rate: ${pRedBelow} 
				${pYellowBelow} 
				${pYellowAbove} 
				${pRedAbove} 
				`;
}

function renderUser(component, user) {
	if (!user._id) return;
	var avatar = <Avatar alt="User Avatar Image" src={config.image_cdn + user.image} />;
	var text = Utility.format_name(user);
	var userId = user._id;
	var background_colour = "ae-plain";
	var viewing = component.state.viewing;
	if (userId === viewing)
		background_colour = "ae-hover-color";

	return (
  <ListItem key={user._id} button classes={listItemClasses} className={background_colour} onClick={par(setViewing, component, userId)}>
  {avatar}
  <ListItemText primary={text} />
  </ListItem>
	);
}

function by_name(x, y){
	return order(x.lname.toLowerCase() + x.fname.toLowerCase(), y.lname.toLowerCase() + y.fname.toLowerCase());
}

function order(x, y){
	if(x < y)
		return -1;
	if(x > y)
		return 1;
	return 0;
}