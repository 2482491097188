/* © 2017 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, January 01, 2017
 * For information or permission request, email info@aetonixsystems.com
 */

"use strict";
var Store = require("./store");
var map = require("fj-map");

module.exports = AdminGroupStore;

function AdminGroupStore(api) {
	var store = new Store();
	reset();
	return store;

	function reset(){
		api.admin.orgGroup.list()
			.then(map(set_group));
	}

	function set_group(group){
		store.set(group._id, group);
	}
}
