/* © 2017 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, January 01, 2017
 * For information or permission request, email info@aetonixsystems.com
 */

"use strict";
var Store = require("./store");

module.exports = OrgAllGroupStore;

function OrgAllGroupStore(api) {
	var store = new Store();
	reset();
	return store;

	function reset(){
		return api.org.group.list(null, null)
			.then(set_store);
	}

	function set_store(groups){
		groups.forEach(group => store.set(group._id, group));
	}
}
