/* © 2014 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, June 19, 2014
 * For information or permission request, email info@aetonixsystems.com
 */



var par = require("par");
var React = require("react");
// eslint-disable-next-line no-unused-vars
var config = require("../../configs/config.json");
var cronParser = require("cron-parser");

var Table = require("@material-ui/1.5.1/Table").default;
var TableHeader = require("@material-ui/1.5.1/TableHead").default;
var TableHeaderColumn = require("@material-ui/1.5.1/TableCell").default;
var TableBody = require("@material-ui/1.5.1/TableBody").default;
var TableRow = require("@material-ui/1.5.1/TableRow").default;
var TableRowColumn = require("@material-ui/1.5.1/TableCell").default;
var TableSortLabel = require("@material-ui/1.5.1/TableSortLabel").default;

var Chip = require("@material-ui/1.5.1/Chip").default;
var Avatar = require("@material-ui/1.5.1/Avatar").default;
var FontIcon = require("@material-ui/1.5.1/Icon").default;

/**
 * User Interface elements Aetonix
 */
var Search = require("../shared/Search.jsx");
var Header = require("../shared/Header.js");
var Utility = require("../shared/Utility.js");
var Scroll = require("../shared/InfiniScroll.jsx");
var Colors = require("../shared/AetonixTheme").palette;

var styles = {
	date: {
		display: "flex",
		flexDirection: "column",
		color: Colors.primary.main
	},
	dateEntry: {
		marginBottom: "30px"
	},
	icon: {
		fontSize: 20,
		color: Colors.canvas
	},
	avatar: {
		"backgroundColor": Colors.primary.dark
	},
	back: {
		"backgroundColor": Colors.primary.light,
		color: Colors.primary.main
	},
	backPrimary: {
		"backgroundColor": "#E0E0E0"
	},
	avatarPrimary: {
		"backgroundColor": "#BCBCBC"
	},
	modalSelects: {
		display: "flex",
		flexDirection: "column",
		marginBottom: "15px"
	},
	inputProp: {
		style: {
			color: Colors.primary.main,
		}
	},
	selectProp: {
		PaperProps: {
			style: {
				transform: "translate3d(0, 0, 0)"
			}
		}
	},
	snackbar: {
		style: {
			backgroundColor: "red"
		}
	},
	tableContainer: {
		overflowX: "scroll",
		overflowY: "hidden"
	}
};

module.exports = render;

function render() {
	var component = this;
	var state = component.state;
	var localization = state.localization;
	var careplanNoticeList = state.careplanChangeList.all();
	var careplanChanges = !!careplanNoticeList.length;
	var offline = state.connection.get("offline");
	var currentPerson = state.currentPerson;

	var rowData = generate_data(component);
	var loadMore = state.more;

	var titleKey = "admin_tasks";

	var headers = [
		{
			name: "type",
			content: localization.get("tasks_type")
		},
		{
			name: "user",
			content: localization.get("tasks_user")
		},
		{
			name: "workflow",
			content: localization.get("tasks_workflow")
		},
		{
			name: "action",
			content: localization.get("tasks_action")
		},
		{
			name: "reaction",
			content: localization.get("tasks_reaction")
		},
		{
			name: "actor",
			content: localization.get("tasks_actor")
		},
		{
			name: "schedule",
			content: localization.get("tasks_schedule")
		},
		{
			name: "timezone",
			content: localization.get("tasks_timezone")
		},
		{
			name: "created",
			content: localization.get("tasks_created")
		},
		{
			name: "actions",
			content: localization.get("tasks_actions")
		}
	];

	if (dataHasChanged(rowData, state.oldRowData)) {
		component.state.userMetrics.trackEvent("admin-tasks: viewed admin tasks", {
			tasks: rowData.map((task) => task._id),
		});
		component.setState({oldRowData: rowData});
	}

	return (
		<div className="flex-vertical flex-1" style={styles.tableContainer}>

			<Header
				careplanChanges={careplanChanges}
				offline={offline}
				currentPerson={currentPerson}
				localization={localization}
				titleKey={titleKey}
			/>
			<div className="flex-horizontal ae-empty">
				{renderUserFilterChip(component)}
			</div>

			<Scroll loadMore={loadMore}>
				<Table>
					{renderHeader(component, headers)}
					{renderBody(component, rowData, headers)}
				</Table>
			</Scroll>

			<Search selection={state.filterUsers} action={par(state.filterUser, component)} ref="searchDialog" search={state.peopleSearch} localization={localization} />
		</div>

	);
}

function dataHasChanged(rowData, oldRowData) {
	rowData = rowData || [];
	oldRowData = oldRowData || [];
	if (rowData.length !== oldRowData.length) return true;
	rowData = rowData.map((r) => r._id);
	oldRowData = oldRowData.map((r) => r._id);
	return rowData.some((r) => oldRowData.indexOf(r) === -1);
}

function renderHeader(component, headers) {
	return (
		<TableHeader>
			<TableRow >
				{headers.map(par(renderHeaderColumn, component))}
			</TableRow>
		</TableHeader>
	);
}

function renderHeaderColumn(component, column, index) {
	return (
		<TableHeaderColumn key={index} className="flex-spread ae-headers-wrap">
			<TableSortLabel active={component.state.sort === column.name} direction={component.state.sortDirection} onClick={par(changeSort, component, column.name)}>
				{column.content}
			</TableSortLabel>
		</TableHeaderColumn>
	);
}

function renderBody(component, rows, headers) {
	return (
		<TableBody>
			{rows.map(par(renderRow, headers, component))}
		</TableBody>
	);
}

function renderRow(headers, component, row, index) {
	return (
		<TableRow key={index} >
			{headers.map(par(renderRowColumn, row, component))}
		</TableRow>
	);
}

function renderRowColumn(row, component, column, index) {
	var localization = component.state.localization;
	var chunk = row[column.name];
	return (
		<TableRowColumn key={index}>
			{column.name === "type" ? localization.get(chunk) : chunk}
		</TableRowColumn>
	);
}

/**
 * This function generates the data that populates the table.
 * @param  {[type]} people	[description]
 * @param  {[type]} reminders [description]
 * @param  {[type]} history   [description]
 * @return {[type]}		   [description]
 */
function generate_data(component) {
	var state = component.state;

	var localization = state.localization;
	var people = state.people;
	var tasks = state.tasks.all();
	var language = state.currentPerson.get("personal").language;

	var ongoing = state.workflowOngoing;
	var definition = state.workflowDefinitions;

	if (!tasks || !ongoing || !definition) return;

	return tasks.map((task) => {
		var user = Utility.format_name(people.get(task.who || task.ongoingWho)) || "--";
		var actor = task.actor || "--";
		var action = task.action || "--";
		var reaction = task.reaction || "--";

		var taskOngoing = task.ongoingWorkflow;

		var workflow = "--";

		if(taskOngoing){
			var taskOngoingDef = ongoing.get(taskOngoing);
			if(taskOngoingDef || Object.keys(taskOngoingDef).length){
				var workflowDefinition = definition.get(taskOngoingDef.workflow);
				if(workflowDefinition){
					workflow = getName(workflowDefinition, language, localization);
				}
			}
		}

		var scheduleDate;

		try {
			cronParser.parseExpression(task.schedule);
			const cronSplit = task.schedule?.split(" ");
			if(cronSplit[3] !== "*")
				cronSplit[3] = parseInt(cronSplit[3], 10) + 1;
			const finalCron = cronSplit.join(" ");
			var expression = cronParser.parseExpression(finalCron);
			scheduleDate = new Date(expression.next().toString());
		} catch (e) {
			scheduleDate = new Date(task.schedule);
		}

		var schedule = scheduleDate.toString() === "Invalid Date" ? "--" : formatDate(scheduleDate);
		var createdAt = task.created_at ? formatDate(new Date(task.created_at)) : "--";
		var timezone = task.timezone ? task.timezone.replace("_", " ") : "--";

		return {
			_id: task._id,
			type: localization.get(getType(task)),
			user: user,
			actor: actor,
			action: action,
			reaction: reaction,
			workflow: workflow,
			schedule: schedule,
			timezone: timezone,
			created: createdAt,
			actions: renderActions(component, task)
		};
	}).sort(par(sortTasks, state.sort, state.sortDirection));
}

function renderActions(component, task){
	var localization = component.state.localization;
	var state = component.state;

	return (
		<div className="flex-horizontal">
			<Chip className=""
				style={styles.backPrimary}
				onClick={par(state.runTask, task._id, component)}
				label={localization.get("tasks_run")}
			/>
			<Chip className="ae-icon ae-fonticon"
				style={styles.backPrimary}
				onClick={par(state.removeTask, task._id, component)}
				label={localization.get("tasks_remove")}
			/>
		</div>
	);
}

function formatDate(date, language){
	var options = {
		year: "numeric",
		month: "2-digit",
		day: "2-digit",
		hour: "2-digit",
		minute: "2-digit",
		hour12: true
	};

	var locale = language;
	if(language === "cn_s")
		locale = "zh-CN";
	else if(language === "cn_t")
		locale = "zh-HK";
	return date.toLocaleDateString(locale, options);
}

function getName(workflow, language, localization){
	var schema = workflow.schema || {};
	var workflowDescription = schema.description || {};
	var keys = Object.keys(workflowDescription);
	var description = workflowDescription[language] || workflowDescription[keys[0]] || localization.get("org_workflow_untitled");
	return description;
}

function getType(task){
	var type = task.type;
	if(type.indexOf("task:compliant") !== -1)
		return "tasks_compliant";
	else if (type.indexOf("task:livechatschedule") !== -1)
		return "tasks_livechatschedule";
	else if (type.indexOf("task:reminder") !== -1)
		return "tasks_reminder";
	else if (type.indexOf("task:schedule") !== -1)
		return "tasks_availability";
	else if (type.indexOf("task:workflow") !== -1)
		return "tasks_workflow_action";
	else if (type.indexOf("task:workflow:reaction") !== -1)
		return "tasks_workflow_reaction";
	else
		return "tasks_unknown";
}

function renderUserFilterChip(component) {
	var state = component.state;
	var deleteOn = noop();
	var styleavatar = styles.avatarPrimary;
	var styleback = styles.backPrimary;

	if (state.filterUsers.length) {
		deleteOn = par(clearSearch, component);
		styleavatar = styles.avatar;
		styleback = styles.back;
	}
	return (
		<Chip className="ae-icon ae-fonticon"
			onDelete={deleteOn}
			style={styleback}
			onClick={par(showSearch, component)}
			avatar={<Avatar style={styleavatar}>
				<FontIcon className="fa fa-filter fa-2x" style={styles.icon} />
			</Avatar>}
			label={renderFilterLabel(component)}
		/>
	);
}

function showSearch(component) {
	component.refs.searchDialog.show();
	component.setState({userFilter: true});
	component.state.userMetrics.trackEvent("admin-tasks: open filter users popup");
}

function clearSearch(component) {
	component.state.clearUserFilter(component);
}

function renderFilterLabel(component) {
	var state = component.state;
	var localization = state.localization;
	var label;

	var length = state.filterUsers.length;
	if (!length) return (localization.get("search_user"));
	label = localization.get("search_user") + " (" + length + ")";
	return label;
}

function changeSort(component, name){
	var sort = component.state.sort;
	var direction = component.state.sortDirection;

	if(sort !== name)
		direction = "asc";
	else
		direction = direction === "asc" ? "desc" : "asc";

	component.setState({
		sort: name,
		sortDirection: direction
	});
	component.state.userMetrics.trackEvent(`admin-tasks: sort ${name}`, {
		direction: direction
	});
}

function sortTasks(sort, direction, a, b) {
	var taskASort = a[sort] ? a[sort].toLowerCase() : "";
	var taskBSort = b[sort] ? b[sort].toLowerCase() : "";
	if (direction === "asc") return taskASort < taskBSort ? 1 : -1;
	return taskASort > taskBSort ? 1 : -1;
}

function noop(){}
