"use strict";

var Store = require("./store");
var par = require("par");
var map = require("fj-map");

import * as Sentry from '@sentry/react';

module.exports = GroupManageGroupManagers;

function GroupManageGroupManagers(api, events, config) {
	const store = new Store();
	let tracking = [];

	store.raw_get = store.get;
	store.get = get;
	store.initAll = initAll;

	return store;

	function initAll() {
		api.org.managergroups.list()
			.then(map(initGroup));
	}

	function initGroup(group) {
		return track(group._id);
	}

	function get(id){
		var existing = store.raw_get(id);
		if(existing) return existing;

		if(!isTracking(id))
			track(id);

		return [];
	}

	function isTracking(id){
		return tracking.indexOf(id) !== -1;
	}

	function track(id){
		tracking.push(id);
		api.org.managergroups.managers.list(id)
			.then(par(setManagers, id))
			.then(trackGroup)
			.catch(error => {
				Sentry.captureException(error);
				console.error(error);
			});
	}

	function setManagers(id, managers){
		store.set(id, managers);
		return id;
	}

	function trackGroup(id){
		const orgGroup = config.orgGroup;
		if(!orgGroup) return;

		events.on(`mqtt:orggroup/${orgGroup}/managegroup/${id}/managers/added`, par(handle_manager_added, id));
		events.on(`mqtt:orggroup/${orgGroup}/managegroup/${id}/managers/removed`, par(handle_manager_removed, id));
	}


	function handle_manager_added(id, data){
		const current = store.raw_get(id);
		const newManagers = current.concat(data.manager);
		store.set(id, newManagers);
	}

	function handle_manager_removed(id, data){
		const current = store.raw_get(id);
		const newManagers = current.filter((manager) => manager !== data.manager);
		store.set(id, newManagers);
	}
}
