import * as Sentry from '@sentry/react';

export function scrubBreadcrumbs(breadcrumb) {
    if (breadcrumb.request) {
        // eslint-disable-next-line node/no-unsupported-features/node-builtins
        const params = new URLSearchParams(breadcrumb.request.query_string);

        if (params.get("token")) {
            params.set("token", "****");
        }

        breadcrumb.request.query_string = params.toString();
    }

    if (breadcrumb.category === "console") {
        return null;
    }

    // Don't report warnings
    if (breadcrumb.level === Sentry.Severity.Warning) {
        return null;
    }

    return breadcrumb;
}

export function scrubEvents(event) {
    if (event.request) {
        // eslint-disable-next-line node/no-unsupported-features/node-builtins
        const params = new URLSearchParams(event.request.query_string);

        if (params.get("token")) {
            params.set("token", "****");
        }

        event.request.query_string = params.toString();
    }

    // Don't report warnings
    if (event.level == Sentry.Severity.Warning) {
        return null;
    }

    return event;
}