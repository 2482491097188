/* © 2014 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, June 19, 2014
 * For information or permission request, email info@aetonixsystems.com
 */

"use strict";

var Store = require("./store");
var map = require("fj-map");
var compose = require("fj-compose");
var pluck = require("fj-pluck");
var getWho = pluck("who");
var Promise = require("promise");

import * as Sentry from '@sentry/react';

module.exports = UserStore;

function UserStore(api, events, config) {
	var store = new Store();

	listen_for_changes(config);

	var query = "";
	var page = 0;
	var status = "";
	let state = "";
	var loading = null;
	var atEnd = false;
	var loadingAll = false;
	let manageGroups = [];

	store.more = more;
	store.search = search;
	store.searchStatus = searchStatus;
	store.searchManageGroup = searchManageGroup;
	store.filterSearch = filterSearch;
	store.getAtEnd = getAtEnd;
	store.loadAllUser = loadAllUser;

	reset();

	return store;

	function reset() {
		page = 0;
		atEnd = false;
		store.clear();

		const filters = { query, status, state, manageGroups };
		return api.org.users
			.listWithFilters(filters, { page })
			.then(map(add_person));
	}

	function more() {
		if (atEnd){
			return Promise.resolve();
		}

		if (loading){
			return loading;
		}

		page += 1;
		loading = true;

		const filters = { query, status, state, manageGroups };
		return api.org.users
			.listWithFilters(filters, { page })
			.then(handle_users);
	}

	function handle_users(users) {
		loading = false;

		if (!users.length) {
			atEnd = true;
			loadingAll = false;
			return;
		}

		users.map(add_person);

		if(loadingAll) {
			more();
		}
	}

	function search(newQuery) {
		query = newQuery;
		return reset();
	}

	// Does a search on a user's status ie. active/inactive.  Null or empty string
	// argument applies no filter
	function searchStatus(newStatus) {
		status = newStatus;
		return reset();
	}

	// apply filter to the query
	function filterSearch(options) {
		if (options.query) query = options.query;
		if (options.manageGroups) manageGroups = options.manageGroups;
		if (options.state) {
			status = "";
			state = [options.state].flat();
			const hasAllActive = state.includes("all_active");
			const hasInActive = state.includes("inactive");
			if (hasAllActive) {
				state = [];
				if (hasInActive) {
					// if both "all_active" & "inactive" options are checked, then the list should
					// not be filtered by state/status
					status = "";
				} else {
					// if "all_active" is selected but "inactive" is not, then all users with
					// inactive=false should be listed
					status = false;
				}
			}
		}
		return reset();
	}

	/**
	 * Sets the manage groups by which the users list should be filtered by.
	 */
	function searchManageGroup(manageGroupIds) {
		manageGroups = manageGroupIds || [];
		return reset();
	}

	function add_person(id) {
		store.set(id, id);
	}

	function remove_person(id) {
		store.remove(id);
	}

	function listen_for_changes(config) {
		if (!config?.orgGroup) {
			return;
		}

		const { orgGroup } = config;

		events.on("mqtt:orggroup/" + orgGroup + "/users/added", compose(add_person, getWho));
		events.on("mqtt:orggroup/" + orgGroup + "/users/removed", compose(remove_person, getWho));
	}

	function getAtEnd() {
		return atEnd;
	}

	function loadAllUser() {
		loadingAll = true;
		more();
	}
}
