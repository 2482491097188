/* © 2016 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, January 2016
 * For information or permission request, email info@aetonixsystems.com
 */

var par = require("par");

var defineUI = require("../shared/define");
var render = require("./render.jsx");

var PersonStore = require("../stores/person_store");
var UserStore = require("../stores/user_active_store");
var UserUrgencyStore = require("../stores/user_urgency_store");
var UserUrgencyPreferenceStore = require("../stores/user_urgency_preference_store");
var StaffStore = require("../stores/staff_store");
var LocalizationStore = require("../stores/localization_store");
var CurrentPersonInfoStore = require("../stores/current_person_info_store");
var CareplanNotificationStore = require("../stores/careplan_notice_store");
var ConnectionStore = require("../stores/connection_store");

var makeStores = require("../shared/stores")({
	people: PersonStore,
	currentPerson: CurrentPersonInfoStore,
	localization: LocalizationStore,
	users: UserStore,
	urgency: UserUrgencyStore,
	urgencyPreferences: UserUrgencyPreferenceStore,
	staff: StaffStore,
	careplanChangeList: CareplanNotificationStore,
	connection: ConnectionStore
});

function make_ui(api, events, config, userMetrics) {
	var storeStore = makeStores(api, events, config);

	window.stores = storeStore.store;

	userMetrics.trackEvent("page view", {
		"page": "user-urgency",
		"category": "user",
		"permission level": "staff",
	});

	return defineUI({
		getInitialState: getInitialState,
		componentDidMount: listen_for_changes,
		render: render,
		componentWillUnmount: removeListeners
	});

	function removeListeners() {
		events.off();
	}

	function listen_for_changes() {
		storeStore.listen(par(handle_change, this));
	}

	function handle_change(component, stores) {
		component.setState({
			people: stores.people,
			currentPerson: stores.currentPerson,
			localization: stores.localization,
			urgency: stores.urgency,
			urgencyPreferences: stores.urgencyPreferences,
			users: stores.users.all(),
			staff: stores.staff.all(),
			careplanChangeList: stores.careplanChangeList,
			connection: stores.connection
		});
	}

	function getInitialState() {
		return {
			people: storeStore.stores.people,
			currentPerson: storeStore.stores.currentPerson,
			localization: storeStore.stores.localization,
			urgency: storeStore.stores.urgency,
			urgencyPreferences: storeStore.stores.urgencyPreferences,
			viewing: null,
			toRemove: null,
			searching: false,
			users: [],
			staff: [],
			addContact: addContact,
			removeContact: removeContact,
			setOrdered: setOrdered,
			search: doSearch,
			selectedIndex: 0,
			loadMore: par(loadMore, storeStore.stores.users),
			searchExist: par(searchExist, storeStore.stores.users),
			careplanChangeList: storeStore.stores.careplanChangeList,
			connection: storeStore.stores.connection,
			userMetrics: userMetrics,
		};
	}

	function loadMore(user){
		return user.more();
	}

	function searchExist(users, event){
    event.persist();
    var query = event.target.value;
		return users.search(query);
	}

	function doSearch(query) {
		return api.people.search(query);
	}

	function addContact(user, who) {
		userMetrics.trackEvent("user-urgency: add urgency contact", {
			patient: who,
			"urgency contact": user
		});
		return api.org.urgency.addStaff(user, who);
	}

	function removeContact(user, who) {
		return api.org.urgency.remove(user, who);
	}

	function setOrdered(user, ordered) {
		return api.org.urgency.preferences.update(user, {
			ordered: ordered
		});
	}

}

module.exports = make_ui;
