
/* © 2017 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, January 01, 2017
 * For information or permission request, email info@aetonixsystems.com
 */

"use strict";


var Store = require("./store");
var par = require("par");

module.exports = UserManagerStore;

function UserManagerStore(api, events, config) {
	var store = new Store();

	store.raw_get = store.get;
	store.get = get;

	api.org.group.current.id()
		.then(listen_for_changes);

	var tracking = [];

	return store;

	function get(id) {
		var existing = store.raw_get(id);
		if (existing) return existing;

		if (!isTracking(id))
			track(id);

		return [];
	}

	function isTracking(id) {
		return tracking.indexOf(id) !== -1;
	}

	function listen_for_changes(group){
		events.on("mqtt:orggroup/" + group + "/resource/user/added", addResource);
		events.on("mqtt:orggroup/" + group + "/resource/user/removed", removeResource);
		events.on("mqtt:orggroup/" + group + "/resource/user/set", setUserResources);
	}

	function track(id) {
		tracking.push(id);
		api.org.resources.user.list(id).then(par(setResources, id));
	}

	function setUserResources(data){
		var id = data.who;
		var resources = data.resources;
		setResources(id, resources);
	}

	function removeResource(data) {
		var id = data.who;
		var resource = data.resource;
		var existing = store.raw_get(id);
		if (!existing) return;

		var filtered = existing.filter(function (existingResource) {
			return existingResource !== resource;
		});

		setResources(id, filtered);
	}

	function addResource(data) {
		var id = data.who;
		var resource = data.resource;
		var existing = store.raw_get(id);
		if (!existing) return;
		if(existing.indexOf(resource) !== -1) return;

		var added = existing.concat(resource);

		setResources(id, added);
	}

	function setResources(id, resources){
		store.set(id, resources);
	}
}
