
/* © 2017 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, January 01, 2017
 * For information or permission request, email info@aetonixsystems.com
 */

var par = require("par");

var defineUI = require("../shared/define");
var render = require("./render.jsx");

var CallHistoryStore = require("../stores/call_history_store");
var CallLogStore = require("../stores/call_log_store");
var PersonStore = require("../stores/person_store");
var LocalizationStore = require("../stores/localization_store");
var CurrentPersonInfoStore = require("../stores/current_person_info_store");
var ConnectionStore = require("../stores/connection_store");

var makeStores = require("../shared/stores")({
	people: PersonStore,
	calls: CallHistoryStore,
	logs: CallLogStore,
	currentPerson: CurrentPersonInfoStore,
	localization: LocalizationStore,
	connection: ConnectionStore
});

function make_ui(api, events, config, userMetrics) {
	var storeStore = makeStores(api, events, config);

	window.stores = storeStore.store;

	userMetrics.trackEvent("page view", {
		"page": "admin-calls",
		"category": "admin",
		"permission level": "admin",
	});

	return defineUI({
		getInitialState: getInitialState,
		componentDidMount: listen_for_changes,
		render: render,
		componentWillUnmount: removeListeners
	});

	function removeListeners() {
		events.off();
	}

	function listen_for_changes() {
		storeStore.listen(par(handle_change, this));
	}

	function handle_change(component, stores) {
		component.setState({
			calls: stores.calls.sorted(),
			people: stores.people,
			logs: stores.logs,
			currentPerson: stores.currentPerson,
			localization: stores.localization,
			connection: stores.connection
		});
	}

	function getInitialState() {
		return {
			calls: storeStore.stores.calls.sorted(),
			people: storeStore.stores.people,
			currentPerson: storeStore.stores.currentPerson,
			logs: storeStore.stores.logs,
			localization: storeStore.stores.localization,
			connection: storeStore.stores.connection,
			details: false,
			more: more,
			filterCallers: filterCallers,
			filterRecipients: filterRecipients,
			filterClear: filterClear,
			filterDate: filterDate,
			filterStatus: filterStatus,
			closeCallerSearch: par(closeCallerSearch, this),
			closeRecipientSearch: par(closeRecipientSearch, this),
			searchCaller: searchCaller,
			searchRecipient: searchRecipient,
			report: false,
			callersFilter: false,
			recipientsFilter: false,
			statusFilter: false,
			dateFilter: false,
			callers: [],
			recipients: [],
			callstatus: "",
			startDate: "",
			endDate: new Date(),
			userMetrics: userMetrics
		};
	}

	function more() {
		storeStore.stores.calls.more();
	}

	/**
	 * Both functions return a list of _any_ person in the system
	 * matching the criteria.  Note the functions are IDENTICAL but that's
	 * because you may want to change one in future.
	 * @param  {objectId} query User_id
	 * @return {array}       User _id's
	 */
	function searchCaller(query) {
		return api.people.search(query);
	}
	function searchRecipient(query) {
		return api.people.search(query);
	}

	function filterCallers(component, data) {
		userMetrics.trackEvent("filter calls by caller", {
			"caller": data
		});
		var callers = component.state.callers;
		component.setState({callers: callers.concat(data)});
		storeStore.stores.calls.searchCallers(data);
	}

	function filterRecipients(component, data) {
		userMetrics.trackEvent("filter calls by recipient", {
			"recipient": data
		});
		var recipients = component.state.recipients;
		component.setState({recipients: recipients.concat(data)});
		storeStore.stores.calls.searchRecipients(data);
	}

	function filterStatus(data) {
		storeStore.stores.calls.searchStatus(data);
	}

	function filterDate(start, end) {
		userMetrics.trackEvent("filter calls by date", {
			"start": start,
			"end": end
		});
		storeStore.stores.calls.searchDate(start, end);
	}

	function filterClear(name) {
		storeStore.stores.calls.clearSearch(name);
	}

	function closeCallerSearch(component) {
		component.setState({callersFilter: false});
	}

	function closeRecipientSearch(component) {
		component.setState({recipientsFilter: false});
	}

}


module.exports = make_ui;
