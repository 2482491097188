/* © 2014 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, June 19, 2014
 * For information or permission request, email info@aetonixsystems.com
 */

var par = require("par");

var defineUI = require("../shared/define");
var render = require("./render.jsx");

var PersonStore = require("../stores/person_store");
var UserStore = require("../stores/user_active_store");
var UserManagerStore = require("../stores/user_manager_store");
var LocalizationStore = require("../stores/localization_store");
var CurrentPersonInfoStore = require("../stores/current_person_info_store");
var PermissionsStore = require("../stores/permissions_store");
var CareplanNotificationStore = require("../stores/careplan_notice_store");
var ConnectionStore = require("../stores/connection_store");
var UserFormsStore = require("../stores/user_form_store");
var DefaultManagerPermissions = require("../stores/org_group_default_manager_permissions");
var PresetManagerPermissions = require("../stores/org_group_preset_manager_permissions");

var makeStores = require("../shared/stores")({
	people: PersonStore,
	currentPerson: CurrentPersonInfoStore,
	localization: LocalizationStore,
	users: UserStore,
	managers: UserManagerStore,
	permissions: PermissionsStore,
	careplanChangeList: CareplanNotificationStore,
	connection: ConnectionStore,
	userForms: UserFormsStore,
	managerDefault: DefaultManagerPermissions,
	managerPreset: PresetManagerPermissions
});

function make_ui(api, events, config, userMetrics) {
	var storeStore = makeStores(api, events, config);

	userMetrics.trackEvent("page view", {
		"page": "user-management",
		"category": "user",
		"permission level": "staff",
	});

	return defineUI({
		getInitialState: getInitialState,
		componentDidMount: listen_for_changes,
		render: render,
		componentWillUnmount: removeListeners
	});

	function removeListeners() {
		events.off();
	}

	function listen_for_changes() {
		storeStore.listen(par(handle_change, this));
	}

	function handle_change(component, stores) {
		component.setState({
			userForms: stores.userForms.all(),
			people: stores.people,
			managers: stores.managers,
			users: stores.users.all(),
			currentPerson: stores.currentPerson,
			localization: stores.localization,
			permissions: stores.permissions,
			careplanChangeList: stores.careplanChangeList,
			connection: stores.connection,
			managerDefault: stores.managerDefault.get("default"),
			managerPreset: stores.managerPreset.get("preset")
		});
	}

	function getInitialState() {
		return {
			people: storeStore.stores.people,
			currentPerson: storeStore.stores.currentPerson,
			managers: storeStore.stores.managers,
			loadMore: par(loadMore, storeStore.stores.users),
			localization: storeStore.stores.localization,
			permissions: storeStore.stores.permissions,
			userForms: [],
			viewing: null,
			toRemove: null,
			users: [],
			filteredManagers: [],
			addManagerMulti: addManagerMulti,
			getPresets: getPresets,
			removeManager: removeManager,
			updatePermissions: updatePermissions,
			updateDefaultPermissions: updateDefaultPermissions,
			search: doSearch,
			searchExist: par(searchExist, storeStore.stores.users),
			selectedIndex: 0,
			permissionUpdates: {},
			defaultPermissionUpdates: {},
			careplanChangeList: storeStore.stores.careplanChangeList,
			connection: storeStore.stores.connection,
			managerDefault: storeStore.stores.managerDefault.get("default"),
			managerPreset: storeStore.stores.managerPreset.get("preset"),
			permissionTabValue: "default",
			createPresetPermission: createPresetPermission,
			updatePresetPermission: updatePresetPermission,
			removePresetPermission: removePresetPermission,
			bulkUpdateTabValue: "add",
			userMetrics: userMetrics,
		};
	}

	function loadMore(user) {
		return user.more();
	}

	function searchExist(users, event) {
		event.persist();
		var query = event.target.value;
		return users.search(query);
	}


	function doSearch(query) {

		var filter = {
			type: {
				exclude: ["atouchaway", "user:mobile", "simplified"]
			}
		};

		return api.people.search(query, filter);
	}

	function addManagerMulti(user, managers, permissions) {
		return api.org.manage.addMulti([user], managers, permissions);
	}

	function getPresets() {
		return api.org.manage.presetPermissions.list();
	}

	function removeManager(user, manager) {
		return api.org.manage.remove(user, manager);
	}

	function updatePermissions(user, manager, permissions) {
		return api.org.manage.permissions.update(user, manager, permissions);
	}

	function updateDefaultPermissions(permissions){
		return api.org.manage.defaultPermissions.set(permissions);
	}

	function createPresetPermission(data){
		return api.org.manage.presetPermissions.add(data);
	}

	function updatePresetPermission(id, data){
		return api.org.manage.presetPermissions.update(id, data);
	}

	function removePresetPermission(id){
		return api.org.manage.presetPermissions.remove(id);
	}
}

module.exports = make_ui;
