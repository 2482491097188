/* © 2017 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, January 01, 2017
 * For information or permission request, email info@aetonixsystems.com
 */

"use strict";


var Store = require("./store");
var par = require("par");
var xtend = require("xtend");

module.exports = AdminFormStore;

function AdminFormStore(api, events, config) {
	var store = new Store();

	store.raw_get = store.get;
	store.get = get;

	var tracking = [];

	return store;

	function get(id) {
		var existing = store.raw_get(id);
		if (existing) return existing;

		if (!isTracking(id))
			track(id);

		return [];
	}

	function isTracking(id) {
		return tracking.indexOf(id) !== -1;
	}

	function track(id) {
		tracking.push(id);
		api.admin.forms.list(id).then(par(setForms, id));
		events.on("mqtt:org/" + id + "/forms/definitions/removed", par(removeForm, id));
		events.on("mqtt:org/" + id + "/forms/definitions/created", par(addForm, id));
		events.on("mqtt:org/" + id + "/forms/definitions/updated", par(updateForm, id));
	}

	function removeForm(id, data) {
		var form = data;
		var existing = store.get(id);
		if (!existing) return;
		var filtered = existing.filter(function (existingForm) {
			return existingForm._id !== form._id;
		});

		store.set(id, filtered);
	}

	function addForm(id, data) {
		var form = data;
		var existing = store.get(id);
		if (!existing) return;

		var added = existing.concat(form);

		store.set(id, added);
	}

	function updateForm(id, data) {
		var form = data;
		var existing = store.get(id);
		if (!existing) return;

		var updated = existing.map(function (currentForm) {
			if (form._id === currentForm._id)
				return xtend(currentForm, form);
			return currentForm;
		});

		store.set(id, updated);
	}

	function setForms(id, forms) {
		store.set(id, forms);
	}

}
