import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { createFormData, formData, validationSchema } from "./library";

import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import ListSubHeader from "@material-ui/core/ListSubheader";
import FormHelperText from "@material-ui/core/FormHelperText";

import { withStyles, styled } from "@material-ui/core/styles";
import { TextareaAutosize, FormLabel } from "@material-ui/core";

const StyledSelect = withStyles({
	outlined: {
		padding: "10.5px 14px"
	}
})(Select);

const StyledTextareaAutosize = styled(TextareaAutosize)({
	width: "100%",
	border: "1px solid #88A4B0",
	borderRadius: "4px",
	fontFamily: "Poppins",
	fontSize: "16px",
	fontWeight: 400,
	color: "#102543",
	resize: "none",
	"&:disabled": {
		backgroundColor: "#00000033",
		borderRadius: "4px",
	},
	minHeight: "80px",
	rows: 3,
	cols: 40,
	wordWrap: "break-word",
	whiteSpace: "pre-line",
	marginTop: -10
});

const StyledFormHelperText = withStyles({
	error: {
		paddingLeft: "14px",
		paddingRight: "14px"
	}
})(FormHelperText);

const PatientCreation = ({
	component,
	showSearchDialog,
	showEmrSearch,
	submitRef,
	doCreate,
	localization,
	renderAddCare
}) => {
	const state = component.state;
	const manageGroup = state.manageGroups;
	const genders = createFormData("gender", localization);
	const langs = createFormData("languages", localization);
	const regions = createFormData("regions", localization);
	const countries = createFormData("country", localization);
	const types = createFormData("types", localization);
	const zones = createFormData("timezones", localization);

	const [dateChanged, setDateChanged] = useState(false);

	const formik = useFormik({
		initialValues: {
			fname: "",
			lname: "",
			gender: "",
			dob: "",
			language: "",
			user_type: "",
			street: "",
			apartment: "",
			country: "",
			city: "",
			province: "",
			postal: "",
			email: "",
			phone: "",
			phone2: "",
			timezone: "",
			healthCardNumber: "",
			patientNumber: "",
			managegroup: [],
			managers: [],
			user_note: ""
		},
		validateOnChange: true,
		validationSchema: validationSchema,
		onSubmit: values => {
			const final = { ...values, managers: state.careCoordinators || [] };
			doCreate(component, false, final);
		}
	});

	useEffect(() => {
		if (state.emrUser) {
			formik.setFieldValue("fname", state.newFName, true);
			formik.setFieldValue("lname", state.newLName, true);
			formik.setFieldValue("patientNumber", state.newNumber, true);
			component.setState({ emrUser: false });
		}

		if(state.createUserType) {
			let defaultUserType;
			var userType = state.createUserType;
			if(userType === "atouchaway") {
				defaultUserType = "aTouchAway";
			} else if (userType === "user:mobile") {
				defaultUserType = "Mobile";
			} else if(userType === "simplified") {
				defaultUserType = "Simplified";
			}

			formik.setFieldValue("user_type", defaultUserType);
		}
	}, [component, component?.state]);

	const renderManageGroupValue = () => {
		const currentManageGroups = formik.values.managegroup || [];
		if (currentManageGroups.length) {
			return currentManageGroups.map(value => state.manageGroups.find(group => value === group?._id)?.name).join(", ");
		} else {
			return (
				<div style={{ color: "rgba(0, 96, 100, 0.4)" }}>{localization.localize("patientCreation.selectGroup")}</div>
			);
		}
	};

	const handleSelectChange = key => {
		return event => {
			formik.setFieldValue(key, event.target.value);
		};
	};

	const renderSelectValue = (formDataKey, key, placeholder) => {
		return () => {
			const label = formData[formDataKey][formik.values[key]] || placeholder;
			return localization.localize(label) || <div style={{ color: "rgba(0, 96, 100, 0.4)" }}>{label}</div>;
		};
	};

	const handleDateChange = event => {
		formik.setFieldValue("dob", event.target.value);
		if (event.target.value === "" && dateChanged) {
			setDateChanged(false);
		} else if (!dateChanged) {
			setDateChanged(true);
		}
	};

	return (
		<form ref={submitRef} onSubmit={formik.handleSubmit}>
			<Grid
				container
				direction="column"
				justifyContent="center"
				alignItems="flex-start"
				spacing={2}
				style={{ marginTop: 5 }}
			>
				<Paper elevation={1} style={{ marginLeft: 8, marginRight: 8 }}>
					<Grid
						item
						container
						direction="row"
						justifyContent="flex-start"
						alignItems="center"
						xs={12}
						spacing={4}
						style={{ paddingLeft: 20, paddingBottom: 20 }}
					>
						<Grid item xs={12} sm={6}>
							<h3>{localization.localize("patientCreation.personalinfo")}</h3>
						</Grid>
						<Grid item xs={12} sm={6}>
							<div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-end" }}>
								{localization.get("search_exist_user")}{" "}
								<IconButton
									color="primary"
									aria-label={localization.get("search_exist_user")}
									className="fa fa-edit"
									onClick={showEmrSearch}
								/>
							</div>
						</Grid>
						<Grid item xs={12} sm={2}>
							<div>{localization.get("users_fname")}</div>
						</Grid>
						<Grid item xs={12} sm={4}>
							<TextField
								id="fname"
								color="secondary"
								placeholder={localization.get("users_fname")}
								inputProps={{ "aria-label": localization.get("users_fname") }}
								size="small"
								fullWidth
								variant="outlined"
								value={formik.values.fname}
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								error={formik.touched.fname && Boolean(formik.errors.fname)}
								helperText={formik.touched.fname && formik.errors.fname}
							/>
						</Grid>
						<Grid item xs={12} sm={2}>
							<div>{localization.get("users_lname")}</div>
						</Grid>
						<Grid item xs={12} sm={4}>
							<TextField
								id="lname"
								color="secondary"
								placeholder={localization.get("users_lname")}
								inputProps={{ "aria-label": localization.get("users_lname") }}
								size="small"
								fullWidth
								variant="outlined"
								value={formik.values.lname}
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								error={formik.touched.lname && Boolean(formik.errors.lname)}
								helperText={formik.touched.lname && formik.errors.lname}
							/>
						</Grid>
						<Grid item xs={12} sm={2}>
							<div>{localization.get("ae_careplan_gender")}</div>
						</Grid>
						<Grid item xs={12} sm={4}>
							<StyledSelect
								displayEmpty
								id="gender"
								inputProps={{ "aria-label": localization.get("ae_careplan_gender") }}
								color="secondary"
								renderValue={renderSelectValue(
									"gender",
									"gender",
									localization.localize("patientCreation.selectGender")
								)}
								value={formik.values.gender}
								onChange={handleSelectChange("gender")}
								error={formik.touched.gender && Boolean(formik.errors.gender)}
								helperText={formik.touched.gender && formik.errors.gender}
								fullWidth
								variant="outlined"
							>
								{genders.map(option => (
									<MenuItem key={option.value} value={option.value}>
										{option.label}
									</MenuItem>
								))}
							</StyledSelect>
						</Grid>
						<Grid item xs={12} sm={2}>
							<div>{localization.get("ae_careplan_dob")}</div>
						</Grid>
						<Grid item xs={12} sm={4}>
							<TextField
								id="dob"
								color="secondary"
								inputProps={{ "aria-label": localization.get("ae_careplan_dob") }}
								type="date"
								InputLabelProps={{ shrink: true }}
								size="small"
								fullWidth
								variant="outlined"
								value={formik.values.dob}
								onChange={handleDateChange}
								error={formik.touched.dob && Boolean(formik.errors.dob)}
								helperText={formik.touched.dob && formik.errors.dob}
								InputProps={{ style: !dateChanged ? { color: "rgba(0, 96, 100, 0.4)" } : {} }}
							/>
						</Grid>
						<Grid item xs={12} sm={2}>
							<div>{localization.get("ae_careplan_language")}</div>
						</Grid>
						<Grid item xs={12} sm={4}>
							<StyledSelect
								displayEmpty
								id="language"
								inputProps={{ "aria-label": localization.get("ae_careplan_language") }}
								color="secondary"
								renderValue={renderSelectValue(
									"languages",
									"language",
									localization.localize("patientCreation.selectLanguage")
								)}
								value={formik.values.language}
								onChange={handleSelectChange("language")}
								error={formik.touched.language && Boolean(formik.errors.language)}
								helperText={formik.touched.language && formik.errors.language}
								fullWidth
								variant="outlined"
							>
								{langs.map(option => (
									<MenuItem key={option.value} value={option.value}>
										{option.label}
									</MenuItem>
								))}
							</StyledSelect>
							{!!formik.touched.language && !!formik.errors.language && (
								<StyledFormHelperText error>{formik.errors.language}</StyledFormHelperText>
							)}
						</Grid>
						<Grid item xs={12} sm={2}>
							<div>{localization.get("user_type_select")}</div>
						</Grid>
						<Grid item xs={12} sm={4}>
							<StyledSelect
								displayEmpty
								id="user_type"
								inputProps={{ "aria-label": localization.get("user_type_select") }}
								color="secondary"
								renderValue={renderSelectValue(
									"types",
									"user_type",
									localization.localize("patientCreation.selectUserType")
								)}
								value={formik.values.user_type}
								onChange={handleSelectChange("user_type")}
								error={formik.touched.user_type && Boolean(formik.errors.user_type)}
								helperText={formik.touched.user_type && formik.errors.user_type}
								size="small"
								fullWidth
								variant="outlined"
							>
								{types.map(option => (
									<MenuItem key={option.value} value={option.value}>
										{option.label}
									</MenuItem>
								))}
							</StyledSelect>
							{!!formik.touched.user_type && !!formik.errors.user_type && (
								<StyledFormHelperText error>{formik.errors.user_type}</StyledFormHelperText>
							)}
						</Grid>
					</Grid>
					<Divider light variant="middle" />
					<Grid
						item
						container
						direction="row"
						justifyContent="flex-start"
						alignItems="center"
						xs={12}
						spacing={4}
						style={{ paddingLeft: 20, paddingBottom: 20 }}
					>
						<Grid item xs={12}>
							<h3>{localization.localize("patientCreation.addressInfo")}</h3>
						</Grid>
						<Grid item xs={12} sm={2}>
							<div>{localization.localize("patientCreation.address1")}</div>
						</Grid>
						<Grid item xxs={12} sm={4}>
							<TextField
								id="street"
								color="secondary"
								inputProps={{ "aria-label": localization.get("patientCreation.address1") }}
								placeholder={localization.localize("patientCreation.address1")}
								size="small"
								fullWidth
								variant="outlined"
								value={formik.values.street}
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								error={formik.touched.street && Boolean(formik.errors.street)}
								helperText={formik.touched.street && formik.errors.street}
							/>
						</Grid>
						<Grid item xs={12} sm={2}>
							<div>{localization.localize("patientCreation.address2")}</div>
						</Grid>
						<Grid item xs={12} sm={4}>
							<TextField
								id="apartment"
								color="secondary"
								inputProps={{ "aria-label": localization.get("patientCreation.address2") }}
								placeholder={localization.localize("patientCreation.address2")}
								size="small"
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								fullWidth
								variant="outlined"
								error={formik.touched.apartment && Boolean(formik.errors.apartment)}
								helperText={formik.touched.apartment && formik.errors.apartment}
							/>
						</Grid>
						<Grid item xs={12} sm={2}>
							<div>{localization.localize("patientCreation.country")}</div>
						</Grid>
						<Grid item xs={12} sm={4}>
							<StyledSelect
								displayEmpty
								id="country"
								color="secondary"
								inputProps={{ "aria-label": localization.get("patientCreation.country") }}
								renderValue={renderSelectValue(
									"country",
									"country",
									localization.localize("patientCreation.selectCountry")
								)}
								value={formik.values.country}
								onChange={handleSelectChange("country")}
								error={formik.touched.country && Boolean(formik.errors.country)}
								helperText={formik.touched.country && formik.errors.country}
								fullWidth
								variant="outlined"
							>
								{countries.map(option => (
									<MenuItem key={option.value} value={option.value}>
										{option.label}
									</MenuItem>
								))}
							</StyledSelect>
						</Grid>
						<Grid item xs={12} sm={2}>
							<div>{localization.get("ae_careplan_city")}</div>
						</Grid>
						<Grid item xs={12} sm={4}>
							<TextField
								id="city"
								color="secondary"
								inputProps={{ "aria-label": localization.get("ae_careplan_city") }}
								placeholder={localization.get("ae_careplan_city")}
								size="small"
								fullWidth
								variant="outlined"
								value={formik.values.city}
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								error={formik.touched.city && Boolean(formik.errors.city)}
								helperText={formik.touched.ciity && formik.errors.city}
							/>
						</Grid>
						<Grid item xs={12} sm={2}>
							<div>{localization.get("sidebar_region")}</div>
						</Grid>
						<Grid item xs={12} sm={4}>
							<StyledSelect
								displayEmpty
								id="province"
								inputProps={{ "aria-label": localization.get("sidebar_region") }}
								renderValue={renderSelectValue(
									"regions",
									"province",
									localization.localize("patientCreation.selectRegion")
								)}
								color="secondary"
								value={formik.values.province}
								onChange={handleSelectChange("province")}
								error={formik.touched.province && Boolean(formik.errors.province)}
								helperText={formik.touched.province && formik.errors.province}
								fullWidth
								variant="outlined"
							>
								{regions.map(option => {
									if (typeof option.label === "object") {
										return <ListSubHeader>{localization.localize(option?.label?.optGroup)}</ListSubHeader>;
									} else if (typeof option.label === "string") {
										return (
											<MenuItem key={option.value} value={option.value}>
												{option.label}
											</MenuItem>
										);
									}
								})}
							</StyledSelect>
						</Grid>
						<Grid item xs={12} sm={2}>
							<div>{localization.localize("patientCreation.postalCode")}</div>
						</Grid>
						<Grid item xs={12} sm={4}>
							<TextField
								id="postal"
								color="secondary"
								inputProps={{ "aria-label": localization.get("patientCreation.postalCode") }}
								placeholder={localization.localize("patientCreation.postalCode")}
								size="small"
								fullWidth
								variant="outlined"
								value={formik.values.postal}
								onChange={event => formik.setFieldValue("postal", event.target.value.toUpperCase())}
								onBlur={formik.handleBlur}
								error={formik.touched.postal && Boolean(formik.errors.postal)}
								helperText={formik.touched.postal && formik.errors.postal}
							/>
						</Grid>
						<Grid item xs={12} sm={2}>
							<div>{localization.get("login_email")}</div>
						</Grid>
						<Grid item xs={12} sm={4}>
							<TextField
								id="email"
								color="secondary"
								inputProps={{ "aria-label": localization.get("login_email") }}
								placeholder={localization.get("login_email")}
								size="small"
								fullWidth
								variant="outlined"
								value={formik.values.email}
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								error={formik.touched.email && Boolean(formik.errors.email)}
								helperText={formik.touched.email && formik.errors.email}
							/>
						</Grid>
						<Grid item xs={12} sm={2}>
							<div>{localization.localize("patientCreation.phone1")}</div>
						</Grid>
						<Grid item xs={12} sm={4}>
							<TextField
								id="phone"
								color="secondary"
								inputProps={{ "aria-label": localization.get("patientCreation.phone1") }}
								placeholder={localization.localize("patientCreation.phone1")}
								size="small"
								fullWidth
								variant="outlined"
								value={formik.values.phone}
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								error={formik.touched.phone && Boolean(formik.errors.phone)}
								helperText={formik.touched.phone && formik.errors.phone}
							/>
						</Grid>
						<Grid item xs={12} sm={2}>
							<div>{localization.localize("patientCreation.phone2")}</div>
						</Grid>
						<Grid item xs={12} sm={4}>
							<TextField
								id="phone2"
								color="secondary"
								inputProps={{ "aria-label": localization.get("patientCreation.phone2") }}
								placeholder={localization.localize("patientCreation.phone2")}
								size="small"
								fullWidth
								variant="outlined"
								value={formik.values.phone2}
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								error={formik.touched.phone2 && Boolean(formik.errors.phone2)}
								helperText={formik.touched.phone2 && formik.errors.phone2}
							/>
						</Grid>
						<Grid item xs={12} sm={2}>
							<div>{localization.localize("patientCreation.timezone")}</div>
						</Grid>
						<Grid item xs={12} sm={4}>
							<StyledSelect
								displayEmpty
								inputProps={{ "aria-label": localization.get("patientCreation.timezone") }}
								renderValue={renderSelectValue(
									"timezones",
									"timezone",
									localization.localize("patientCreation.selectTimezone")
								)}
								id="timezone"
								color="secondary"
								value={formik.values.timezone}
								onChange={handleSelectChange("timezone")}
								error={formik.touched.timezone && Boolean(formik.errors.timezone)}
								helperText={formik.touched.timezone && formik.errors.timezone}
								fullWidth
								variant="outlined"
							>
								{zones.map(option => (
									<MenuItem key={option.value} value={option.value}>
										{option.label}
									</MenuItem>
								))}
							</StyledSelect>
						</Grid>
					</Grid>
					<Divider light variant="middle" />
					<Grid
						item
						container
						direction="row"
						justifyContent="flex-start"
						alignItems="center"
						xs={12}
						spacing={4}
						style={{ paddingLeft: 20, paddingBottom: 20 }}
					>
						<Grid item xs={12}>
							<h3>{localization.localize("patientCreation.healthInfo")}</h3>
						</Grid>
						<Grid item xs={12} sm={2}>
							<div>{localization.get("ae_careplan_card_number")}</div>
						</Grid>
						<Grid item xs={12} sm={4}>
							<TextField
								id="healthCardNumber"
								inputProps={{ "aria-label": localization.get("ae_careplan_card_number") }}
								color="secondary"
								placeholder={localization.get("ae_careplan_card_number")}
								size="small"
								fullWidth
								variant="outlined"
								value={formik.values.healthCardNumber}
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								error={formik.touched.healthCardNumber && Boolean(formik.errors.healthCardNumber)}
								helperText={formik.touched.healthCardNumber && formik.errors.healthCardNumber}
							/>
						</Grid>
						<Grid item xs={12} sm={2}>
							<div>{localization.localize("patientCreation.patientNumber")}</div>
						</Grid>
						<Grid item xs={12} sm={4}>
							<TextField
								id="patientNumber"
								inputProps={{ "aria-label": localization.get("patientCreation.patientNumber") }}
								color="secondary"
								placeholder={localization.localize("patientCreation.patientNumber")}
								size="small"
								fullWidth
								variant="outlined"
								value={formik.values.patientNumber}
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								error={formik.touched.patientNumber && Boolean(formik.errors.patientNumber)}
								helperText={formik.touched.patientNumber && formik.errors.patientNumber}
							/>
						</Grid>
						<Grid item xs={12} sm={2}>
							<div>{localization.get("managergroups_usergroup")}</div>
						</Grid>
						<Grid item xs={12} sm={4}>
							<StyledSelect
								displayEmpty
								id="managegroup"
								color="secondary"
								inputProps={{ "aria-label": localization.get("managergroups_usergroup") }}
								multiple
								value={formik.values.managegroup}
								renderValue={renderManageGroupValue}
								onChange={handleSelectChange("managegroup")}
								error={formik.touched.managegroup && Boolean(formik.errors.managegroup)}
								helperText={formik.touched.managegroup && formik.errors.managegroup}
								fullWidth
								variant="outlined"
							>
								{manageGroup.map(option => (
									<MenuItem key={option._id} value={option._id}>
										{option.name}
									</MenuItem>
								))}
							</StyledSelect>
						</Grid>
						<Grid
							item
							xs={12}
						>
							<h3>{localization.localize("pinned_note")}</h3>
						</Grid>
						<Grid
							item
							style={{ paddingTop: "10px" }}
							xs={12}
						>
							<StyledTextareaAutosize
								id="user_note"
								aria-label={localization.get("pinned_note")}
								minRows={1}
								maxRows={2}
								value={formik.values.user_note}
								onChange={formik.handleChange}
								onBlur={formik.handleBlur("user_note")}
							/>
							{formik.touched.user_note && formik.errors.user_note && (
								<span style={{ color: "red" }}>{formik.errors.user_note}</span>
							)}
						</Grid>
						<Grid item xs={12}>
							<div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
								{localization.get("user_carecoordinator")}{" "}
								<IconButton
									color="primary"
									aria-label={localization.get("user_carecoordinator")}
									className="fa fa-edit"
									onClick={showSearchDialog}
								/>{" "}
							</div>
						</Grid>
						<Grid item xs={12}>
							<Grid item>
								{renderAddCare(component)}
							</Grid>
						</Grid>
					</Grid>
				</Paper>
			</Grid>
		</form>
	);
};

export default PatientCreation;
