/* © 2014 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, June 19, 2014
 * For information or permission request, email info@aetonixsystems.com
 */

"use strict";


/**
 * This store derives its data from listening for a connectivity mqtt
 * message indicating whether the current user is off line ie doesn't
 * have a socket connection.
 */

var Store = require("./store");

module.exports = ConnectionStore;

function ConnectionStore(api, events, config) {

	var store = new Store();

	store.set("offline", false);

	begin_listening();

	return store;

	function begin_listening() {
		events.on("connectivity:online", handle_online);
		events.on("connectivity:offline", handle_offline);
	}

	function handle_offline() {
		store.set("offline", true);
	}

	function handle_online() {
		store.set("offline", false);
	}

}
