"use strict";

var Store = require("./store");
var pluck = require("fj-pluck");
var map = require("fj-map");

import * as Sentry from '@sentry/react';

module.exports = InvitationStore;

function InvitationStore(api, events, config){
	var store = new Store();
	api.org.users.invitation.list().then(map(add_invitation));
	api.org.users.invitation.list_existing().then(map(add_invitation));
	api.org.group.current.data()
		.then(pluck("_id"))
		.then(listen_for_changes)
		.catch(error => {
			Sentry.captureException(error);
			console.error.bind(console, error);
		});

	return store;

	function add_invitation(invitation){
		var id = invitation._id;
		store.set(id, invitation);
	}

	function remove_invitation(invitation){
		var id = invitation._id;
		store.remove(id);
	}

	function listen_for_changes(group){
		events.on("mqtt:orggroup/" + group + "/users/invite/new", add_invitation);
		events.on("mqtt:orggroup/" + group + "/users/invite/removed", remove_invitation);
		events.on("mqtt:orggroup/" + group + "/users/invite/used", remove_invitation);
	}
}
