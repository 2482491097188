
/* © 2017 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, January 01, 2017
 * For information or permission request, email info@aetonixsystems.com
 */

"use strict";

var Store = require("./store");
var map = require("fj-map");
var xtend = require("xtend");

module.exports = OrgResources;

function OrgResources(api, events, config){
	var store = new Store();

	api.org.resources.list()
		.then(map(add_resource));

	api.org.group.current.id()
		.then(listen_for_changes);

	return store;

	function add_resource(resource){
		var id = resource._id;
		store.set(id, resource);
	}

	function remove_resource(data){
		var id = data.resource;
		store.remove(id);
	}

	function update_resource(data){
		var id = data.resource;
		var current = store.get(id);
		var newResource = xtend(current, data);

		store.set(id, newResource);
	}

	function listen_for_changes(group){
		events.on("mqtt:orggroup/" + group + "/resource/created", add_resource);
		events.on("mqtt:orggroup/" + group + "/resource/removed", remove_resource);
		events.on("mqtt:orggroup/" + group + "/resource/updated", update_resource);
	}
}
