/* © 2014 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, June 19, 2014
 * For information or permission request, email info@aetonixsystems.com
 */

"use strict";

var Store = require("./store");
const localize = require("ae-localizer").default;

var localization = {
	en: require("../localization/localization-en.json"),
	fr: require("../localization/localization-fr.json"),
	cn_t: require("../localization/localization-cn-t.json"),
	cn_s: require("../localization/localization-cn-s.json"),
	es: require("../localization/localization-es.json"),
	pt: require("../localization/localization-pt.json"),
	ta: require("../localization/localization-ta.json")
};

module.exports = LocalizationStore;

function LocalizationStore(api, events) {

	var store = new Store();

	store.localization = localization;

	store.setAll(localization.en);

	store.get = function(data) {
		if(!data) return;
		var parse = data.split(".");
		var translation = parse.reduce(function (acc, curr) {
			if (!curr) return acc;
			return acc[curr] ? acc[curr] : curr;
		}, this._data);
		return translation;
	};

	store.localize = function(data, args) {
		if(!data) return data;
		return localize(this._data, data, args);
	};

	refresh();

	events.on("mqtt:$/settings", refresh);

	return store;

	function refresh() {
		api.people.data().then(change_language);
	}

	function change_language(data) {
		var language = data.language;
		store.setAll(localization[language]);
	}
}
