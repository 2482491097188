
/* © 2017 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, January 01, 2017
 * For information or permission request, email info@aetonixsystems.com
 */

var par = require("par");

var defineUI = require("../shared/define");
var render = require("./render.jsx");

// Stores required for all pages
var PersonStore = require("../stores/person_store");
var LocalizationStore = require("../stores/localization_store");
var CareplanNotificationStore = require("../stores/careplan_notice_store");
var ConnectionStore = require("../stores/connection_store");

// Stores unique to this page
var WorkflowOngoingStore = require("../stores/admin_workflow_ongoing");
var WorkflowDefinitionsStore = require("../stores/admin_workflow_definitions");
var TasksStore = require("../stores/admin_tasks_store");

var CurrentPersonInfoStore = require("../stores/current_person_info_store");

var makeStores = require("../shared/stores")({
	people: PersonStore,
	workflowOngoing: WorkflowOngoingStore,
	workflowDefinitions: WorkflowDefinitionsStore,
	tasks: TasksStore,

	currentPerson: CurrentPersonInfoStore,
	localization: LocalizationStore,
	careplanChangeList: CareplanNotificationStore,
	connection: ConnectionStore
});

function make_ui(api, events, config, userMetrics) {
	var storeStore = makeStores(api, events, config);

	window.stores = storeStore.store;

	userMetrics.trackEvent("page view", {
		"page": "admin-tasks",
		"category": "admin",
		"permission level": "admin",
	});

	return defineUI({
		getInitialState: getInitialState,
		componentDidMount: listen_for_changes,
		render: render,
		componentWillUnmount: removeListeners
	});

	function removeListeners() {
		events.off();
	}

	function listen_for_changes() {
		storeStore.listen(par(handle_change, this));
	}

	function handle_change(component, stores) {
		component.setState({
			people: stores.people,
			workflowDefinitions: stores.workflowDefinitions,
			workflowOngoing: stores.workflowOngoing,
			tasks: stores.tasks,
			currentPerson: stores.currentPerson,
			localization: stores.localization,
			careplanChangeList: stores.careplanChangeList,
			connection: stores.connection
		});
	}

	function getInitialState() {
		return {
			filterUsers: [],
			people: storeStore.stores.people,
			workflowDefinitions: storeStore.stores.workflowDefinitions,
			workflowOngoing: storeStore.stores.workflowOngoing,
			tasks: storeStore.stores.tasks,

			localization: storeStore.stores.localization,
			careplanChangeList: storeStore.stores.careplanChangeList,
			connection: storeStore.stores.connection,
			currentPerson: storeStore.stores.currentPerson,

			more: storeStore.stores.tasks.loadMore,
			filterUser: filterUser,
			clearUserFilter: clearUserFilter,

			runTask: runTask,
			removeTask: removeTask,
			peopleSearch: peopleSearch,

			sort: "none",
			sortDirection: "asc",

			userMetrics: userMetrics,
		};
	}

	function filterUser(component, data){
		var filterUsers = component.state.filterUsers;
		component.setState({
			filterUsers: filterUsers.concat(data)
		});
		storeStore.stores.tasks.search(data);
		userMetrics.trackEvent("admin-tasks: filter by user", {
			"users": data,
		});
	}

	function clearUserFilter(component){
		component.setState({
			filterUsers: []
		});
		storeStore.stores.tasks.clearSearch();
		userMetrics.trackEvent("admin-tasks: clear user filter");
	}

	function runTask(taskId, component){
		if(!taskId) return;
		component.state.userMetrics.trackEvent("admin-tasks: run task", {
			"task": taskId,
		});
		return api.admin.tasks.run(taskId);
	}

	function removeTask(taskId, component){
		if(!taskId) return;
		component.state.userMetrics.trackEvent("admin-tasks: remove task", {
			"task": taskId,
		});
		return api.admin.tasks.remove(taskId);
	}

	function peopleSearch(query){
		return api.admin.search.users(query);
	}
}

module.exports = make_ui;
