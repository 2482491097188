import { Button } from "@material-ui/1.5.1";
import { styled } from "@material-ui/styles";
import React from 'react'
const ActionButton = styled(Button)({});
const PrimaryButton = styled(ActionButton)({
	backgroundColor: "#237480"
});
const ImportantButton = styled(ActionButton)({
	backgroundColor: "#b24242"
});

export {
	ActionButton,
	PrimaryButton,
	ImportantButton
}