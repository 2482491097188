/* © 2014 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, May 3, 2018
 * For information or permission request, email info@aetonixsystems.com
 */

var MakeAppComponent = require("../shared/AppComponent");

import { LogisticsPage, CustomReportsPage, PatientsPage, AdminOrganizationsPage, LogisticsNotificationsPage, PatientNotificationsPage } from "@aetonix/web-ui";

var group_careplans = MakeAppComponent(require("../group-careplans/ui.jsx"));
var overview = MakeAppComponent(require("../overview/ui.jsx"));
var user_fall_live = MakeAppComponent(require("../user-fall-live/ui.jsx"));
var urgency_live = MakeAppComponent(require("../urgency-live/ui.jsx"));
var reminders_live = MakeAppComponent(require("../reminders-live/ui.jsx"));
var user_wander_live = MakeAppComponent(require("../user-wander-live/ui.jsx"));
var group_users = MakeAppComponent(require("../group-users/ui.jsx"));
var user_management = MakeAppComponent(require("../user-management/ui.jsx"));
var user_urgency = MakeAppComponent(require("../user-urgency/ui.jsx"));
var user_bracelets = MakeAppComponent(require("../user-bracelets/ui.jsx"));
var org_staff = MakeAppComponent(require("../org-staff/ui.jsx"));
var user_fall_history = MakeAppComponent(require("../user-fall-history/ui.jsx"));
var group_calls = MakeAppComponent(require("../group-calls/ui.jsx"));
var reminders_history = MakeAppComponent(require("../reminders-history/ui.jsx"));
var user_wander_history = MakeAppComponent(require("../user-wander-history/ui.jsx"));
var user_urgency_history = MakeAppComponent(require("../user-urgency-history/ui.jsx"));
var org_forms_submission_history = MakeAppComponent(require("../org-forms-submission-history/ui.jsx"));
var org_forms_changes_history = MakeAppComponent(require("../org-forms-changes-history/ui.jsx"));
var user_steps_history = MakeAppComponent(require("../user-steps-history/ui.jsx"));
var user_glucose_history = MakeAppComponent(require("../user-glucose-history/ui.jsx"));
var user_weight_history = MakeAppComponent(require("../user-weight-history/ui.jsx"));
var user_bloodpressure_history = MakeAppComponent(require("../user-bloodpressure-history/ui.jsx"));
var user_bloodoxygen_history = MakeAppComponent(require("../user-bloodoxygen-history/ui.jsx"));
var user_bodytemperature_history = MakeAppComponent(require("../user-bodytemperature-history/ui.jsx"));
var org_groups = MakeAppComponent(require("../org-groups/ui.jsx"));
var org_forms = MakeAppComponent(require("../org-forms/ui.jsx"));
var org_workflow = MakeAppComponent(require("../org-workflow/ui.jsx"));
var org_managers = MakeAppComponent(require("../org-managers/ui.jsx"));
var admin_forms = MakeAppComponent(require("../admin-forms/ui.jsx"));
var admin_workflow = MakeAppComponent(require("../admin-workflow/ui.jsx"));
var org_audit = MakeAppComponent(require("../org-audit/ui.jsx"));
var admin_calls = MakeAppComponent(require("../admin-calls/ui.jsx"));
var admin_bracelets_live = MakeAppComponent(require("../admin-bracelets-live/ui.jsx"));
var dashboard = MakeAppComponent(require("../dashboards/ui.jsx"));
var callgroups_creation = MakeAppComponent(require("../group-callgroups/ui.jsx"));
var resources_creation = MakeAppComponent(require("../org-resources/ui.jsx"));
var user_resources = MakeAppComponent(require("../user-resources/ui.jsx"));
var admin_resources = MakeAppComponent(require("../admin-resources/ui.jsx"));
var availability = MakeAppComponent(require("../availability/ui.jsx"));
var livechat_creation = MakeAppComponent(require("../group-livechats/ui.jsx"));
var user_overview = require("../user-overview").RootComponent;
var group_users_overview = MakeAppComponent(require("../group-users-overview/ui.jsx"));
var user_thresholds = MakeAppComponent(require("../user-thresholds/ui.jsx"));
var group_workflow_autostart = MakeAppComponent(require("../group-workflow-autostart/ui.jsx"));
var admin_promo_code = MakeAppComponent(require("../admin-promo-code/ui.jsx"));
var admin_group_settings = MakeAppComponent(require("../admin-group-settings/ui.jsx"));
var group_manage_groups = MakeAppComponent(require("../group-manage-groups/ui.jsx"));
var admin_stats = MakeAppComponent(require("../admin-stats/ui.jsx"));
var org_stats = MakeAppComponent(require("../org-stats/ui.jsx"));
var admin_tables = MakeAppComponent(require("../admin-tables/ui.jsx"));
var workflow_history = MakeAppComponent(require("../workflow-history/ui.jsx"));
var admin_user_management = MakeAppComponent(require("../admin-user-management/ui.jsx"));
var admin_tasks = MakeAppComponent(require("../admin-tasks/ui.jsx"));
var admin_option_schemas = MakeAppComponent(require("../admin-options-schemas/ui.jsx"));
var group_options = MakeAppComponent(require("../group-options/ui.jsx"));
var org_data_reports = MakeAppComponent(require("../org-reports/ui.jsx"));

const CustomIndicators = MakeAppComponent(require("../org-custom-indicators/ui.jsx"));
const CustomIndicatorHistory = MakeAppComponent(require("../user-custom-indicator-history/ui.jsx"));

/* Routes object
* key: Used for feature flag access, admin/general/logistics pages don't need this flag
* name: Localization key
* url: Route url
* type: Dashboards category on all dashboards hierarchy page
* component: Component for page
*/

module.exports = function() {
	return {
		notifications: [
			{
				name: "dashboards_logistics_notifications",
				url: "/logisticNotifications/",
				type: "logistic_notifications",
				component: () => LogisticsNotificationsPage()
			},
			{
				name: "dashboards_patients_notifications",
				url: "/patientNotifications/",
				type: "patient_notifications",
				component: () => PatientNotificationsPage()
			}
		],
		logistics: [
			{
				name: "dashboards_logistics",
				url: "/logistics/",
				type: "logistics",
				component: () => LogisticsPage()
			}
		],
		staff: [
			{
				key: "dashboards_patients",
				name: "dashboards_patients",
				url: "/patients/",
				type: "user",
				component: PatientsPage
			},
			{
				key: "dashboards_availability_label",
				name: "dashboards_availability_label",
				url: "/availability/",
				type: "monitor",
				component: availability
			},
			{
				key: "",
				url: "/user-overview/:id",
				type: "",
				component: user_overview
			},
			{
				key: "dashboards_group_careplans",
				name: "dashboards_group_careplans",
				url: "/group-careplans/",
				type: "group",
				component: group_careplans
			},
			{
				key: "dashboards_overview",
				name: "dashboards_overview",
				url: "/overview/",
				type: "monitor",
				component: overview
			},
			{
				key: "dashboards_group_user_overview",
				name: "dashboards_group_user_overview",
				url: "/group-users-overview",
				type: "monitor",
				component: group_users_overview
			},
			{
				key: "dashboards_userfalllive",
				name: "dashboards_userfalllive",
				url: "/user-fall-live/",
				type: "monitor",
				component: user_fall_live
			},
			{
				key: "dashboards_urgency",
				name: "dashboards_urgency",
				url: "/urgency-live/",
				type: "monitor",
				component: urgency_live
			},
			{
				key: "dashboards_reminders",
				name: "dashboards_reminders",
				url: "/reminders-live/",
				type: "monitor",
				component: reminders_live
			},
			{
				key: "dashboards_userwanderlive",
				name: "dashboards_userwanderlive",
				url: "/user-wander-live/",
				type: "monitor",
				component: user_wander_live
			},
			{
				key: "dashboards_user",
				name: "dashboards_user",
				url: "/group-users/",
				type: "user",
				component: group_users
			},
			{
				key: "dashboards_usermanage",
				name: "dashboards_usermanage",
				url: "/user-management/",
				type: "user",
				component: user_management
			},
			{
				key: "dashboards_userresources",
				name: "dashboards_userresources",
				url: "/user-resources/",
				type: "user",
				component: user_resources
			},
			{
				key: "dashboards_user_thresholds",
				name: "dashboards_user_thresholds",
				url: "/user-thresholds/",
				type: "user",
				component: user_thresholds
			},
			{
				key: "dashboards_userurgency",
				name: "dashboards_userurgency",
				url: "/user-urgency/",
				type: "user",
				component: user_urgency
			},
			{
				key: "dashboards_userbracelets",
				name: "dashboards_userbracelets",
				url: "/user-bracelets/",
				type: "user",
				component: user_bracelets
			},
			{
				key: "dashboards_staff",
				name: "dashboards_staff",
				url: "/org-staff/",
				type: "group",
				component: org_staff
			},
			{
				key: "dashboards_userfallhistory",
				name: "dashboards_userfallhistory",
				url: "/user-fall-history/",
				type: "history",
				component: user_fall_history
			},
			{
				key: "",
				name: "dashboards_customindicatorhistory",
				url: "/user-custom-indicator-history/:indicatorId/",
				type: "customindicator",
				component: CustomIndicatorHistory
			},
			{
				key: "dashboards_groupcalls",
				name: "dashboards_groupcalls",
				url: "/group-calls/",
				type: "history",
				component: group_calls
			},
			{
				key: "dashboards_reminderhistory",
				name: "dashboards_reminderhistory",
				url: "/reminders-history/",
				type: "history",
				component: reminders_history
			},
			{
				key: "dashboards_userwanderhistory",
				name: "dashboards_userwanderhistory",
				url: "/user-wander-history/",
				type: "history",
				component: user_wander_history
			},
			{
				key: "dashboards_urgencycallhistory",
				name: "dashboards_urgencycallhistory",
				url: "/user-urgency-history/",
				type: "history",
				component: user_urgency_history
			},
			{
				key: "dashboards_formSubmissionsHistory",
				name: "dashboards_formSubmissionsHistory",
				url: "/org-forms-submission-history/",
				type: "history",
				component: org_forms_submission_history
			},
			{
				key: "dashboards_formSubmissionsChangeHistory",
				name: "dashboards_formSubmissionsChangeHistory",
				url: "/org-forms-changes-history/",
				type: "group",
				component: org_forms_changes_history
			},
			{
				key: "dashboards_stepsHistory",
				name: "dashboards_stepsHistory",
				url: "/user-steps-history/",
				type: "myhealth",
				component: user_steps_history
			},
			{
				key: "dashboards_glucoseHistory",
				name: "dashboards_glucoseHistory",
				url: "/user-glucose-history/",
				type: "myhealth",
				component: user_glucose_history
			},
			{
				key: "dashboards_weightHistory",
				name: "dashboards_weightHistory",
				url: "/user-weight-history/",
				type: "myhealth",
				component: user_weight_history
			},
			{
				key: "dashboards_bloodpressureHistory",
				name: "dashboards_bloodpressureHistory",
				url: "/user-bloodpressure-history/",
				type: "myhealth",
				component: user_bloodpressure_history
			},
			{
				key: "dashboards_bloodoxygenHistory",
				name: "dashboards_bloodoxygenHistory",
				url: "/user-bloodoxygen-history/",
				type: "myhealth",
				component: user_bloodoxygen_history
			},
			{
				key: "dashboards_bodytemperatureHistory",
				name: "dashboards_bodytemperatureHistory",
				url: "/user-bodytemperature-history/",
				type: "myhealth",
				component: user_bodytemperature_history
			},
			{
				key: "dashboards_group_livechats",
				name: "dashboards_group_livechats",
				url: "/group-livechats/",
				type: "group",
				component: livechat_creation
			},
			{
				key: "dashboards_callgroups",
				name: "dashboards_callgroups",
				url: "/group-callgroups",
				type: "group",
				component: callgroups_creation
			},
			{
				key: "dashboards_group_workflowAutostart",
				name: "dashboards_group_workflowAutostart",
				url: "/group-pathway-autostart/",
				type: "group",
				component: group_workflow_autostart
			},
			{
				key: "dashboards_manager_groups",
				name: "dashboards_manager_groups",
				url: "/group-manage-groups/",
				type: "group",
				component: group_manage_groups
			},
			{
				key: "dashboards_options",
				name: "dashboards_options",
				url: "/group-options/",
				type: "group",
				component: group_options
			}
		],
		"org:manager": [
			{
				key: "dashboards_group",
				name: "dashboards_group",
				url: "/org-groups/",
				type: "group",
				component: org_groups
			},
			{
				key: "dashboards_forms",
				name: "dashboards_forms",
				url: "/org-forms/",
				type: "org",
				component: org_forms
			},
			{
				key: "dashboards_workflow",
				name: "dashboards_workflow",
				url: "/org-pathway/",
				type: "org",
				component: org_workflow
			},
			{
				key: "dashboards_resources",
				name: "dashboards_resources",
				url: "/org-resources/",
				type: "org",
				component: resources_creation
			},
			{
				key: "dashboards_manager",
				name: "dashboards_manager",
				url: "/org-managers/",
				type: "org",
				component: org_managers
			},
			{
				key: "dashboards_org_stats",
				name: "dashboards_org_stats",
				url: "/org-stats/",
				type: "org",
				component: org_stats
			},
			{
				key: "dashboards_audit",
				name: "dashboards_audit",
				url: "/org-audit/",
				type: "org",
				component: org_audit
			},
			{
				key: "dashboards_org_custom_indicators",
				name: "dashboards_org_custom_indicators",
				url: "/org-custom-indicators",
				type: "org",
				component: CustomIndicators
			},
			{
				key: "dashboards_org_reports",
				name: "dashboards_org_reports",
				url: "/org-reports",
				type: "org",
				component: org_data_reports
			},
			{
				key: "dashboards_custom_reports",
				name: "dashboards_custom_reports",
				url: "/custom-reports/",
				type: "org",
				component: CustomReportsPage
			}
		],
		admin: [
			{
				name: "dashboards_forms",
				url: "/admin-forms/",
				type: "admin",
				component: admin_forms
			},
			{
				name: "dashboards_workflow",
				url: "/admin-pathway/",
				type: "admin",
				component: admin_workflow
			},
			{
				name: "dashboards_resources",
				url: "/admin-resources/",
				type: "admin",
				component: admin_resources
			},
			{
				name: "dashboards_tables",
				url: "/admin-tables/",
				type: "admin",
				component: admin_tables
			},
			{
				name: "dashboards_admin_options",
				url: "/admin-options-schemas/",
				type: "admin",
				component: admin_option_schemas
			},
			{
				name: "dashboards_create",
				url: "/org-creation/",
				type: "org",
				component: AdminOrganizationsPage
			},
			{
				name: "dashboards_admincalls",
				url: "/admin-calls/",
				type: "monitor",
				component: admin_calls
			},
			{
				name: "dashboards_workflowHistory",
				url: "/pathway-history/",
				type: "history",
				component: workflow_history
			},
			{
				name: "dashboards_adminbracelets",
				url: "/admin-bracelets-live/",
				type: "monitor",
				component: admin_bracelets_live
			},
			{
				name: "admin_promo_code_title",
				url: "/admin-promo-code/",
				type: "admin",
				component: admin_promo_code
			},
			{
				name: "admin_group_settings_title",
				url: "/admin-group-settings/",
				type: "admin",
				component: admin_group_settings
			},
			{
				name: "dashboards_stats",
				url: "/admin-stats/",
				type: "admin",
				component: admin_stats
			},
			{
				name: "dashboards_user_management",
				url: "/admin-user-management/",
				type: "admin",
				component: admin_user_management
			},
			{
				name: "dashboards_tasks",
				url: "/admin-tasks/",
				type: "admin",
				component: admin_tasks
			}
		],
		dashboard: [
			{
				name: "dashboard_dashboard",
				url: "/",
				type: "dashboard",
				component: dashboard
			}
		]
	};
};
