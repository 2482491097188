/* © 2014 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, February, 2024
 * For information or permission request, email info@aetonixsystems.com
 */

var Store = require("./store");
var par = require("par");

import * as Sentry from "@sentry/react";


module.exports = StaffEmailStore;

function StaffEmailStore(api) {
	var store = new Store();

	store.raw_get = store.get;
	store.get = get;

	var tracking = [];

	return store;

	function get(id) {
		var data = store.raw_get(id);
		if (data) return data;

		if (!is_tracking(id)) track(id);

		return [];
	}


	function is_tracking(id) {
		return tracking.indexOf(id) !== -1;
	}

    function track(id) {
        tracking.push(id);
        api.org.staff.email(id).then(par(set_staff, id)).catch(error => {
            Sentry.captureException(error);
            store.error(error);
        });
    }

    function set_staff(id, data) {
        store.set(id, data);
    }
}
