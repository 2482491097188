/* © 2014 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, June 19, 2014
 * For information or permission request, email info@aetonixsystems.com
 */

"use strict";

var Cyan = require("@material-ui/1.5.1/colors/cyan").default;
var Grey = require("@material-ui/1.5.1/colors/grey").default;
var ColorManipulator = require("@material-ui/1.5.1/styles/colorManipulator");
var getMuiTheme = require("@material-ui/1.5.1/styles/createMuiTheme").default;

var AetonixTheme = {
	palette: {
		primary: {
			main: Cyan[900],
			dark: Cyan[800],
			light: Cyan[100],
		},
		secondary: {
			main: Cyan[100]
		},
		accent1: Cyan[600],
		accent2: Cyan[700],
		accent3: Cyan[400],
		accent4: ColorManipulator.fade(Cyan[900], 0.54),
		text: {
			primary: Cyan[900]
		},
		canvas: "#FAFAFA",
		border: Grey[300],
		disabled: ColorManipulator.fade(Grey[800], 0.3),
	},
	overrides: {
		MuiCheckbox: {
			colorSecondary: {
				'&$checked': {
					color: Cyan[900]
				}
			}
		},
		MuiListItemText: {
			primary: {
				color: "rgb(0, 96, 100)"
			},
			secondary: {
				color: "rgba(0, 0, 0, 1)"
			}
		},
		MuiTable: {
			root: {
				backgroundColor: "#FFF"
			}
		},
		MuiButton: {
			raised: {
				backgroundColor: "rgb(40, 94, 99)",
				color: "white"
			}
		},
		MuiAvatar: {
			colorDefault: {
				color: "rgb(117, 117, 117)",
				backgroundColor: "transparent"
			}
		},
		MuiIcon: {
			root: {
				overflow: "visible"
			}
		},
		MuiInput: {
			underline: {
				"&:before": {
					borderBottom: "1px solid rgba(0,0,0,0.1)"
				}
			}
		},
		MuiDialog: {
			paper: {
				color: Cyan[900]
			}
		},
		MuiSelect: {
			selectMenu: {
				color: Cyan[900]
			}
		},
		MuiMenuItem: {
			root: {
				color: Cyan[900]
			}
		},
		MuiListSubheader: {
			sticky: {
				color: Cyan[900]
			}
		},
		MuiIconButton: {
			label: {
				width: "auto"
			}
		},
		MuiTabs: {
			root: {
				maxWidth: "none",
				backgroundColor: "rgb(41, 92, 92)",
				color: "rgb(255, 255, 255)"
			},
		},
		MuiTab: {
			root: {
				maxWidth: "none",
			},
			selected: {
				backgroundColor: '#EAF4F4',
				color: '#152D56'
			},
		}
	}
};

module.exports = getMuiTheme(AetonixTheme);
