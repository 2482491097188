/* © 2014 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, June 19, 2014
 * For information or permission request, email info@aetonixsystems.com
 */

var par = require("par");

var config = require("../../configs/config.json");

var React = require("react");
var CreateReactClass = require("create-react-class");
var ReactPropTypes = require("prop-types");
var AetonixTheme = require("./AetonixTheme.js");

var Dialog = require("./Dialog");
var List = require("@material-ui/1.5.1/List").default;
var ListItem = require("@material-ui/1.5.1/ListItem").default;
var ListItemText = require("@material-ui/1.5.1/ListItemText").default;
var TextField = require("@material-ui/1.5.1/TextField").default;
var Avatar = require("@material-ui/1.5.1/Avatar").default;
var Button = require("@material-ui/1.5.1/Button").default;

var Utility = require("./Utility.js");
var Colors = require("../shared/AetonixTheme").palette;

import * as Sentry from "@sentry/react";

module.exports = CreateReactClass({
	displayName: "Search",
	childContextTypes: {
		muiTheme: ReactPropTypes.object
	},
	getChildContext: getChildContext,
	getInitialState: getInitialState,

	render: render,
	show: show,
	dismiss: dismiss,
	doSearch: doSearch,
	setResults: setResults
});

function getChildContext() {
	return {
		muiTheme: AetonixTheme
	};
}

function getInitialState() {
	return {
		showing: false,
		results: []
	};
}

function render() {
	var actions = [
		(<Button key={"search_close"} onClick={this.dismiss}>{this.props.localization.get("search_close")}</Button >)
	];

	var searchResults = this.state.results;
	var showing = this.state.showing;
	var searchTitle = this.props.localization.get("search_displayname");
	var searchName = this.props.localization.get("search_name");
	var isIds = (typeof searchResults[0]) === "string";
	var people = this.props.people;
	var selection = this.props.selection;

	if(isIds)
		searchResults = searchResults.map(people.get);

	return (
		<Dialog actions={actions} open={showing} title={searchTitle} >
			<TextField placeholder={searchName} inputProps={{"aria-label": searchName}} onChange={this.doSearch} />
			<List>
				{searchResults.sort(by_name).map(par(renderSearchItem, this, selection))}
			</List>
		</Dialog>
	);
}

function by_name(x, y) {
	var X = x.fname.toLowerCase();
	var Y = y.fname.toLowerCase();
	if (X === Y && x.lname && y.lname) {
		X = x.lname.toLowerCase();
		Y = y.lname.toLowerCase();
	}
	if(X === Y && x._id && y._id) {
		X = x._id.toLowerCase();
		Y = y._id.toLowerCase();
	}

	if (X < Y)
		return -1;
	if (X > Y)
		return 1;
	return 0;
}

function renderSearchItem(component, selection, person) {
	var disabled = false;
	var avatar = <Avatar alt='Member Avatar Image' src={config.image_cdn + person.image}/>;
	var text = Utility.format_name(person);

	const noPicture = component.props.noPicture;

	var personInfo = {
		fname: person.fname,
		lname: person.lname
	};
	if(person.patientNumber) {
		personInfo.patientNumber = person.patientNumber;
	}

	var style = {
		backgroundColor: "transparent"
	};

	var clickAction = par(component.props.action, person._id, personInfo);
	if (selection && selection.length > 0) {
		if (selection.indexOf(person._id) >= 0)	{
			disabled = true;
			style.backgroundColor = Colors.primary.light;
		}
	}
	return (
		<ListItem key={person._id} button disabled={disabled} onClick={clickAction} style={style} >
			{!noPicture && avatar}
			<ListItemText>
				{text}
			</ListItemText>
		</ListItem>
	);
}

function doSearch(e) {
	var query = e.target.value;

	var search = this.props.search;
	if(!search) return;
	this._current_query = query;
	var result = search(query);
	if(result)
		result.then(par(this.setResults, query))
		.catch(error => {
			Sentry.captureException(error);
			console.error.bind(console, error);
		});
}

function setResults(query, results) {
	if(this._current_query !== query) return;
	this.setState({
		results: results
	});
}

function show() {
	this.setState({
		showing: true,
		results: []
	});
}

function dismiss() {
	this.setState({
		results: [],
		showing: false
	});
	this.props.onDismiss && this.props.onDismiss();
}
