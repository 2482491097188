/* © 2014 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, June 19, 2014
 * For information or permission request, email info@aetonixsystems.com
 */

const par = require("par");

const defineUI = require("../shared/define");
const render = require("./render.jsx");

const LocalizationStore = require("../stores/localization_store");
const CurrentPersonInfoStore = require("../stores/current_person_info_store");
const CareplanNotificationStore = require("../stores/careplan_notice_store");
const ConnectionStore = require("../stores/connection_store");

module.exports = make_ui;

const makeStores = require("../shared/stores")({
	currentPerson: CurrentPersonInfoStore,
	localization: LocalizationStore,
	connection: ConnectionStore,
	careplanChangeList: CareplanNotificationStore
});

function make_ui(api, events, config, userMetrics) {
	const storeStore = makeStores(api, events, config);

	userMetrics.trackEvent("page view", {
		"page": "dashboard",
		"category": "dashboard",
		"permission level": "dashboard"
	});

	return defineUI({
		getInitialState: initialState,
		componentDidMount: listen_for_changes,
		render: render,
		componentWillUnmount: removeListeners
	});

	function removeListeners() {
		events.off();
	}

	function listen_for_changes() {
		storeStore.listen(par(handle_change, this));
	}

	function handle_change(component, stores) {
		component.setState({
			currentPerson: stores.currentPerson,
			localization: stores.localization,
			careplanChangeList: stores.careplanChangeList,
			connection: stores.connection,
		});
	}

	function initialState() {
		return {
			currentPerson: storeStore.stores.currentPerson,
			localization: storeStore.stores.localization,
			careplanChangeList: storeStore.stores.careplanChangeList,
			connection: storeStore.stores.connection,
		};
	}
}
