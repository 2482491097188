/* © 2014 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, May 3, 2018
 * For information or permission request, email info@aetonixsystems.com
 */

var par = require("par");

var defineUI = require("../shared/define");
var render = require("./render.jsx");

var PersonStore = require("../stores/person_store");
var OrgStore = require("../stores/org_store");
var LocalizationStore = require("../stores/localization_store");
var CurrentPersonInfoStore = require("../stores/current_person_info_store");
var CareplanNotificationStore = require("../stores/careplan_notice_store");
var ConnectionStore = require("../stores/connection_store");
var AdminOrgCounts = require("../stores/admin_org_count");
var AdminGroupCounts = require("../stores/admin_group_count");
var AdminGroupStore = require("../stores/admin_org_group_store");

var makeStores = require("../shared/stores")({
	orgs: OrgStore,
	people: PersonStore,
	currentPerson: CurrentPersonInfoStore,
	localization: LocalizationStore,
	careplanChangeList: CareplanNotificationStore,
	connection: ConnectionStore,
	groups: AdminGroupStore,
	groupCount: AdminGroupCounts,
	orgCount: AdminOrgCounts
});

module.exports = make_ui;

function make_ui(api, events, config, userMetrics) {
	var storeStore = makeStores(api, events, config);

	window.stores = storeStore.store;

	userMetrics.trackEvent("page view", {
		"page": "admin-stats",
		"category": "admin",
		"permission level": "admin",
	});

	return defineUI({
		getInitialState: getInitialState,
		componentDidMount: listen_for_changes,
		render: render,
		componentWillUnmount: removeListeners
	});

	function removeListeners() {
		events.off();
	}

	function listen_for_changes() {
		storeStore.listen(par(handle_change, this));
	}

	function handle_change(component, stores) {
		component.setState({
			orgs: stores.orgs.all(),
			people: stores.people,
			currentPerson: stores.currentPerson,
			localization: stores.localization,
			careplanChangeList: stores.careplanChangeList,
			connection: stores.connection,
			groupCount: stores.groupCount,
			groups: stores.groups,
			orgCount: stores.orgCount
		});
	}

	function getInitialState() {
		return {
			orgs: [],
			loadMore: par(loadMore, storeStore.stores.orgs),
			people: storeStore.stores.people,
			currentPerson: storeStore.stores.currentPerson,
			localization: storeStore.stores.localization,
			searchOrg: par(searchOrg, storeStore.stores.orgs),
			careplanChangeList: storeStore.stores.careplanChangeList,
			connection: storeStore.stores.connection,
			groupCount: storeStore.stores.groupCount,
			groups: storeStore.stores.groups,
			orgCount: storeStore.stores.orgCount,
			sorting: "name",
			sortingDirection: "asc",
			fullScreen: false,
			userMetrics: userMetrics,
		};
	}

	function searchOrg(orgs, event) {
		event.persist();
		var query = event.target.value;
		return orgs.search(query);
	}

	function loadMore(orgs) {
		return orgs.more();
	}
}
