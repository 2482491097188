/* © 2017 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, January 01, 2017
 * For information or permission request, email info@aetonixsystems.com
 */

"use strict";

var Store = require("./store");
var map = require("fj-map");

module.exports = ChangesHistory;

function ChangesHistory(api, events, config) {
	var store = new Store();

	var loading = null;
	var user = [];
	var forms = [];
	var editor = [];
	var page = 0;
	var start = "";
	var end = "";
	store.sorted = sorted;
	store.searchUser = searchUser;
	store.searchEditor = searchEditor;
	store.searchForms = searchForms;
	store.searchDate = searchDate;
	store.clearSearch = clearSearch;
	store.confirmViewed = confirmViewed;
	store.confirmSubmissionViewed = confirmSubmissionViewed;
	store.reset = reset;
	store.last_viewed = null;

	checkLastViewed();

	return store;

	function reset() {
		store.clear();
		start = store.last_viewed;
		api.org.forms.listGroupCombinedSubmissionChanges(user, forms, page, start, end, editor)
			.then(handle_create)
			.then(listen_single_last_viewed_update)
			.then(next_page);
	}

	function finish() {
		loading = null;
	}

	function sorted() {
		return store.all()
			.sort(by_time);
	}

	function confirmViewed() {
		return api.org.group.current.confirm()
			.then(function(result) {
				store.last_viewed = result.last_viewed;
			})
			.then(reset);
	}

	function confirmSubmissionViewed(submission, owner) {
		return api.org.forms.confirmGroupSubmissionChange(submission, owner);
	}

	function checkLastViewed() {
		return api.org.group.current.check()
		    .then(function(result){
			store.last_viewed = result.last_viewed;
			localStorage.setItem("last_viewed", result.last_viewed);
			return result;
		})
			.then(listen_last_viewed_update)
			.then(reset);
	}

	function handle_create(result) {
		if (result.length) {
			map(add_submission, result);
		} else {
			store.clear();
		}
	}

	function add_submission(form) {
		var id = form._id;
		var owner = form.owner;
		store.set(id, form);
		events.on("mqtt:orggroup/" + owner + "/last_viewed/updated", reset);
	}

	function next_page() {
		page += 1;
	}

	function searchUser(filterquery) {
		user = user.concat(filterquery);
		return reset();
	}

	function searchEditor(filterquery) {
		editor = editor.concat(filterquery);
		return reset();
	}

	function searchForms(filterquery) {
		forms = filterquery;
		return reset();
	}

	function searchDate(startdate, enddate) {
		start = startdate;
		end = enddate;
		if (!!start && !!end) {
			return reset();
		}
		return;
	}

	function clearSearch(name) {
		if (name === "user") user = [];
		if (name === "form") forms = [];
		if (name === "editor") editor = [];
		if (name === "time") {
			start = "";
			end = "";
		}
		return reset();
	}

	function listen_last_viewed_update(result) {
		var group = result._id;
		store.last_viewed = result.last_viewed;
		events.on("mqtt:orggroup/" + group + "/last_viewed/updated", handle_viewed_update);
	}

	function listen_single_last_viewed_update() {
		return api.org.group.current.id()
			.then(function(result){
				events.on("mqtt:orggroup/" + result + "/single/last_viewed/updated", reset);
			});
	}

	function handle_viewed_update(data) {
		store.last_viewed = data.last_viewed;
		return reset();
	}
}

function by_time(prev, next) {
	var prev_time = (new Date(prev.created_at)).getTime();
	var next_time = (new Date(next.created_at)).getTime();

	var time_delta = next_time - prev_time;
	return time_delta;
}