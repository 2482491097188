/* © 2014 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, June 19, 2014
 * For information or permission request, email info@aetonixsystems.com
 */

"use strict";

/**
 * This store is for the live monitoring of falls.
 */

var Store = require("./store");
var map = require("fj-map");
var par = require("par");

import * as Sentry from '@sentry/react';

module.exports = FallLiveStore;

function FallLiveStore(api, events, config) {
	var store = new Store();

	var page = 0;
	var loading = null;

	store.sorted = sorted;
	store.more = more;

	begin_listening();

	more();

	return store;

	function begin_listening() {
		//  Start listening on individual users for connect/disconnect events
		api.org.devices.list()
			.then(map(listen_to_bracelet))
			.catch(error => {
				Sentry.captureException(error);
				console.error.bind(console, error);
			});
	}

	function listen_to_bracelet(data) {
		var id = data.owner;
		var orgGroup = config.orgGroup;
		if(!orgGroup) return;
		events.on("mqtt:orggoup:/" + orgGroup + "/u/" + id + "/fall", par(handle_falllive, id));
	}

	function handle_falllive(id, device) {
		var who = device.who;
		var location = device.location;
		var time = device.created_at;
		var status = device.status;
		var mac = device.bracelet;
		store.update(id, {
			who: who,
			status: status,
			location: location,
			time: time,
			mac: mac
		});
	}

	function more() {
		if(loading) return loading;
		loading = api.org.devices.list()
			.then(map(handle_create))
			.then(next_page)
			.catch(error => {
				Sentry.captureException(error);
				console.error.bind(console, error);
			})
			.then(finish);
	}

	function finish() {
		loading = null;
	}

	//Want sorted by time
	function sorted() {
		return store.all().sort(by_time);
	}

	function handle_create(data) {
		var id = data.owner;
		var mac = data.mac;
		store.set(id, {
			who: id,
			status: "",
			location: "",
			time: "",
			mac: mac
		});
	}

	function next_page() {
		page += 1;
	}
}

function by_time(prev, next) {
	var prev_time = (new Date(prev.created_at)).getTime();
	var next_time = (new Date(next.created_at)).getTime();

	var time_delta = next_time - prev_time;
	return time_delta;
}
