/* © 2014 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, June 19, 2014
 * For information or permission request, email info@aetonixsystems.com
 */

var par = require("par");

var defineUI = require("../shared/define");
var render = require("./render.jsx");

var PersonStore = require("../stores/person_store");
var LocalizationStore = require("../stores/localization_store");
var CurrentPersonInfoStore = require("../stores/current_person_info_store");
var CallGroupsStore = require("../stores/org_group_callgroups_store");
var CareplanNotificationStore = require("../stores/careplan_notice_store");
var ConnectionStore = require("../stores/connection_store");
var StaffStore = require("../stores/staff_store");

var makeStores = require("../shared/stores")({
	people: PersonStore,
	currentPerson: CurrentPersonInfoStore,
	localization: LocalizationStore,
	connection: ConnectionStore,
	callgroups: CallGroupsStore,
	careplanChangeList: CareplanNotificationStore,
	staff: StaffStore
});

function make_ui(api, events, config, userMetrics) {
	var storeStore = makeStores(api, events, config);

	window.stores = storeStore.store;

	userMetrics.trackEvent("page view", {
		"page": "group-callgroups",
		"category": "group",
		"permission level": "staff",
	});

	return defineUI({
		getInitialState: getInitialState,
		componentDidMount: listen_for_changes,
		render: render
	});

	function listen_for_changes() {
		storeStore.listen(par(handle_change, this));
	}

	function handle_change(component, stores) {
		component.setState({
			currentPerson: stores.currentPerson,
			localization: stores.localization,
			people: stores.people,
			callgroups: stores.callgroups,
			careplanChangeList: stores.careplanChangeList,
			connection: stores.connection,
			staff: stores.staff
		});
	}

	function getInitialState() {
		return {
			currentPerson: storeStore.stores.currentPerson,
			localization: storeStore.stores.localization,
			people: storeStore.stores.people,
			connection: storeStore.stores.connection,
			careplanChangeList: storeStore.stores.careplanChangeList,
			callgroups: storeStore.stores.callgroups,
			staff: storeStore.stores.staff,
			viewing: null,
			creating: false,
			updating: false,
			adding: false,
			removing: false,
			removingMember: false,
			newName: null,
			newDescription: null,
			updateName: null,
			updateDescription: null,
			filteredMembers: null,
			file: null,
			fileView: null,
			create: createCallGroup,
			remove: removeCallGroup,
			update: updateCallGroup,
			removeMember: removeMember,
			addMember: addMember,
			search: doSearch,
			userMetrics: userMetrics,
		};
	}

	function createCallGroup(name, description, file){
		return api.org.callgroups.create(name, description, null, file);
	}

	function removeCallGroup(group){
		return api.org.callgroups.remove(group);
	}

	function updateCallGroup(group, name, description, file){
		return api.org.callgroups.update(group, name, description, null, file);
	}

	function removeMember(group, member){
		return api.org.callgroups.members.remove(group, member);
	}

	function addMember(group, member){
		return api.org.callgroups.members.add(group, member);
	}

	function doSearch(query) {
		var filter = {
			type: {
				exclude: ["atouchaway", "user:mobile", "simplified"]
			}
		};

		return api.people.search(query, filter);
	}
}


module.exports = make_ui;
