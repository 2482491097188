/* © 2014 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, June 19, 2014
 * For information or permission request, email info@aetonixsystems.com
 */

var par = require("par");
var debounce = require("debounce");

var defineUI = require("../shared/define");
var render = require("./render.jsx");

const PersonStore = require("../stores/person_store");
var OrgStaffStore = require("../stores/staff_store");
const StaffEmailStore = require("../stores/staff_email_store");
var LocalizationStore = require("../stores/localization_store");
var CurrentPersonInfoStore = require("../stores/current_person_info_store");
var StaffPreferencesStore = require("../stores/staff_preferences_store");
var CareplanNotificationStore = require("../stores/careplan_notice_store");
var ConnectionStore = require("../stores/connection_store");
var ManagerGroupsStore = require("../stores/group_manager_groups_store");
var ManagerGroupsManagersStore = require("../stores/group_manager_group_managers_store");

var makeStores = require("../shared/stores")({
	people: PersonStore,
	currentPerson: CurrentPersonInfoStore,
	localization: LocalizationStore,
	staff: OrgStaffStore,
	staffEmail: StaffEmailStore,
	staffPreferences: StaffPreferencesStore,
	careplanChangeList: CareplanNotificationStore,
	managerGroups: ManagerGroupsStore,
	managerGroupsManagers: ManagerGroupsManagersStore,
	connection: ConnectionStore
});

function make_ui(api, events, config, userMetrics) {
	var storeStore = makeStores(api, events, config);

	userMetrics.trackEvent("page view", {
		"page": "org-staff",
		"category": "group",
		"permission level": "staff",
	});

	return defineUI({
		getInitialState: getInitialState,
		componentDidMount: listen_for_changes,
		render: render,
		componentWillUnmount: removeListeners
	});

	function removeListeners() {
		events.off();
	}

	function listen_for_changes() {
		storeStore.listen(par(handle_change, this));
	}


	function handle_change(component, stores) {
		component.setState({
			people: stores.people,
			staff: stores.staff.all(),
			staffEmail: stores.staffEmail,
			currentPerson: stores.currentPerson,
			localization: stores.localization,
			staffPreferences: stores.staffPreferences,
			careplanChangeList: stores.careplanChangeList,
			connection: stores.connection,
			managerGroups: stores.managerGroups,
			managerGroupManagers: stores.managerGroupsManagers,
			keys: stores.staff.keys()
		});
	}

	function getInitialState() {
		storeStore.stores.managerGroupsManagers.initAll();
		return {
			people: storeStore.stores.people,
			currentPerson: storeStore.stores.currentPerson,
			staff: [],
			staffEmail: storeStore.stores.staffEmail,
			toRemove: {},
			removing: false,
			searching: false,
			editStaff: false,
			staffPreferences: getPreference,
			setPreferences: setPreferences,
			preferenceChange: {},
			removeStaff: api.org.staff.remove,
			addStaff: api.org.staff.add,
			searchNew: debounce(searchNew.bind(this), 200),
			searchExist: par(searchExist, storeStore.stores.staff),
			searchResults: [],
			loadMore: par(loadMore, storeStore.stores.staff),
			localization: storeStore.stores.localization,
			careplanChangeList: storeStore.stores.careplanChangeList,
			connection: storeStore.stores.connection,
			clearStaffVerification: verifyAccounts,
			verificationEdit: false,
			selectedStaff: [],
			keys: [],
			loadAllStaff: loadAllStaff,
			openVerify: false,
			singleVerification: false,
			filteredManagers: null,
			filteredManagerGroups: null,
			openManagerGroupsDialog: false,
			openManagerGroupAddConfirmation: false,
			openManagerGroupDeleteConfirmation: false,
			shouldClearManagerGroupSearch: false,
			managerGroups: storeStore.stores.managerGroups,
			managerGroupManagers: storeStore.stores.managerGroupsManagers,
			addAllToManagerGroup: addManagers,
			removeAllFromManagerGroup: removeManagers,
			staffName: "",
			toastText: "",
			openToast: false,
			userMetrics: userMetrics,
		};
	}

	function verifyAccounts(ids){
		return api.org.staff.clearverification(ids);
	}

	function addManagers(groupId, ids) {
		return api.org.managergroups.managers.addAll(groupId, ids);
	}

	function removeManagers(groupId, ids) {
		return api.org.managergroups.managers.removeAll(groupId, ids);
	}

	function searchNew(query) {
		var filter = {
			type: {
				exclude: ["atouchaway", "user:mobile", "simplified"]
			}
		};

		api.people.search(query, filter)
			.then(set_search_results.bind(this));
	}

	function searchExist(staff, event) {
		event.persist();
		var query = event.target.value;
		return staff.search(query);
	}

	function loadMore(staff) {
		return staff.more();
	}

	function set_search_results(results) {
		var staff = storeStore.stores.staff.all();
		var filtered = results.filter(function (item) {
			return (staff.indexOf(item._id) === -1);
		});
		this.setState({
			searchResults: filtered
		});
	}

	function getPreference(id) {
		return storeStore.stores.staffPreferences.get(id);
	}

	function setPreferences(id, data) {
		var preferenceData = data;
		api.org.staff.preferences.set(id, preferenceData);
	}

	function loadAllStaff(){
		storeStore.stores.staff.loadAllStaff();
	}
}

module.exports = make_ui;
