/* © 2017 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, January 01, 2017
 * For information or permission request, email info@aetonixsystems.com
 */

var par = require("par");

var defineUI = require("../shared/define");
var render = require("./render.jsx");

// Boilerplate required stores
var PersonStore = require("../stores/person_store");
var LocalizationStore = require("../stores/localization_store");
var CareplanNotificationStore = require("../stores/careplan_notice_store");
var ConnectionStore = require("../stores/connection_store");

// Stores unique to this UI
var FormDefinitionStore = require("../stores/org_forms_store");
var FormSubmissionsHistoryStore = require("../stores/org_forms_submission_history_store");
var CurrentPersonInfoStore = require("../stores/current_person_info_store");

var makeStores = require("../shared/stores")({
	people: PersonStore,
	localization: LocalizationStore,
	formdefinitionStore: FormDefinitionStore,
	submissionhistoryStore: FormSubmissionsHistoryStore,
	currentPerson: CurrentPersonInfoStore,
	careplanChangeList: CareplanNotificationStore,
	connection: ConnectionStore
});

function make_ui(api, events, config, userMetrics) {
	var storeStore = makeStores(api, events, config);

	window.stores = storeStore.stores;

	userMetrics.trackEvent("page view", {
		"page": "org-forms-submission-history",
		"category": "history",
		"permission level": "staff",
	});

	return defineUI({
		getInitialState: getInitialState,
		componentDidMount: listen_for_changes,
		render: render,
		componentWillUnmount: removeListeners
	});

	function removeListeners() {
		events.off();
	}

	function listen_for_changes() {
		storeStore.listen(par(handle_change, this));
	}

	function handle_change(component, stores) {
		component.setState({
			people: stores.people,
			localization: stores.localization,
			currentPerson: stores.currentPerson,
			definitions: stores.formdefinitionStore,
			submissions: stores.submissionhistoryStore.sorted(),
			careplanChangeList: stores.careplanChangeList,
			connection: stores.connection
		});
	}

	function getInitialState() {
		return {
			people: storeStore.stores.people,
			localization: storeStore.stores.localization,
			currentPerson: storeStore.stores.currentPerson,
			definitions: storeStore.stores.definitions,
			submissions: storeStore.stores.submissionhistoryStore.sorted(),
			more: more,
			openingModal: true,
			openingDataView: false,
			chosen_form: "",
			filterUser: filterUser,
			filterEditor: filterEditor,
			filterForms: filterForms,
			filterDate: filterDate,
			filterClear: filterClear,
			closeSearch: closeSearch,
			search: searchName,
			report: false,
			userFilter: false,
			editorFilter: false,
			formsFilter: false,
			dateFilter: false,
			user: [],
			editor: [],
			forms: [],
			startDate: "",
			endDate: new Date(),
			fileName: "",
			careplanChangeList: storeStore.stores.careplanChangeList,
			connection: storeStore.stores.connection,
			userMetrics: userMetrics,
		};
	}

	function more() {
		storeStore.stores.submissionhistoryStore.more();
	}

	/**
	 * Returns a list of the groups users
	 * @param  {objectId} query User_id
	 * @return {array}       User _id's
	 */
	function searchName(query) {
		return api.org.group.members(query);
	}

	function filterUser(component, data) {
		var user = component.state.user;
		component.setState({
			user: user.concat(data)
		});
		storeStore.stores.submissionhistoryStore.searchUser(data);
		userMetrics.trackEvent("org-forms-submission-history: filter by user", {
			user: data
		});
	}

	function filterEditor(component, data) {
		var editor = component.state.editor;
		component.setState({
			editor: editor.concat(data)
		});
		storeStore.stores.submissionhistoryStore.searchEditor(data);
		userMetrics.trackEvent("org-forms-submission-history: filter by editor", {
			editor: data
		});
	}

	function filterForms(component, data) {
		var forms = component.state.forms;
		component.setState({
			forms: forms.concat(data)
		});
		storeStore.stores.submissionhistoryStore.searchForms(data);
	}

	function filterDate(start, end) {
		storeStore.stores.submissionhistoryStore.searchDate(start, end);
	}

	function filterClear(name) {
		storeStore.stores.submissionhistoryStore.clearSearch(name);
	}

	function closeSearch(component, name) {
		if (name === "user") {
			component.setState({
				userFilter: false
			});
			userMetrics.trackEvent("org-forms-submission-history: close user filter popup");
		}

		if (name === "editor") {
			component.setState({
				editorFilter: false
			});
			userMetrics.trackEvent("org-forms-submission-history: close editor filter popup");
		}
	}
}

module.exports = make_ui;
