/* © 2014 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, June 19, 2014
 * For information or permission request, email info@aetonixsystems.com
 */

import * as Sentry from "@sentry/react";

var par = require("par");

var defineUI = require("../shared/define");
var render = require("./render.jsx");

var LocalizationStore = require("../stores/localization_store");
var CurrentPersonInfoStore = require("../stores/current_person_info_store");
var CareplanNotificationStore = require("../stores/careplan_notice_store");
var ConnectionStore = require("../stores/connection_store");
var AdminOrgGroupStore = require("../stores/admin_group_paged_store");
var AdminOrgStore = require("../stores/admin_org_store");
var AdminOrgGroupSettings = require("../stores/admin_org_group_settings");
var CustomProperties = require("../stores/admin_group_custom_properties");

var makeStores = require("../shared/stores")({
	currentPerson: CurrentPersonInfoStore,
	localization: LocalizationStore,
	careplanChangeList: CareplanNotificationStore,
	connection: ConnectionStore,
	groups: AdminOrgGroupStore,
	orgs: AdminOrgStore,
	settings: AdminOrgGroupSettings,
	customProperties: CustomProperties
});

function make_ui(api, events, config, userMetrics) {
	var storeStore = makeStores(api, events, config);

	window.stores = storeStore.store;

	userMetrics.trackEvent("page view", {
		"page": "admin-group-settings",
		"category": "admin",
		"permission level": "admin",
	});

	return defineUI({
		getInitialState: getInitialState,
		componentDidMount: listen_for_changes,
		render: render,
		componentWillUnmount: removeListeners
	});

	function removeListeners() {
		events.off();
	}

	function listen_for_changes() {
		storeStore.listen(par(handle_change, this));
	}

	function handle_change(component, stores) {
		component.setState({
			currentPerson: stores.currentPerson,
			localization: stores.localization,
			careplanChangeList: stores.careplanChangeList,
			connection: stores.connection,
			groups: stores.groups,
			orgs: stores.orgs,
			settings: stores.settings,
			customProperties: stores.customProperties
		});
	}

	function getInitialState() {
		return {
			currentPerson: storeStore.stores.currentPerson,
			localization: storeStore.stores.localization,
			careplanChangeList: storeStore.stores.careplanChangeList,
			connection: storeStore.stores.connection,
			groups: storeStore.stores.groups,
			orgs: storeStore.stores.orgs,
			settings: storeStore.stores.settings,
			customProperties: storeStore.stores.customProperties,
			loadMore: par(loadMore, storeStore.stores.groups),
			search: par(search, storeStore.stores.groups),
			update: null,
			viewing: null,
			save: saveSettings,
			isCustomPropertiesDialogOpen: false,
			groupCustomProperties: null,
			willUpdateCustomProperties: null,
			updateCustomProperties: updateCustomProperties,
			errorToast: false,
			openToast: false,
			toastMessage: "",
			userMetrics: userMetrics,
		};
	}

	function loadMore(groups) {
		groups.loadMore();
	}

	function search(groups, query) {
		groups.search(query);
	}

	function saveSettings(component, group, settings) {
		return api.admin.orgGroup.settings.set(group, settings)
			.then(() => {
				component.setState({
					openToast: true,
					toastMessage: "Settings have been saved successfully!"
				});
			}).catch(error => {
				Sentry.captureException(error);
				showError(component, error);
			});
	}

	function updateCustomProperties(component, groupId, customProperties) {
		return api.admin.orgGroup.updateCustomProperties(groupId, customProperties)
			.then(() => {
				component.setState({
					isCustomPropertiesDialogOpen: false,
					willUpdateCustomProperties: null,
					openToast: true,
					toastMessage: "Update group custom properties successfully"
				});
			}).catch(error => {
				Sentry.captureException(error);
				showError(component, error);
			});
	}

	function showError(component, err) {
		component.setState({errorToast: err.response.message});
	}
}

module.exports = make_ui;
