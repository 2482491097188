/* © 2014 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, June 19, 2014
 * For information or permission request, email info@aetonixsystems.com
 */

import React, { useState, useMemo, useCallback, useRef } from "react";

var par = require("par");
var config = require("../../configs/config.json");
var Promise = require("promise");
var escape_regex = require("escape-regexp");

var Style = require("ae-style").default;

var Header = require("../shared/Header.js");
var IconButton = require("@material-ui/1.5.1/IconButton").default;
var Avatar = require("@material-ui/1.5.1/Avatar").default;
var ListItem = require("@material-ui/1.5.1/ListItem").default;
var ListItemText = require("@material-ui/1.5.1/ListItemText").default;
var Dialog = require("../shared/Dialog");
var FontIcon = require("@material-ui/1.5.1/Icon").default;
var Grid = require("@material-ui/core/Grid").default;
var Button = require("@material-ui/1.5.1/Button").default;
var TextField = require("@material-ui/core/TextField").default;
var Select = require("@material-ui/1.5.1/Select").default;
var FormControl = require("@material-ui/1.5.1/FormControl").default;
var InputLabel = require("@material-ui/1.5.1/InputLabel").default;
var MenuItem = require("@material-ui/1.5.1/MenuItem").default;
var Tabs = require("@material-ui/1.5.1/Tabs").default;
var Tab = require("@material-ui/1.5.1/Tab").default;
var Toggle = require("@material-ui/1.5.1/Switch").default;
var Snackbar = require("@material-ui/1.5.1/Snackbar").default;
var Tooltip = require("@material-ui/1.5.1/Tooltip").default;
var Checkbox = require("@material-ui/1.5.1/Checkbox").default;
var FormHelperText = require("@material-ui/1.5.1/FormHelperText").default;
const FormGroup = require("@material-ui/1.5.1/FormGroup").default;
const FormControlLabel = require("@material-ui/1.5.1/FormControlLabel").default;
import { TextareaAutosize, FormLabel } from "@material-ui/core";
import { withStyles, styled } from "@material-ui/core/styles";
// eslint-disable-next-line node/no-missing-require
const PatientCreation = require("./PatientCreation").default;


var Utility = require("../shared/Utility");
var LazyList = require("../shared/LazyList.jsx");
var Search = require("../shared/Search.jsx");
var Colors = require("../shared/AetonixTheme").palette;

const { GlobalAlertContext } = require("../shared/GlobalAlert");

import * as Sentry from "@sentry/react";
import { ACTIVE_STATES, USER_STATES } from "../shared/UserStates";
import { useUserMetrics } from "@aetonix/user-metrics";

var iconButtonClasses = {
	root: "ae-inline"
};

var styles = {
	dropUnderline: {borderTop: 0 },
	listItem: {
		width: "50%",
		padding: "0 16px"
	},
	listItemContainer: {
		width: "100%",
		display: "flex",
		flexDirection: "row"
	},
	dropDown: {
		alignItems: "baseline",
		marginLeft: "30px",
		padding: "0 5px",
		color: Colors.primary.main
	},
	search: {
		color: Colors.primary.main,
		minWidth: "30%",
		padding: 8
	},
	subHeader: {
		display: "flex",
		flexDirection: "row",
		paddingLeft: "1em",
		alignItems: "baseline"
	},
	input: {
		color: "grey",
		marginTop: "8px",
		marginBottom: "8px"
	},
	inputProp: {style: {color: Colors.primary.main}},
	formControlStyle: {
		marginTop: "8px",
		marginBottom: "8px"
	},
	userInactive: {color: "#C70000"},
	snackbar: {style: {backgroundColor: "red"}},
	selectStyle: {minWidth: "10%"},
	textFieldStyle: {minWidth: "25%"},
	button: {marginLeft: "0.5%"},
	toast: {
		margin: "100px",
		padding: "10px",
		backgroundColor: "rgb(0, 172, 193)", //rgb(0, 199, 26)
		borderRadius: "10px"
	},
	toastText: {
		color: Colors.canvas,
		margin: "5px"
	}
};

const StyledTextareaAutosize = styled(TextareaAutosize)({
	width: "100%",
	border: "1px solid #88A4B0",
	borderRadius: "4px",
	fontFamily: "Poppins",
	fontSize: "16px",
	fontWeight: 400,
	color: "#102543",
	resize: "none",
	"&:disabled": {
		backgroundColor: "#00000033",
		borderRadius: "4px",
	},
	minHeight: "80px",
	rows: 3,
	cols: 40,
	wordWrap: "break-word",
	whiteSpace: "pre-line",
	marginTop: -5
});


const InactivePatientModal = ({ component, localization, isConfirming }) => {
	const [shouldRemoveManagers, setShouldRemoveManagers] = useState(false);
	const [shouldStopWorkflows, setShouldStopWorkflows] = useState(false);
	const [shouldRemoveContacts, setShouldRemoveContacts] = useState(false);

	const deactivatePatient = useCallback(() => doUpdate(component, { shouldRemoveManagers, shouldStopWorkflows, shouldRemoveContacts }), [
		component, shouldRemoveManagers, shouldStopWorkflows, shouldRemoveContacts
	]);

	const actions = useMemo(() => (
		<>
			<Button key={"buttondeactivate"} onClick={deactivatePatient}>
				{localization.get("users_inactive_deactive")}
			</Button >
			<Button key={"buttoncancel"} onClick={par(hideInfo, component)}>{localization.get("users_cancel")}</Button>
		</>
	));

	const onRemoveManagers = useCallback(checked => {
		setShouldStopWorkflows(checked);
		setShouldRemoveManagers(checked);
	}, [setShouldStopWorkflows, setShouldRemoveManagers]);

	return (
		<Dialog actions={actions} open={!!isConfirming} title={localization.get("users_setinactive_title")} >
			{localization.get("users_inactive_change")}

			<FormGroup style={{ paddingTop: "0.5em" }}>
				<FormControlLabel control={
					<Checkbox
						checked={!!shouldRemoveManagers}
						onChange={event => onRemoveManagers(event.target.checked)}
					/>}
					label={localization.get("users_inactive_remove_managers")}
				/>
				<FormControlLabel control={
					<Checkbox
						checked={!!shouldRemoveContacts}
						onChange={event => setShouldRemoveContacts(event.target.checked)}
					/>}
					label={localization.get("users_inactive_remove_contacts")}
				/>
				<FormControlLabel control={
					<Checkbox
						checked={!!shouldStopWorkflows}
						disabled={!!shouldRemoveManagers}
						onChange={event => setShouldStopWorkflows(event.target.checked)}
					/>}
					label={localization.get("users_inactive_stop_workflows")}
				/>
			</FormGroup>
		</Dialog>
	);
};

module.exports = render;

const CreateModal = ({component, localization, open}) => {
	const { state } = component;
	const { careCoordinatorSearch, emrUserSearch, createUserLanguage, searchExist } = state;

	const { userMetrics } = useUserMetrics();

	const [tabValue, setTabValue] = useState(0);

	const emrSearchRef = useRef(null);
	const searchRef = useRef(null);
	const submitRef = useRef(null);

	const onClickCreate = tabValue === 0
		? () => {
			component.state.userMetrics.trackEvent("group-users: create user");
			submitRef?.current?.dispatchEvent(new Event("submit", { cancelable: true }));
		}
		: par(doCreate, component, true);

	const actions = [
		<Button key={"buttoncreate"} onClick={onClickCreate}>{localization.get("users_create")}</Button>,
		<Button key={"buttoncancel"} onClick={par(hideCreate, component)}>{localization.get("users_cancel")}</Button>,
	];

	const showEmrSearch = () => {
		component.state.userMetrics.trackEvent("group-users: open emr search popup");
		emrSearchRef?.current?.show();
	};
	const showSearchDialog = () => {
		component.state.userMetrics.trackEvent("group-users: open care coordinator search popup");
		searchRef?.current?.show();
	};

	const handleAddEmrUser = (_id, person) => {
		state.addEMRUser(component, _id, person);
		emrSearchRef?.current?.dismiss();
		component.state.userMetrics.trackEvent("group-users: add emr user", {
			patient: _id,
			emr: person,
		});
	};

	const renderSearchExist = (
		<div>
			<div className="flex-horizontal ae-createSubtitles ae-spaceBetween">
				<div className="ae-padright">{localization.get("users_create_search_existing")} : </div>
				<IconButton color="primary" className="fa fa-edit" onClick={showEmrSearch} />
			</div>
			{renderAddEMRUser(component)}
			<Search noPicture action={handleAddEmrUser} ref={emrSearchRef} search={emrUserSearch} localization={localization} />
		</div>
	);

	const renderEmailSearchExist = searchExist ? renderSearchExist : null;

	const checkedManageGroups = getSelectedManageGroups(state.newManageGroup);
	const renderedNewManageGroup = getSelectedManageGroups(state.newManageGroup);
	const manageGroupsRenderValue = (ids) => {
		return getManageGroupProperty(state.manageGroups, ids, "name");
	};
	const renderManageGroups = renderUserManageGroupsList(component, checkedManageGroups);

	return (
		<Dialog actions={actions} open={!!open} title={localization.get("users_create")}>
			<Tabs fullWidth value={tabValue}
			onChange={(event, value) => {
				setTabValue(value);
				const eventText = [
					"group-users: navigate to create new user tab in create user popup",
					"group-users: navigate to email invitation tab in create user popup",
				];
				userMetrics.trackEvent(eventText);
			}}
			indicatorColor="primary"
		>
			<Tab label={localization.get("users_create_new")} onClick={par(hidePendingUser, component)} />
			<Tab label={localization.get("users_email_invitation")} onClick={par(hidePendingUser, component)} />
		</Tabs>
		{tabValue === 0 && (
			<Grid container direction="column" justifyContent="center" alignItems="center" spacing={4}>
				<Grid item>
					<PatientCreation
						localization={state.localization}
						component={component}
						submitRef={submitRef}
						showEmrSearch={showEmrSearch}
						showSearchDialog={showSearchDialog}
						renderAddCare={renderAddCare}
						doCreate={doCreate}
					/>
				</Grid>
				<Search
					onDismiss={() =>
						component.state.userMetrics.trackEvent("group-users: close care coordinator search popup")
					}
					selection={state.careCoordinators}
					action={value => {
						component.state.userMetrics.trackEvent("group-users: add care coordinator", {
							patient: state.newPatient?._id,
							"care coordinator": value,
						});
						return state.addCareCoordinator(component, value);
					}}
					ref={searchRef}
					search={careCoordinatorSearch}
					localization={localization}
				/>
				<Search
					onDismiss={() =>
						component.state.userMetrics.trackEvent("group-users: close emr search popup")
					}
					noPicture
					action={handleAddEmrUser}
					ref={emrSearchRef}
					search={emrUserSearch}
					localization={localization}
				/>
			</Grid>
		)}
			{tabValue === 1 && (
				<div>
					<div className="flex-horizontal ae-createSubtitles">
						<div className="ae-padright">{localization.get("search_exist_user")} : </div>
						<div className="ae-padright">{localization.get("search_exist_no")}</div>
						<Toggle checked={state.searchExist} inputProps={{ "aria-label": localization.get("search_exist_user") }} onChange={par(updateToggleChanged, component, "searchExist")} />
						<div className="ae-padleft">{localization.get("search_exist_yes")}</div>
					</div>
					{renderEmailSearchExist}
					<div className="flex-horizontal ae-createSubtitles">
						<div className="ae-padright">{localization.get("users_email")} : </div>
						<TextField style={styles.textFieldStyle} {...(addTextFieldErrorProps(component, "newEmail"))} inputProps={{ "aria-label": localization.get("users_email") }} placeholder={localization.get("users_email")} value={state.newEmail} onChange={par(updateActionChanged, component, "newEmail")} InputProps={styles.inputProp} />
					</div>
					{renderCreateUserLanguageSelect(component, createUserLanguage)}
					<div className="flex-horizontal ae-createSubtitles">
						<div className="ae-padright">{localization.get("managergroups_usergroup") + ":"}</div>
						<Select
							style={styles.selectStyle}
							onChange={par(updateActionChanged, component, "newManageGroup")}
							value={renderedNewManageGroup}
							inputProps={{ "aria-label": localization.get("managergroups_usergroup") }}
							renderValue={manageGroupsRenderValue}
							multiple
						>
							{renderManageGroups}
						</Select>
					</div>
					<div className="flex-horizontal ae-createSubtitles ae-spaceBetween">
						<div className="ae-padright">{localization.get("user_carecoordinator")} : <i>{localization.get("user_carecoordinator_warning")}</i> </div>
						<IconButton aria-label={localization.get("user_carecoordinator_warning")} color="primary" className="fa fa-edit" onClick={showSearchDialog} />
					</div>
					{renderAddCare(component)}
					<Search selection={state.careCoordinators} action={par(state.addCareCoordinator, component)} ref={searchRef} search={careCoordinatorSearch} localization={localization} />
					<Grid
						item
						xs={12}
					>
						<div>{localization.get("pinned_note")}</div>
					</Grid>
					<Grid
						item
						style={{ paddingTop: "10px" }}
						xs={12}
					>
						<StyledTextareaAutosize
							id="user_note"
							aria-label={localization.get("pinned_note")}
							minRows={1}
							maxRows={2}
							onChange={par(updateActionChanged, component, "newUserNotes")}
						/>
						{state.userNoteError && <div style={{ color: "red" }}>{localization.get("patientCreation.user_note_error")}</div>}
					</Grid>
				</div>
			)}
		</Dialog>
	);
};

function render() {
	var component = this;
	var state = component.state;
	var users = state.users;
	var invitations = state.invitations;
	var people = state.people;
	var userData = state.userData;
	var toRemove = state.toRemove;
	var toRemoveInvitation = state.toRemoveInvitation;
	var toRemoveOrgGroupInvitation = state.toRemoveOrgGroupInvitation;
	var localization = state.localization;
	var careplanNoticeList = state.careplanChangeList.all();
	var careplanChanges = !!careplanNoticeList.length;
	var offline = state.connection.get("offline");
	var tokens = state.tokens;
	var tokenUser = state.tokenUser; // not defined in state
	var anonUser = state.anonUser;  // not defined in state
	var creating = state.creating;
	var currentPerson = state.currentPerson;
	var editUser = state.editUser;
	var usersInactive = state.usersInactive;
	var sortedUsers = users.map(userData.get).sort(by_name);
	var loadMore = state.loadMore;
	var search = state.search;
	var userStateFilter = state.userStateFilter;
	var titleKey = "users_title";
	var renderList = invitations.concat(sortedUsers);
	var openEmail = state.openEmail;
	var errorToast = state.errorToast;
	var confirming = state.confirming;
	var managerGroups = state.managerGroups;
	const createUserType = state.createUserType;
	const createUserLanguage = state.createUserLanguage;
	var filteredManageGroups = state.filteredManageGroups;
	var selectedManageGroup = state.selectedManageGroup;
	const filterManageGroupValue = state.filterManageGroupValue;
	const manageGroupList = renderUserManageGroupsList(component, filterManageGroupValue);

	var usersSelect = state.usersSelect;
	var manageGroupsDialog = state.manageGroupsDialog;
	var removeManageGroup = state.removeManageGroup;
	var openManageGroupAddConfirmation = state.openManageGroupAddConfirmation;
	var openToast = state.openToast;
	var toastSuccess = state.toastSuccess;
	var selectAllWarningToast = state.selectAllWarningToast;

	state.manageGroups.sort(byManagerGroupsName);
	var allManageGroups = managerGroups.all().sort(byManagerGroupsName);


	if (state.shouldClearManageGroupSearch) {
		state.filteredManageGroups = null;
		state.shouldClearManageGroupSearch = false;
	}

	var shownManageGroups = filteredManageGroups ? filteredManageGroups : allManageGroups;

	var remove_actions = [(
		<Button key={"buttonok"} onClick={par(confirmRemove, component)}>{localization.get("users_ok")}</Button >
	), (
		<Button key={"buttoncancel"} onClick={par(hideRemove, component)}>{localization.get("users_cancel")}</Button >
	)];

	var token_actions = [
		<Button key={"buttonclose"} onClick={par(hideToken, component)} >
			{localization.get("users_close")}
		</Button >
	];

	var email_actions = [
		<Button key={"buttonclose"} onClick={par(hideEmail, component)} >
			{localization.get("users_close")}
		</Button >
	];

	var anonymous_actions = [
		<Button key={"buttonanonymous"} onClick={par(hideAnonymous, component)} >
			{localization.get("users_close")}
		</Button  >
	];

	var edit_actions = [(
		<Button key={"buttoncancel"} onClick={par(hideInfo, component)}>{localization.get("users_cancel")}</Button >
	), (
		<Button key={"buttonsave"} disabled={(state.newFName === null || state.newLName === null) ? true : false} onClick={par(showConfirmUpdate, component)}>{localization.get("users_edit_save")}</Button >
	)];

	var remove_inviation_actions = [(
		<Button key={"buttonok"} onClick={par(confirmInvitationRemove, component)}>{localization.get("users_ok")}</Button>
	), (
		<Button key={"buttoncancel"} onClick={par(hideInvitationRemove, component)}>{localization.get("users_cancel")}</Button>
	)];

	var remove_orggroup_inviation_actions = [(
		<Button key={"buttonok"} onClick={par(confirmOrgGroupInvitationRemove, component)}>{localization.get("users_ok")}</Button>
	), (
		<Button key={"buttoncancel"} onClick={par(hideOrgGroupInvitationRemove, component)}>{localization.get("users_cancel")}</Button>
	)];

	var manageGroup_actions = (
		<Button onClick={par(closeManageGroupsDialog, component)} >{localization.get("group_users_multiselection_change_managegroups_done_button")} </Button>
	);

	var managegroup_delete_actions = [(
		<Button key={"buttonok"} onClick={par(removeAllFromManageGroups, component)} >{localization.get("group_users_multiselection_change_managegroups_ok_button")}</Button>
	), (
		<Button key={"buttoncancel"} onClick={par(closeManageGroupsDialog, component)} >{localization.get("group_users_multiselection_change_managegroups_cancel_button")}</Button>
	)];

	var managegroup_add_actions = [(
		<Button key={"buttonok"} onClick={par(addAllToManageGroup, component)} >{localization.get("group_users_multiselection_change_managegroups_ok_button")}</Button>
	), (
		<Button key={"buttoncancel"} onClick={par(closeManageGroupsConfirm, component)} >{localization.get("group_users_multiselection_change_managegroups_cancel_button")}</Button>
	)];

	var renderUserListItem = par(renderUser, component, usersInactive, par(showUserRemoval, component), par(showInvitationRemoval, component), par(showOrgGroupInvitationRemoval, component));

	const manageGroupsRenderValue = (ids) => {
		return getManageGroupProperty(state.manageGroups, ids, "name");
	};

	var selectDoneButton =  usersSelect ? localization.get("group_users_multiselection_done_button") : localization.get("group_users_multiselection_select_button");

	var renderSelectAll = usersSelect ? (
		<Button variant="raised" color="secondary" onClick={par(selectAll, component)} style={styles.button} >{localization.get("group_users_multiselection_select_all_button")}</Button>
	) : null;

	var renderRemoveAllManageGroup = (state.selectedUser.length !== 0) ? (
		<Button variant="raised" color="secondary" onClick={par(openRemoveManageGroupDialog, component)} style={styles.button} >{localization.get("group_users_multiselection_remove_all_managegroups_button")}</Button>
	) : null;

	var renderManageGroupsButton = (state.selectedUser.length !== 0) ? (
		<Button variant="raised" color="secondary" onClick={par(openManageGroupsDialog, component)} style={styles.button} >{localization.get("group_users_multiselection_change_managegroups_button")}</Button>
	) : null;

	var renderTestmodeEnable = (state.selectedUser.length !== 0) ? (
		<Button variant="raised" color="secondary" onClick={par(enableSelectedUsersTestMode, component)} style={styles.button} >{localization.get("testmode.turnon")}</Button>
	) : null;

	var renderTestModeDisable = (state.selectedUser.length !== 0) ? (
		<GlobalAlertContext.Consumer>
			{(ga)=>{
				return <Button variant="raised" color="secondary" onClick={par(disableSelectedUsersTestMode, component, ga)} style={styles.button} >{localization.get("testmode.turnoff")}</Button>;
			}}
		</GlobalAlertContext.Consumer>

	) : null;

	var manageGroupName = selectedManageGroup ? selectedManageGroup.name : "";
	var managegroup_add_confirmation = `${localization.get("group_users_multiselection_change_managegroups_add_confirmation")} ${manageGroupName}?`;
	var toastCheckmark = toastSuccess ? <FontIcon className="fa fa-check fa-2x" style={styles.icon} /> : <FontIcon className="fa fa-exclamation fa-2x" style={styles.icon} />;

	if (rowsHaveChanged(renderList, state.previousRenderList)) {
		component.state.userMetrics.trackEvent("group-users: view creation/token table", {
			patients: renderList.map((user) => user._id).filter((id) => id),
		});
		component.setState({ previousRenderList: renderList });
	}

	return (
		<div className="flex-vertical flex-1">
			<Header
				careplanChanges={careplanChanges}
				offline={offline}
				currentPerson={currentPerson}
				localization={localization}
				titleKey={titleKey}
			/>

			<div style={styles.subHeader}>
				<TextField placeholder={localization.get("search_onNameorpatientNum")} inputProps={{"aria-label": localization.get("search_onNameorpatientNum")}} onChange={search} style={styles.search} InputProps={styles.inputProp} />
				<Select
					value={userStateFilter}
					onChange={par(filterStatus, component)}
					style={styles.dropDown}
					renderValue={values => renderUserStatesValue(values, localization)}
					displayEmpty
					multiple
				>
					{renderUserStatesList(component, userStateFilter)}
				</Select>
				<Select
					value={filterManageGroupValue}
					onChange={par(filterManageGroup, component)}
					style={styles.dropDown}
					renderValue={ids => manageGroupsRenderValue(ids) || localization.get("status_filter_all")}
					displayEmpty
					multiple
				>
					{manageGroupList}
				</Select>
			</div>
			<div style={{ marginBottom: 8, paddingLeft: "1em"  }}>
				<Button variant="raised"  color="secondary" onClick={par(selectButton, component)} style={styles.button} >{selectDoneButton}</Button>
				{renderSelectAll}
				{renderManageGroupsButton}
				{renderTestmodeEnable}
				{renderTestModeDisable}
			</div>

			<LazyList loadMore={loadMore} renderItem={renderUserListItem} items={renderList} />

			<Button variant="raised" onClick={par(showCreate, component)} color="secondary" >{localization.get("users_add")}</Button>

			<RemoveFromOrgDialog
				open={!!toRemove}
				localization={localization}
				toRemove={toRemove ? people.get(toRemove) : null}
				onConfirm={par(confirmRemove, component)}
				onClose={par(hideRemove, component)}
				remove_actions={remove_actions}
			/>

			<InactivePatientModal localization={localization} component={component} isConfirming={confirming}/>

			<Dialog actions={token_actions} open={!!tokenUser} title={localization.get("users_token_title")} >
				{localization.get("users_token_message")}
				<b className="ae-selectable" > {tokens.get(tokenUser)} </b>
			</Dialog>

			<Dialog actions={email_actions} open={!!openEmail} title={localization.get("users_email_title")} >
				{localization.get("users_email_message")}
				<b className="ae-selectable" > {openEmail} </b>
			</Dialog>

			<Dialog actions={anonymous_actions} open={!!anonUser} title={localization.get("anonymous_id_title")} >
				{localization.get("anonymous_id_text")}
				<b className="ae-selectable" > {getUserId(anonUser)} </b>
			</Dialog>

			<Dialog actions={remove_inviation_actions} open={!!toRemoveInvitation} title={localization.get("users_cancel_email_invitation")} >
				{localization.get("users_email_warning")}
				{toRemoveInvitation ? toRemoveInvitation.email : null}
				?
			</Dialog>

			<Dialog actions={remove_orggroup_inviation_actions} open={!!toRemoveOrgGroupInvitation} title={localization.get("users_cancel_email_invitation")} >
				{localization.get("users_email_warning")}
				{toRemoveOrgGroupInvitation ? toRemoveOrgGroupInvitation.email : null}
				?
			</Dialog>

			<Dialog actions={edit_actions} open={!!editUser} title={localization.get("users_edit")}>
				{renderUserInfo(component)}
			</Dialog>

			<Dialog open={removeManageGroup} maxWidth="md" title={localization.get("group_users_multiselection_remove_all_managegroups_confirmation_title")} actions={managegroup_delete_actions} >
				<div>{localization.get("group_users_multiselection_remove_all_managegroups_confirmation")}</div>
			</Dialog>

			<Dialog actions={manageGroup_actions} open={manageGroupsDialog} maxWidth="md" title={localization.get("group_users_multiselection_change_managegroups_title")}>
				<div>
					{renderRemoveAllManageGroup}
				</div>
				<TextField placeholder={localization.get("group_users_multiselection_change_managegroups_search")} inputProps={{"aria-label": localization.get("group_users_multiselection_change_managegroups_search")}} onChange={par(searchManageGroups, component, allManageGroups, "ManageGroups")} style={styles.search} InputProps={styles.inputProp} />
				<LazyList className=" ae-scrollable" renderItem={par(renderManageGroupsDialog, component)} items={shownManageGroups} />
			</Dialog>

			<Dialog open={openManageGroupAddConfirmation} maxWidth="md" title={localization.get("group_users_multiselection_change_managegroups_add_confirmation_title")} actions={managegroup_add_actions} >
				<div>{managegroup_add_confirmation}</div>
			</Dialog>

			<CreateModal component={component} localization={localization} open={!!creating} />

			<Snackbar open={openToast} autoHideDuration={5000} onClose={par(handleCloseToast, this)}>
				<div className="flex-horizontal ae-dropshadow" style={styles.toast}>
					{toastCheckmark}
					<div style={styles.toastText}>
						{toastSuccess ? localization.get("group_users_multiselection_change_managegroups_toast_successful") : localization.get("group_users_multiselection_change_managegroups_toast_failure")}
					</div>
				</div>
			</Snackbar>

			<Snackbar open={selectAllWarningToast} autoHideDuration={8000} onClose={par(handleCloseToast, this)}>
				<div className="flex-horizontal ae-dropshadow" style={styles.toast}>
					<FontIcon className="fa fa-exclamation fa-2x" style={styles.icon} />
					<div style={styles.toastText}>
						{localization.get("group_users_multiselection_select_all_warning_toast")}
					</div>
				</div>
			</Snackbar>

			<Snackbar
				ContentProps={styles.snackbar}
				open={errorToast}
				autoHideDuration={6000}
				onClose={par(hideToast, component)}
				message={errorToast}
				action={<IconButton className="fa fa-times" onClick={par(hideToast, component)}></IconButton>}
			/>
		</div>
	);
}

function rowsHaveChanged(newRows, oldRows) {
	newRows = newRows || [];
	oldRows = oldRows || [];
	if (newRows.length !== oldRows.length) return true;
	for (var i = 0; i < newRows.length; i++) {
		if (newRows[i]._id !== oldRows[i]._id) return true;
	}
	return false;
}

function hideToast(component) {
	component.setState({errorToast: false});
}

function getUserId(anonUser) {
	if (!anonUser) return "";
	return anonUser.slice(0, 4) + anonUser.slice(-4);
}

function renderAddEMRUser(component) {
	var state = component.state;
	var localization = state.localization;
	const formInlineErrors = state.formInlineErrors;
	const errorFName = formInlineErrors["newFName"];
	const errorLName = formInlineErrors["newLName"];

	const renderFName = errorFName ? (
		<FormControl error>
			<FormHelperText>{localization.get("errors.group_users.no_newLName")}</FormHelperText>
		</FormControl>
	) : <div className="ae-padright">{state.newFName}</div>;

	const renderLName = errorLName ? (
		<FormControl error>
			<FormHelperText>{localization.get("errors.group_users.no_newFName")}</FormHelperText>
		</FormControl>
	) : <div className="ae-padright">{state.newLName}</div>;

	return (
		<div>
			<div className="flex-horizontal ae-createSubtitles">
				<div className="ae-padright">{localization.get("users_fname")} : </div>
				{renderFName}
			</div>
			<div className="flex-horizontal ae-createSubtitles">
				<div className="ae-padright">{localization.get("users_lname")} : </div>
				{renderLName}
			</div>
			<div className="flex-horizontal ae-createSubtitles">
				<div className="ae-padright">{localization.get("user_emrNumber")} : </div>
				<div className="ae-padright">{state.newNumber}</div>
			</div>
		</div>
	);
}

function renderAddCare(component) {
	var state = component.state;
	var loadMore = state.loadMore;
	var people = state.people;
	var sortedCareCoordinators = state.careCoordinators.map(people.get).sort(by_name);
	return (
		<LazyList loadMore={loadMore} renderItem={par(renderCareCoordItem, component)} items={sortedCareCoordinators} />
	);
}

function renderCareCoordItem(component, data) {
	if (!data) return;
	var id = data._id;
	var fname = data.fname;
	var lname = data.lname;
	var fullName = lname + ", " + fname;
	var remove = <IconButton color="primary" className="fa fa-times-circle" onClick={par(removeCareCoordinator, component, id)} />;

	return (
		<div key={id} className="flex-horizontal flex-space-between">
			<ListItem>
				<ListItemText primary={fullName} />
			</ListItem>
			{remove}
		</div>
	);
}

function removeCareCoordinator(component, id) {
	var state = component.state;
	var existing = state.careCoordinators;
	component.state.userMetrics.trackEvent("group-users: remove care coordinator", {
		"care coordinator": id,
	});
	var remaining = existing.filter(function (care) {
		return care !== id;
	});
	component.setState({careCoordinators: remaining});
}

function by_name(x, y) {
	return order(x.lname.toLowerCase() + x.fname.toLowerCase(), y.lname.toLowerCase() + y.fname.toLowerCase());
}

function byManagerGroupsName(x, y) {
	return order(x.name.toLowerCase(), y.name.toLowerCase());
}

function order(x, y){
	if(x < y)
		return -1;
	if(x > y)
		return 1;
	return 0;
}

function getUserStateUpdate(currentTarget, prevStates, currStates) {
	const currHasAllActive = currStates.includes("all_active");
	const prevHadAllActive = prevStates.includes("all_active");
	const currHasInactive = currStates.includes("inactive");

	const checkedActive = !prevHadAllActive && currHasAllActive;
	const uncheckedActive = prevHadAllActive && !currHasAllActive;

	let result = currHasInactive ? ["inactive"] : [];

	if (uncheckedActive) return result;
	if (checkedActive) {
		result.push("all_active");
		return result;
	}

	// if "all_active" is currently checked and an active state is clicked, then the "all_active"
	// checkbox should be unchecked, as well as the active state that was clicked.
	if (currHasAllActive && currentTarget.attributes["data-active-state"]) {
		const selectedState = currentTarget.getAttribute("data-value");
		return ACTIVE_STATES.filter(userState => userState !== selectedState).concat(result);
	}

	return currStates;
}

function filterStatus(component, event) {
	event.persist();
	const { value } = event.target;

	const prevValue = component.state.userStateFilter;
	const newUserState = getUserStateUpdate(event.currentTarget, prevValue, value);

	component.setState({ userStateFilter: newUserState });
	component.state.searchOnStatus(newUserState);

	component.state.userMetrics.trackEvent("group-users: filter patients by status", {
		page: "group-users",
		filter: newUserState,
	});
}

function filterManageGroup(component, event) {
	event.persist();
	const value = event.target.value;
	component.setState({ filterManageGroupValue: value });
	const searchOnManageGroup = component.state.searchOnManageGroup;
	component.state.userMetrics.trackEvent("group-users: filter patients by patient group", {
		page: "group-users",
		filter: value,
	});
	return searchOnManageGroup(value);
}

function updateToggleChanged(component, name, event) {
	event.persist();
	var value = event.target.checked;
	var update = {
		newFName: "",
		newLName: "",
		newNumber: "",
		newUserNotes: "",
		userNoteError: false,
		careCoordinators: [],
		newEmail: "",
		newManageGroup: [],
		createUserType: (component.state.groupSettings && component.state.groupSettings.defaultUserType) || "atouchaway",
		createUserLanguage: "",
		formInlineErrors: {}
	};
	update[name] = value;
	component.setState(update);
}

function updateActionChanged(component, name, event) {
	event.persist();
	var value = event.target.value;
	var update = {};
	if( name === "newUserNotes" && value.length > 30) {
			update["userNoteError"] = true;
	}
	if (name === "newFName" || name === "newLName") {
        if (value.trim() === "") {
            update[name] = null;
        } else {
            update[name] = value;
        }
    } else {
        update[name] = value;
    }
	component.setState(update);
}

function renderUser(component, usersInactive, remove, removeInvite, removeGroupInvite, user) {
	var state = component.state;
	var localization = state.localization;
	var imageUrl = user.image || "default.png";
	var avatar = <Avatar alt="User Avatar Image" src={config.image_cdn + imageUrl} />;
	var type = user.type || [];

	var selectedUser = component.state.selectedUser || [];
	var isChecked = selectedUser.indexOf(user._id) !== -1;

	if (type.indexOf("registration:mobile") !== -1 || type.indexOf("org:user") !== -1) {
		return renderEmailInvite(type, user, localization, avatar, removeInvite, removeGroupInvite);
	} else if (type.indexOf("user:mobile") !== -1) {
		return renderUserMobile(user, usersInactive, remove, component, avatar, isChecked);
	} else {
		return renderUserAtouchaway(user, remove, component, usersInactive, avatar, isChecked);
	}
}


function getManageGroupProperty(manageGroups, ids, property) {
	if (!Array.isArray(manageGroups) || !manageGroups || !ids) {
		return;
	}
	ids = Utility.getAsArray(ids);
	const manageGroupNames = [];
	ids.forEach((manageGroupId) => {
		const manageGroup = manageGroups.find(({ _id }) => manageGroupId === _id);
		const manageGroupName = manageGroup ? manageGroup[property] : "";
		if (manageGroupName) {
			manageGroupNames.push(manageGroupName);
		}
	});
	return manageGroupNames.join(", ");
}

function renderUserAtouchaway(user, remove, component, usersInactive, avatar, isChecked){
	var localization = component.state.localization;
	var editButton = renderEdit(remove, user, component, localization, usersInactive);
	var userId = user._id;
	var background_colour = "ae-plain";
	var textStyle = {};

	const manageGroups = component.state.manageGroups;
	const manageGroupId = user.manage_group;
	const manageGroupName = getManageGroupProperty(manageGroups, manageGroupId, "name");

	if (usersInactive.get(userId)) {
		background_colour = background_colour + "ae-fade";
		textStyle = styles.userInactive;
	}

	var viewing = component.state.viewing;
	if (viewing && userId === viewing._id) background_colour = "ae-hover-color";

	var checkboxes = renderCheckboxes(component, userId, isChecked);

	var userData = component.state.userData;
	var userNumber = userData.get(userId).patientNumber;
	var patientNumber = userNumber ? userNumber : null;
	var number = <div className="ae-numbers">{patientNumber ? patientNumber : ""}</div>;

	var text = (
		<div className="ae-listText" style={textStyle}>
			<div>
				{Utility.format_name(user)}
			</div>
			{number}
		</div>
	);

	return (
		<ListItem key={userId} className={background_colour} onClick={par(set_viewing, component, user)}>
			{checkboxes}
			{avatar}
			<div style={styles.listItemContainer}>
				<ListItemText style={styles.listItem} primary={text} />
				<ListItemText style={styles.listItem} primary={manageGroupName} />
			</div>

			{editButton}
		</ListItem>
	);
}

function renderEmailInvite(type, user, localization, avatar, removeInvite, removeGroupInvite){
	var invitationId = user._id;
	var background_colour = "ae-plain";

	var clickAction = type.indexOf("registration:mobile") !== -1 ? par(removeInvite, user) : par(removeGroupInvite, user);

	var popperProps = {disablePortal: true};

	var removeButton = (
		<Tooltip title={localization.get("tooltip_remove")} PopperProps={popperProps}>
			<IconButton  color="primary" className="fa fa-user-times" onClick={clickAction} />
		</Tooltip>
	);

	var text = (
		<div className="ae-listText" >
			<div>
				{user.email}
			</div>
			{localization.get("users_email_pending")}
		</div>
	);

	return (
		<ListItem key={invitationId} className={background_colour} >
			{avatar}
			<ListItemText primary={text} />
			{removeButton}
		</ListItem>
	);
}

function renderUserMobile(user, usersInactive, remove, component, avatar, isChecked){
	var localization = component.state.localization;
	var background_colour = "ae-plain";
	var userId = user._id;
	var textStyle = {};

	const manageGroups = component.state.manageGroups;
	const manageGroupId = user.manage_group;
	const manageGroupName = getManageGroupProperty(manageGroups, manageGroupId, "name");

	if (usersInactive.get(userId)) {
		background_colour = background_colour + "ae-fade";
		textStyle = styles.userInactive;
	}

	var userIsInactive = usersInactive.get(user._id);
	var viewing = component.state.viewing;
	if (viewing && userId === viewing._id) background_colour = "ae-hover-color";

	var checkboxes = renderCheckboxes(component, userId, isChecked);

	var userData = component.state.userData;

	var userNumber = userData.get(userId).patientNumber;
	var patientNumber = userNumber ? userNumber : null;
	var number = <div className="ae-numbers">{patientNumber ? patientNumber : ""}</div>;

	var text = (
		<div className="ae-listText" style={textStyle}>
			<div>
				{Utility.format_name(user)}
			</div>
			{number}
		</div>
	);

	var tokenButton = par(renderToken, component, user._id);
	var infoButton = par(showEdit, component, user);
	var emailButton = par(renderEmail, component, user.email);
	var anonButton = par(renderAnonymous, component, user._id);
	var go = par(goToPatient, component, user._id);

	const testModeEnabled = !!user.testModeEnabled;

	var popperProps = {disablePortal: true};

	var renderEmailOrToken = user.type.indexOf("login:none") !== -1 ? (
		!userIsInactive && <Tooltip title={localization.get("tooltip_token")} PopperProps={popperProps}>
			<IconButton color="primary" className="fa fa-certificate" onClick={tokenButton} />
		</Tooltip>
	) : (
		<Tooltip title={localization.get("tooltip_email")} PopperProps={popperProps}>
			<IconButton color="primary" className="fa fa-at" onClick={emailButton} />
		</Tooltip>
	);

	var renderButton = (
		<div style={{display: "flex"}}>
			<Tooltip title={localization.get("tooltip_view")} PopperProps={popperProps}>
				<IconButton color="primary" className="fa fa-eye" onClick={go} />
			</Tooltip>
			<Tooltip title={localization.get("tooltip_edit")} PopperProps={popperProps}>
				<IconButton color="primary" className="fa fa-edit" onClick={infoButton} />
			</Tooltip>

			{(!userIsInactive || testModeEnabled) && <GlobalAlertContext.Consumer>
				{(ga)=><Tooltip title={localization.get("testmode.title") + ": " + (testModeEnabled ? localization.get("common.toggle.enabled") : localization.get("common.toggle.disabled"))} PopperProps={popperProps}>
				<IconButton color="primary" className="fa fa-bug" style={{color: testModeEnabled ? Style.colors.orange : undefined}}  onClick={par(handleTestModeToggle, component, ga, testModeEnabled, user._id)} />
				</Tooltip>}
			</GlobalAlertContext.Consumer>}
			{renderEmailOrToken}
			<Tooltip title={localization.get("tooltip_anon")} PopperProps={popperProps}>
				<IconButton color="primary" className="fa fa-user-secret" onClick={anonButton} />
			</Tooltip>
			<Tooltip title={localization.get("tooltip_remove_org")} PopperProps={popperProps}>
				<IconButton  color="primary" className="fa fa-user-times" onClick={par(remove, user._id)} />
			</Tooltip>
		</div>
	);

	return (
		<ListItem key={userId} className={background_colour} onClick={par(set_viewing, component, user)} >
			{checkboxes}
			{avatar}
			<div style={styles.listItemContainer}>
				<ListItemText style={styles.listItem} primary={text} />
				<ListItemText style={styles.listItem} primary={manageGroupName} />
			</div>
			{renderButton}
		</ListItem>
	);
}

function set_viewing(component, user) {
	component.setState({viewing: user});
}

function showInvitationRemoval(component, invitation) {
	component.setState({toRemoveInvitation: invitation});
	component.state.userMetrics.trackEvent("group-users: open remove invitation popup", {
		invitation: invitation._id,
	});
}

function confirmInvitationRemove(component) {
	var id = component.state.toRemoveInvitation._id;
	component.state.removeInvitation(id);
	component.setState({toRemoveInvitation: null});
	component.state.userMetrics.trackEvent("group-users: remove invitation", {
		invitation: id,
	});
}

function hideInvitationRemove(component) {
	component.setState({toRemoveInvitation: null});
	component.state.userMetrics.trackEvent("group-users: cancel remove invitation popup");
}

function showOrgGroupInvitationRemoval(component, invitation) {
	component.setState({toRemoveOrgGroupInvitation: invitation});
	component.state.userMetrics.trackEvent("group-users: open remove invitation popup", {
		invitation: invitation._id,
	});
}

function confirmOrgGroupInvitationRemove(component) {
	var id = component.state.toRemoveOrgGroupInvitation._id;
	component.state.removeOrgGroupInvitation(id);
	component.setState({toRemoveOrgGroupInvitation: null});
	component.state.userMetrics.trackEvent("group-users: remove invitation", {
		invitation: id,
	});
}

function hideOrgGroupInvitationRemove(component) {
	component.setState({toRemoveOrgGroupInvitation: null});
	component.state.userMetrics.trackEvent("group-users: close remove invitation popup");
}

function getSelectedManageGroups(newManageGroup, savedManageGroups) {
	if (newManageGroup) return newManageGroup;
	if (savedManageGroups) return savedManageGroups;

	return [];
}

function renderUserInfo(component) {
	var state = component.state;
	var user = state.editUser;
	if (!user) return;
	var userId = user._id;
	var fname = user.fname;
	var lname = user.lname;
	var user_notes = user?.user_notes || [];
	var defaultNotes = user_notes.length > 0 ? user_notes[0]?.comment : state.newUserNotes;
	var localization = state.localization;

	var usersInactive = state.usersInactive;
	var userIsInactive = usersInactive.get(userId);
	var currentNewInactive = state.newInactive;
	var renderedNewInactive = (currentNewInactive === null) ? userIsInactive : currentNewInactive;

	var usersPrivate = state.usersPrivate;
	var privateData = usersPrivate.get(userId);
	var currentNewSearch = state.newSearchHidden;
	var renderedNewSearch = (currentNewSearch === null) ? privateData.searchHidden : currentNewSearch;

	var userData = state.userData;
	var userNumber = userData.get(userId).patientNumber;

	const manageGroups = state.manageGroups;
	const checkedManageGroups = getSelectedManageGroups(state.newManageGroup, Utility.getAsArray(user.manage_group));
	var renderManageGroups = renderUserManageGroupsList(component, checkedManageGroups);

	var updateNewFName = par(updateActionChanged, component, "newFName");
	var updateNewUserNotes = par(updateActionChanged, component, "newUserNotes");
	var updateNewLName = par(updateActionChanged, component, "newLName");
	var updateNewInactive = par(updateActionChanged, component, "newInactive");
	var updateNewNumber = par(updateActionChanged, component, "newNumber");
	var updateNewSearchHidden = par(updateActionChanged, component, "newSearchHidden");
	var handleUpdateNewUserType = par(updateNewUserType, component, user);
	var updateManageGroup = (event) => {
		component.setState({ currManageGroups: Utility.getAsArray(user.manage_group) });
		updateActionChanged(component, "newManageGroup", event);
	};

	var searchSelect = privateData.loading ? "" : (
		<FormControl fullWidth={true} style={styles.formControlStyle}>
			<InputLabel>{localization.get("private_title")}</InputLabel>
			<Select aria-label= {localization.get("private_title")} className={"ae-useredit"} onChange={updateNewSearchHidden} value={renderedNewSearch}>
				<MenuItem value={false}>{localization.get("private_visible")}</MenuItem>
				<MenuItem value={true}>{localization.get("private_hidden")}</MenuItem>
			</Select>
		</FormControl>
	);

	var currentUserType = "atouchaway";
	if (user.type.indexOf("user:mobile") !== -1) {
		currentUserType = "user:mobile";
	} else if (user.type.indexOf("simplified") !== -1) {
		currentUserType = "simplified";
	}
	var renderUserTypeValue = state.newUserType ? state.newUserType : currentUserType;
	var renderUserTypeSelect = (
		<FormControl fullWidth={true} style={styles.formControlStyle}>
			<InputLabel>{localization.get("user_type_select")}</InputLabel>
			<Select aria-label= {localization.get("user_type_select")} inputProps={{"aria-label": localization.get("user_type_select")}} className={"ae-useredit"} onChange={handleUpdateNewUserType} value={renderUserTypeValue}>
				<MenuItem value={"atouchaway"}>{localization.get("ata_user")}</MenuItem>
				<MenuItem value={"user:mobile"}>{localization.get("mobile_user")}</MenuItem>
				<MenuItem value={"simplified"}>{localization.get("mobile_simplified")}</MenuItem>
			</Select>
		</FormControl>
	);

	return (
		<div>
			<TextField fullWidth defaultValue={fname} inputProps={{"aria-label": localization.get("users_fname")}} error={ state.newFName === null  ? true : false}  onChange={updateNewFName} helperText= {state.newFName === null  ? localization.get("errors.group_users.no_newFName") : null} label={localization.get("users_fname")} style={styles.input} InputProps={styles.inputProp} />
			<TextField fullWidth defaultValue={lname} inputProps={{"aria-label": localization.get("users_lname")}}  error={ state.newLName === null  ? true : false}  helperText= {state.newLName === null  ? localization.get("errors.group_users.no_newLName") : null}  onChange={updateNewLName} label={localization.get("users_lname")} style={styles.input} InputProps={styles.inputProp} />
			<TextField fullWidth defaultValue={userNumber} inputProps={{"aria-label": localization.get("user_emrNumber")}} onChange={updateNewNumber} label={localization.get("user_emrNumber")} style={styles.input} InputProps={styles.inputProp} />
			{renderUserTypeSelect}
			<FormControl fullWidth={true} style={styles.formControlStyle}>
				<InputLabel>{localization.get("private_active_state")}</InputLabel>
				<Select aria-label= {localization.get("private_active_state")}  className={"ae-useredit"} onChange={updateNewInactive} value={renderedNewInactive}>
					<MenuItem value={true}>{localization.get("user_inactive")}</MenuItem>
					<MenuItem value={false}>{localization.get("user_active")}</MenuItem>
				</Select>
			</FormControl>
			<FormControl fullWidth={true} style={styles.formControlStyle}>
				<InputLabel>{localization.get("managergroups_usergroup")}</InputLabel>
				<Select
					className={"ae-useredit"}
					onChange={updateManageGroup}
					value={checkedManageGroups}
					aria-label= {localization.get("managergroups_usergroup")}
					renderValue={ids => getManageGroupProperty(manageGroups, ids, "name")}
					multiple
				>
					{renderManageGroups}
				</Select>
			</FormControl>
			{searchSelect}
			<Grid
				item
				xs={12}
			>
				<div>{localization.get("pinned_note")}</div>
			</Grid>
			<Grid
				item
				style={{ paddingTop: "10px" }}
				xs={12}
			>
				<StyledTextareaAutosize
					id="user_note"
					aria-label={localization.get("pinned_note")}
					minRows={1}
					maxRows={2}
					defaultValue={defaultNotes}
					onChange={updateNewUserNotes}
				/>
				{state.userNoteError && <div style={{ color: "red" }}>{localization.get("patientCreation.user_note_error")}</div>}
			</Grid>
		</div>
	);
}

function renderEdit(remove, user, component, localization, usersInactive) {
	var tokenButton = par(renderToken, component, user._id);
	var anonButton = par(renderAnonymous, component, user._id);
	var removeButton = par(remove, user._id);
	var go = par(goToPatient, component, user._id);

	var infoButton = par(showEdit, component, user);
	var userIsInactive = usersInactive.get(user._id);

	var popperProps = {disablePortal: true};

	const testModeEnabled = !!user.testModeEnabled;
	return (
		<div style={{display: "flex"}}>
			<Tooltip title={localization.get("tooltip_view")} PopperProps={popperProps}>
				<IconButton color="primary" aria-label={localization.get("tooltip_view")}  title={localization.get("tooltip_view")}  role="button" className="fa fa-eye" onClick={go} />
			</Tooltip>
			<Tooltip title={localization.get("tooltip_edit")} PopperProps={popperProps}>
				<IconButton color="primary" aria-label={localization.get("tooltip_edit")} title={localization.get("tooltip_edit")} role="button" className="fa fa-edit" onClick={infoButton} />
			</Tooltip>
			{(!userIsInactive || testModeEnabled) && <GlobalAlertContext.Consumer>
				{(ga)=><Tooltip title={localization.get("testmode.title") + ": " + (testModeEnabled ? localization.get("common.toggle.enabled") : localization.get("common.toggle.disabled"))} PopperProps={popperProps}>
				<IconButton color="primary" aria-label={localization.get("testmode.title") + ": " + (testModeEnabled ? localization.get("common.toggle.enabled") : localization.get("common.toggle.disabled"))}  title={localization.get("testmode.title") + ": " + (testModeEnabled ? localization.get("common.toggle.enabled") : localization.get("common.toggle.disabled"))}  role="button" className="fa fa-bug" style={{color: testModeEnabled ? Style.colors.orange : undefined}}  onClick={par(handleTestModeToggle, component, ga, testModeEnabled, user._id)} />
			</Tooltip>}
			</GlobalAlertContext.Consumer>}
			{!userIsInactive && <Tooltip title={localization.get("tooltip_token")} PopperProps={popperProps}>
				<IconButton color="primary" aria-label={localization.get("tooltip_token")} title={localization.get("tooltip_token")} role="button" className="fa fa-certificate" onClick={tokenButton} />
			</Tooltip>}
			<Tooltip title={localization.get("tooltip_anon")} PopperProps={popperProps}>
				<IconButton color="primary" aria-label={localization.get("tooltip_anon")}title={localization.get("tooltip_anon")} role="button" className="fa fa-user-secret" onClick={anonButton} />
			</Tooltip>
			<Tooltip title={localization.get("tooltip_remove_org")} PopperProps={popperProps}>
				<IconButton color="primary" aria-label={localization.get("tooltip_remove_org")} title={localization.get("tooltip_remove_org")} role="button" className="fa fa-user-times" onClick={removeButton} />
			</Tooltip>
		</div>
	);
}

function handleTestModeToggle(component, ga, testModeEnabled, userId){
	const localization = component.state.localization;
	if(testModeEnabled){
		component.state.userMetrics.trackEvent("group-users: open disable test mode popup", {
			user: userId,
			enabled: !testModeEnabled,
		});
		ga.show({
			affirmativeText: localization.get("common.ok"),
			negativeText: localization.get("common.cancel"),
			affirmativeAction: ()=> {
				component.state.userMetrics.trackEvent("group-users: disable test mode for patient", {
					user: userId,
				});
				return component.state.disableTestMode(userId);
			},
			negativeAction: ()=>{
				component.state.userMetrics.trackEvent("group-users: close disable test mode popup", {
					user: userId,
				});
			},
			title: localization.get("testmode.title"),
			description: localization.get("testmode.description")
		});
	}else{
		component.state.userMetrics.trackEvent("group-users: enable test mode for patient", {
			user: userId,
			enabled: !testModeEnabled,
		});
		component.state.enableTestMode(userId);
	}
}

function goToPatient(component, userId){
	component.state.goToUser(userId);
	component.state.userMetrics.trackEvent("group-users: view patient", {
		user: userId,
	});
}

function renderAnonymous(component, user) {
	component.state.userMetrics.trackEvent("group-users: open anonymous ID popup", {
		user,
	});
	component.setState({anonUser: user});
}

function renderToken(component, user) {
	component.setState({tokenUser: user});
	component.state.userMetrics.trackEvent("group-users: open view token popup", {
		user: user._id,
	});
}
function renderEmail(component, email) {
	var emailAddress = email ? email : "User has no email.";
	component.setState({openEmail: emailAddress});
}

function showUserRemoval(component, person) {
	component.setState({toRemove: person});
	const userIsMobile = component.state.userData.get(person).type.indexOf("user:mobile") !== -1;
	component.state.userMetrics.trackEvent(
		userIsMobile
			? "group-users: open remove user from organization popup"
			: "group-users: open permanently delete user popup",
		{
			user: person
		}
	);
}

function confirmRemove(component) {
	var id = component.state.toRemove;
	component.state.removeUser(id);
	component.setState({toRemove: null});
	component.state.userMetrics.trackEvent("group-users: remove user from organization", {
		user: id,
	});
}

function hideRemove(component) {
	component.setState({toRemove: null});
	const userIsMobile = component.state.userData.get(component.state.toRemove).type.indexOf("user:mobile") !== -1;
	component.state.userMetrics.trackEvent(
		userIsMobile
			? "group-users: close remove user from organization popup"
			: "group-users: close permanently delete user popup"
	);
}

function hideToken(component) {
	component.setState({tokenUser: null});
	component.state.userMetrics.trackEvent("group-users: close token popup");
}

function hideEmail(component) {
	component.setState({openEmail: null});
	component.state.userMetrics.trackEvent("group-users: close email popup");
}

function hideAnonymous(component) {
	component.setState({anonUser: null});
	component.state.userMetrics.trackEvent("group-users: close anonymous ID popup");
}

function showCreate(component) {
	const defaultUserType = (component.state.groupSettings && component.state.groupSettings.defaultUserType) || "atouchaway";
	component.setState({ creating: true, createUserType: defaultUserType });
	component.state.userMetrics.trackEvent("group-users: create user popup opened");
}


function showEdit(component, user) {
	component.state.userMetrics.trackEvent("group-users: edit user popup opened");
	return component.setState({editUser: user, newNumber: user.patientNumber});
}

function doCreate(component, shouldEmailCreate, data) {
	var state = component.state;
	var fname = data.fname || state.newFName;
	var lname = data.lname || state.newLName;
	var patientNumber = data.patient_id || state.newNumber;
	var user_note = data.user_note || state.newUserNotes;
	var manageGroup = data.managegroup || state.newManageGroup;
	var careCoordinators = data.managers || state.careCoordinators;
	var email = data.email || state.newEmail;
	var createUserType = data.user_type || state.createUserType;
	const lang = data.language || state.createUserLanguage;
	const isSearchExist = state.searchExist;

	component.state.userMetrics.trackEvent("group-users: create user");

	const requiredLanguage = ["createUserLanguage"];
	let requiredFields = [];
	if (shouldEmailCreate && isSearchExist) {
		requiredFields = requiredLanguage.concat(["newEmail", "newFName", "newLName"]);
	} else if (shouldEmailCreate) {
		requiredFields = requiredLanguage.concat(["newEmail"]);
	}
	const formInlineErrors = checkFormInlineErrors(component, requiredFields);

	if (Object.keys(formInlineErrors).length > 0) {
		component.setState({
			formInlineErrors: formInlineErrors
		});
		return;
	}

	if (email && shouldEmailCreate) {
		const emailRE = /\S+@\S+\.\S+/;
		const isValid = emailRE.test(email);
		if (!isValid) {
			component.setState({
				formInlineErrors: {newEmail: state.localization.get("errors.group_users.email_format")}
			});
			return;
		}
		state.sendInvitation(email, careCoordinators, fname, lname, patientNumber, manageGroup, lang, user_note)
			.catch(error => {
				Sentry.captureException(error);
				console.error.bind(console, error);
			});
		hideCreate(component);
	} else if (fname && lname) {
		let createHandler = state.createUserATA;
		if (createUserType === "Mobile") {
			createHandler = state.createUserMobile;
		} else if (createUserType === "Simplified") {
			createHandler = state.createUserBasic;
		}

		const {
			managers: createManagers,
			managegroup: createManageGroup,
			patientNumber: createPatientNumber,
			...restData
		} = data;
		createHandler(component, fname, lname, patientNumber, lang, user_note, restData)
			.then(function (result) {
				if (!result) throw new Error("Handled API error");
				if (createPatientNumber) {
					var user_id = result.id;
					state.updateCareplanNumber(createPatientNumber, user_id);
				}
				if (createManageGroup && createManageGroup.length > 0)
					state.assignGroup(result.id, createManageGroup);
				return result.id;
			})
			.then(function (id) {
				return Promise.all(
					createManagers.map(function (manager) {
						state.createCareCoordinator(component, id, manager);
					})
				);
			})
			.then(() => hideCreate(component))
			.catch(error => {
				// API errors have already been logged into sentry in ./ui.jsx
				if (error.message === "Handled API error") return;
				Sentry.captureException(error);
				console.error.bind(console, error);
			});
	}
}

function hideCreate(component) {
	component.setState({
		creating: false,
		newFName: "",
		newUserNotes: "",
		userNoteError: false,
		newLName: "",
		searchExist: false,
		newNumber: "",
		careCoordinators: [],
		newEmail: null,
		newUserType: null,
		newManageGroup: null,
		currManageGroups: null,
		createUserType: (component.state.groupSettings && component.state.groupSettings.defaultUserType) || "atouchaway",
		createUserLanguage: "",
		formInlineErrors: {}
	});
	component.state.userMetrics.trackEvent("group-users: close create user popup");
}

function showConfirmUpdate(component){
	var state = component.state;
	var newInactive = state.newInactive;

	component.state.userMetrics.trackEvent("group-users: open update user popup");

	if(newInactive)
		component.setState({confirming: true});
	else
		doUpdate(component);
}

function doUpdate(component, shouldRemoveFlags) {
	var state = component.state;
	var editingUser = state.editUser;
	var user_id = editingUser._id;

	var user_note = editingUser.user_notes ? editingUser.user_notes[0]?.comment : "";
	var newFName = state.newFName;
	var newLName = state.newLName;
	var newUserNotes = state.newUserNotes;
	var newNumber = state.newNumber;
	var newInactive = state.newInactive;
	var newSearchHidden = state.newSearchHidden;
	var newUserType = state.newUserType;

	if (newFName || newLName || (newSearchHidden !== null) || newUserNotes !== null) {

		var existingFname = editingUser.fname;
		var existingLname = editingUser.lname;

		var user_data = {};

		if ((existingFname !== newFName) || (existingLname !== newLName)) {
			user_data.fname = state.newFName || existingFname;
			user_data.lname = state.newLName || existingLname;
		}

		user_data.user_note = newUserNotes !== null ? newUserNotes : user_note;

		if (newSearchHidden !== null)
			user_data.searchHidden = newSearchHidden;

		if (Object.keys(user_data).length)
			component.state.updateUser(user_data, user_id)
				.catch(error => {
					Sentry.captureException(error);
					console.error.bind(console, error);
				});
	}

	if (newInactive !== null) {
		var existingInactive = state.usersInactive.get(user_id);
		if (newInactive !== existingInactive)
			state.updateInactive(newInactive, user_id, shouldRemoveFlags);
	}

	var existingNumber = state.userData.get(user_id).patientNumber;
	if (newNumber != existingNumber)
		state.updateCareplanNumber(newNumber, user_id);

	if (newUserType) {
		state.updateUserType(newUserType, user_id);
	}

	const {
		manageGroupsToRemove = [],
		manageGroupsToAdd = [],
	} = updateManageGroups(state);
	if (manageGroupsToRemove.length > 0) {
		state.removeGroup(user_id, manageGroupsToRemove);
	}
	if (manageGroupsToAdd.length > 0) {
		state.assignGroup(user_id, manageGroupsToAdd);
	}
	hideInfo(component);

	component.state.userMetrics.trackEvent("group-users: update user", {
		user: user_id,
	});
}

function updateManageGroups(state) {
	const { currManageGroups, newManageGroup } = state;
	if (!newManageGroup) return {};

	// if currManageGroups is empty, then user is not currently in any group
	// and all groups are new groups
	if(!currManageGroups || currManageGroups.length === 0) {
		return { manageGroupsToAdd: newManageGroup };
	}

	const manageGroupsToRemove = currManageGroups.filter((currManageGroup) => {
		return newManageGroup.indexOf(currManageGroup) <= -1;
	});

	const manageGroupsToAdd = newManageGroup.filter((newGroup) => {
		return currManageGroups.indexOf(newGroup) <= -1;
	});

	return { manageGroupsToAdd, manageGroupsToRemove };
}

function hideInfo(component) {
	component.setState({
		editUser: null,
		newInactive: null,
		newFName: "",
		newUserNotes: "",
		userNoteError: false,
		newLName: "",
		searchExist: false,
		newNumber: "",
		newSearchHidden: null,
		newEmail: null,
		confirming: null,
		removeManagers: null,
		newUserType: null,
		newManageGroup: null,
		currManageGroups: null,
	});
	component.state.userMetrics.trackEvent("group-users: close edit user popup");
}

function hidePendingUser(component) {
	component.setState({
		newFName: "",
		newLName: "",
		newUserNotes: "",
		userNoteError: false,
		searchExist: false,
		newNumber: "",
		emrUser: "",
		careCoordinators: [],
		newEmail: "",
		newManageGroup: null,
		currManageGroups: null,
		createUserType: (component.state.groupSettings && component.state.groupSettings.defaultUserType) || "atouchaway",
		createUserLanguage: "",
		formInlineErrors: {}
	});
}

function updateNewUserType(component, user, event) {
	event.persist();
	var value = event.target.value;
	if (value === "user:mobile" && user.type.indexOf("user:mobile") === -1) {
		component.setState({
			newUserType: "user:mobile"
		});
	} else if (value === "atouchaway" && user.type.indexOf("atouchaway") === -1) {
		component.setState({
			newUserType: "atouchaway"
		});
	} else if (value === "simplified" && user.type.indexOf("simplified") === -1) {
		component.setState({
			newUserType: "simplified"
		});
	} else {
		component.setState({
			newUserType: null
		});
	}
}

function renderUserManageGroupsList(component, checkedManageGroups) {
	const state = component.state;
	const manageGroups = state.manageGroups;

	return [].concat(manageGroups.map((group) => (
		<MenuItem key={group._id} value={group._id}>
			<Checkbox inputProps={{"aria-label": group.name}} checked={checkedManageGroups.indexOf(group._id) > -1}/>
			<ListItemText primary={group.name} />
		</MenuItem>
	)));
}

function renderUserStatesList(component, checkedStates) {
	const { localization } = component.state;

	const isActiveState = userState => ACTIVE_STATES.includes(userState);
	const isChecked = (userState) => {
		const shouldCheckActiveStates = checkedStates.includes("all_active");
		if (shouldCheckActiveStates && isActiveState(userState)) return true;

		return checkedStates.includes(userState);
	};

	return USER_STATES.map((userState) => {
		const dataAttrs = {};
		if (isActiveState(userState)) dataAttrs["data-active-state"] = true;
		if (userState === "all_active") dataAttrs["data-all_active"] = true;
		return (
			<MenuItem key={userState} value={userState} {...dataAttrs}>
				<Checkbox checked={isChecked(userState)} {...dataAttrs}/>
				<ListItemText primary={localization.get(`status_filter_${userState}`)} />
			</MenuItem>
		);
	});
}

function renderUserStatesValue(values, localization) {
	if (values.length === 0) return localization.get("status_filter_all");

	return values.map(val => localization.get(`state_${val}`)).join(", ");
}

function selectButton(component) {
	component.setState({
		usersSelect: !component.state.usersSelect,
		selectedUser: [],
		removeManageGroup: false
	});
	component.state.userMetrics.trackEvent(`group-users: toggle multi select to ${!component.state.usersSelect ? "on" : "off"}`);
}

function renderCheckboxes(component, userId, isChecked) {
	var usersSelect = component.state.usersSelect;
	var localization = component.state.localization;
	if (!usersSelect) return null;
	return (<Checkbox
		id={userId}
		value={userId}
		key={userId + isChecked}
		inputProps={{"aria-label": localization.get("checkbox_button") }}
		defaultChecked={isChecked}
		onChange={par(handleSelectCheckBox, component, userId)}/>);
}

function handleSelectCheckBox(component, userId) {
	var selectedUser = [].concat(component.state.selectedUser || []);
	var index = selectedUser.indexOf(userId);
	if(index === -1){
		selectedUser.push(userId);
	}else{
		selectedUser.splice(index, 1);
	}

	component.setState({selectedUser});
	component.state.userMetrics.trackEvent(`group-users: ${index === -1 ? "add user to" : "remove user from"} multiselect`, {
		user: userId,
		"current selected users": selectedUser,
	});
}

function selectAll(component) {
	var {state} = component;
	var {keys} = state;
	var selectedUser = keys;

	state.loadAllUser();
	component.setState({
		selectedUser,
		selectAllWarningToast: true
	});

	component.state.userMetrics.trackEvent("group-users: select all");
}

function openManageGroupsDialog(component) {
	component.setState({
		manageGroupsDialog: true
	});
	component.state.userMetrics.trackEvent("group-users: open multiselect change patient groups popup");
}

function openRemoveManageGroupDialog(component){
	component.setState({
		removeManageGroup: true
	});
	component.state.userMetrics.trackEvent("group-users: open multiselect remove all from patient groups popup");
}

function closeManageGroupsDialog(component) {
	component.setState({
		selectedUser: [],
		usersSelect: false,
		manageGroupsDialog: false,
		shouldClearManageGroupSearch: true,
		removeManageGroup: false
	});
	component.state.userMetrics.trackEvent("group-users: close multiselect change patient groups popup");
}

function searchManageGroups(component, managerGroupArray, key, event) {
	event.persist();
	var query = event.target.value;
	var terms = escape_regex(query).split(" ").join("");
	var regex = new RegExp(terms, "i");

	var filtered = managerGroupArray.filter((item) => {
		var matches = getName(item).split(" ").join("").match(regex);
		if (matches) return item;
	});

	var update = {};
	update["filtered" + key] = filtered;

	component.setState(update);
}

function getName(item) {
	var name = item.name || item.lname + item.fname;
	return name;
}

function renderManageGroupsDialog(component, group) {
	var text = group.name;
	var secondaryText = group.description;
	var groupId = group._id;
	var background_colour = "ae-white";

	return (
		<ListItem key={groupId} className={background_colour}>
			<ListItemText primary={text} secondary={secondaryText} />
			<IconButton aria-label={text} classes={iconButtonClasses} disableRipple color="primary" className="fa fa-user-plus" onClick={par(confirmAddToManageGroup, component, group)} />
		</ListItem>
	);
}

function confirmAddToManageGroup(component, group) {
	component.setState({
		openManageGroupAddConfirmation: true,
		selectedManageGroup: group
	});
	component.state.userMetrics.trackEvent("group-users: open bulk add to patient group confirmation popup");
}

function handleCloseToast(component) {
	component.setState({
		openToast: false,
		selectAllWarningToast: false,
	});
}

function sendToast(component, type) {
	component.setState({
		toastSuccess: (type === "Success"),
		openToast: true
	});
}

function closeManageGroupsConfirm(component) {
	component.setState({
		openManageGroupAddConfirmation: false,
		openManageGroupDeleteConfirmation: false,
		selectedManageGroup: null
	});
	component.state.userMetrics.trackEvent("group-users: close bulk add to patient group confirmation popup");
}

function addAllToManageGroup(component) {
	var state = component.state;
	var groupId = state.selectedManageGroup._id;
	var arrOfIds = state.selectedUser;
	if (arrOfIds.length === 0) {
		sendToast(component, "Success");
	} else {
		state.assignUsersToManageGroup(arrOfIds, groupId)
			.then(par(sendToast, component, "Success"))
			.catch(error => {
				Sentry.captureException(error);
				par(sendToast, component, "Fail");
			});
	}
	closeManageGroupsConfirm(component);
}

function removeAllFromManageGroups(component) {
	const state = component.state;
	const arrOfIds = state.selectedUser;
	if (arrOfIds.length === 0) {
		sendToast(component, "Success");
	} else {
		state.removeUsersFromManageGroups(arrOfIds)
			.then(par(sendToast, component, "Success"))
			.catch(error => {
				Sentry.captureException(error);
				par(sendToast, component, "Fail");
			});
	}
	closeManageGroupsDialog(component);
	component.state.userMetrics.trackEvent("group-users: remove all users from patient groups");
}

function disableSelectedUsersTestMode(component, ga) {
	const state = component.state;
	const localization = state.localization;
	const arrOfIds = state.selectedUser;
	component.state.userMetrics.trackEvent("group-users: disable test mode for selected users", {
		users: arrOfIds,
	});
	if (arrOfIds.length !== 0) {
		ga.show({
			affirmativeText: localization.get("common.ok"),
			negativeText: localization.get("common.cancel"),
			affirmativeAction: () => {

				return Promise.resolve(state.disableTestModeBulk(arrOfIds)).finally(par(closeUserSelect, component));
			},
			title: localization.get("testmode.title"),
			description: localization.get("testmode.description")
		});
	}
}

function enableSelectedUsersTestMode(component) {
	var state = component.state;
	var arrOfIds = state.selectedUser;
	if (arrOfIds.length !== 0) {
		state.enableTestModeBulk(arrOfIds);
	}
	closeUserSelect(component);
	component.state.userMetrics.trackEvent("group-users: enable test mode for selected users");
}

function closeUserSelect(component){
	component.setState({
		selectedUser: [],
		usersSelect: false,
	});
}

function renderCreateUserLanguageSelect(component, renderValue) {
	const state = component.state;
	const localization = state.localization;
	const formInlineErrors = state.formInlineErrors;
	const requiredFieldError = formInlineErrors["createUserLanguage"] ? true : false;
	const languageList = ["en", "fr", "cn_s", "cn_t", "es", "pt", "ta"];

	const renderLanguageList = () => {
		return [].concat(languageList.map(lang => (
			<MenuItem key={lang} value={lang}>{localization.get(lang)}</MenuItem>
		)));
	};

	return (
		<div className="flex-horizontal ae-createSubtitles">
			<div className="ae-padright">{localization.get("users_create_language") + ":"}</div>
			<FormControl style={styles.selectStyle} error={true}>
				<Select error={requiredFieldError} inputProps={{"aria-label": localization.get("users_create_language")}} onChange={par(updateActionChanged, component, "createUserLanguage")} value={renderValue}>
					{renderLanguageList()}
				</Select>
				{requiredFieldError ? (<FormHelperText>{localization.get("errors.group_users.no_createUserLanguage")}</FormHelperText>) : null}
			</FormControl>
		</div>
	);
}

function checkFormInlineErrors(component, fields) {
	const state = component.state;
	const localization = state.localization;

	const formInlineErrors = {};
	fields.forEach(field => {
		if (!state[field]) {
			formInlineErrors[field] = localization.get(`errors.group_users.no_${field}`);
		}
	});
	return formInlineErrors;
}


function addTextFieldErrorProps(component, field) {
	const formInlineErrors = component.state.formInlineErrors;

	return {
		error: formInlineErrors[field] ? true : false,
		helperText: formInlineErrors[field] ? formInlineErrors[field] : null
	};
}

const RemoveFromOrgDialog = props => {
	const { toRemove, onConfirm, onClose, localization, open, remove_actions } = props;
	const { userMetrics } = useUserMetrics();
	const promptSolution = localization.get("permanently_delete");
	const [input, setInput] = useState("");

	if (!toRemove || !toRemove.type || !toRemove.type.length) {
		return null;
	}
	if(toRemove.type.includes("loading")) {
		// In case we ever want to add a spinner
		return null;
	}

	const isMobileUser = toRemove.type.includes("user:mobile");
	if (isMobileUser) {
		return (
			<Dialog actions={remove_actions} open={!!toRemove} title={localization.get("users_remove")}>
				{localization.get("users_warning")}
				{Utility.format_name(toRemove)}?
			</Dialog>
		);
	}

	const closeDialog = () => {
		userMetrics.trackEvent("group-users: close remove user popup");
		setInput("");
		onClose();
	};

	const confirmDialog = () => {
		userMetrics.trackEvent(
			isMobileUser ? "group-users: remove user from organization" : "group-users: permanently delete user",
			{
				user: toRemove._id
			}
		);
		setInput("");
		onConfirm();
	};

	const actions = [
		<Button key={"buttoncancel"} onClick={closeDialog}>
			{localization.get("users_cancel")}
		</Button>
	];

	const confirmed = input === promptSolution;
	if (confirmed) {
		actions.push(
			<Button key={"buttonconfirm"} onClick={confirmDialog}>
				{localization.get("users_ok")}
			</Button>
		);
	}

	return (
		<Dialog
			open={open}
			actions={actions}
			title={`${localization.get("users_warning")}${Utility.format_name(toRemove)}?`}
		>
			<p>{localization.get("delete_ata_patient")}</p>
			<p>
				{localization.get("delete_prompt_help")}
				<b> {promptSolution}</b>
			</p>
			<TextField key={"permanently_delete_prompt"} test-id={"permanently_delete_textfield"} fullWidth onChange={e => setInput(e.target.value)} />
		</Dialog>
	);
};