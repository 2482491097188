/* © 2014 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, June 19, 2014
 * For information or permission request, email info@aetonixsystems.com
 */

"use strict";

/**
 * This store derives its data from an api call to /org/users creating a list
 * of listeners on connection events for a group's users.  It contains
 * data concerning the current bracelet connectivity of members of a group.
 */

var Store = require("./store");
var map = require("fj-map");
var par = require("par");

import * as Sentry from '@sentry/react';

module.exports = WanderLiveStore;

function WanderLiveStore(api, events, config) {
	var store = new Store();

	var loading = null;
	var page = 0;

	store.sorted = sorted;
	store.by_name = sort_name;
	store.more = more;

	begin_listening();
	more();

	return store;

	function begin_listening() {
		//  Start listening on individual users for connect/disconnect events
		api.org.devices.list()
			.then(map(listen_to_bracelet))
			.catch(error => {
				Sentry.captureException(error);
				console.error.bind(console, error);
			});
	}

	function listen_to_bracelet(data) {
		var orgGroup = config.orgGroup;
		if(!orgGroup) return;
		var id = data.owner;
		events.on("mqtt:orggroup/" + orgGroup + "/u/" + id + "/wander/connect", par(handle_wanderlive, id));
		events.on("mqtt:orggroup/" + orgGroup + "/u/" + id + "/wander/disconnect", par(handle_wanderlive, id));
		events.on("mqtt:orggroup/" + orgGroup + "/u/" + id + "/wander/acknowledge", par(handle_wanderlive, id));
		events.on("mqtt:orggroup/" + orgGroup + "/u/" + id + "/wander/wander", par(handle_wanderlive, id));
		events.on("mqtt:orggroup/" + orgGroup + "/u/" + id + "/wander/invalid", par(handle_wanderlive, id));
	}

	function handle_wanderlive(id, device) {
		store.set(id, {
			who: device.who,
			status: device.status,
			location: device.location,
			time: device.created_at,
			care: device.care
		});
	}

	function more() {
		if(loading) return loading;
		loading = api.org.devices.wander.latest(page)
			.then(map(handle_create))
			.then(next_page)
			.catch(error => {
				Sentry.captureException(error);
				console.error.bind(console, error);
			})
			.then(finish);
	}

	function finish() {
		loading = null;
	}

	//Want sorted by time
	function sorted() {
		return store.all().sort(by_time);
	}

	//Want sorted by name
	function sort_name() {
		return store.all().sort();
	}

	function handle_create(data) {
		var id = data._id;
		var device = data.device;
		store.set(id, {
			who: id,
			status: device.connected_state,
			location: device.connected_to,
			time: device.connected_at,
			care: device.acknowledger,
		});
	}

	function next_page() {
		page += 1;
	}
}

function by_time(prev, next) {
	var prev_time = (new Date(prev.created_at)).getTime();
	var next_time = (new Date(next.created_at)).getTime();

	var time_delta = next_time - prev_time;
	return time_delta;
}
