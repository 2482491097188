/* © 2014 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, June 19, 2014
 * For information or permission request, email info@aetonixsystems.com
 */

"use strict";

/**
 * This store derives its data from an api call to /org/reminders.  It contains
 * data concerning the reminders history of members of a group.
 */

var Store = require("./store");

module.exports = WorkflowDefinitionsStore;

function WorkflowDefinitionsStore(api) {
	var store = new Store();

	reset();

	return store;

	function reset() {
		return api.org.workflow.definitions()
			.then( function (result){
				if (!result) return;
				result.map(function (item) {
					var id = item._id;
					return setDefinition(id, item);
				});
			});
	}

	function setDefinition(id, definition) {
		store.set(id, definition);
	}

}