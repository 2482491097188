/* © 2014 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, June 19, 2014
 * For information or permission request, email info@aetonixsystems.com
 */

var React = require("react");
var par = require("par");
var xtend = require("xtend");
var escape_regex = require("escape-regexp");

var config = require("../../configs/config.json");

var Header = require("../shared/Header.js");
var IconButton = require("@material-ui/1.5.1/IconButton").default;
var Avatar = require("@material-ui/1.5.1/Avatar").default;
var List = require("@material-ui/1.5.1/List").default;
var ListItem = require("@material-ui/1.5.1/ListItem").default;
var ListItemText = require("@material-ui/1.5.1/ListItemText").default;
var Snackbar = require("@material-ui/1.5.1/Snackbar").default;
var FontIcon = require("@material-ui/1.5.1/Icon").default;

var Dialog = require("../shared/Dialog");

var FormControlLabel = require("@material-ui/1.5.1/FormControlLabel").default;
var Button = require("@material-ui/1.5.1/Button").default;

var TextField = require("@material-ui/1.5.1/TextField").default;
var Checkbox = require("@material-ui/1.5.1/Checkbox").default;

var Utility = require("../shared/Utility.js");

var LazyList = require("../shared/LazyList.jsx");
var Tooltip = require("@material-ui/1.5.1/Tooltip").default;

var Colors = require("../shared/AetonixTheme").palette;

import * as Sentry from "@sentry/react";

var iconButtonClasses = {
	root: "ae-inline"
};

var styles = {
	paper: {
		color: Colors.primary.main,
		padding: 32
	},
	search: {
		color: Colors.primary.main,
		minWidth: "30%",
		padding: 8
	},
	icon: {
		fontSize: 20,
		color: Colors.canvas,
		margin: "5px"
	},
	toast: {
		margin: "100px",
		padding: "10px",
		backgroundColor: "rgb(0, 172, 193)",
		borderRadius: "10px"
	},
	toastText: {
		color: Colors.canvas,
		margin: "5px"
	},
	inputProp: { style: { color: Colors.primary.main } },
	button: { marginLeft: "0.5%" },
	email: { userSelect: "text" }
};

module.exports = render;

function render() {
	var component = this;
	var state = component.state;
	var removing = state.removing;
	var searching = state.searching;
	var staff = state.staff;
	var people = state.people;
	var toRemove = state.toRemove;
	var removeStaff = state.removeStaff;
	var addStaff = state.addStaff;
	var searchNew = state.searchNew;
	var searchResults = state.searchResults;
	var localization = state.localization;
	var careplanNoticeList = state.careplanChangeList.all();
	var careplanChanges = !!careplanNoticeList.length;
	var offline = state.connection.get("offline");
	var currentPerson = state.currentPerson;
	var loadMore = state.loadMore;
	var searchExist = state.searchExist;
	var editStaff = state.editStaff;
	var titleKey = "org_staff_title";
	var verificationEdit = state.verificationEdit;
	var sortedStaffs = staff.map(people.get).sort(by_name);
	var filteredManagerGroups = state.filteredManagerGroups;

	// this allows for smoother clearing of the search box
	if (state.shouldClearManagerGroupSearch) {
		state.filteredManagerGroups = null;
		state.shouldClearManagerGroupSearch = false;
	}

	var managerGroups = state.managerGroups;

	var allManagerGroups = managerGroups.all();
	var shownManagerGroups = filteredManagerGroups ? filteredManagerGroups : allManagerGroups;

	var renderStaffListItem = par(renderStaff, component, par(showUserRemoval, component));

	var removal_actions = [
		<Button key={"buttonok"} onClick={par(confirmRemove, component, removeStaff)}>
			{localization.get("org_staff_OK")}
		</Button>,
		<Button key={"buttoncancel"} onClick={par(hideRemove, component)}>
			{localization.get("org_staff_cancel")}
		</Button>
	];

	var search_actions = [
		<Button key={"buttondone"} onClick={par(hideSearch, component)}>
			{localization.get("org_staff_done")}
		</Button>
	];

	var edit_actions = [
		<Button key={"buttoncancel"} onClick={par(hideInfo, component)}>
			{localization.get("org_staff_cancel")}
		</Button>,
		<Button key={"buttonsubmit"} onClick={par(doUpdate, component)}>
			{localization.get("org_staff_submit")}
		</Button>
	];

	var renderSelectAll = verificationEdit ? (
		<Button variant="raised" color="secondary" onClick={par(selectAll, component)} style={styles.button}>
			{localization.get("clear_all_verification_select_all")}
		</Button>
	) : null;

	var renderMarkAll =
		state.selectedStaff.length !== 0 ? (
			<Button variant="raised" color="secondary" onClick={par(startMarkAll, component)} style={styles.button}>
				{localization.get("clear_all_verification_mark_all")}
			</Button>
		) : null;

	var renderManagerGroupsButton =
		state.selectedStaff.length !== 0 ? (
			<Button variant="raised" color="secondary" onClick={par(openManagerGroupDialog, component)} style={styles.button}>
				{localization.get("change_managergroup_button")}
			</Button>
		) : null;

	var verificationBackButton = verificationEdit
		? localization.get("org_staff_done")
		: localization.get("groupforms.edit");

	var verify_actions = [
		<Button onClick={par(verifyAccounts, component, state.selectedStaff)}>{localization.get("org_groups_yes")}</Button>,
		<Button onClick={par(editButton, component)}>{localization.get("org_groups_no")}</Button>
	];

	var single_verify_actions = [
		<Button onClick={par(verifySingle, component, state.verifying)}>{localization.get("org_groups_yes")}</Button>,
		<Button onClick={par(closeDialog, component)}>{localization.get("org_groups_no")}</Button>
	];

	var managergroup_actions = (
		<Button onClick={par(closeManagerGroupsDialog, component, state.selectedStaff)}>
			{localization.get("org_staff_done")}{" "}
		</Button>
	);

	var managergroup_delete_actions = [
		<Button onClick={par(removeAllFromManagerGroup, component)}>{localization.get("org_groups_yes")}</Button>,
		<Button onClick={par(closeManagerGroupsConfirm, component)}>{localization.get("org_groups_no")}</Button>
	];

	var managergroup_add_actions = [
		<Button onClick={par(addAllToManagerGroup, component)}>{localization.get("org_groups_yes")}</Button>,
		<Button onClick={par(closeManagerGroupsConfirm, component)}>{localization.get("org_groups_no")}</Button>
	];

	var managerGroupName = state.selectedManagerGroup ? state.selectedManagerGroup.name : "";
	var managergroup_delete_title = `${localization.get("change_managergroup_delete_confirmation")} ${managerGroupName}?`;
	var managergroup_add_title = `${localization.get("change_managergroup_add_confirmation")} ${managerGroupName}?`;
	var toastCheckmark = state.toastSuccess ? (
		<FontIcon className="fa fa-check fa-2x" style={styles.icon} />
	) : (
		<FontIcon className="fa fa-exclamation fa-2x" style={styles.icon} />
	);

	return (
		<div className="flex-vertical flex-1">
			<Header
				careplanChanges={careplanChanges}
				offline={offline}
				currentPerson={currentPerson}
				localization={localization}
				titleKey={titleKey}
			/>

			<div className="ae-left-margin">
				<TextField
					placeholder={localization.get("search_onName")}
					inputProps={{ "aria-label": localization.get("search_onName") }}
					onChange={searchExist}
					style={styles.search}
					InputProps={styles.inputProp}
				/>
			</div>
			<div>
				<Button variant="raised" color="secondary" onClick={par(editButton, component)} style={styles.button}>
					{verificationBackButton}
				</Button>
				{renderSelectAll}
				{renderMarkAll}
				{renderManagerGroupsButton}
			</div>

			<LazyList loadMore={loadMore} renderItem={renderStaffListItem} items={sortedStaffs} />

			<Button variant="raised" onClick={par(showSearch, component)} color="secondary">
				{localization.get("org_staff_Add")}
			</Button>

			<Dialog open={removing} maxWidth="md" title={localization.get("org_staff_remove")} actions={removal_actions}>
				{localization.get("org_staff_warning")}
				{Utility.format_name(toRemove)}?
			</Dialog>

			<Dialog
				open={searching}
				onBackdropClick={hideSearch}
				maxWidth="md"
				title={localization.get("org_staff_add")}
				actions={search_actions}
			>
				<TextField
					placeholder={localization.get("org_staff_name")}
					inputProps={{ "aria-label": localization.get("org_staff_name") }}
					onChange={par(doSearch, searchNew)}
					InputProps={styles.inputProp}
				/>
				<List>{searchResults.sort(by_name).map(par(renderSearchItem, addStaff, component))}</List>
			</Dialog>

			<Dialog open={editStaff} maxWidth="md" title={localization.get("org_staff_edit")} actions={edit_actions}>
				{renderStaffInfo(component)}
			</Dialog>

			<Dialog
				open={state.singleVerification}
				maxWidth="md"
				title={localization.get("clear_all_verification_confirmation")}
				actions={single_verify_actions}
			>
				<div> {localization.get("clear_single_verification_confirmation") + state.singleVerification}?</div>
			</Dialog>

			<Dialog
				open={state.openVerify}
				maxWidth="md"
				title={localization.get("clear_all_verification_confirmation")}
				actions={verify_actions}
			>
				<div>{localization.get("verify_all_question")}</div>
			</Dialog>

			<Dialog
				open={state.openManagerGroupsDialog}
				maxWidth="md"
				title={localization.get("change_managergroup_title")}
				actions={managergroup_actions}
			>
				<TextField
					placeholder={localization.get("managergroups_search")}
					onChange={par(searchManagerGroups, component, allManagerGroups, "ManagerGroups")}
					style={styles.search}
					InputProps={styles.inputProp}
				/>
				<LazyList
					className=" ae-scrollable"
					renderItem={par(renderManagerGroupsDialog, component)}
					items={shownManagerGroups}
				/>
			</Dialog>

			<Dialog
				open={state.openManagerGroupDeleteConfirmation}
				maxWidth="md"
				title={managergroup_delete_title}
				actions={managergroup_delete_actions}
			></Dialog>

			<Dialog
				open={state.openManagerGroupAddConfirmation}
				maxWidth="md"
				title={managergroup_add_title}
				actions={managergroup_add_actions}
			></Dialog>

			<Snackbar open={state.openToast} autoHideDuration={1000} onClose={par(handleCloseToast, this)}>
				<div className="flex-horizontal ae-dropshadow" style={styles.toast}>
					{toastCheckmark}
					<div style={styles.toastText}>
						{state.toastSuccess
							? localization.get("manager_group_toast_successful")
							: localization.get("manager_group_toast_failure")}
					</div>
				</div>
			</Snackbar>
		</div>
	);
}

function handleCloseToast(component) {
	component.setState({ openToast: false });
}

function sendToast(component, type) {
	component.setState({
		toastSuccess: type === "Success",
		openToast: true
	});
}

function startMarkAll(component) {
	component.setState({ openVerify: true });
	component.state.userMetrics.trackEvent("org-staff: open verify all popup");
}

function openManagerGroupDialog(component) {
	component.setState({
		openManagerGroupsDialog: true
	});
	component.state.userMetrics.trackEvent("org-staff: open change patient group popup");
}

function by_name(x, y) {
	var X = x?.lname?.toLowerCase() || null;
	var Y = y?.lname?.toLowerCase() || null;

	if (X < Y) return -1;
	if (X > Y) return 1;
	return 0;
}

function showSearch(component) {
	component.setState({ searching: true });
	component.state.userMetrics.trackEvent("org-staff: open add staff popup");
}

function hideSearch(component) {
	component.setState({
		searching: false,
		searchResults: []
	});
	component.state.userMetrics.trackEvent("org-staff: close add staff popup");
}

function doSearch(search, e) {
	var query = e.target.value;
	search(query);
}

function renderSearchItem(add, copmponent, person) {
	var avatar = <Avatar alt="Staff Avatar Image" src={config.image_cdn + person.image} />;
	var text = Utility.format_name(person);
	var clickAction = par(addStaffs, add, person._id, copmponent);

	var id = person._id;
	return (
		<ListItem key={id} onClick={clickAction}>
			{avatar}
			<ListItemText primary={text} />
		</ListItem>
	);
}

function addStaffs(add, id, component) {
	add(id);
	hideSearch(component);
	component.state.userMetrics.trackEvent("org-staff: add user to staff", {
		who: id
	});
}

function renderStaff(component, remove, staff) {
	if (!staff.lname) return;
	var avatar = <Avatar alt="Staff Avatar Image" src={config.image_cdn + staff.image} />;
	var text = Utility.format_name(staff);
	var renderActionButtons = renderActions(component, remove, staff);
	var email = component.state.staffEmail.get(staff._id);
	var localization = component.state.localization;
	var staffSelected = component.state.selectedStaff || [];
	var checked = staffSelected.indexOf(staff._id) !== -1;

	var background_colour = "ae-plain";
	var viewing = component.state.viewing || {};
	var checkboxes = component.state.verificationEdit ? (
		<Checkbox
			id={staff._id}
			value={staff._id}
			inputProps={{ "aria-label": localization.get("checkbox_button") }}
			key={staff._id + checked}
			defaultChecked={checked}
			onChange={par(handleVerificationCheckBox, component, staff._id)}
		/>
	) : null;
	if (viewing && staff === viewing) background_colour = "ae-hover-color";

	return (
		<ListItem key={staff._id} className={background_colour} onClick={par(set_viewing, component, staff)}>
			{checkboxes}
			{avatar}
			<ListItemText primary={text} secondary={email} style={styles.email} />
			{renderActionButtons}
		</ListItem>
	);
}

function set_viewing(component, staff) {
	component.setState({ viewing: staff });
}

function verifySingleAccount(component, staff) {
	var id = staff._id;
	var name = `${staff.fname} ${staff.lname}`;
	component.setState({
		singleVerification: name,
		verifying: id
	});
	component.state.userMetrics.trackEvent("org-staff: verify staff", {
		who: id
	});
}

function verifySingle(component, staff) {
	const { state } = component;
	const verify = state.clearStaffVerification;
	verify(staff);
	component.setState({
		verifying: null,
		singleVerification: false
	});
	component.state.userMetrics.trackEvent("org-staff: verify staff and close popup", {
		who: staff
	});
}

function renderActions(component, remove, staff) {
	var id = staff._id;
	var editStaffButton = par(showInfo, component, staff);
	var removeButton = par(remove, staff);
	var verifyAccount = par(verifySingleAccount, component, staff);
	var { state } = component;
	var { localization } = state;
	var preference = component.state.staffPreferences.get(id) || {};
	var verified = preference.unverified ? localization.get("clear_all_verification_unverified") : null;

	var popperProps = { disablePortal: true };

	var renderVerifyButton = preference.unverified ? (
		<Tooltip title={localization.get("clear_all_verification_tooltip_verify")} PopperProps={popperProps}>
			<IconButton key={"buttonverify"} color="primary" className="fa fa-check" onClick={verifyAccount} />
		</Tooltip>
	) : null;

	return (
		<span>
			{verified}
			{renderVerifyButton}
			<Tooltip
				aria-label={localization.get("staff_settings")}
				role="button"
				title={localization.get("tooltip_edit")}
				PopperProps={popperProps}
			>
				<IconButton key={"buttonedit"} color="primary" className="fa fa-edit" onClick={editStaffButton} />
			</Tooltip>
			<Tooltip
				aria-label={localization.get("remove_button")}
				role="button"
				title={localization.get("changes.remove")}
				PopperProps={popperProps}
			>
				<IconButton key={"buttonremove"} color="primary" className="fa fa-user-times" onClick={removeButton} />
			</Tooltip>
		</span>
	);
}

function verifyAccounts(component, ids) {
	var { state } = component;
	state.clearStaffVerification(ids);
	component.setState({
		openVerify: false,
		verificationEdit: false,
		selectedStaff: []
	});
	component.state.userMetrics.trackEvent("org-staff: verify all selected accounts", {
		who: ids
	});
}

function renderManagerGroupsDialog(component, group) {
	var text = group.name;
	var secondaryText = group.description;
	var groupId = group._id;
	const isLocked = group?.isLocked;
	const isOrgManager = component.state.currentPerson.get("personal").type?.includes("org:manager");
	const isAdmin = component.state.currentPerson.get("personal").type?.includes("admin");
	var background_colour = "ae-white";

	var localization = component.state.localization;

	if (isLocked) {
		return (
			<ListItem key={groupId} className={background_colour}>
				<ListItemText primary={text} secondary={secondaryText} />
				<IconButton
					classes={iconButtonClasses}
					disableRipple
					aria-label={localization.get("lock_button")}
					title={localization.get("lock_button")}
					role="button"
					color="primary"
					className="fa fa-lock"
					onClick={() => {}}
				/>
				{(isOrgManager || isAdmin) && (
					<>
						<IconButton
							classes={iconButtonClasses}
							disableRipple
							aria-label={localization.get("audit.staff-add")}
							title={localization.get("audit.staff-add")}
							role="button"
							color="primary"
							className="fa fa-user-plus"
							onClick={par(confirmAddToManagerGroup, component, group)}
						/>
						<IconButton
							classes={iconButtonClasses}
							disableRipple
							aria-label={localization.get("audit.staff-remove")}
							title={localization.get("audit.staff-remove")}
							role="button"
							color="primary"
							className="fa fa fa-times-circle"
							onClick={par(confirmRemoveFromManagerGroup, component, group)}
						/>
					</>
				)}
			</ListItem>
		);
	}

	return (
		<ListItem key={groupId} className={background_colour}>
			<ListItemText primary={text} secondary={secondaryText} />
			<IconButton
				classes={iconButtonClasses}
				disableRipple
				aria-label={localization.get("audit.staff-add")}
				title={localization.get("audit.staff-add")}
				role="button"
				color="primary"
				className="fa fa-user-plus"
				onClick={par(confirmAddToManagerGroup, component, group)}
			/>
			<IconButton
				classes={iconButtonClasses}
				disableRipple
				aria-label={localization.get("audit.staff-remove")}
				title={localization.get("audit.staff-remove")}
				role="button"
				color="primary"
				className="fa fa fa-times-circle"
				onClick={par(confirmRemoveFromManagerGroup, component, group)}
			/>
		</ListItem>
	);
}

function searchManagerGroups(component, array, key, event) {
	event.persist();
	var query = event.target.value;
	var terms = escape_regex(query)
		.split(" ")
		.join("");
	var regex = new RegExp(terms, "i");

	var filtered = array.filter(function(item) {
		var matches = getName(item)
			.split(" ")
			.join("")
			.match(regex);
		if (matches) return item;
	});

	var update = {};
	update["filtered" + key] = filtered;

	component.setState(update);
}

function getName(item) {
	var name = item.name || item.fname + item.lname;
	return name;
}

function confirmAddToManagerGroup(component, group) {
	component.setState({
		openManagerGroupAddConfirmation: true,
		selectedManagerGroup: group
	});
	component.state.userMetrics.trackEvent("org-staff: open add to patient group confirmation popup");
}

function confirmRemoveFromManagerGroup(component, group) {
	component.setState({
		openManagerGroupDeleteConfirmation: true,
		selectedManagerGroup: group
	});
	component.state.userMetrics.trackEvent("org-staff: open remove from patient group popup", {
		group
	});
}

function addAllToManagerGroup(component) {
	var groupId = component.state.selectedManagerGroup._id;
	var groupManagers = component.state.managerGroupManagers.get(groupId);
	var filteredIds = filterManagerAdd(groupManagers, component.state.selectedStaff);
	if (filteredIds.length === 0) {
		sendToast(component, "Success");
	} else {
		component.state
			.addAllToManagerGroup(groupId, filteredIds)
			.then(par(sendToast, component, "Success"))
			.catch(error => {
				Sentry.captureException(error);
				par(sendToast, component, "Fail");
			});
	}
	closeManagerGroupsConfirm(component);
	component.state.userMetrics.trackEvent("org-staff: add selected staff to patient group", {
		groupId,
		staff: filteredIds
	});
}

function filterManagerAdd(groupManagers, ids) {
	return ids.filter(id => !groupManagers.includes(id));
}

function removeAllFromManagerGroup(component) {
	var groupId = component.state.selectedManagerGroup._id;
	var groupManagers = component.state.managerGroupManagers.get(groupId);
	var filteredIds = filterManagerRemove(groupManagers, component.state.selectedStaff);
	if (filteredIds.length === 0) {
		sendToast(component, "Success");
	} else {
		component.state
			.removeAllFromManagerGroup(groupId, filteredIds)
			.then(par(sendToast, component, "Success"))
			.catch(error => {
				Sentry.captureException(error);
				par(sendToast, component, "Fail");
			});
	}
	closeManagerGroupsConfirm(component);
	component.state.userMetrics.trackEvent("org-staff: remove selected staff from patient group", {
		groupId,
		staff: filteredIds
	});
}

function filterManagerRemove(groupManagers, ids) {
	return ids.filter(id => groupManagers.includes(id));
}

function showUserRemoval(component, person) {
	component.setState({
		toRemove: person,
		removing: true
	});
	component.state.userMetrics.trackEvent("org-staff: open remove staff popup");
}

function confirmRemove(component) {
	var id = component.state.toRemove._id;
	component.state.removeStaff(id);
	component.setState({ removing: false });
	component.state.userMetrics.trackEvent("org-staff: remove staff member and close popup", {
		who: id
	});
}

function hideRemove(component) {
	component.setState({ removing: false });
	component.state.userMetrics.trackEvent("org-staff: close remove staff popup");
}

function renderStaffInfo(component) {
	var state = component.state;
	var localization = state.localization;
	var editing = state.editStaff;
	var viewing = state.viewing;

	if (editing) {
		var id = viewing._id;
		var preference = state.staffPreferences.get(id) || {};
		var sectionList = ["autologout", "multifactor"];

		var renderContent = sectionList.map(function(section) {
			var localizationStr = "org_staff_" + section;

			var defaultValue = preference[section];
			if (defaultValue !== true && defaultValue !== false) {
				defaultValue = false;
				preference[section] = defaultValue;
			}

			return (
				<div key={section}>
					<FormControlLabel
						control={
							<Checkbox
								key={defaultValue}
								defaultChecked={defaultValue}
								onChange={par(handle_preference_change, component, section)}
							/>
						}
						label={localization.get(localizationStr)}
					/>
				</div>
			);
		});
		return renderContent;
	} else {
		return null;
	}
}

function hideInfo(component) {
	component.setState({
		editStaff: false,
		preferenceChange: {}
	});
	component.state.userMetrics.trackEvent("org-staff: close edit staff settings popup");
}

function showInfo(component) {
	component.setState({ editStaff: true });
	component.state.userMetrics.trackEvent("org-staff: open edit staff settings popup");
}

function doUpdate(component) {
	var state = component.state;
	var staff = state.viewing;
	var staffId = staff._id;
	var preferences = state.preferenceChange;
	if (!preferences) return;

	state.setPreferences(staffId, preferences);

	hideInfo(component);

	component.state.userMetrics.trackEvent("org-staff: edit staff permissions", {
		who: staffId,
		preferences: preferences
	});
}

function handle_preference_change(component, section, e, change) {
	var prefChange = component.state.preferenceChange;
	var changeObj = {};
	changeObj[section] = change;
	var prefUpdate = xtend(prefChange, changeObj);
	component.setState({ preferenceChange: prefUpdate });
}

function selectAll(component) {
	var { state } = component;
	var { keys } = state;
	var selectedStaff = keys;

	state.loadAllStaff();
	component.setState({ selectedStaff });

	component.state.userMetrics.trackEvent("org-staff: select all");
}

function editButton(component) {
	component.setState({
		verificationEdit: !component.state.verificationEdit,
		selectedStaff: [],
		openVerify: false
	});
	component.state.userMetrics.trackEvent("org-staff: toggle multiselect", {
		"set to": !component.state.verificationEdit ? "on" : "off"
	});
}

function closeDialog(component) {
	component.setState({
		selectedStaff: [],
		singleVerification: false
	});
	component.state.userMetrics.trackEvent("org-staff: close verify staff popup");
}

function closeManagerGroupsDialog(component) {
	component.setState({
		selectedStaff: [],
		verificationEdit: false,
		openManagerGroupsDialog: false,
		shouldClearManagerGroupSearch: true
	});
	component.state.userMetrics.trackEvent("org-staff: close change patient group popup");
}

function closeManagerGroupsConfirm(component) {
	component.setState({
		openManagerGroupAddConfirmation: false,
		openManagerGroupDeleteConfirmation: false,
		selectedManagerGroup: null
	});
	component.state.userMetrics.trackEvent("org-staff: close add all to manage group popup");
}

function handleVerificationCheckBox(component, id) {
	var selectedStaff = [].concat(component.state.selectedStaff || []);
	var index = selectedStaff.indexOf(id);
	if (index === -1) {
		selectedStaff.push(id);
	} else {
		selectedStaff.splice(index, 1);
	}

	component.setState({ selectedStaff: selectedStaff });
}
