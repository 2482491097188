/* © 2014 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, June 19, 2014
 * For information or permission request, email info@aetonixsystems.com
 */

"use strict";

/**
 * This store derives its data from an api call to /org/devices.  It contains
 * data concerning the bracelet connection history of members of a group.
 */

var Store = require("./store");
var map = require("fj-map");

import * as Sentry from '@sentry/react';

module.exports = BloodSugarHistoryStore;

function BloodSugarHistoryStore(api, events, config) {
	var store = new Store();

	var loading = null;
	var page = 0;
	var user = [];
	var start = "";
	var end = "";


	store.sorted = sorted;
	store.more = more;
	store.searchQuery = searchQuery;
	store.searchDate = searchDate;
	store.clearSearch = clearSearch;
	store.reset = reset;

	return store;

	function reset() {
		page = 0;
		store.clear();
		return api.org.devices.health.glucose(user, page, start, end)
			.then(function (result) {
				if (result.length) {
					map(handle_create, result);
				} else {
					store.clear();
				}
			}).catch(error => {
				Sentry.captureException(error);
				store.error(error);
			});
	}

	function more() {
		if (loading) return loading;
		const originalPage = page;
		next_page();
		loading = api.org.devices.health.glucose(user, page, start, end)
			.then(map(handle_create))
			.then(next_page)
			.catch((err) => {
				page = originalPage;
				store.error(err);
				Sentry.captureException(err);
			}).then(finish);
	}

	function finish() {
		loading = null;
	}

	function sorted() {
		return store.all().sort(by_time);
	}

	function handle_create(data) {
		var id = data._id;
		store.set(id, data);
	}

	function next_page() {
		page += 1;
	}

	function searchQuery(filterquery) {
		user = user.concat(filterquery);
		return reset();
	}

	function searchDate(startdate, enddate) {
		start = startdate;
		end = enddate;
		if (!!start && !!end) {
			return reset();
		}
		return;
	}

	function clearSearch(name) {
		if (name === "user") user = [];
		if (name === "time") {
			start = "";
			end = "";
		}
		return reset();
	}
}

function by_time(prev, next) {
	var prev_time = (new Date(prev.created_at)).getTime();
	var next_time = (new Date(next.created_at)).getTime();

	var time_delta = next_time - prev_time;
	return time_delta;
}
