/* © 2014 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, June 19, 2014
 * For information or permission request, email info@aetonixsystems.com
 */

var Store = require("./store");
var par = require("par");

import * as Sentry from "@sentry/react";

module.exports = CallLogStore;

function CallLogStore(api) {
	var store = new Store();

	store.raw_get = store.get;
	store.get = get;

	var tracking = [];

	return store;

	function get(id) {
		var existing = store.raw_get(id);
		if (existing) return existing;

		if (!isTracking(id))
			track(id);

		return [];
	}

	function isTracking(id) {
		return tracking.indexOf(id) !== -1;
	}

	function track(id) {
		tracking.push(id);
		api.admin.logs(id).then(par(setLogs, id)).catch(error => {
			Sentry.captureException(error);
			console.error.bind(console, error);
		});
	}

	function setLogs(id, logs) {
		store.set(id, logs);
	}
}
