/* © 2014 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, June 19, 2014
 * For information or permission request, email info@aetonixsystems.com
 */

"use strict";

var Store = require("./store");

module.exports = UserTotalStore;
const { getLocalStorageArray } = require("../shared/Utility.js");

function UserTotalStore(api, events, config) {
	var store = new Store();

	const userLocalStorageKey = localStorage.getItem("userLocalStorageKey");
	store.manageGroups = getLocalStorageArray(`${userLocalStorageKey}manageGroups`);
	store.stateFilter = getLocalStorageArray(`${userLocalStorageKey}stateFilter`);
	store.isManager = localStorage.getItem(`${userLocalStorageKey}listType`) === "managed";

	store.changeStateFilter = changeStateFilter;
	store.changeManageGroups = changeManageGroups;
	store.changeIsManager = changeIsManager;
	store.inactive = null;

	reset();

	return store;

	function reset(){
		const { stateFilter, manageGroups, isManager, inactive } = store;
		const filters = {
			state: stateFilter.includes("all_active") ? [] : stateFilter,
			manageGroups,
			isManager,
			inactive: stateFilter.includes("all_active") ? false : inactive,
		};
		return Promise.all([
			api.org.users.total.patients(filters),
			api.org.users.total.red(filters),
			api.org.users.total.yellow(filters),
			api.org.users.total.green(filters),
		]).then(function(results){
			var total = results[0];
			var red = results[1];
			var yellow = results[2];
			var green = results[3];
			store.setAll({
				total: total,
				red: red,
				yellow: yellow,
				green: green,
			});
		}).then(trackChanges);
	}

	function trackChanges(){
		var orgGroup = config.orgGroup;
		if(!orgGroup) return;
		events.on("mqtt:orggroup/" + orgGroup + "/users/status/change", handle_status_change);
		events.on("mqtt:orggroup/" + orgGroup + "/users/added", increment_total_patients);
		events.on("mqtt:orggroup/" + orgGroup + "/users/removed", decrement_total_patients);
	}

	function changeManageGroups(newManageGroups) {
		store.manageGroups = newManageGroups;
		return reset();
	}

	function changeStateFilter(newFilter) {
		newFilter = [newFilter].flat();
		store.inactive = null;
		const hasAllActive = newFilter.includes("all_active");
		const hasInActive = newFilter.includes("inactive");
		if (hasAllActive) {
			newFilter = [];
			if (!hasInActive) {
				// if "all_active" is selected but "inactive" is not, then all users with
				// inactive=false should be listed
				store.inactive = false;
			}
		}
		store.stateFilter = newFilter;
		return reset();
	}

	function changeIsManager(isManager) {
		store.isManager = isManager;
		return reset();
	}

	function increment_total_patients(){
		store.set("total", store.get("total") + 1);
	}

	function decrement_total_patients(){
		store.set("total", store.get("total") - 1);
	}

	function handle_status_change(data){
		var newStatus = data.status;
		var oldStatus = data.old_status;

		if(newStatus === oldStatus) return;

		var increase = null;
		var decrease = null;

		if(newStatus === 2) increase = "red";
		if(newStatus === 1) increase = "yellow";
		if(newStatus === 0) increase = "green";

		if(oldStatus === 2) decrease = "red";
		if(oldStatus === 1) decrease = "yellow";
		if(oldStatus === 0) decrease = "green";

		var decreased = store.get(decrease) - 1;
		var increased = store.get(increase) + 1;

		if(decrease && decreased >= 0) store.set(decrease, decreased);
		if(increase && increased >= 0) store.set(increase, increased);
	}
}
