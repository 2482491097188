/* © 2014 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, May 3, 2018
 * For information or permission request, email info@aetonixsystems.com
 */

var par = require("par");

var defineUI = require("../shared/define");
var render = require("./render.jsx");

var PersonStore = require("../stores/person_store");
var OrgStore = require("../stores/org_store");
var LocalizationStore = require("../stores/localization_store");
var CurrentPersonInfoStore = require("../stores/current_person_info_store");
var CareplanNotificationStore = require("../stores/careplan_notice_store");
var ConnectionStore = require("../stores/connection_store");
var AdminOrgStore = require("../stores/admin_org_store");
var AdminOptionSchemaStore = require("../stores/admin_options_schema_store");

var makeStores = require("../shared/stores")({
	orgs: OrgStore,
	allOrgs: AdminOrgStore,
	people: PersonStore,
	currentPerson: CurrentPersonInfoStore,
	localization: LocalizationStore,
	careplanChangeList: CareplanNotificationStore,
	connection: ConnectionStore,
	options: AdminOptionSchemaStore
});

module.exports = make_ui;

function make_ui(api, events, config, userMetrics) {
	var storeStore = makeStores(api, events, config);

	window.stores = storeStore.store;

	userMetrics.trackEvent("page view", {
		"page": "admin-options-schemas",
		"category": "admin",
		"permission level": "admin",
	});

	return defineUI({
		getInitialState: getInitialState,
		componentDidMount: listen_for_changes,
		render: render,
		componentWillUnmount: removeListeners
	});

	function removeListeners() {
		events.off();
	}

	function listen_for_changes() {
		storeStore.listen(par(handle_change, this));
	}

	function getInitialState() {
		return {
			orgs: [],
			allOrgs: [],
			loadMore: par(loadMore, storeStore.stores.orgs),
			people: storeStore.stores.people,
			currentPerson: storeStore.stores.currentPerson,
			localization: storeStore.stores.localization,
			searchOrg: par(searchOrg, storeStore.stores.orgs),
			careplanChangeList: storeStore.stores.careplanChangeList,
			connection: storeStore.stores.connection,
			options: storeStore.stores.options,
			updating: false,
			creating: false,
			schemaError: false,
			duplicate,
			addOptionSchema,
			updateOptionSchema,
			removeOptionSchema,
			isDuplicating: false,
			duplicateOrg: "",
			errorToast: false,
			openToast: false,
			inProgressToast: false,
			toastMessage: "",
			isMissingDestination: false,
			userMetrics: userMetrics,
		};
	}

	function duplicate(schema, org, destination) {
		return api.admin.options.schema.duplicate(schema, org, destination);
	}

	function searchOrg(orgs, event) {
		event.persist();
		var query = event.target.value;
		return orgs.search(query);
	}

	function loadMore(orgs) {
		return orgs.more();
	}

	function handle_change(component, stores) {
		component.setState({
			orgs: stores.orgs.all(),
			allOrgs: stores.allOrgs.all(),
			people: stores.people,
			currentPerson: stores.currentPerson,
			localization: stores.localization,
			careplanChangeList: stores.careplanChangeList,
			connection: stores.connection,
			options: stores.options
		});
	}

	function addOptionSchema(optionSchema, viewing){
		var {schema} = optionSchema;
		if(!schema) return;
		var {emrId} = schema;
		return api.admin.options.schema.create(schema, emrId, viewing);
	}

	function updateOptionSchema(optionSchema, viewing){
		var {schema, _id} = optionSchema;
		if(!schema) return;
		var {emrId} = schema;
		return api.admin.options.schema.update(schema, emrId, viewing, _id);
	}

	function removeOptionSchema(optionSchema, viewing){
		var {_id} = optionSchema;
		if(!_id) return;
		return api.admin.options.schema.remove(_id, viewing);
	}
}
