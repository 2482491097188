/* © 2014 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, June 19, 2014
 * For information or permission request, email info@aetonixsystems.com
 */

var par = require("par");

var defineUI = require("../shared/define");
var render = require("./render.jsx");


var OrgStore = require("../stores/org_store");
var PersonStore = require("../stores/person_store");
var LocalizationStore = require("../stores/localization_store");
var CurrentPersonInfoStore = require("../stores/current_person_info_store");
var CareplanNotificationStore = require("../stores/careplan_notice_store");
var ConnectionStore = require("../stores/connection_store");
var OrgGroupStore = require("../stores/org_group_store");
const UserStore = require("../stores/org_users_store");

var makeStores = require("../shared/stores")({
	orgs: OrgStore,
	people: PersonStore,
	users: UserStore,
	currentPerson: CurrentPersonInfoStore,
	localization: LocalizationStore,
	careplanChangeList: CareplanNotificationStore,
	connection: ConnectionStore,
	groups: OrgGroupStore
});

module.exports = make_ui;

function make_ui(api, events, config, userMetrics) {
	var storeStore = makeStores(api, events, config);

	window.stores = storeStore.store;

	userMetrics.trackEvent("page view", {
		"page": "org-audit",
		"category": "org",
		"permission level": "org:manager",
	});

	return defineUI({
		getInitialState: getInitialState,
		componentDidMount: listen_for_changes,
		render: render,
		componentWillUnmount: removeListeners,
	});

	function removeListeners() {
		events.off();
	}

	function listen_for_changes() {
		storeStore.listen(par(handle_change, this));
	}

	function getInitialState() {
		return {
			orgs: [],
			users: [],
			people: storeStore.stores.people,
			currentPerson: storeStore.stores.currentPerson,
			localization: storeStore.stores.localization,
			careplanChangeList: storeStore.stores.careplanChangeList,
			connection: storeStore.stores.connection,
			groups: [],
		};
	}

	function handle_change(component, stores) {
		component.setState({
			orgs: stores.orgs.all(),
			people: stores.people,
			currentPerson: stores.currentPerson,
			localization: stores.localization,
			connection: stores.connection,
			careplanChangeList: stores.careplanChangeList,
			groups: stores.groups.all(),
			users: stores.users.all(),
			searchUsers,
			loadMoreUsers: par(loadMoreUsers, storeStore.stores.users),
		});
	}

	function loadMoreUsers(user){
		return user.more();
	}

	function searchUsers(query) {
		storeStore.stores.users.search(query);
	}
}
