/* © 2017 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, January 01, 2017
 * For information or permission request, email info@aetonixsystems.com
 */

"use strict";


import React from 'react'

import Dialog from "@material-ui/1.5.1/Dialog";
import DialogActions from "@material-ui/1.5.1/DialogActions";
import DialogContent from "@material-ui/1.5.1/DialogContent";
import DialogTitle from "@material-ui/1.5.1/DialogTitle";
import { useLocalize } from './contexts/LocalizeContext'
import { Button } from '@material-ui/1.5.1';

export default function ConfirmationDialog({
	title,
	description,
	affirmativeAction = () => { },
	affirmativeText,
	affirmativeDisabled,

	negativeAction = () => { },
	negativeText,
	negativeDisabled,

	open,
	onClose = () => { },
	maxWidth = "sm",
	fullWidth = true
}) {
	const localize = useLocalize()
	var renderTitle = title ?? "";
	var renderDescription = description ?? localize("common.areyousure");
	var renderAffirmativeText = affirmativeText ?? localize("common.yes");
	var rendernegativeText = negativeText ?? localize("common.no");

	return (
		<Dialog open={open} maxWidth={maxWidth} fullWidth={fullWidth} onClose={onClose}>
			<DialogTitle>
				{renderTitle}
			</DialogTitle>
			<DialogContent>
				{renderDescription}
			</DialogContent>
			<DialogActions>
				<DialogActions>
					<Button onClick={affirmativeAction} disabled={affirmativeDisabled}>
						{renderAffirmativeText}
					</Button>
					<Button onClick={negativeAction} disabled={negativeDisabled}>
						{rendernegativeText}
					</Button>
				</DialogActions>
			</DialogActions>
		</Dialog>
	);
}
