import React, { useMemo, useCallback } from "react";
import { useState } from "react";
import { Dialog, DialogContent, Typography, DialogTitle, IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import {
	useLocalize,
	useWorkflowDefinitions,
	useAllPatientContacts,
	useAllPatientManagers,
	usePatientGroupManagers,
	useOwnerOngoingWorkflows,
	useOwnContacts,
	usePatientsPatientGroup,
	useCurrentPerson
} from "@aetonix/hooks";
import { toDefinitionlist, createSchema, getActors } from "./WorkflowHelpers";

import WorkflowCreateDefinition from "./definitions/WorkflowCreateDefinition";
import Form from "ae-forms";
import { useUserMetrics } from "@aetonix/user-metrics";

export const WorkflowCreateModal = ({ open, onClose }) => {
	const [selectedWorkflow, setSelectedWorkflow] = useState(null);
	const { localize } = useLocalize();
	const { personal: { language }} = useCurrentPerson();
	const { sortedDefinitions } = useWorkflowDefinitions();
	const { startWorkflow } = useOwnerOngoingWorkflows();

	const { contacts: ownContacts } = useOwnContacts();
	const { contacts: patientAllContacts } = useAllPatientContacts();
	const { managers: patientAllManagers } = useAllPatientManagers();
	const { managers: patientGroupManagers } = usePatientGroupManagers();
	const { patientGroups: patientGroups } = usePatientsPatientGroup();

	const onCancel = () => {
		setSelectedWorkflow(null);
		onClose();
	};

	const definitionList = useMemo(() => toDefinitionlist(sortedDefinitions), [sortedDefinitions]);
	const creationFormSchema = useMemo(() => createSchema(selectedWorkflow, "start", localize, language), [selectedWorkflow]);
	const formSources = useMemo(
		() => ({
			ownContacts: createKeyValuePeople(ownContacts) || [],
			manageGroups: createKeyValueGroup(patientGroups),
			patientAllContacts: createKeyValuePeople(patientAllContacts),
			patientAllManagers: createKeyValuePeople(patientAllManagers),
			patientGroupManagers: createKeyValuePeople(patientGroupManagers)
		}),
		[patientGroups, patientAllContacts, patientAllManagers, patientGroupManagers]
	);

	const onSubmit = useCallback((data) => {
		if(!selectedWorkflow) return;
		const actors = getActors(data);
		startWorkflow(data.label, selectedWorkflow._id, actors, data.defaults);
		onCancel();
	}, [selectedWorkflow]);

	return (
		<Dialog open={open} scroll="paper" onClose={onCancel} fullWidth maxWidth="md">
			<DialogTitle disableTypography>
				<Typography variant="body1" align="center">
					{localize("workflow.create")}
				</Typography>
				<IconButton onClick={onClose} style={{ padding: "0px" }}>
					<CloseIcon color="secondary" aria-label="Close Button" />
				</IconButton>
			</DialogTitle>
			<DialogContent>
				<Form
					schema={WorkflowCreateDefinition}
					sources={{ workflows: definitionList }}
					autoSubmit={true}
					onSubmit={data => {
						if (data.definitions) {
							const foundDefinition = sortedDefinitions.find(definition => definition._id === data.definitions);
							if (foundDefinition) setSelectedWorkflow(foundDefinition);
						}
					}}
				/>
				{selectedWorkflow && (
					<Form
						schema={creationFormSchema}
						onSubmit={onSubmit}
						onCancel={onCancel}
						sources={formSources}
						cancelText={localize("common.cancel")}
					/>
				)}
			</DialogContent>
		</Dialog>
	);
};

const createKeyValuePeople = list => {
	return list.map(person => ({ label: `${person.fname} ${person.lname}`, value: person._id }));
};

const createKeyValueGroup = list => {
	return list.map(group => ({ label: group.name, value: group._id }));
};
