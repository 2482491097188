
/* © 2017 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, January 01, 2017
 * For information or permission request, email info@aetonixsystems.com
 */

var par = require("par");

var defineUI = require("../shared/define");
var render = require("./render.jsx");

var PersonStore = require("../stores/person_store");
var ReminderHistoryStore = require("../stores/reminder_history_pending_store");
var ReminderDataStore = require("../stores/reminder_data_store");
var LocalizationStore = require("../stores/localization_store");
var CurrentPersonInfoStore = require("../stores/current_person_info_store");
var CareplanNotificationStore = require("../stores/careplan_notice_store");
var ConnectionStore = require("../stores/connection_store");

var makeStores = require("../shared/stores")({
	people: PersonStore,
	history: ReminderHistoryStore,
	reminders: ReminderDataStore,
	currentPerson: CurrentPersonInfoStore,
	localization: LocalizationStore,
	careplanChangeList: CareplanNotificationStore,
	connection: ConnectionStore
});

function make_ui(api, events, config, userMetrics) {
	var storeStore = makeStores(api, events, config);

	window.stores = storeStore.store;

	userMetrics.trackEvent("page view", {
		"page": "reminders-live",
		"category": "monitor",
		"permission level": "staff",
	});

	return defineUI({
		getInitialState: getInitialState,
		componentDidMount: listen_for_changes,
		render: render,
		componentWillUnmount: removeListeners
	});

	function removeListeners() {
		events.off();
	}

	function listen_for_changes() {
		storeStore.listen(par(handle_change, this));
	}

	function handle_change(component, stores) {
		component.setState({
			people: stores.people,
			reminders: stores.reminders,
			history: stores.history.sorted(),
			currentPerson: stores.currentPerson,
			localization: stores.localization,
			careplanChangeList: stores.careplanChangeList,
			connection: stores.connection
		});
	}

	function getInitialState() {
		return {
			people: storeStore.stores.people,
			currentPerson: storeStore.stores.currentPerson,
			reminders: storeStore.stores.reminders,
			history: storeStore.stores.history.sorted(),
			localization: storeStore.stores.localization,
			careplanChangeList: storeStore.stores.careplanChangeList,
			connection: storeStore.stores.connection,
			more: storeStore.stores.history.more,
			userMetrics: userMetrics
		};
	}
}

module.exports = make_ui;
