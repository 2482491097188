
/* © 2017 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, January 01, 2017
 * For information or permission request, email info@aetonixsystems.com
 */

"use strict";

var values = require("object-values");
var frameDebounce = require("frame-debounce");
var par = require("par");
module.exports = makeStoreFactory;

function makeStoreFactory(storeList) {
	return function (api, events, config) {
		var stores = buildStores(storeList, api, events, config);
		window.$app = stores;

		return {
			stores: stores,
			listen: buildListen(stores)
		};
	};
}

function buildListen(stores) {
	return function listen(fn) {
		var update = frameDebounce(par(fn, stores));

		values(stores).forEach(function (store) {
			store.on("change", update);
		});
	};
}

function buildStores(storeList, api, events, config) {
	return Object.keys(storeList).reduce(function (stores, name) {
		stores[name] = storeList[name](api, events, config);
		return stores;
	}, {});
}
