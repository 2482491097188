/* © 2017 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, January 01, 2017
 * For information or permission request, email info@aetonixsystems.com
 */

var par = require("par");

var defineUI = require("../shared/define");
var render = require("./render.jsx");

// Boilerplate required stores
var PersonStore = require("../stores/person_store");
var LocalizationStore = require("../stores/localization_store");
var CareplanNotificationStore = require("../stores/careplan_notice_store");
var ConnectionStore = require("../stores/connection_store");

var WeightHistoryStore = require("../stores/health_weight_store");

const CustomIndicatorHistoryStore = require("../stores/health_custom_indicator_store");
const OrgCustomIndicators = require("../stores/org_custom_indicators_store");
var CurrentPersonInfoStore = require("../stores/current_person_info_store");

var makeStores = require("../shared/stores")({
	people: PersonStore,
	localization: LocalizationStore,
	weighthistoryStore: WeightHistoryStore,
	currentPerson: CurrentPersonInfoStore,
	careplanChangeList: CareplanNotificationStore,
	connection: ConnectionStore,
	customIndicatorStore: CustomIndicatorHistoryStore,
	orgIndicators: OrgCustomIndicators
});

function make_ui(api, events, config, userMetrics) {
	var storeStore = makeStores(api, events, config);

	window.stores = storeStore.stores;

	const url = new URL(window.location);
	const path = url.pathname;

	const indicatorId = path.split("/")?.[2]?.replace(/\/$/, "") ?? null;
	const customStoreWithIndicator = storeStore.stores.customIndicatorStore.indicator(indicatorId);

	userMetrics.trackEvent("page view", {
		"page": "user-custom-indicator-history",
		"category": "customindicator",
		"indicator": indicatorId
	});

	return defineUI({
		getInitialState: getInitialState,
		componentDidMount: listen_for_changes,
		render: render,
		componentWillUnmount: removeListeners
	});

	function removeListeners() {
		events.off();
	}

	function listen_for_changes() {
		storeStore.listen(par(handle_change, this));
	}

	function handle_change(component, stores) {
		component.setState({
			people: stores.people,
			localization: stores.localization,
			currentPerson: stores.currentPerson,
			customIndicators: customStoreWithIndicator.sorted(),
			careplanChangeList: stores.careplanChangeList,
			connection: stores.connection,
			orgIndicators: storeStore.stores.orgIndicators
		});
	}

	function getInitialState() {
		customStoreWithIndicator.reset();

		return {
			people: storeStore.stores.people,
			localization: storeStore.stores.localization,
			currentPerson: storeStore.stores.currentPerson,
			customIndicators: customStoreWithIndicator.sorted(),
			orgIndicators: storeStore.stores.orgIndicators,
			more: more,
			indicatorId,
			filterUser: filterUser,
			filterDate: filterDate,
			filterClear: filterClear,
			closeSearch: par(closeSearch, this),
			search: searchName,
			report: false,
			userFilter: false,
			dateFilter: false,
			query: [],
			startDate: "",
			endDate: new Date(),
			fileName: "",
			careplanChangeList: storeStore.stores.careplanChangeList,
			connection: storeStore.stores.connection,
			userMetrics: userMetrics,
		};
	}

	function more() {
		customStoreWithIndicator.more();
	}

	/**
	 * Returns a list of the groups users
	 * @param  {objectId} query User_id
	 * @return {array}       User _id's
	 */
	function searchName(query) {
		return api.org.users.list(query);
	}

	function filterUser(component, data) {
		var query = component.state.query;
		component.setState({
			query: query.concat(data)
		});
		customStoreWithIndicator.searchQuery(data);
	}

	function filterDate(start, end) {
		customStoreWithIndicator.searchDate(start, end);
	}

	function filterClear(name) {
		customStoreWithIndicator.clearSearch(name);
	}

	function closeSearch(component) {
		component.setState({
			userFilter: false
		});
	}
}

module.exports = make_ui;
