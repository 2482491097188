/* © 2021 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, August 17, 2021
 * For information or permission request, email info@aetonixsystems.com
 */

import React from "react";
import ChartComponent from "react-chartjs-2";
import { saveAs } from "file-saver";
import { convertData } from "./library";

const Divider = require("@material-ui/core/Divider").default;
var Avatar = require("@material-ui/core/Avatar").default;
var FontIcon = require("@material-ui/core/Icon").default;
const Chip = require("@material-ui/core/Chip").default;

const DEFAULT_WIDTH = 800;
const DEFAULT_HEIGHT = 400;
const DEFAULT_OPTIONS = {
	responsive: false,
	maintainAspectRatio: true,
	scales: {
		yAxes: [
			{
				ticks: {
					beginAtZero: true
				}
			}
		]
	}
};

const styles = {
	chipAvatarPrimary: {
		backgroundColor: "#BCBCBC"
	},
};

module.exports = function CustomChart(props) {
	var { data, externals, language, dateRange, schema, localization } = props;

	if (!data || !externals || !language || !schema || !schema.chartsToRender)
		return null;

	return schema.chartsToRender?.map((chartToRender, i) => {
		var chartID = `chart_${i+1}`;
		return (
			<>
				<Divider />
				<div className="flex-horizontal chip-padding">
					<Chip
						className="ae-icon ae-fonticon"
						onClick={() => {
							document.getElementById(chartID).toBlob((blob) => {
								saveAs(blob, chartID.concat(".png"))
							});
						}}
						avatar={
							<Avatar style={styles.chipAvatarPrimary}>
								<FontIcon className={"fa fa-download fa-2x"} style={styles.chipIcon} />
							</Avatar>
						}
						label={localization.get("custom_chart_download_image")}
					/>
				</div>
				<ChartComponent
					id={chartID}
					alt={localization.get("custom_chart_display")}
					aria-label={localization.get("custom_chart_display")}
					role="img"
					title="Chart"
					data={convertData(chartToRender, dateRange || {}, data, schema, externals, language)}
					width={chartToRender.width || DEFAULT_WIDTH}
					height={chartToRender.height || DEFAULT_HEIGHT}
					options={chartToRender.renderOptions || DEFAULT_OPTIONS}
				/>
			</>
		);
	});
};
