import React from "react";
import { OwnerOngoingWorkflowsProvider } from "@aetonix/hooks";
import makeAppComponent from "../shared/AppComponent";

const UserOverview = makeAppComponent(require("../user-overview/ui.jsx"));

export const RootComponent = (props) => {
	const id = props.match.params.id;
	return (
		<OwnerOngoingWorkflowsProvider owner={id}>
			<UserOverview {...props} />
		</OwnerOngoingWorkflowsProvider>
	);
};