/* © 2014 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, June 19, 2014
 * For information or permission request, email info@aetonixsystems.com
 */

var React = require("react");
var par = require("par");
var prop = require("prop");
var traverse = require("traverse");

var config = require("../../configs/config.json");

var Header = require("../shared/Header.js");
var Avatar = require("@material-ui/1.5.1/Avatar").default;
var List = require("@material-ui/1.5.1/List").default;
var ListItem = require("@material-ui/1.5.1/ListItem").default;
var ListItemText = require("@material-ui/1.5.1/ListItemText").default;
var Dialog = require("../shared/Dialog");
var Button = require("@material-ui/1.5.1/Button").default;
var SubHeader = require("@material-ui/1.5.1/ListSubheader").default;
var TextField = require("@material-ui/1.5.1/TextField").default;
var Paper = require("@material-ui/1.5.1/Paper").default;
var Divider = require("@material-ui/1.5.1/Divider").default;

var Utility = require("../shared/Utility");
var LazyList = require("../shared/LazyList.jsx");
var Colors = require("../shared/AetonixTheme").palette;

var CLEAN = /\d+/g;
var CHUNK = /\/[a-zA-Z]+\/\d+/g;
var VALUE_IN = /\/\d+/g;
var VALUE_OUT = /\//g;
var PATH_IN = /\/[a-zA-Z]+\//g;
var PATH_OUT = /\//g;

var styles = {
	search: {
		color: Colors.primary.main,
		minWidth: "50%",
		padding: 8
	},
	inputProp: {
		style: {
			color: Colors.primary.main,
		}
	},
	card: {
		flexDirection: "column",
		color: Colors.primary.main
	},
	fullWidth: {
		width: "100%"
	}
};

module.exports = render;

function render() {
	var component = this;
	var state = component.state;

	var users = state.users;
	var localization = state.localization;
	var currentPerson = state.currentPerson;
	var people = state.people;
	var loadMore = state.loadMore;
	var careplanNoticeList = state.careplanChangeList.all();
	var careplanChanges = !!careplanNoticeList.length;
	var offline = state.connection.get("offline");
	var toRemove = state.toRemove;
	var titleKey = "careplan_change_title";
	var changes = [];
	var careplan = {};
	var viewing = state.viewing;

	var sortedUsersList = careplanNoticeList.filter(function (id) {
		return users.indexOf(id) !== -1;
	}).map(people.get).sort(by_name);

	if (viewing) {
		changes = state.changes.get(viewing);
		careplan = state.careplan.get(viewing);
	}

	var renderUserListItem = par(renderUser, component);

	var remove_actions = [(
		<Button key={"buttonokd"} onClick={par(confirmCommitted, component, viewing, toRemove)}>{localization.get("careplan_commit_ok")}</Button >
	), (
		<Button key={"buttoncancel"} onClick={par(hideRemove, component)}>{localization.get("careplan_commit_cancel")}</Button >
	)];

	return (
		<div className="flex-vertical flex-1">
			<Header
				careplanChanges={careplanChanges}
				offline={offline}
				currentPerson={currentPerson}
				localization={localization}
				titleKey={titleKey}
			/>

			<div className="flex-horizontal flex-1">
				<div className="ae-scrollable flex-vertical flex-1 ae-left-margin">
					<SubHeader>
						{localization.get("user_manage_users")}
					</SubHeader>
					<div className="flex-horizontal">
						<TextField placeholder={localization.get("search_onNameorpatientNum")} inputProps={{"aria-label": localization.get("search_onNameorpatientNum")}} onChange={par(handleSearch, component)} style={styles.search} InputProps={styles.inputProp} />
					</div>
					<LazyList loadMore={loadMore} renderItem={renderUserListItem} items={sortedUsersList} />
				</div>

				<div className="flex-vertical flex-1">
					<div className="flex-vertical flex-1">
						<SubHeader className="flex-horizontal">
							<div className="flex-1">
								{localization.get("careplan_change_changesTitle")}
							</div>
							{renderPatientNumber(component, careplan)}
						</SubHeader>
						{renderLazyList(component, changes)}
					</div>
				</div>
			</div>

			<Dialog actions={remove_actions} open={!!toRemove} title={localization.get("care_plan_commit")} >
				{localization.get("careplan_commit_warning")}
				{toRemove}
				?
			</Dialog>

		</div>
	);
}

function renderPatientNumber(component, careplan) {
	if (Object.keys(careplan).length === 0) return;
	var localization = component.state.localization;
	var patient = careplan.patientNumber;
	return (
		<div className="flex-1 ae-bold" >
			{localization.get("careplan_clientNumberTitle")}: {patient}
		</div>
	);
}

function renderLazyList(component, changes) {
	var show = Object.keys(changes).map(function (key) {
		return key;
	});
	var renderChangeListItem = par(renderField, component);

	return (
		<LazyList loadMore={noop} renderItem={renderChangeListItem} items={show} />
	);
}

function by_name(x, y) {
	var X = x.fname.toLowerCase();
	var Y = y.fname.toLowerCase();
	if (X === Y) {
		X = x.lname.toLowerCase();
		Y = y.lname.toLowerCase();
	}
	return order(X, Y);
}

function order(X, Y) {
	if (X < Y)
		return -1;
	if (X > Y)
		return 1;
	return 0;
}

function renderField(component, change) {
	var state = component.state;
	var localization = state.localization;
	var viewing = state.viewing;
	var detail = state.changes.get(viewing)[change];
	var committed = localization.get("careplan_change_committed");
	var section = localization.get("sections_careplan_" + change);

	return (
		<Paper key={change} zDepth={1} className={"ae-user-select"}>
			<div className="ae-sectionName">
				<div className="ae-bold">{section}</div>
				<Button
					onClick={par(proposeCommitted, component, change)}
				>{committed}</Button>
			</div>
			{renderChangeDetail(component, change, detail)}
		</Paper>
	);
}

function renderChangeDetail(component, section, change) {
	var action = change.map(function (item) {
		return item.changes;
	});
	var direction = action.map(function (item) {
		return item.map(par(get_change_details, component, section));
	});

	return (
		<div>
			<List>
				{direction}
			</List>
		</div>
	);
}

function get_change_details(component, section, item) {
	var localization = component.state.localization;
	var operation = localization.get("careplan_change_" + item.op);
	var sectionDetail = get_current_data(component, section, item.path);

	var clarity = clarify(localization, item.path);

	if (item.op === "replace") return renderReplace(localization, operation, item, sectionDetail, clarity);
	if (item.op === "add") return renderAdd(localization, operation, item, clarity);
	if (item.op === "remove") return renderRemove(localization, operation, item, sectionDetail, clarity);
}

function clarify(localization, rawpath) {
	var raw = rawpath;
	var clean = raw.replace(CLEAN, function (match) {
		var matched = parseInt(match, 10);
		return matched + 1;
	});

	var chunk = clean.match(CHUNK);
	if (!chunk) return;
	var tags = chunk.map(function (item) {
		var value = item.match(VALUE_IN)[0].replace(VALUE_OUT, "");
		var path = item.match(PATH_IN)[0].replace(PATH_OUT, "");
		return {
			"value": value,
			"path": path
		};
	});

	var translate = tags.reduce(function (acc, curr, index) {
		if (!index)
			return acc = acc.concat(curr);
		var newPath = acc[index - 1].path + "/" + curr.path;
		var newValue = curr.value;
		return acc.concat({
			"value": newValue,
			"path": newPath
		});
	}, []);

	var statement = translate.reduce(function (acc, curr) {
		var key = localization.get("careplan_changes")["/" + curr.path];
		var piece = localization.get(key) + ": " + curr.value + "  ";
		return acc = acc + piece;
	}, [""]);

	return (
		<Paper className="ae-clarify" zDepth={1} style={styles.fullWidth}>{statement}</Paper>
	);
}

function renderAdd(localization, operation, item, clarity) {
	return (
		<div>
			<ListItem button style={styles.card}>
				{clarity}
				<div className="ae-direction ae-bold">
					{operation}
				</div>

				<div className="ae-direction">
					{get_add_detail(localization, item)}
				</div>
			</ListItem>
			<Divider />
		</div>
	);
}

function renderRemove(localization, operation, item, sectionDetail, clarity) {
	var value = "";
	if (typeof sectionDetail.value === "string") value = sectionDetail.value;
	if (typeof sectionDetail.value === "object") value = sectionDetail.value["Name"];

	var renderRemoveDetail = (
		<span>{value}</span>
	);

	return (
		<div>
			<ListItem button style={styles.card}>
				{clarity}
				<div className="ae-direction ae-bold">
					{operation}
				</div>
				<div className="ae-direction">
					<span>{sectionDetail.part}:</span>
					{renderRemoveDetail}
				</div>
			</ListItem>
			<Divider />
		</div>
	);
}

function get_add_detail(localization, item) {
	var value = item.value;
	var path = item.path;

	var leaves = traverse(value).reduce(function (acc) {
		if (this.isLeaf)
			acc.push({
				x: acc_clean(this.path),
				y: this.node
			});
		return acc;
	}, []);

	return leaves.map(function (leaf) {
		var localized_path = derive_key(localization, path, leaf.x);
		var leaf_value = leaf.y;
		if (typeof (leaf_value) !== "string") {
			leaf_value = "";
		}
		return (
			<div className="ae-direction">
				<span>{localized_path}: {leaf_value}</span>
			</div>
		);
	});
}

function acc_clean(leaves) {
	return leaves.reduce(function (acc, curr) {
		return (acc + "/" + curr).replace(/\/([0-9]|-)/g, "");
	}, []);
}

function derive_key(localization, path, x) {
	var combine = path + x;
	var cleaned = combine.replace(/\/([0-9]|-)/g, "");
	var key_root = localization.get("careplan_changes")[cleaned];
	var derived_key = localization.get(key_root);
	return derived_key;
}

function renderReplace(localization, operation, item, sectionDetail, clarity) {
	return (
		<div>
			<ListItem button style={styles.card}>
				{clarity}
				<div className="ae-direction ae-bold">
					{operation}
				</div>
				<div className="ae-direction">
					<span>{sectionDetail.part}: {sectionDetail.value}</span>
				</div>
				<div className="ae-direction ae-bold">{localization.get("careplan_change_with")}</div>
				<div className="ae-direction">
					{get_add_detail(localization, item)}
				</div>
			</ListItem>
			<Divider />
		</div>
	);
}

function get_current_data(component, section, path) {
	var state = component.state;
	var viewing = state.viewing;
	var localization = state.localization;
	var careplan = state.careplan.get(viewing).data; // careplan data
	if (!careplan) return;
	var indice = (section + path).replace(/\//g, ".");
	var part = derive_key(localization, "", path);
	var value = prop(indice)(careplan);

	return ({
		part: part,
		value: value
	});
}

function renderUser(component, user) {
	var avatar = <Avatar src={config.image_cdn + user.image} />;
	var text = Utility.format_name(user);
	var userId = user._id;
	var background_colour = "ae-plain";
	var viewing = component.state.viewing;
	if (userId === viewing) background_colour = "ae-hover-color";

	return (
		<ListItem key={userId} className={background_colour} onClick={par(viewChangesFor, component, userId)}>
			{avatar}
			<ListItemText primary={text} />
		</ListItem>
	);
}

function viewChangesFor(component, user) {
	component.setState({
		viewing: user,
	});
	component.state.changes.get(user);
	component.state.userMetrics.trackEvent("group-careplans: careplan changes viewed", {
		"patient": user
	});
}

function noop() { }

function proposeCommitted(component, change, event) {
	event.stopPropagation();

	component.setState({
		toRemove: change,
	});
	component.state.userMetrics.trackEvent("group-careplans: open careplan commit popup", {
		"section": change,
		"patient": component.state.viewing
	});
}

function confirmCommitted(component, user, section) {
	var state = component.state;
	var existing = state.changes.get(user)[section];
	var change = existing.map(function (item) {
		return item._id;
	});
	state.sectionCommitted(user, section, change);
	var committed_sections = component.state.committed_sections;
	committed_sections.push(section);
	component.setState({
		committed_sections: committed_sections
	});
	hideRemove(component);
	component.state.userMetrics.trackEvent("group-careplans: careplan committed", {
		"section": section,
		"patient": user
	});
}

function hideRemove(component) {
	component.state.userMetrics.trackEvent("group-careplans: close careplan commit popup");
	component.setState({
		toRemove: null
	});
}

function handleSearch(component, e) {
	e.preventDefault();
	var state = component.state;
	var searchExist = state.searchExist;
	var query = e.target.value;

	searchExist(query);
}
