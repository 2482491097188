/* © 2014 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, June 19, 2014
 * For information or permission request, email info@aetonixsystems.com
 */

var Store = require("./store");
var map = require("fj-map");
var compose = require("fj-compose");
var pluck = require("fj-pluck");
var getWho = pluck("who");
const { getLocalStorageArray } = require("../shared/Utility.js");
const isEmpty = require ('lodash/isEmpty');
module.exports = UserStore;

function UserStore(api, events, config) {
	var store = new Store();

	var page = 0;
	var loading = null;
	var atEnd = false;

	var userLocalStorageKey = localStorage.getItem("userLocalStorageKey");
	store.activity = getActivity(getLocalStorageArray(`${userLocalStorageKey}stateFilter`));
	store.manageGroups = getLocalStorageArray(`${userLocalStorageKey}manageGroups`);
	store.more = more;
	store.reset = reset;
	store.trackUser = trackUser;
	store.search = search;
	store.sort = sort;
	store.changeActivity = changeActivity;
	store.changeManageGroups = changeManageGroups;
	store.query = "";
	store.sortQuery = "";
	store.status = null;

	reset();

	return store;

	function trackUser(user){
		add_person(user);
	}

	function reset() {
		page = 0;
		atEnd = false;
		store.clear();

		const filters = {
			query: store.query,
			sortQuery: store.sortQuery,
			state: store.activity,
			manageGroups: store.manageGroups,
			status: store.status,
		};
		return api.org.users
			.listByCurrentStatus(filters, page)
			.then(map(add_person))
			.then(listen_for_changes);
	}

	function more() {
		if (atEnd)
			return Promise.resolve();
		if (loading)
			return loading;
		page += 1;
		const currState = getLocalStorageArray(`${userLocalStorageKey}stateFilter`);
		const hasActive = getLocalStorageArray(`${userLocalStorageKey}stateFilter`).includes("all_active");
		const hasInActive = getLocalStorageArray(`${userLocalStorageKey}stateFilter`).includes("inactive");
		let filters;
		if(!isEmpty(currState) && (hasActive && !hasInActive)){
		 filters = {
				query: store.query,
				sortQuery: store.sortQuery,
				state: store.activity,
				manageGroups: store.manageGroups,
				status: false,
			};
		} else {
			filters = {
				query: store.query,
				sortQuery: store.sortQuery,
				state: store.activity,
				manageGroups: store.manageGroups,
				status: store.status,
			};
		}
		return api.org.users
			.listByCurrentStatus(filters, page)
			.then(handle_users);
	}

	function handle_users(users) {
		if (!users.length) {
			atEnd = true;
			return;
		}

		users.map(add_person);

		loading = null;
	}

	function search(newQuery) {
		store.query = newQuery;
		return reset();
	}

	function sort(newQuery) {
		store.sortQuery = newQuery;
		return reset();
	}

	function changeActivity(newActivity) {
		store.activity = getActivity(newActivity);
		return reset();
	}

	function changeManageGroups(newManageGroups) {
		store.manageGroups = newManageGroups;
		return reset();
	}

	function getActivity(activity){
		activity = [activity].flat();
		store.status = null;
		const hasAllActive = activity.includes("all_active");
		const hasInActive = activity.includes("inactive");
		if (hasAllActive) {
			activity = [];
			if (!hasInActive) {
				// if "all_active" is selected but "inactive" is not, then all users with
				// inactive=false should be listed
				store.status = false;
			}
		}
		return activity;
	}

	function add_person(id) {
		store.set(id, id);
	}

	function remove_person(id) {
		store.remove(id);
	}

	function listen_for_changes() {
		var orgGroup = config.orGroup;
		if(!orgGroup) return;
		events.on("mqtt:orggroup/" + orgGroup + "/users/added", compose(add_person, getWho));
		events.on("mqtt:orggroup/" + orgGroup + "/users/removed", compose(remove_person, getWho));
	}
}
