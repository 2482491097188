/* © 2014 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, June 19, 2014
 * For information or permission request, email info@aetonixsystems.com
 */

"use strict";

/**
 * This store derives its data from api calls to /org.  It contains
 * data that is a list of administrators of a group.
 */

var Store = require("./store");
var map = require("fj-map");
var par = require("par");
var Promise = require("promise");

module.exports = OrgGroupStore;

function OrgGroupStore(api, events, config) {
	var store = new Store();

	// api.org.group.list().then(map(add_new_group));
	api.org.id().then(listen_for_additions);

	var query = "";
	var page = 0;
	var loading = null;
	var atEnd = false;

	store.more = more;
	store.search = search;

	reset();

	return store;

	function reset() {
		page = 0;
		atEnd = false;
		store.clear();
		return api.org.group.list(query, page)
			.then(map(add_new_group));
	}

	function more() {
		if (atEnd)
			return Promise.resolve();
		if (loading)
			return loading;
		page += 1;
		return api.org.group.list(query, page).then(handle_group);
	}

	function handle_group(group) {
		if (!group.length) {
			atEnd = true;
			return;
		}

		group.map(add_new_group);

		loading = null;
	}

	function search(newQuery) {
		query = newQuery;
		return reset();
	}

	function add_new_group(group) {
		var id = group._id;
		store.set(id, group);
		listen_for_changes(group);
	}

	function listen_for_additions(org) {
		events.on("mqtt:org/" + org + "/groups/added", add_new_group);
	}

	function listen_for_changes(group) {
		var id = group._id;
		events.on("mqtt:orggroup/" + id + "/updated", par(update_group, id));
		events.on("mqtt:orggroup/" + id + "/administrator/added", par(add_administrator, id));
		events.on("mqtt:orggroup/" + id + "/administrator/removed", par(remove_administrator, id));
	}

	function update_group(id, data) {
		store.update(id, data);
	}

	function add_administrator(id, data) {
		store.update(id, {
			administrator: data.who
		});
	}

	function remove_administrator(id) {
		store.update(id, {
			administrator: null
		});
	}
}
