import React from "react";
import CreateReactClass from "create-react-class";
import ReactPropTypes from "prop-types";
import AetonixTheme from "./AetonixTheme.js";
import Dialog from "@material-ui/1.5.1/Dialog";
import DialogActions from "@material-ui/1.5.1/DialogActions";
import DialogContent from "@material-ui/1.5.1/DialogContent";
import DialogTitle from "@material-ui/1.5.1/DialogTitle";

module.exports = CreateReactClass({
	displayName: "Dialog",
	childContextTypes: {
		muiTheme: ReactPropTypes.object
	},
	getChildContext: getChildContext,
	getInitialState: getInitialState,

	render: render
});

function getChildContext() {
	return {
		muiTheme: AetonixTheme
	};
}

function getInitialState() {
	return {
		open: false
	};
}

function render() {
	var props = this.props;
	var title = props.title || "";
	var open = props.open || false;
	var actions = props.actions;

	return (
		<Dialog
			key={props.key}
			open={open}
			maxWidth="md"
			inputProps={{
				"aria-hidden": "true",
			}}
			fullWidth
			tabIndex='0'
			scroll={props.scroll}
			onClose={props.onClose}
			disableEnforceFocus
		>
			<DialogTitle>{title}</DialogTitle>
			<DialogContent tabIndex="0" >{props.children}</DialogContent>
			<DialogActions>{actions}</DialogActions>
		</Dialog>
	);
}
