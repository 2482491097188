"use strict";

var Store = require("./store");
var par = require("par");
var map = require("fj-map");
var xtend = require("xtend");

import * as Sentry from '@sentry/react';

module.exports = GroupManageGroups;

function GroupManageGroups(api, events, config) {
	var store = new Store();

	refresh();

	return store;

	function refresh(){
		return api.org.managergroups.list()
			.then(map(setGroup))
			.then(listenCreated)
			.catch(error => {
				Sentry.captureException(error);
				console.error(error);
			});
	}

	function listenCreated(){
		const orgGroup = config.orgGroup;
		if(!orgGroup) return;
		events.on(`mqtt:orggroup/${orgGroup}/managegroup/created`, setGroup);
	}

	function setGroup(group){
		store.set(group._id, group);
		listenGroup(group);
	}

	function listenGroup(group){
		const orgGroup = config.orgGroup;
		if(!orgGroup) return;
		const groupId = group._id;
		events.on(`mqtt:orggroup/${orgGroup}/managegroup/${groupId}/removed`, par(handle_removed, groupId));
		events.on(`mqtt:orggroup/${orgGroup}/managegroup/${groupId}/updated`, par(handle_updated, groupId));
		events.on(`mqtt:orggroup/${orgGroup}/managegroup/${groupId}/permissions/updated`, par(handle_permissions_update, groupId));
		events.on(`mqtt:orggroup/${orgGroup}/managegroup/${groupId}/locked`, par(handle_locked, groupId));
		events.on(`mqtt:orggroup/${orgGroup}/managegroup/${groupId}/unlocked`, par(handle_unlocked, groupId));
	}

	function handle_locked(groupId){
		const current = store.get(groupId);
		const newData = xtend(current, {
			locked: true
		});
		store.set(groupId, newData);
		refresh();
	}

	function handle_unlocked(groupId){
		const current = store.get(groupId);
		const newData = xtend(current, {
			locked: false
		});
		store.set(groupId, newData);
		refresh();
	}

	function handle_permissions_update(groupId, permissions){
		const current = store.get(groupId);
		const newData = xtend(current, {
			permissions: permissions
		});

		store.set(groupId, newData);
	}

	function handle_removed(groupId){
		store.remove(groupId);
	}

	function handle_updated(groupId, data){
		const current = store.get(groupId);
		const newData = xtend(current, data);
		store.set(groupId, newData);
	}
}
