
/* © 2017 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, January 01, 2017
 * For information or permission request, email info@aetonixsystems.com
 */


var React = require("react");
var par = require("par");

/**
 * User Interface elements material-ui
 */
var Header = require("../shared/Header.js");

var Table = require("@material-ui/1.5.1/Table").default;
var TableHeader = require("@material-ui/1.5.1/TableHead").default;
var TableHeaderColumn = require("@material-ui/1.5.1/TableCell").default;
var TableBody = require("@material-ui/1.5.1/TableBody").default;
var TableRow = require("@material-ui/1.5.1/TableRow").default;
var TableRowColumn = require("@material-ui/1.5.1/TableCell").default;
var Utility = require("../shared/Utility.js");
var Colors = require("../shared/AetonixTheme").palette;

var styles = {
	header: {
		color: Colors.primary.dark,
	}
};

module.exports = render;

function render() {
	var component = this;
	var state = component.state;
	var localization = state.localization;
	var careplanNoticeList = state.careplanChangeList.all();
	var careplanChanges = !!careplanNoticeList.length;
	var offline = state.connection.get("offline");
	var titleKey = "user_fall_live_title";

	var people = state.people;
	var currentPerson = state.currentPerson;
	var rowData = state.falling.map(par(convertData, localization, people));

	var headers = [
		{
			name: "user",
			content: localization.get("user_fall_history_user")
		}, {
			name: "status",
			content: localization.get("user_fall_history_status")
		}, {
			name: "time",
			content: localization.get("user_fall_history_time")
		}, {
			name: "location",
			content: localization.get("user_fall_history_location")
		}
	];

	if (dataHasChanged(state.falling, state.previousFalling)) {
		component.state.userMetrics.trackEvent("user-fall-live: viewed falls", {
			falls: state.falling,
		});
		component.setState({
			previousFalling: state.falling,
		});
	}

	return (
		<div className="flex-vertical flex-1">
			<Header
				careplanChanges={careplanChanges}
				offline={offline}
				currentPerson={currentPerson}
				localization={localization}
				titleKey={titleKey}
			/>
			<div className="flex-1">
				<Table>
					{renderHeader(headers)}
					{renderBody(headers, rowData)}
				</Table>
			</div>

		</div>
	);
}

function dataHasChanged(newData, oldData) {
	newData = newData || [];
	oldData = oldData || [];
	if (newData.length !== oldData.length) return true;
	for (var i = 0; i < newData.length; i++) {
		if (newData[i]._id !== oldData[i]._id) return true;
	}
	return false;
}

function renderHeader(headers) {
	return (
		<TableHeader>
			<TableRow style={styles.header}>
				{headers.map(renderHeaderColumn)}
			</TableRow>
		</TableHeader>
	);
}

function renderHeaderColumn(column, index) {
	return (
		<TableHeaderColumn key={column + index}>{column.content}</TableHeaderColumn>
	);
}

function renderBody(headers, rows) {
	return (
		<TableBody>
			{rows.map(par(renderRow, headers))}
		</TableBody>
	);
}

function renderRow(headers, row, index) {
	return (
		<TableRow key={index}>
			{headers.map(par(renderRowColumn, row))}
		</TableRow>
	);
}

function renderRowColumn(row, column, index) {
	return (
		<TableRowColumn key={row + column + index}>
			{row[column.name]}
		</TableRowColumn>
	);
}

function convertData(localization, people, record) {
	var bracelet_user = Utility.format_name(people.get(record.who));
	var time_of_event = Utility.timestamp(record.time);
	var status = record.status;
	var machine_user = record.location;
	if (!machine_user)	machine_user = localization.get("status_unknown");
	if (status === "fall") status = localization.get("status_fall");

	if (record.location === "") machine_user = "";

	return {
		"user": bracelet_user,
		"status": status,
		"time": time_of_event,
		"location": machine_user
	};
}
