import * as Sentry from '@sentry/react';

const validTypes = ["staff", "org:manager", "admin", "logistics"];

export function handleAuthLocalStorage(config, setAuthed, setInitComplete){
	const token = localStorage.getItem("token", token);
	const region = localStorage.getItem("region") || "Canada";

	const hosts = config.hosts || [];
	const foundHost = hosts.find(host => host.name === region) || {};
	const hostAPI = foundHost.host;

	const host = config.host || hostAPI;
	const api = require("ae-api")({host});

	if(token){
		auth();
	} else {
		setInitComplete(true);
	}

	async function auth(){
		try {
			const response = await api.auth.auth(token);
			if(hasValidTypes(response)){
				setAuthed(true);
			} else {
				localStorage.setItem("token", "");
				localStorage.setItem("region", "");
			}
			setInitComplete(true);
		} catch (error){
			if(error.status !== 401)
				Sentry.captureException(error);

			setInitComplete(true);
			console.log(error);
		}
	}
}

function hasValidTypes(person) {
	var types = person.type;
	return validTypes.some(type => types.includes(type));
}

