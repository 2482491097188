
/* © 2017 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, May 3, 2018
 * For information or permission request, email info@aetonixsystems.com
 */

require("./admin-stats.css");
var React = require("react");
var par = require("par");

var Header = require("../shared/Header.js");
var SubHeader = require("@material-ui/1.5.1/ListSubheader").default;
var TextField = require("@material-ui/1.5.1/TextField").default;
var ListItem = require("@material-ui/1.5.1/ListItem").default;
var ListItemText = require("@material-ui/1.5.1/ListItemText").default;
var Button = require("@material-ui/1.5.1/Button").default;

var Table = require("@material-ui/1.5.1/Table").default;
var TableHeader = require("@material-ui/1.5.1/TableHead").default;
var TableHeaderColumn = require("@material-ui/1.5.1/TableCell").default;
var TableBody = require("@material-ui/1.5.1/TableBody").default;
var TableRow = require("@material-ui/1.5.1/TableRow").default;
var TableRowColumn = require("@material-ui/1.5.1/TableCell").default;
var TableSortLabel = require("@material-ui/1.5.1/TableSortLabel").default;

var Paper = require("@material-ui/1.5.1/Paper").default;

var LazyList = require("../shared/LazyList.jsx");

var styles = {};

module.exports = render;

function render() {
	var component = this;
	var state = component.state;

	var orgs = state.orgs;
	var localization = state.localization;
	var careplanNoticeList = state.careplanChangeList.all();
	var careplanChanges = !!careplanNoticeList.length;
	var offline = state.connection.get("offline");
	var currentPerson = state.currentPerson;

	var sortedOrgs = orgs.sort(by_name);
	var loadMore = state.loadMore;
	var searchOrg = state.searchOrg;

	var fullScreen = state.fullScreen;

	var renderOrgListItem = par(renderOrg, component);

	var content = fullScreen ? (
		<div className="flex-vertical flex-1">
			{renderStats(component)}
		</div>
	) : (
		<div className="flex-vertical flex-1">
			<Header
				careplanChanges={careplanChanges}
				offline={offline}
				currentPerson={currentPerson}
				localization={localization}
				titleKey={"dashboards_admin_stats_title"}
			/>
			<div className="flex-horizontal flex-1 ae-scrollable">
				<div className="flex-vertical flex-1 ae-left-margin ">
					<SubHeader>{localization.get("admin_form_org")}</SubHeader>
					<TextField placeholder={localization.get("admin_workflow_search")} inputProps={{"aria-label": localization.get("admin_workflow_search")}}  onChange={searchOrg} style={styles.search} InputProps={styles.inputProp} />
					<LazyList loadMore={loadMore} renderItem={renderOrgListItem} items={sortedOrgs} />
				</div>
				<div className="flex-vertical flex-2">
					<SubHeader>{localization.get("admin_stats_stats")}</SubHeader>
					<div className="flex-vertical ae-scrollable flex-1">
						{renderStats(component)}
					</div>
				</div>
			</div>
		</div>
	);

	return content;
}

function renderStats(component){
	var viewing = component.state.viewing;
	if(!viewing) return;
	return (
		<div className="flex-vertical">
			{renderOrgStats(component)}
			{renderGroupStats(component)}
		</div>
	);
}

function renderOrgStats(component){
	var viewing = component.state.viewing;
	var orgStats = component.state.orgCount.get(viewing);
	var localization = component.state.localization;
	var orgName = component.state.viewingName || "";
	var fullScreen = component.state.fullScreen;

	var fullScreenButtonText = fullScreen ? localization.get("admin_stats_exit_full_screen") : localization.get("admin_stats_full_screen");

	return (
		<Paper className="flex-vertical org-container ae-align-centre">
			<div className="flex-horizontal org-title-container">
				<div className="ae-title org-title-item">{`${orgName} ${localization.get("admin_stats_org_overview")}`}</div>
				<Button className="org-title-item" onClick={par(toggleFullScreen, component)}>{fullScreenButtonText}</Button>
			</div>
			<div className="flex-horizontal">
				{renderBox(localization.get("admin_stats_groups"), orgStats.groups)}
				{renderBox(localization.get("admin_stats_managers"), orgStats.managers)}
				{renderBox(localization.get("admin_stats_users"), orgStats.users)}
				{renderBox(localization.get("admin_stats_staff"), orgStats.staff)}
				{renderBox(localization.get("admin_stats_patients"), orgStats.patients)}
				{renderBox(localization.get("admin_stats_active"), orgStats.active)}
				{renderBox(localization.get("admin_stats_inactive"), orgStats.inactive)}
			</div>
		</Paper>
	);
}

function renderGroupStats(component){
	var localization = component.state.localization;
	var viewing = component.state.viewing;
	var sorting = component.state.sorting;
	var sortDirection = component.state.sortDirection;
	var groups = component.state.groups.get(viewing);

	var sortedGroups = sortGroups(component, groups);

	if(!groups || !groups.length) return;

	var renderGroupRow = sortedGroups.map(group => renderGroupStat(component, group));

	return (
		<div className="group-container flex-vertical ae-align-centre">
			<div className="ae-title">{localization.get("admin_stats_groups_overview")}</div>
		<Table>
			<TableHeader>
				<TableRow>
					<TableHeaderColumn>
						<TableSortLabel active={sorting === "name"} direction={sortDirection} onClick={par(changeSort, component, "name")}>
							{localization.get("admin_stats_name")}
						</TableSortLabel>
					</TableHeaderColumn>
					<TableHeaderColumn align="right">
						<TableSortLabel active={sorting === "users"} direction={sortDirection} onClick={par(changeSort, component, "users")}>
							{localization.get("admin_stats_users")}
						</TableSortLabel>
					</TableHeaderColumn>
					<TableHeaderColumn align="right">
						<TableSortLabel active={sorting === "staff"} direction={sortDirection} onClick={par(changeSort, component, "staff")}>
							{localization.get("admin_stats_staff")}
						</TableSortLabel>
					</TableHeaderColumn>
					<TableHeaderColumn align="right">
						<TableSortLabel active={sorting === "patients"} direction={sortDirection} onClick={par(changeSort, component, "patients")}>
							{localization.get("admin_stats_patients")}
						</TableSortLabel>
					</TableHeaderColumn>
					<TableHeaderColumn align="right">
						<TableSortLabel active={sorting === "active"} direction={sortDirection} onClick={par(changeSort, component, "active")}>
							{localization.get("admin_stats_active")}
						</TableSortLabel>
					</TableHeaderColumn>
					<TableHeaderColumn align="right">
						<TableSortLabel active={sorting === "inactive"} direction={sortDirection} onClick={par(changeSort, component, "inactive")}>
							{localization.get("admin_stats_inactive")}
						</TableSortLabel>
					</TableHeaderColumn>
				</TableRow>
			</TableHeader>
			<TableBody>
				{renderGroupRow}
			</TableBody>
		</Table>
	</div>
	);
}

function toggleFullScreen(component){
	var fullScreen = component.state.fullScreen;

	component.state.userMetrics.trackEvent("admin-stats: toggle full screen", {
		"full screen": !fullScreen
	});
	component.setState({
		fullScreen: !fullScreen
	});
}

function changeSort(component, name){
	var current = component.state.sorting;
	var sortDirection = component.state.sortDirection;

	if(current !== name)
		sortDirection = "asc";
	else
		sortDirection = sortDirection === "asc" ? "desc" : "asc";

	component.state.userMetrics.trackEvent("admin-stats: change sort", {
		"sort": name,
		"direction": sortDirection
	});
	component.setState({
		sorting: name,
		sortDirection: sortDirection
	});
}

function renderGroupStat(component, group){
	var groupId = group._id;
	var name = group.name;

	var stats = component.state.groupCount.get(groupId);

	return (
		<TableRow>
			<TableRowColumn component="th" scope="row">
				{name}
			</TableRowColumn>
			<TableRowColumn>{stats.users}</TableRowColumn>
			<TableRowColumn>{stats.staff}</TableRowColumn>
			<TableRowColumn>{stats.patients}</TableRowColumn>
			<TableRowColumn>{stats.active}</TableRowColumn>
			<TableRowColumn>{stats.inactive}</TableRowColumn>
		</TableRow>
	);
}

function renderBox(title, count){
	return (
		<div className="flex-1 flex-vertical ae-align-centre box-container">
			<div className="ae-title box-title">{title}</div>
			<div className="box-value">{count}</div>
		</div>
	);
}

function renderOrg(component, org) {
	var name = org.name;
	var description = org.description;
	var orgId = org._id;

	var background_colour = "ae-plain";
	var viewing = component.state.viewing;
	if (viewing && orgId === viewing) background_colour = "ae-hover-color";

	var onClick = par(viewStatsFor, component, orgId, name);

	return (
		<ListItem button key={orgId} className={background_colour} onClick={onClick}>
			<ListItemText primary={name} secondary={description} />
		</ListItem>
	);
}

function viewStatsFor(component, orgId, name){
	component.state.userMetrics.trackEvent("admin-stats: view org stats", {
		"org": orgId,
	});
	component.setState({
		viewing: orgId,
		viewingName: name
	});
}

function sortGroups(component, groups){
	var sorting = component.state.sorting;
	var sortDirection = component.state.sortDirection;

	var sortFunction = par(by_name_group, sortDirection);
	if(sorting !== "name")
		sortFunction = par(by_count, sortDirection, sorting, component);

	return groups.sort(sortFunction);
}

function by_count(direction, sorting, component, x, y){
	var countX = component.state.groupCount.get(x._id);
	var countY = component.state.groupCount.get(y._id);

	var statX = countX[sorting];
	var statY = countY[sorting];

	return direction === "asc" ? statX - statY : statY - statX;
}

function by_name(x, y) {
	if(typeof x.name !== "string")
		x.name = "";

	if(typeof y.name !== "string")
		y.name = "";

	var X = x.name.toLowerCase();
	var Y = y.name.toLowerCase();
	if (X < Y) return -1;
	if (X > Y) return 1;
	return 0;
}

function by_name_group(direction, x, y){
	var X = x.name.toLowerCase();
	var Y = y.name.toLowerCase();
	if (X < Y) return direction === "asc" ? -1 : 1;
	if (X > Y) return direction === "asc" ? 1 : -1;
	return 0;
}
