/* © 2022 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, February 11, 2022
 * For information or permission request, email info@aetonixsystems.com
 */


var par = require("par");

var defineUI = require("../shared/define");
var render = require("./render.jsx");

var LocalizationStore = require("../stores/localization_store");
var CurrentPersonInfoStore = require("../stores/current_person_info_store");
var CareplanNotificationStore = require("../stores/careplan_notice_store");
var ConnectionStore = require("../stores/connection_store");
var ReportsStore = require("../stores/org_reports_store");

var makeStores = require("../shared/stores")({
	currentPerson: CurrentPersonInfoStore,
	localization: LocalizationStore,
	careplanChangeList: CareplanNotificationStore,
	connection: ConnectionStore,
	reports: ReportsStore,
});

module.exports = make_ui;

function make_ui(api, events, config, userMetrics) {
	var storeStore = makeStores(api, events, config);

	window.stores = storeStore.store;

	userMetrics.trackEvent("page view", {
		"page": "org-reports",
		"category": "org",
		"permission level": "org:manager",
	});

	return defineUI({
		getInitialState: getInitialState,
		componentDidMount: listen_for_changes,
		render: render,
		componentWillUnmount: removeListeners
	});

	function removeListeners() {
		events.off();
	}

	function listen_for_changes() {
		storeStore.listen(par(handle_change, this));
	}

	function handle_change(component, stores) {
		component.setState({
			currentPerson: stores.currentPerson,
			localization: stores.localization,
			careplanChangeList: stores.careplanChangeList,
			connection: stores.connection,
			reports: stores.reports,
		});
	}

	function getInitialState() {
		return {
			currentPerson: storeStore.stores.currentPerson,
			localization: storeStore.stores.localization,
			careplanChangeList: storeStore.stores.careplanChangeList,
			connection: storeStore.stores.connection,
			reports: storeStore.stores.reports,
			openFileInfoModal: false,
			viewingReport: {},
			makeDownloadLink: makeDownloadLink,
			bitsToString: bitsToString,
			userMetrics: userMetrics,
		};
	}

	function makeDownloadLink(Key) {
		return api.org.reports.make_download_url(Key);
	}

	function bitsToString(bytes) {
		const thresh = 1024;

		if (Math.abs(bytes) < thresh) {
		  return bytes + " B";
		}

		const units = ["kB", "MB", "GB", "TB"];
		let u = -1;
		const r = 10;
		do {
		  bytes /= thresh;
		  ++u;
		} while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);

		return bytes.toFixed(1) + " " + units[u];
	}
}
